import React from 'react';
import paths from '@/constants/paths';
import Fonts from '@/pages/system/content/tabs/fonts';
// import Images from '@/pages/system/content/tabs/images';
import News from '@/pages/system/content/tabs/news';
import RunningLines from '@/pages/system/content/tabs/runningLines';
import Videos from '@/pages/system/content/tabs/videos';
export default [
    { id: paths.fonts, name: 'Шрифты', component: <Fonts /> },
    { id: paths.videos, name: 'Видео', component: <Videos /> },
    // { id: paths.images, name: 'Изображения', component: <Images /> },
    { id: paths.news, name: 'Новости', component: <News /> },
    {
        id: paths.runningLines,
        name: 'Бегущая строка',
        component: <RunningLines />,
    },
];
