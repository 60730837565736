import React, { useEffect, useState } from 'react';
import Input from '@/components/input/input';
import Select from '@/components/input/select';
import { defaultSelectOptions } from '@/pages/statistics/reports/filters/filterParametrs';
import { FilterStore } from '@/store';
var MarkFilters = function (_a) {
    var handleSetFilters = _a.handleSetFilters;
    var filterParams = FilterStore.useState(function (s) { return s.initialParams; });
    var allOptionState = defaultSelectOptions.find(function (el) { return el.value === filterParams.markcnt.split(' ')[0]; });
    var mark1OptionState = defaultSelectOptions.find(function (el) { return el.value === filterParams.mark1.split(' ')[0]; });
    var mark2OptionState = defaultSelectOptions.find(function (el) { return el.value === filterParams.mark2.split(' ')[0]; });
    var mark3OptionState = defaultSelectOptions.find(function (el) { return el.value === filterParams.mark3.split(' ')[0]; });
    var mark4OptionState = defaultSelectOptions.find(function (el) { return el.value === filterParams.mark4.split(' ')[0]; });
    var mark5OptionState = defaultSelectOptions.find(function (el) { return el.value === filterParams.mark5.split(' ')[0]; });
    var _b = useState({
        value: allOptionState ? allOptionState.value : '',
        name: allOptionState ? allOptionState.name : '',
    }), allOption = _b[0], setAllOption = _b[1];
    var _c = useState(filterParams.markcnt.split(' ')[1]), allValue = _c[0], setAllValue = _c[1];
    var _d = useState({
        value: mark1OptionState ? mark1OptionState.value : '',
        name: mark1OptionState ? mark1OptionState.name : '',
    }), mark1Option = _d[0], setMark1Option = _d[1];
    var _e = useState(filterParams.mark1.split(' ')[1]), mark1Value = _e[0], setMark1Value = _e[1];
    var _f = useState({
        value: mark2OptionState ? mark2OptionState.value : '',
        name: mark2OptionState ? mark2OptionState.name : '',
    }), mark2Option = _f[0], setMark2Option = _f[1];
    var _g = useState(filterParams.mark2.split(' ')[1]), mark2Value = _g[0], setMark2Value = _g[1];
    var _h = useState({
        value: mark3OptionState ? mark3OptionState.value : '',
        name: mark3OptionState ? mark3OptionState.name : '',
    }), mark3Option = _h[0], setMark3Option = _h[1];
    var _j = useState(filterParams.mark3.split(' ')[1]), mark3Value = _j[0], setMark3Value = _j[1];
    var _k = useState({
        value: mark4OptionState ? mark4OptionState.value : '',
        name: mark4OptionState ? mark4OptionState.name : '',
    }), mark4Option = _k[0], setMark4Option = _k[1];
    var _l = useState(filterParams.mark4.split(' ')[1]), mark4Value = _l[0], setMark4Value = _l[1];
    var _m = useState({
        value: mark5OptionState ? mark5OptionState.value : '',
        name: mark5OptionState ? mark5OptionState.name : '',
    }), mark5Option = _m[0], setMark5Option = _m[1];
    var _o = useState(filterParams.mark5.split(' ')[1]), mark5Value = _o[0], setMark5Value = _o[1];
    var handleSelect = function (_a) {
        var option = _a.option, name = _a.name;
        switch (name) {
            case 'markcnt':
                if (option.value === '') {
                    setAllOption({
                        value: '',
                        name: '',
                    });
                    setAllValue(undefined);
                    handleSetFilters(name, 'all');
                    break;
                }
                setAllOption(option);
                break;
            case 'mark1':
                if (option.value === '') {
                    setMark1Option({
                        value: '',
                        name: '',
                    });
                    setMark1Value(undefined);
                    handleSetFilters(name, 'all');
                    break;
                }
                setMark1Option(option);
                break;
            case 'mark2':
                if (option.value === '') {
                    setMark2Option({
                        value: '',
                        name: '',
                    });
                    setMark2Value(undefined);
                    handleSetFilters(name, 'all');
                    break;
                }
                setMark2Option(option);
                break;
            case 'mark3':
                if (option.value === '') {
                    setMark3Option({
                        value: '',
                        name: '',
                    });
                    setMark3Value(undefined);
                    handleSetFilters(name, 'all');
                    break;
                }
                setMark3Option(option);
                break;
            case 'mark4':
                if (option.value === '') {
                    setMark4Option({
                        value: '',
                        name: '',
                    });
                    setMark4Value(undefined);
                    handleSetFilters(name, 'all');
                    break;
                }
                setMark4Option(option);
                break;
            case 'mark5':
                if (option.value === '') {
                    setMark5Option({
                        value: '',
                        name: '',
                    });
                    setMark5Value(undefined);
                    handleSetFilters(name, 'all');
                    break;
                }
                setMark5Option(option);
                break;
        }
    };
    var handleInput = function (e) {
        var _a = e.target, value = _a.value, name = _a.name;
        switch (name) {
            case 'markcnt':
                setAllValue(value);
                handleSetFilters(name, "".concat(allOption.value, " ").concat(value));
                break;
            case 'mark1':
                setMark1Value(value);
                handleSetFilters(name, "".concat(mark1Option.value, " ").concat(value));
                break;
            case 'mark2':
                setMark2Value(value);
                handleSetFilters(name, "".concat(mark2Option.value, " ").concat(value));
                break;
            case 'mark3':
                setMark3Value(value);
                handleSetFilters(name, "".concat(mark3Option.value, " ").concat(value));
                break;
            case 'mark4':
                setMark4Value(value);
                handleSetFilters(name, "".concat(mark4Option.value, " ").concat(value));
                break;
            case 'mark5':
                setMark5Value(value);
                handleSetFilters(name, "".concat(mark5Option.value, " ").concat(value));
                break;
        }
    };
    useEffect(function () {
        if (allValue) {
            handleSetFilters('markcnt', "".concat(allOption.value, " ").concat(allValue));
        }
        if (mark1Value) {
            handleSetFilters('mark1', "".concat(mark1Option.value, " ").concat(mark1Value));
        }
        if (mark2Value) {
            handleSetFilters('mark2', "".concat(mark2Option.value, " ").concat(mark2Value));
        }
        if (mark3Value) {
            handleSetFilters('mark3', "".concat(mark3Option.value, " ").concat(mark3Value));
        }
        if (mark4Value) {
            handleSetFilters('mark4', "".concat(mark4Option.value, " ").concat(mark4Value));
        }
        if (mark5Value) {
            handleSetFilters('mark5', "".concat(mark5Option.value, " ").concat(mark5Value));
        }
    }, [
        allOption,
        mark1Option,
        mark2Option,
        mark3Option,
        mark4Option,
        mark5Option,
    ]);
    return (<div className="report_filters__box">
      <p className="report_filters__title">Оценка</p>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Всего</p>
        <Select width={180} placeholder="Выбрать" name="markcnt" value={allOption.name} onChange={handleSelect} options={defaultSelectOptions}/>
        <Input type="number" width={80} name="markcnt" value={allValue} onChange={handleInput} disabled={allOption.name === ''}/>
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Отлично</p>
        <Select width={180} placeholder="Выбрать" name="mark5" value={mark5Option.name} onChange={handleSelect} options={defaultSelectOptions}/>
        <Input type="number" width={80} name="mark5" value={mark5Value} onChange={handleInput} disabled={mark5Option.name === ''}/>
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Хорошо</p>
        <Select width={180} placeholder="Выбрать" name="mark4" value={mark4Option.name} onChange={handleSelect} options={defaultSelectOptions}/>
        <Input type="number" width={80} name="mark4" value={mark4Value} onChange={handleInput} disabled={mark4Option.name === ''}/>
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Нормально</p>
        <Select width={180} placeholder="Выбрать" name="mark3" value={mark3Option.name} onChange={handleSelect} options={defaultSelectOptions}/>
        <Input type="number" width={80} name="mark3" value={mark3Value} onChange={handleInput} disabled={mark3Option.name === ''}/>
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Плохо</p>
        <Select width={180} placeholder="Выбрать" name="mark2" value={mark2Option.name} onChange={handleSelect} options={defaultSelectOptions}/>
        <Input type="number" width={80} name="mark2" value={mark2Value} onChange={handleInput} disabled={mark2Option.name === ''}/>
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Очень плохо</p>
        <Select width={180} placeholder="Выбрать" name="mark1" value={mark1Option.name} onChange={handleSelect} options={defaultSelectOptions}/>
        <Input type="number" width={80} name="mark1" value={mark1Value} onChange={handleInput} disabled={mark1Option.name === ''}/>
      </div>
    </div>);
};
export default MarkFilters;
