import React from 'react';
import { useLocation } from 'react-router';
var BreadCrumbs = function (_a) {
    var _b, _c;
    var activeNav = _a.activeNav;
    var pathname = useLocation().pathname;
    return (<div className="breadcrumbs">
      {((_b = activeNav === null || activeNav === void 0 ? void 0 : activeNav.subnav) === null || _b === void 0 ? void 0 : _b.length)
            ? "".concat(activeNav === null || activeNav === void 0 ? void 0 : activeNav.name, " | ").concat((_c = activeNav.subnav.find(function (item) { return pathname.includes(item.path); })) === null || _c === void 0 ? void 0 : _c.name)
            : activeNav === null || activeNav === void 0 ? void 0 : activeNav.name}
    </div>);
};
export default BreadCrumbs;
