var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Button from '@/components/button';
import Input from '@/components/input/input';
import Select from '@/components/input/select';
import Loader from '@/components/loader';
import { services, workWeeks } from '@/utils/rest';
var ListEdit = function (_a) {
    var _b, _c;
    var data = _a.data, handleCancel = _a.handleCancel, refetch = _a.refetch, _d = _a.background, background = _d === void 0 ? 'transparent' : _d;
    var queryClient = useQueryClient();
    var _e = useQuery('workWeeks', workWeeks.get), isLoading = _e.isLoading, weeksData = _e.data;
    var _f = useState(__assign(__assign({}, data), { workWeek: {
            value: (_b = data === null || data === void 0 ? void 0 : data.workWeek) === null || _b === void 0 ? void 0 : _b.workWeekId,
            name: (_c = data === null || data === void 0 ? void 0 : data.workWeek) === null || _c === void 0 ? void 0 : _c.workWeekName,
        } })), formData = _f[0], setFormData = _f[1];
    var serviceAdd = useMutation(services.add, {
        onSuccess: function () {
            // TODO: Попросить сделать новый ответ с созданной услугой
            if (refetch) {
                refetch();
            }
        },
    });
    var serviceUpdate = useMutation(services.update, {
        onSuccess: function (data) {
            var services = queryClient.getQueryData('services');
            if (services) {
                var newServices = services.map(function (item) {
                    var _a, _b;
                    if (item.id === data.data.updatedId) {
                        return __assign(__assign(__assign({}, item), formData), { workWeek: {
                                workWeekId: (_a = formData === null || formData === void 0 ? void 0 : formData.workWeek) === null || _a === void 0 ? void 0 : _a.value,
                                workWeekName: (_b = formData === null || formData === void 0 ? void 0 : formData.workWeek) === null || _b === void 0 ? void 0 : _b.name,
                            } });
                    }
                    return item;
                });
                queryClient.setQueryData('services', newServices);
            }
        },
    });
    var prefix = formData.prefix, position = formData.position, name = formData.name, fullname = formData.fullname, workWeek = formData.workWeek, prior = formData.prior;
    var workWeekOptions = useMemo(function () {
        return weeksData === null || weeksData === void 0 ? void 0 : weeksData.map(function (item) { return ({ name: item.name, value: item.id }); });
    }, [weeksData]);
    var handleInput = function (e) {
        var _a = e.target, value = _a.value, name = _a.name;
        setFormData(function (oldData) {
            var _a;
            return (__assign(__assign({}, oldData), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleSelect = function (_a) {
        var option = _a.option, name = _a.name;
        setFormData(function (oldData) {
            var _a;
            return (__assign(__assign({}, oldData), (_a = {}, _a[name] = option, _a)));
        });
    };
    var handleSubmit = function (e) {
        e.preventDefault();
        var payload = {
            data: __assign(__assign({}, formData), { workWeek: { workWeekId: formData.workWeek.value } }),
        };
        if (data) {
            serviceUpdate.mutate({
                id: data.id,
                payload: payload,
            });
        }
        else {
            serviceAdd.mutate({
                payload: payload,
            });
        }
        handleCancel();
    };
    return isLoading ? (<Loader />) : (<div className="editable-item__form">
      {!!data && (<p className="editable-item__title">{'Редактирование услуги'}</p>)}
      <div className={background === 'transparent'
            ? 'editable-item__form-row'
            : 'editable-item__form-row editable-item__form-row_transparent'}>
        <div className="drawerStyle">
          <Input text="Префикс" placeholder="Введите префикс" value={prefix} name="prefix" onChange={handleInput} upperCase={true} width={180}/>
          <Input text="Позиция" type="number" placeholder="Введите позицию" value={position} name="position" onChange={handleInput} width={180}/>
          <Input text="Короткое название" placeholder="Введите название" value={name} name="name" onChange={handleInput} width={360}/>
          <Input text="Полное название" placeholder="Введите название" value={fullname} name="fullname" onChange={handleInput} width={360}/>
        </div>
      </div>
      <div className={background === 'transparent'
            ? 'editable-item__form-row'
            : 'editable-item__form-row editable-item__form-row_transparent'}>
        <div className="drawerStyle">
          <Select value={workWeek.name} options={workWeekOptions} text="Рабочая неделя" placeholder="Выберите из списка" width={300} name="workWeek" onChange={handleSelect}/>
          <Input text="Приоритет" type="number" placeholder="Введите приоритет" value={prior} name="prior" onChange={handleInput} width={180}/>
        </div>
      </div>
      <div className="editable-item__form-btns">
        <Button text="Сохранить" onClick={handleSubmit} type="submit"/>
        {/* {!!data && <Button text="Отменить" onClick={handleCancel} />} */}
      </div>
    </div>);
};
export default ListEdit;
