var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { notification } from 'antd';
import { useMutation } from 'react-query';
import Button from '@/components/button';
import Input from '@/components/input/input';
import Select from '@/components/input/select';
import { statuses } from '@/utils/rest/pages/statuses';
var NewSubstatus = function (_a) {
    var setShowAdd = _a.setShowAdd, refetch = _a.refetch, statusData = _a.statusData;
    var _b = useState({
        id: '',
        parent_status: undefined,
        description: '',
    }), formData = _b[0], setFormData = _b[1];
    var _c = useState(''), selectValue = _c[0], setSelectValue = _c[1];
    var _d = useState(false), error = _d[0], setError = _d[1];
    var addSubstatus = useMutation('addSubstatus', statuses.addSubstatus, {
        onSuccess: function () {
            refetch();
            setShowAdd(false);
        },
    });
    var statusOptions = [];
    if (statuses) {
        statusData.forEach(function (el) {
            statusOptions.push({ name: el.description, value: el.id });
        });
    }
    var handleInput = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        var regExp = /[a-z-_0-9]/gi;
        if (name === 'id' && !regExp.test(String(value))) {
            setError(true);
        }
        if (name === 'id' && regExp.test(String(value))) {
            setError(false);
        }
        setFormData(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleSelect = function (selectOption) {
        var name = selectOption.name, option = selectOption.option;
        setFormData(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = Number(option.value), _a)));
        });
        setSelectValue(String(option.name));
    };
    var handleSubmit = function () {
        if (error) {
            notification.error({
                message: 'Упс',
                description: 'id субстатуса введён некорректно',
            });
        }
        else {
            addSubstatus.mutate(formData);
        }
    };
    // const handleCancel = () => {
    //   setShowAdd(false);
    // };
    return (<div style={{ marginBottom: '20px' }}>
      <div className="editable-item__form">
        <div className="editable-item__form-row">
          <div className="drawerStyle">
            <Select name={'parent_status'} options={statusOptions} text={'Статус'} placeholder={'Виберите статус'} value={selectValue} onChange={handleSelect} width={380}/>
            <Input text={'id субстатуса (только латинские символы без пробелов)'} name={'id'} placeholder={'Введите id субстатуса'} onChange={handleInput} value={formData.id} error={error} width={380}/>
            <Input text={'Описание субстатуса'} name={'description'} placeholder={'Введите описание'} onChange={handleInput} value={formData.description} width={380}/>
          </div>
        </div>
        <div className="editable-item__form-btns">
          <Button text={'Сохранить'} onClick={handleSubmit}/>
          {/* <Button text={'Отменить'} onClick={handleCancel} /> */}
        </div>
      </div>
    </div>);
};
export default NewSubstatus;
