import moment from 'moment';
export var columns = [
    {
        title: 'Источник',
        dataIndex: 'source',
        key: 'source',
        width: 250,
        fixed: 'left',
    },
    {
        title: 'Обслужено',
        children: [
            {
                title: 'Закрыто',
                dataIndex: 'close_cnt',
                key: 'close_cnt',
                width: 110,
                sorter: function (a, b) { return Number(a.close_cnt) - Number(b.close_cnt); },
            },
            {
                title: 'Неявка',
                dataIndex: 'neyavka_cnt',
                key: 'neyavka_cnt',
                width: 110,
                sorter: function (a, b) { return Number(a.neyavka_cnt) - Number(b.neyavka_cnt); },
            },
            {
                title: 'Всего',
                dataIndex: 'all_cnt',
                key: 'all_cnt',
                width: 110,
                sorter: function (a, b) { return Number(a.all_cnt) - Number(b.all_cnt); },
            },
        ],
    },
    {
        title: 'Длительность',
        children: [
            {
                title: 'Ожидания',
                children: [
                    {
                        title: 'Средняя',
                        dataIndex: 'avg_wait_time',
                        width: 160,
                        sorter: function (a, b) {
                            var aToSec = moment(a.avg_wait_time, 'HH:mm:ss').format('x');
                            var bToSec = moment(b.avg_wait_time, 'HH:mm:ss').format('x');
                            return Number(aToSec) - Number(bToSec);
                        },
                    },
                    {
                        title: 'Общая',
                        dataIndex: 'total_wait_time',
                        width: 160,
                        sorter: function (a, b) {
                            var aToSec = moment(a.total_wait_time, 'HH:mm:ss').format('x');
                            var bToSec = moment(b.total_wait_time, 'HH:mm:ss').format('x');
                            return Number(aToSec) - Number(bToSec);
                        },
                    },
                ],
            },
            {
                title: 'Oбслуживания',
                children: [
                    {
                        title: 'Средняя',
                        dataIndex: 'avg_proc_time',
                        width: 160,
                        sorter: function (a, b) {
                            var aToSec = moment(a.avg_proc_time, 'HH:mm:ss').format('x');
                            var bToSec = moment(b.avg_proc_time, 'HH:mm:ss').format('x');
                            return Number(aToSec) - Number(bToSec);
                        },
                    },
                    {
                        title: 'Общая',
                        dataIndex: 'total_proc_time',
                        width: 160,
                        sorter: function (a, b) {
                            var aToSec = moment(a.total_proc_time, 'HH:mm:ss').format('x');
                            var bToSec = moment(b.total_proc_time, 'HH:mm:ss').format('x');
                            return Number(aToSec) - Number(bToSec);
                        },
                    },
                ],
            },
        ],
    },
];
