import Cookies from 'js-cookie';
import moment from 'moment';
import PathToRegexp, { compile } from 'path-to-regexp';
import { sessionToken } from '@/constants/auth';
export var getToken = function () {
    return Cookies.get(sessionToken) || '';
};
export var setToken = function (val) {
    return Cookies.set(sessionToken, val);
};
export var removeToken = function () {
    return Cookies.remove(sessionToken);
};
export var createSubLink = function (item) {
    return Array.isArray(item.subnav) ? createSubLink(item.subnav[0]) : item.path;
};
export var createSubTab = function (item) {
    return Array.isArray(item.subtab) ? createSubTab(item.subtab[0]) : item.id;
};
export var getPages = function (rows, pageSize, pageNumber) {
    return rows.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};
export var generateLanguage = function (locale, location) {
    var ROUTE = '/:locale/:path*';
    var definePath = compile(ROUTE);
    var routeComponents = PathToRegexp(ROUTE).exec(location.pathname);
    var subPaths = null;
    if (routeComponents && routeComponents[2]) {
        subPaths = routeComponents[2].split('/');
    }
    return definePath({
        locale: locale,
        path: subPaths,
    });
};
export var formatDate = function (date, format) {
    return moment(date, 'YYYY.MM.DD HH:mm:ss.SSS').format(format);
};
export var findOption = function (data, id) {
    var _a;
    return ((_a = data === null || data === void 0 ? void 0 : data.find(function (item) { return item.id === id; })) === null || _a === void 0 ? void 0 : _a.name) || 'Не задано';
};
export var secToTime = function (duration) {
    var seconds = parseInt(String(duration % 60));
    var minutes = parseInt(String((duration / 60) % 60));
    var hours = parseInt(String((duration / 60 / 60) % 24));
    hours = hours < 10 ? "0".concat(hours) : hours;
    minutes = minutes < 10 ? "0".concat(minutes) : minutes;
    seconds = seconds < 10 ? "0".concat(seconds) : seconds;
    return "".concat(hours, ":").concat(minutes, ":").concat(seconds);
};
