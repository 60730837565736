var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import DragDrop from '@/components/drag&drop';
import { FieldDescription } from '@/pages/statistics/reports/filters/filterParametrs';
import '../reportFilters.css';
var Order = function (_a) {
    var orderFields = _a.orderFields, showAdd = _a.showAdd, handleSetOrder = _a.handleSetOrder;
    var _b = useState([]), orders = _b[0], setOrders = _b[1];
    var orderItems = orderFields.map(function (item) {
        return { value: item, item: FieldDescription(item), name: '' };
    });
    var orderOptions = [
        { name: 'от А до Я', numName: 'от меньшего к большему', value: 'ASC' },
        { name: 'от Я до А', numName: 'от большего к меньшему', value: 'DESC' },
    ];
    var handleOptionSelect = function (e) {
        var newOrders = __spreadArray([], orders, true);
        var value = e.option.value;
        newOrders.forEach(function (el) {
            if (el.name === e.name) {
                el.value = value;
            }
        });
        setOrders(newOrders);
    };
    var handleDrop = function (params) {
        var newOrders = __spreadArray([], orders, true);
        params.forEach(function (param) {
            var i = newOrders.some(function (el) {
                return el.name === param;
            });
            if (!i) {
                newOrders.push({ name: param, value: 'ASC' });
            }
        });
        if (newOrders.length > params.length) {
            newOrders.forEach(function (param, idx) {
                if (!params.includes(param.name)) {
                    newOrders.splice(idx, 1);
                }
            });
        }
        setOrders(newOrders);
    };
    useEffect(function () {
        handleSetOrder(orders);
    }, [orders]);
    return (<div>
      <DragDrop select={true} options={orderOptions} buttons={false} showAdd={showAdd} items={orderItems} checked={[]} setNewParams={handleDrop} handleOptionSelect={handleOptionSelect}/>
    </div>);
};
export default Order;
