import React from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { sessionToken, userRoles } from '@/constants/auth';
import paths from '@/constants/paths';
import { getToken } from '@/utils';
import { login } from '@/utils/rest';
import Header from '../header';
import Loader from '../loader';
import './wrapper.css';
export var access = false;
var Wrapper = function (_a) {
    var children = _a.children;
    var token = new URLSearchParams(window.location.search).get('token') || '';
    var query = useQueryClient();
    var user = query.getQueryData('userInfo');
    var i18n = useTranslation().i18n;
    var history = useHistory();
    var isLoading = useQuery('userInfo', login.getUserInfo, {
        enabled: !(user === null || user === void 0 ? void 0 : user.data.fio) || (!getToken() && !token),
        onSuccess: function (data) {
            if (data.error && data.error.code === '204') {
                Cookies.remove(sessionToken);
                Cookies.remove(userRoles);
                history.push("/".concat(i18n.language).concat(paths.login));
            }
            else {
                if (data.data.roles.includes('eq_manager') ||
                    data.data.roles.includes('eq_root')) {
                    access = true;
                }
            }
        },
    }).isLoading;
    if (isLoading) {
        return <Loader />;
    }
    return (<div className="wrapper">
      <Header />
      <div className="page__content">{children}</div>
    </div>);
};
export default Wrapper;
