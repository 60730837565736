var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
var TableRow = function (_a) {
    var rowData = _a.rowData, columns = _a.columns, onClick = _a.onClick, rowStyle = _a.rowStyle, cellStyle = _a.cellStyle, index = _a.index;
    var renderCells = useMemo(function () {
        return columns.map(function (column) { return (<td key={column.field} className="flex-row" colSpan={1} style={__assign(__assign({ textAlign: 'left', alignItems: 'center' }, cellStyle), column.style)} onClick={function (e) { return (onClick ? onClick(e, rowData, column) : null); }}>
          {((column === null || column === void 0 ? void 0 : column.render)
                ? column === null || column === void 0 ? void 0 : column.render(rowData, index)
                : rowData[column.field])}
        </td>); });
    }, [columns, rowData]);
    return (<tr className="flex-table flex-table__row" style={rowStyle ? rowStyle(rowData, index) : undefined}>
      {renderCells}
    </tr>);
};
export default TableRow;
