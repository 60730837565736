import React from 'react';
import { ReactComponent as AddBtnImg } from '@/assets/images/add-btn.svg';
import './button.css';
var AddBtn = function (_a) {
    var handleClick = _a.handleClick, _b = _a.text, text = _b === void 0 ? 'Добавить' : _b;
    return (<button className="add-btn" onClick={handleClick}>
      <AddBtnImg />
      {text}
    </button>);
};
export default AddBtn;
