import React, { useState } from 'react';
import Input from '@/components/input/input';
import Select from '@/components/input/select';
import { gradeOptions, priorityOptions, statusOptions, } from '@/pages/statistics/reports/filters/filterParametrs';
import { FilterStore } from '@/store';
var ClientParamsFilters = function (_a) {
    var _b;
    var handleSetFilters = _a.handleSetFilters;
    var filterParams = FilterStore.useState(function (s) { return s.initialParams; });
    var _c = useState(filterParams['ticket_number'] === 'all'
        ? ''
        : filterParams['ticket_number'].replace(/= /, '').replace(/'/g, '')), ticketNumber = _c[0], setTicketNumber = _c[1];
    var _d = useState(filterParams['prior'] === 'all'
        ? 'Все'
        : filterParams['prior'].replace(/= /g, '').replace(/'/g, '')), priorityValue = _d[0], setPriorityValue = _d[1];
    var _e = useState(filterParams['status'] === 'all'
        ? 'Все'
        : filterParams['status'].replace(/= /g, '').replace(/'/g, '')), statusValue = _e[0], setStatusValue = _e[1];
    var _f = useState(filterParams['ticket_mark'] === 'all'
        ? 'Все'
        : (_b = gradeOptions.find(function (el) {
            return el.value ===
                filterParams['ticket_mark'].replace(/= /g, '').replace(/'/g, '');
        })) === null || _b === void 0 ? void 0 : _b.name), gradeValue = _f[0], setGradeValue = _f[1];
    var handleInput = function (e) {
        var _a = e.target, value = _a.value, name = _a.name;
        setTicketNumber(value);
        if (value === '') {
            handleSetFilters(name, 'all');
        }
        else {
            handleSetFilters(name, "= '".concat(value, "'"));
        }
    };
    var handleSelect = function (_a) {
        var option = _a.option, name = _a.name;
        switch (name) {
            case 'prior':
                setPriorityValue(option.name);
                break;
            case 'status':
                setStatusValue(option.name);
                break;
            case 'ticket_mark':
                setGradeValue(option.name);
                break;
        }
        if (option.name === 'Обработано') {
            handleSetFilters(name, "in (".concat(option.value, ")"));
            return;
        }
        if (option.value === 'all') {
            handleSetFilters(name, "".concat(option.value));
        }
        else {
            handleSetFilters(name, "= '".concat(option.value, "'"));
        }
    };
    return (<div className="report_filters__box">
      <p className="report_filters__title">Параметры клиента</p>
      <div className="report_filters__input">
        <p className="report_filters__input-text">№ Клиента</p>
        <Input name="ticket_number" value={ticketNumber} onChange={handleInput} width={264} placeholder="Введите полный номер клиента"/>
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Приоритет</p>
        <Select width={264} placeholder="Выбрать" name="prior" value={priorityValue} onChange={handleSelect} options={priorityOptions}/>
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Статус</p>
        <Select width={264} placeholder="Выбрать" options={statusOptions} onChange={handleSelect} name="status" value={statusValue}/>
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Оценка</p>
        <Select width={264} placeholder="Выбрать" name="ticket_mark" options={gradeOptions} value={gradeValue} onChange={handleSelect}/>
      </div>
    </div>);
};
export default ClientParamsFilters;
