import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Select from '@/components/input/select';
var Element = function (_a) {
    var item = _a.item, index = _a.index, column_id = _a.column_id, select = _a.select, options = _a.options, handleOptionSelect = _a.handleOptionSelect;
    var _b = useState(options ? (item.isNumber ? options[0].numName : options[0].name) : ''), selectValue = _b[0], setSelectValue = _b[1];
    var numOption = [];
    options === null || options === void 0 ? void 0 : options.forEach(function (el) {
        numOption.push({ name: el.numName, value: el.value });
    });
    var changeSelect = function (e) {
        if (handleOptionSelect) {
            handleOptionSelect(e);
        }
        setSelectValue(e.option.name);
    };
    return (<Draggable draggableId={item.field} index={index} key={item.id}>
      {function (provided) {
            return (<div key={index} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className="draggable__item">
            {select && column_id === 'selected' ? (item.isNumber ? (<div className="draggable__item-selected">
                  <p>{item.description}</p>
                  <Select width={220} options={numOption} onChange={changeSelect} name={item.field} value={selectValue}/>
                </div>) : (<div className="draggable__item-selected">
                  <p>{item.description}</p>
                  <Select width={220} options={options} onChange={changeSelect} name={item.field} value={selectValue}/>
                </div>)) : (<div className="draggable__item-margin">
                <p className="draggable__item-text">{item.description}</p>
              </div>)}
          </div>);
        }}
    </Draggable>);
};
export default Element;
