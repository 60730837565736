import React from 'react';
import paths from '@/constants/paths';
// import Hourly from '@/pages/statistics/reports/tabs/hourly';
import Logs from '@/pages/logs';
import HourlyReport from '@/pages/statistics/reports/tabs/hourly';
import Operators from '@/pages/statistics/reports/tabs/operators';
import QualityControl from '@/pages/statistics/reports/tabs/qualityControl';
import Services from '@/pages/statistics/reports/tabs/services';
import Source from '@/pages/statistics/reports/tabs/source';
import Survey from '@/pages/statistics/reports/tabs/survey';
import WPlaces from '@/pages/statistics/reports/tabs/wplaces';
import Consolidated from './tabs/consolidated';
import Tickets from './tabs/tickets';
export default [
    { id: paths.consolidated, name: 'Сводный', component: <Consolidated /> },
    { id: paths.tickets, name: 'По клиентам', component: <Tickets /> },
    { id: paths.servicesReport, name: 'По услугам', component: <Services /> },
    { id: paths.operators, name: 'По операторам', component: <Operators /> },
    { id: paths.wplaces, name: 'По рабочим местам', component: <WPlaces /> },
    { id: paths.source, name: 'По источнику', component: <Source /> },
    { id: paths.survey, name: 'Опросник', component: <Survey /> },
    { id: paths.hourly, name: 'Почасовой', component: <HourlyReport /> },
    { id: paths.quality, name: 'Оценка качества', component: <QualityControl /> },
    {
        id: paths.logs,
        name: 'Лог действий пользователей',
        component: <Logs />,
    },
    // { id: paths.operators, name: 'По очередям', component: <Operators /> },
    // { id: paths.operators, name: 'По рабочим местам', component: <Operators /> },
    // { id: paths.operators, name: 'За интервал', component: <Operators /> },
    // { id: paths.operators, name: 'Логи операторов', component: <Operators /> },
    // { id: paths.operators, name: 'Групповой', component: <Operators /> },
    // {
    //   id: paths.serviceSchedule,
    //   name: 'Расписание очереди',
    //   component: <ScheduleIntervals />,
    //   subtab: [
    //     {
    //       id: paths.serviceIntervals,
    //       name: 'Интервалы',
    //       component: <ScheduleIntervals />,
    //     },
    //     { id: paths.serviceDays, name: 'Дни', component: <ScheduleDays /> },
    //     { id: paths.serviceWeeks, name: 'Недели', component: <ScheduleWeeks /> },
    //   ],
    // },
    // {
    //   id: paths.serviceQuestions,
    //   name: 'Вопросы',
    //   component: <ServicesQuestions />,
    // },
    // { id: 'service-polls', name: 'Опросы' },
    // { id: 'service-calendar', name: 'Календарь' },
    // { id: 'service-pre-entry', name: 'Предварительная запись' },
];
