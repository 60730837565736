var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Button from '@/components/button';
import Input from '@/components/input/input';
import Loader from '@/components/loader';
import MultiSelect from '@/components/multiSelect';
import { days, intervals } from '@/utils/rest';
var DayEdit = function (_a) {
    var data = _a.data, refetch = _a.refetch, _b = _a.background, background = _b === void 0 ? 'transparent' : _b;
    var queryClient = useQueryClient();
    var _c = useQuery('intervals', intervals.get), intervalsData = _c.data, isLoading = _c.isLoading;
    var _d = useState({
        name: (data === null || data === void 0 ? void 0 : data.name) || '',
        description: (data === null || data === void 0 ? void 0 : data.description) || '',
    }), formData = _d[0], setFormData = _d[1];
    var _e = useState((data === null || data === void 0 ? void 0 : data.workIntervals) || []), selectedIntervals = _e[0], setSelectedIntervals = _e[1];
    var day = useMutation('day', days.add, {
        onSuccess: function () {
            // TODO: Попросить сделать новый ответ с созданной услугой
            if (refetch) {
                refetch();
            }
        },
    });
    var dayUpdate = useMutation(days.update, {
        onSuccess: function (data) {
            var days = queryClient.getQueryData('days');
            if (days) {
                var newDays = days.map(function (item) {
                    if (item.id === data.updatedId) {
                        return __assign(__assign(__assign({}, item), formData), { workIntervals: selectedIntervals });
                    }
                    return item;
                });
                queryClient.setQueryData('days', newDays);
            }
        },
    });
    var name = formData.name, description = formData.description;
    var handleInput = function (e) {
        var _a = e.target, name = _a.name, value = _a.value, checked = _a.checked;
        if (name === 'status') {
            setFormData(function (oldData) { return (__assign(__assign({}, oldData), { status: checked ? 2 : 1 })); });
        }
        else {
            setFormData(function (oldData) {
                var _a;
                return (__assign(__assign({}, oldData), (_a = {}, _a[name] = value, _a)));
            });
        }
    };
    var handleSubmit = function (e) {
        var newData = {
            description: description,
            name: name,
            workIntervals: selectedIntervals,
        };
        e.preventDefault();
        if (data) {
            dayUpdate.mutate({ id: data.id, payload: newData });
        }
        else {
            day.mutate(newData);
        }
    };
    var handleCheck = function (intervalId) {
        var newIntervals = __spreadArray([], selectedIntervals, true);
        if (intervalId === -1) {
            newIntervals =
                (intervalsData === null || intervalsData === void 0 ? void 0 : intervalsData.map(function (item) { return item.id; })) || [];
        }
        else if (intervalId === -2) {
            newIntervals = [];
        }
        else if (selectedIntervals.includes(intervalId)) {
            newIntervals = newIntervals === null || newIntervals === void 0 ? void 0 : newIntervals.filter(function (item) { return item !== intervalId; });
        }
        else {
            newIntervals === null || newIntervals === void 0 ? void 0 : newIntervals.push(intervalId);
        }
        setSelectedIntervals(__spreadArray([], newIntervals, true));
    };
    return day.isLoading || dayUpdate.isLoading || isLoading ? (<Loader />) : (<div className="editable-item__form">
      {!!data && <p className="editable-item__title">Редактирование дня</p>}
      <div className={background === 'transparent'
            ? 'editable-item__form-rows'
            : 'editable-item__form-rows editable-item__form-row_transparent'}>
        <div className="editable-item__form-row  editable-item__form-column" style={{ maxWidth: 400, width: '100%' }}>
          <div className="drawerStyle">
            <Input name="name" placeholder="Введите название" text="Наименование" value={name} onChange={handleInput} width={380}/>
            <Input name="description" placeholder="Введите название" text="Описание" value={description} onChange={handleInput} width={380}/>
          </div>
        </div>
        <div className="drawerStyle">
          <MultiSelect width={780} title="Интервал" data={intervalsData === null || intervalsData === void 0 ? void 0 : intervalsData.map(function (item) { return ({
            id: item.id,
            name: item.name,
        }); })} selected={selectedIntervals} handleCheck={handleCheck}/>
        </div>
      </div>
      <div className={!!data ? 'editable-item__form-row' : 'drawerStyle_table-btn'}>
        <Button text="Сохранить" onClick={handleSubmit} type="submit"/>
        {/* {!!data && <Button text="Отменить" onClick={handleCancel} />} */}
      </div>
    </div>);
};
export default DayEdit;
