import React from 'react';
import { ReactComponent as CopiesBtnImg } from '@/assets/images/copies-btn.svg';
import './button.css';
var CopiesBtn = function (_a) {
    var handleClick = _a.handleClick, _b = _a.text, text = _b === void 0 ? 'Добавить' : _b;
    return (<button className="add-btn" onClick={handleClick}>
      <CopiesBtnImg />
      {text}
    </button>);
};
export default CopiesBtn;
