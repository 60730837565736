import React from 'react';
import { useQuery } from 'react-query';
import Loader from '@/components/loader';
import AntTableWrapper from '@/components/table/antTableWrapper';
import ReportFilters from '@/pages/statistics/reports/filters/reportFilters';
import { columns } from '@/pages/statistics/reports/tabs/hourly/TableColumns';
import { FilterStore } from '@/store';
import { hourlyReport, queueObjects } from '@/utils/rest/pages/reports';
var HourlyReport = function () {
    var filterParams = FilterStore.useState(function (s) { return s.initialParams; });
    var _a = useQuery('hourly report', function () {
        return hourlyReport.get(filterParams);
    }), isLoading = _a.isLoading, data = _a.data, refetch = _a.refetch;
    var _b = useQuery('filtersData', queueObjects.get), filtersData = _b.data, isFilterLoading = _b.isLoading;
    var setFilterParams = function (newFilterParams) {
        FilterStore.update(function (s) {
            s.initialParams = newFilterParams;
        });
    };
    var applyFilters = function () {
        refetch();
    };
    if (isFilterLoading) {
        return <Loader />;
    }
    return (
    // <Table
    //   valueKey="ndate"
    //   headerStyle={{ fontSize: 14 }}
    //   cellStyle={{ fontSize: 12 }}
    //   data={data}
    //   columns={columns}
    //   isLoading={isLoading}
    //   onClick={handleRow}
    // />
    <>
      <ReportFilters showFilters={{
            main: ['reportRange', 'operators', 'workplaces', 'queues'],
            others: [],
            orders: ['ndate', 'all_cnt'],
        }} filterParams={filterParams} setFilterParams={setFilterParams} applyFilters={applyFilters} filtersData={filtersData}/>
      <AntTableWrapper columns={columns} data={data} rowKey={'ndate'} loading={isLoading} bordered={false} size={'small'}/>
    </>);
};
export default HourlyReport;
