import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Alert from '@/components/alert';
import Button from '@/components/button';
import ContentInput from '@/components/contentInput/contentInput';
import Input from '@/components/input/input';
import Loader from '@/components/loader';
import Modal from '@/components/modal';
import Table from '@/components/table';
import { getFileExtension, getVideosColumns, } from '@/pages/system/content/utils/utils';
import { content } from '@/utils/rest/pages/system';
var Videos = function () {
    var _a = useQuery('videos', content.getVideos), isLoading = _a.isLoading, data = _a.data, refetch = _a.refetch;
    var _b = useState(''), text = _b[0], setText = _b[1];
    var _c = useState(), file = _c[0], setFile = _c[1];
    var _d = useState(false), modal = _d[0], setModal = _d[1];
    var _e = useState('Неверный формат файла'), errorText = _e[0], setErrorText = _e[1];
    var extensions = [
        'flv',
        'mp4',
        'm3u8',
        'ts',
        '3gp',
        'mov',
        'avi',
        'wmv',
    ];
    var updateHandler = function (item) { return updateVideo.mutate(item.id); };
    var deleteHandler = function (item) { return deleteVideo.mutate(item.id); };
    var columns = getVideosColumns(deleteHandler, updateHandler);
    var deleteVideo = useMutation(content.deleteVideo, {
        onSuccess: function () { return refetch(); },
        onError: function (_a) {
            var _b;
            var response = _a.response;
            if (response) {
                setErrorText((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.error);
                setModal(true);
            }
        },
    });
    var updateVideo = useMutation(content.updateVideo, {
        onSuccess: function () { return refetch(); },
        onError: function (_a) {
            var _b;
            var response = _a.response;
            if (response) {
                setErrorText((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.error);
                setModal(true);
            }
        },
    });
    var uploadVideo = useMutation(content.addVideo, {
        onSuccess: function () {
            refetch();
            setFile(undefined);
            setText('');
        },
        onError: function (_a) {
            var _b;
            var response = _a.response;
            if (response) {
                setErrorText((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.error);
                setModal(true);
            }
        },
    });
    var inputHandler = function (e) { return setText(e.target.value); };
    var fileInputHandler = function (e) {
        e.target.files && setFile(e.target.files[0]);
    };
    var submitHandler = function () {
        var fileExtension = file && getFileExtension(file.name);
        if (!extensions.includes(fileExtension)) {
            setFile(undefined);
            setModal(true);
            return;
        }
        return file && text && uploadVideo.mutate({ file: file, text: text });
    };
    if (isLoading)
        return <Loader />;
    return (<div className="content__box">
      <Modal clean={true} open={modal}>
        <Alert text={errorText} handleSubmit={function () {
            setModal(false);
            setErrorText('Неверный формат файла');
        }}/>
      </Modal>
      <div className="content__top">
        {uploadVideo.isLoading ? (<Loader />) : (<>
            <div className="content__left">
              <Input text="Сперва введите название видео" value={text} onChange={inputHandler}/>
              <Button disabled={Boolean(!text)} text="Сохранить видео" onClick={submitHandler}/>
            </div>
            <div className="content__right">
              <ContentInput isShown={Boolean(text)} onChange={fileInputHandler} text="видео" content={file} accept={extensions.map(function (el) { return "video/".concat(el); }).toString()} extensions={extensions}/>
            </div>
          </>)}
      </div>
      <div className="content__bottom">
        <p className="section-title">Список загруженных видео</p>
        <Table data={data} columns={columns} isLoading={isLoading} headerStyle={{ fontSize: 14 }} cellStyle={{ fontSize: 12 }}/>
      </div>
    </div>);
};
export default Videos;
