import React, { useEffect } from 'react';
import { notification } from 'antd';
import { useMutation, useQuery } from 'react-query';
import ExportBtn from '@/components/button/exportBtn';
import Loader from '@/components/loader';
import AntTableWrapper from '@/components/table/antTableWrapper';
import ReportFilters from '@/pages/statistics/reports/filters/reportFilters';
import { columns } from '@/pages/statistics/reports/tabs/source/tableColumns';
import { FilterStore } from '@/store';
import { queueObjects, sourceReport } from '@/utils/rest/pages/reports';
// import Table from '@/components/table';
// import { SourceReport } from '@/models/reports';
// import { Column } from '@/types/common';
var Source = function () {
    var filterParams = FilterStore.useState(function (s) { return s.initialParams; });
    var setFilterParams = function (newFilterParams) {
        FilterStore.update(function (s) {
            s.initialParams = newFilterParams;
        });
    };
    var _a = useQuery('filtersData', queueObjects.get), filtersData = _a.data, isLoadingFilters = _a.isLoading;
    var report = useMutation('sourceReportData', sourceReport.get);
    var exportReport = useMutation('exportData', sourceReport.exportReport, {
        onSuccess: function (data) {
            var success = data.success;
            if (success) {
                notification.success({
                    message: 'Отчет подготавливается',
                    description: 'По окончанию подготовки отчет будет доступен для скачивания в разделе "Архив"',
                });
            }
            else {
                notification.error({
                    message: 'Упс',
                    description: 'Что-то пошло не так',
                });
            }
        },
    });
    var applyFilters = function () {
        report.mutate(filterParams);
    };
    // const handlePage = (page: number) => {
    //   const newFilterParams = { ...filterParams };
    //   const { limit } = newFilterParams;
    //   setFilterParams((oldData) => ({
    //     ...oldData,
    //     offset: page * Number(limit) - Number(limit),
    //   }));
    // };
    //
    // const handlePerPage = (perPage: number) => {
    //   setFilterParams((oldData) => ({ ...oldData, limit: perPage }));
    // };
    useEffect(function () {
        report.mutate(filterParams);
    }, []);
    if (isLoadingFilters || report.isLoading) {
        return <Loader />;
    }
    var exportData = function () {
        exportReport.mutate({ filterParams: filterParams, columns: columns });
    };
    // const columns: Column<SourceReport>[] = [
    //   {
    //     field: 'source',
    //     title: 'Источник',
    //     style: { textAlign: 'center' },
    //     render: (rowData) => (
    //       <p>{rowData.source.length > 0 ? rowData.source : 'Не указан'}</p>
    //     ),
    //   },
    //   {
    //     field: 'served',
    //     title: 'Обслужено',
    //     render: (rowData) => (
    //       <>
    //         <div className="report__table__text">
    //           <p>Всего</p>
    //           <p>{rowData.all_cnt}</p>
    //         </div>
    //         <br />
    //         <div className="report__table__text">
    //           <p>Закрыто</p>
    //           <p>{rowData.close_cnt}</p>
    //         </div>
    //         <br />
    //         <div className="report__table__text">
    //           <p>Закрыто по неявке</p>
    //           <p>{rowData.neyavka_cnt}</p>
    //         </div>
    //       </>
    //     ),
    //   },
    //   {
    //     field: 'waitTime',
    //     title: 'Длительность ожидания',
    //     render: (rowData) => (
    //       <>
    //         <div className="report__table__text">
    //           <p>Общая</p>
    //           <p>{rowData.total_wait_time}</p>
    //         </div>
    //         <br />
    //         <div className="report__table__text">
    //           <p>Средняя</p>
    //           <p>{rowData.avg_wait_time}</p>
    //         </div>
    //       </>
    //     ),
    //   },
    //   {
    //     field: 'procTime',
    //     title: 'Длительность обслуживания',
    //     render: (rowData) => (
    //       <>
    //         <div className="report__table__text">
    //           <p>Общая</p>
    //           <p>{rowData.total_proc_time}</p>
    //         </div>
    //         <br />
    //         <div className="report__table__text">
    //           <p>Средняя</p>
    //           <p>{rowData.avg_proc_time}</p>
    //         </div>
    //       </>
    //     ),
    //   },
    // ];
    return (<>
      <ReportFilters showFilters={{
            main: ['reportRange'],
            others: [
                'servedfilters',
                'waitTimeFilters',
                'procTimeFilter',
                'actionFilters',
            ],
            orders: [],
        }} filterParams={filterParams} setFilterParams={setFilterParams} applyFilters={applyFilters} filtersData={filtersData}/>
      <p className="table-title">Отчёт</p>
      {/*<Table*/}
      {/*  columns={columns}*/}
      {/*  isLoading={report.isLoading}*/}
      {/*  data={report.data}*/}
      {/*  headerStyle={{ fontSize: 14 }}*/}
      {/*  cellStyle={{ fontSize: 12 }}*/}
      {/*  handlePage={handlePage}*/}
      {/*  handlePerPage={handlePerPage}*/}
      {/*/>*/}
      <ExportBtn onClick={exportData}/>
      <AntTableWrapper columns={columns} data={report.data} rowKey={'source'} loading={report.isLoading} scroll={{ x: 1500 }}/>
    </>);
};
export default Source;
