import React from 'react';
import { ReactComponent as Delete } from '@/assets/images/delete-item.svg';
var DeleteBtn = function (_a) {
    var handleClick = _a.handleClick, text = _a.text;
    return (<button className="table__btn" onClick={handleClick}>
    <Delete />
    {text}
  </button>);
};
export default DeleteBtn;
