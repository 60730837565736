import React, { useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import { getAvgProcSqlTime, getAvgWaitSqlString, } from '@/pages/statistics/reports/tabs/consolidated/getSqlStr';
import { consolidatedReport } from '@/utils/rest/pages/reports';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
var AvgTimeReport = function (_a) {
    var filterParams = _a.filterParams;
    var labels = [];
    var waitValues = [];
    var procValues = [];
    var waitSql = getAvgWaitSqlString(filterParams.startDate, filterParams.endDate);
    var procSql = getAvgProcSqlTime(filterParams.startDate, filterParams.endDate);
    var _b = useQuery('waitData', function () { return consolidatedReport.get(waitSql); }), isWaitLoading = _b.isLoading, waitData = _b.data, waitRefetch = _b.refetch;
    var _c = useQuery('procData', function () { return consolidatedReport.get(procSql); }), isProcLoading = _c.isLoading, procData = _c.data, procRefetch = _c.refetch;
    if (!isProcLoading && !isWaitLoading) {
        waitData.data.forEach(function (el) {
            labels.push(el[0]);
            waitValues.push(Number(el[3]));
        });
        procData.data.forEach(function (el) {
            procValues.push(Number(el[3]));
        });
    }
    var options = {
        responsive: true,
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'Время в секундах',
                },
            },
        },
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 20,
                },
            },
        },
    };
    var data = {
        labels: labels,
        datasets: [
            {
                label: 'Ожидание',
                data: waitValues,
                backgroundColor: 'rgba(55, 121, 142, 0.7)',
            },
            {
                label: 'Обслуживание',
                data: procValues,
                backgroundColor: 'rgba(118, 201, 68, 0.7)',
            },
        ],
    };
    useEffect(function () {
        waitRefetch();
        procRefetch();
    }, [filterParams]);
    return (<div className="report__section">
      <p className="section-title">Среднее время по услугам</p>
      {!isWaitLoading && !isProcLoading && (<Bar data={data} options={options} height={120}/>)}
    </div>);
};
export default AvgTimeReport;
