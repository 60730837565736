var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { notification, Table } from 'antd';
import locale from 'antd/es/locale/ru_RU';
import ReactDragListView from 'react-drag-listview';
import { useMutation, useQuery } from 'react-query';
import BanForm from '@/components/banForm';
import ExportBtn from '@/components/button/exportBtn';
import Loader from '@/components/loader';
import Modal from '@/components/modal';
import ReportFilters from '@/pages/statistics/reports/filters/reportFilters';
import { FilterStore } from '@/store';
import { queueObjects, ticketReport } from '@/utils/rest/pages/reports';
import { statuses } from '@/utils/rest/pages/statuses';
import { getColumns } from './tableColumns';
var Tickets = function () {
    var filterParams = FilterStore.useState(function (s) { return s.initialParams; });
    var _a = useState(false), openModal = _a[0], setOpenModal = _a[1];
    var _b = useState(''), currentIP = _b[0], setCurrentIP = _b[1];
    var _c = useState([]), tableData = _c[0], setTableData = _c[1];
    var _d = useState([]), statusData = _d[0], setStatusData = _d[1];
    var _e = useState([]), columns = _e[0], setColumns = _e[1];
    useQuery('statuses', statuses.get, {
        onSuccess: function (data) {
            data === null || data === void 0 ? void 0 : data.forEach(function (el, idx) {
                el['key'] = idx;
            });
            setStatusData(data);
        },
    });
    var _f = useQuery('filtersData', queueObjects.get), filtersData = _f.data, isLoadingFilters = _f.isLoading, filtersRefetch = _f.refetch;
    var _g = useQuery('bannedUsers', ticketReport.getBanned), banData = _g.data, isBannedLoading = _g.isLoading, refetch = _g.refetch;
    var reportData = useMutation('reportData', ticketReport.get, {
        onSuccess: function (data) {
            if (banData) {
                data.forEach(function (el) { return (el.banned = banData.items); });
                setTableData(data);
            }
        },
    });
    var refetchData = function () {
        refetch();
        filtersRefetch();
    };
    var setFilterParams = function (newFilterParams) {
        FilterStore.update(function (s) {
            s.initialParams = newFilterParams;
        });
    };
    var exportReport = useMutation('exportData', ticketReport.exportReport, {
        onSuccess: function (data) {
            var success = data.success;
            if (success) {
                notification.success({
                    message: 'Отчет подготавливается',
                    description: 'По окончанию подготовки отчет будет доступен для скачивания в разделе "Архив"',
                });
            }
            else {
                notification.error({
                    message: 'Упс',
                    description: 'Что-то пошло не так',
                });
            }
        },
    });
    // const columns = getColumns(setOpenModal, setCurrentIP, statusData);
    var applyFilters = function () {
        refetch().then(function () {
            reportData.mutate(filterParams);
        });
    };
    var exportData = function () {
        exportReport.mutate({ filterParams: filterParams, columns: columns });
    };
    useEffect(function () {
        if (banData) {
            reportData.mutate(filterParams);
            var col = getColumns(setOpenModal, setCurrentIP, statusData);
            setColumns(col);
        }
    }, [banData]);
    var dragProps = {
        onDragEnd: function (fromIndex, toIndex) {
            if (!columns[toIndex].fixed) {
                var newColumns = __spreadArray([], columns, true);
                var item = newColumns.splice(fromIndex, 1)[0];
                newColumns.splice(toIndex, 0, item);
                setColumns(newColumns);
            }
        },
        nodeSelector: 'th',
        handleSelector: '.draggable',
    };
    if (isLoadingFilters || isBannedLoading) {
        return <Loader />;
    }
    return (<>
      <ReportFilters applyFilters={applyFilters} filtersData={filtersData} filterParams={filterParams} setFilterParams={setFilterParams} showFilters={{
            main: ['reportRange', 'operators', 'workplaces', 'queues'],
            others: [
                'servedDateFilters',
                'clientParamsFilters',
                'servedTimeFilters',
            ],
            orders: [
                'create_data',
                'close_data',
                'ticket_number',
                'user_fio',
                'workplace_name',
                'queue_name',
                'prior',
                'total_wait_time',
                'total_proc_time',
            ],
        }}/>
      <p className="table-title">Отчёт</p>
      <ExportBtn onClick={exportData}/>
      <Modal clean open={openModal}>
        <BanForm currentIP={currentIP} handleCancel={function () { return setOpenModal(false); }} refetch={refetchData} banned={banData && banData.items}/>
      </Modal>
      <ReactDragListView.DragColumn {...dragProps}>
        <Table locale={__assign(__assign({}, locale.Table), { triggerDesc: 'Отсортировано по возрастанию', triggerAsc: 'Без сортировки', cancelSort: 'Отсортировано по убыванию' })} rowKey="ticket_id" columns={columns} dataSource={tableData} scroll={{ x: 3850 }} loading={reportData.isLoading} bordered/>
      </ReactDragListView.DragColumn>
    </>);
};
export default Tickets;
