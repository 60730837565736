import React, { useEffect, useState } from 'react';
import Input from '@/components/input/input';
import Select from '@/components/input/select';
import { defaultSelectOptions } from '@/pages/statistics/reports/filters/filterParametrs';
import { FilterStore } from '@/store';
var ActionFilters = function (_a) {
    var handleSetFilters = _a.handleSetFilters;
    var filterParams = FilterStore.useState(function (s) { return s.initialParams; });
    var shareOptionState = defaultSelectOptions.find(function (el) { return el.value === filterParams.share_cnt.split(' ')[0]; });
    var solvedOptionState = defaultSelectOptions.find(function (el) { return el.value === filterParams.solved_questions.split(' ')[0]; });
    var _b = useState({
        value: shareOptionState ? shareOptionState.value : '',
        name: shareOptionState ? shareOptionState.name : '',
    }), shareOption = _b[0], setShareOption = _b[1];
    var _c = useState({
        value: solvedOptionState ? solvedOptionState.value : '',
        name: solvedOptionState ? solvedOptionState.name : '',
    }), solvedOption = _c[0], setSolvedOption = _c[1];
    var _d = useState(filterParams.share_cnt.split(' ')[1]), totalValue = _d[0], setTotalValue = _d[1];
    var _e = useState(filterParams.solved_questions.split(' ')[1]), awgValue = _e[0], setAwgValue = _e[1];
    var handleSelect = function (_a) {
        var option = _a.option, name = _a.name;
        switch (name) {
            case 'share_cnt':
                if (option.value === '') {
                    setShareOption({
                        value: '',
                        name: '',
                    });
                    setTotalValue(undefined);
                    handleSetFilters(name, 'all');
                    break;
                }
                setShareOption(option);
                break;
            case 'solved_questions':
                if (option.value === '') {
                    setSolvedOption({
                        value: '',
                        name: '',
                    });
                    setAwgValue(undefined);
                    handleSetFilters(name, 'all');
                    break;
                }
                setSolvedOption(option);
                break;
        }
    };
    var handleInput = function (e) {
        var _a = e.target, value = _a.value, name = _a.name;
        switch (name) {
            case 'share_cnt':
                setTotalValue(value);
                handleSetFilters(name, "".concat(shareOption.value, " ").concat(value));
                break;
            case 'solved_questions':
                setAwgValue(value);
                handleSetFilters(name, "".concat(solvedOption.value, " ").concat(value));
                break;
        }
    };
    useEffect(function () {
        if (totalValue) {
            handleSetFilters('share_cnt', "".concat(shareOption.value, " ").concat(totalValue));
        }
        if (awgValue) {
            handleSetFilters('solved_questions', "".concat(solvedOption.value, " ").concat(awgValue));
        }
    }, [shareOption, solvedOption]);
    return (<div className="report_filters__box">
      <p className="report_filters__title">Действия</p>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Клиентов обработано</p>
        <Select name="share_cnt" placeholder="Выбрать" value={shareOption.name} onChange={handleSelect} options={defaultSelectOptions} width={264}/>
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Количество</p>
        <Input type="number" name="share_cnt" onChange={handleInput} value={totalValue} width={264} disabled={shareOption.name === ''}/>
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Решённых воросов</p>
        <Select name="solved_questions" placeholder="Выбрать" value={solvedOption.name} onChange={handleSelect} options={defaultSelectOptions} width={264}/>
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Количество</p>
        <Input type="number" name="solved_questions" onChange={handleInput} value={awgValue} width={264} disabled={solvedOption.name === ''}/>
      </div>
    </div>);
};
export default ActionFilters;
