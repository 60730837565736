export var columns = [
    {
        dataIndex: 'ndate',
        title: 'Даты/\nчасы',
        align: 'center',
        width: 110,
    },
    {
        dataIndex: 'd00',
        title: '00:00\n01:00',
        align: 'center',
    },
    {
        dataIndex: 'd01',
        title: '01:00\n02:00',
        align: 'center',
    },
    {
        dataIndex: 'd02',
        title: '02:00\n03:00',
        align: 'center',
    },
    {
        dataIndex: 'd03',
        title: '03:00\n04:00',
        align: 'center',
    },
    {
        dataIndex: 'd04',
        title: '04:00\n05:00',
        align: 'center',
    },
    {
        dataIndex: 'd05',
        title: '05:00\n06:00',
        align: 'center',
    },
    {
        dataIndex: 'd06',
        title: '06:00\n07:00',
        align: 'center',
    },
    {
        dataIndex: 'd07',
        title: '07:00\n08:00',
        align: 'center',
    },
    {
        dataIndex: 'd08',
        title: '08:00\n09:00',
        align: 'center',
    },
    {
        dataIndex: 'd09',
        title: '09:00\n10:00',
        align: 'center',
    },
    {
        dataIndex: 'd10',
        title: '10:00\n11:00',
        align: 'center',
    },
    {
        dataIndex: 'd11',
        title: '11:00\n12:00',
        align: 'center',
    },
    {
        dataIndex: 'd12',
        title: '12:00\n13:00',
        align: 'center',
    },
    {
        dataIndex: 'd13',
        title: '13:00\n14:00',
        align: 'center',
    },
    {
        dataIndex: 'd14',
        title: '14:00\n15:00',
        align: 'center',
    },
    {
        dataIndex: 'd15',
        title: '15:00\n16:00',
        align: 'center',
    },
    {
        dataIndex: 'd16',
        title: '16:00\n17:00',
        align: 'center',
    },
    {
        dataIndex: 'd17',
        title: '17:00\n18:00',
        align: 'center',
    },
    {
        dataIndex: 'd18',
        title: '18:00\n19:00',
        align: 'center',
    },
    {
        dataIndex: 'd19',
        title: '19:00\n20:00',
        align: 'center',
    },
    {
        dataIndex: 'd20',
        title: '20:00\n21:00',
        align: 'center',
    },
    {
        dataIndex: 'd21',
        title: '21:00\n22:00',
        align: 'center',
    },
    {
        dataIndex: 'd22',
        title: '22:00\n23:00',
        align: 'center',
    },
    {
        dataIndex: 'd23',
        title: '23:00\n00:00',
        align: 'center',
    },
    {
        dataIndex: 'all_cnt',
        title: 'Всего за день',
        align: 'center',
    },
];
