var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { Table } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { ReactComponent as Params } from '@/assets/images/table-params.svg';
import { ReactComponent as Submit } from '@/assets/images/table-submit.svg';
import CheckBoxList from '@/components/checkBoxList/checkBoxList';
import DragDrop from '@/components/drag&drop';
import Checkbox from '@/components/input/checkbox';
import Input from '@/components/input/input';
import Loader from '@/components/loader';
import { getColumnSearchProps } from '@/pages/services/tabs/servicePolls/helpers/utils';
import { officeParams } from '@/utils/rest';
import { soundlist } from '@/utils/rest/pages/services';
import { operatorHideWidgetsData } from './tableConfig';
import './parameters.css';
var Parameters = function () {
    var _a = useState(null), newParams = _a[0], setNewParams = _a[1];
    var _b = useState(false), isEditable = _b[0], setIsEditable = _b[1];
    var _c = useState(null), currentRow = _c[0], setCurrentRow = _c[1];
    var _d = useState([]), checkedValues = _d[0], setCheckedValues = _d[1];
    var _e = useState([]), checkedSounds = _e[0], setCheckedSounds = _e[1];
    var _f = useState([]), sounds = _f[0], setSounds = _f[1];
    var _g = useState(0), expanded = _g[0], setExpanded = _g[1];
    var _h = useQuery('officeParams', function () { return officeParams.get(undefined); }, {
        onSuccess: function (data) {
            var operatorHideWidgetsParams = data
                .filter(function (item) { return item.param === 'operatorHideWidgets'; })[0]
                .value.split(',');
            var checked = [];
            operatorHideWidgetsData.forEach(function (item) {
                if (operatorHideWidgetsParams.includes(item.key)) {
                    checked.push(Number(item.id));
                }
            });
            setCheckedValues(checked);
            var soundsParams = data
                .filter(function (item) { return item.param === 'wplaceDefaultSoundString'; })[0]
                .value.split(' ');
            setCheckedSounds(soundsParams);
        },
    }), isLoading = _h.isLoading, paramsData = _h.data, refetch = _h.refetch;
    if (!isLoading) {
        paramsData === null || paramsData === void 0 ? void 0 : paramsData.forEach(function (el, idx) {
            el['key'] = idx + 1;
        });
    }
    useQuery('soundList', soundlist.get, {
        onSuccess: function (data) {
            setSounds(__spreadArray(__spreadArray([], data.sounds, true), data.variables, true));
        },
    });
    //Сохранение изменений параметров
    var saveParams = useMutation(officeParams.add);
    //Действия пользователя
    var expand = function (index) {
        if (expanded === index) {
            setExpanded(0);
        }
        else {
            setExpanded(index);
        }
    };
    var handleCheck = function (e) {
        var _a = e.target, checked = _a.checked, name = _a.name;
        var newValue;
        paramsData === null || paramsData === void 0 ? void 0 : paramsData.forEach(function (item) {
            if (item.param === name) {
                item.value = checked ? '1' : '0';
                newValue = { param: name, value: item.value };
            }
        });
        if (newValue !== undefined) {
            setNewParams(newValue);
        }
    };
    var handleInput = function (e) {
        var _a = e.target, value = _a.value, name = _a.name;
        var newValue;
        paramsData === null || paramsData === void 0 ? void 0 : paramsData.forEach(function (item) {
            if (item.param === name) {
                item.value = value;
                newValue = { param: name, value: item.value };
            }
        });
        if (newValue !== undefined) {
            setNewParams(newValue);
        }
    };
    var handleRow = function (rowData) {
        if (rowData.param === 'wplaceDefaultSoundString' &&
            sounds.length !== 0 &&
            checkedSounds.length !== 0) {
            console.log(rowData);
            expand(rowData.key);
        }
        if (!isEditable) {
            setIsEditable(true);
            setCurrentRow(rowData);
            refetch();
        }
        else {
            handleSubmit();
            setIsEditable(false);
        }
    };
    var handleSetData = function (e) {
        var disableElements = [];
        var disableElementsId = e.connectedRoles;
        disableElementsId.forEach(function (disableId) {
            operatorHideWidgetsData.forEach(function (element) {
                if (element.id === disableId) {
                    disableElements.push(element.key);
                }
            });
        });
        var newValue = {
            param: 'operatorHideWidgets',
            value: disableElements.join(','),
        };
        setNewParams(newValue);
    };
    var handleSubmit = function () {
        if (newParams) {
            saveParams.mutate(__assign({}, newParams));
            setNewParams(null);
            setIsEditable(false);
        }
        setIsEditable(false);
    };
    var handleDrop = function (params) {
        var newParams = {
            param: 'wplaceDefaultSoundString',
            value: params.join(' '),
        };
        setNewParams(newParams);
    };
    //Рендер таблицы
    var renderValue = function (rowData, enabled) {
        var id = rowData.id, param = rowData.param, param_type = rowData.param_type, value = rowData.value;
        if (param === 'operatorHideWidgets') {
            return (<div className={enabled ? 'values values-enabled' : 'values'}>
          <CheckBoxList item={{
                    name: 'connectedRoles',
                    options: operatorHideWidgetsData,
                    checked: checkedValues,
                }} setData={handleSetData} disabled={!enabled}/>
        </div>);
        }
        return paramsData === null || paramsData === void 0 ? void 0 : paramsData.map(function (item) {
            if (item.param === param) {
                switch (param_type) {
                    case 'float':
                    case 'number':
                        return (<div key={id} className={enabled ? 'values values-enabled' : 'values'}>
                <Input key={id} onChange={handleInput} name={param} type="number" value={value} disabled={!enabled}/>
              </div>);
                    case 'boolean':
                        return (<div key={id} className={enabled ? 'values values-enabled' : 'values'}>
                <Checkbox key={id} value={Boolean(Number(value))} name={param} text={Boolean(Number(value)) ? 'Включено' : 'Выключено'} onChange={handleCheck} disabled={!enabled} style={{ margin: '0', padding: '0' }}/>
              </div>);
                    case 'string':
                    case 'text':
                        return (<div key={id} className={enabled ? 'values values-enabled' : 'values'}>
                <Input key={id} value={value} name={param} type="text" onChange={handleInput} disabled={!enabled}/>
              </div>);
                    case 'soundstring':
                        return;
                }
            }
        });
    };
    //Настройка столбцов таблицы
    var columns = [
        __assign({ dataIndex: 'param', title: 'Параметр' }, getColumnSearchProps('param', 'по параметру')),
        __assign({ dataIndex: 'description', title: 'Описание' }, getColumnSearchProps('description', 'по описанию')),
        __assign(__assign({ dataIndex: 'group_name', title: 'Группа', width: 150 }, getColumnSearchProps('group_name', 'по группе')), { sorter: function (a, b) {
                if (a.group_name.toLowerCase() < b.group_name.toLowerCase()) {
                    return -1;
                }
                if (a.group_name.toLowerCase() > b.group_name.toLowerCase()) {
                    return 1;
                }
                return 0;
            } }),
        {
            dataIndex: 'value',
            title: 'Значение',
            width: 280,
            align: 'center',
            render: function (_, rowData) {
                return renderValue(rowData, isEditable && (currentRow === null || currentRow === void 0 ? void 0 : currentRow.id) === rowData.id);
            },
        },
        {
            key: 'params',
            align: 'center',
            width: 55,
            render: function (_, rowData) {
                return (<button onClick={function () { return handleRow(rowData); }}>
            {isEditable && (currentRow === null || currentRow === void 0 ? void 0 : currentRow.id) === rowData.id ? (<Submit />) : (<Params />)}
          </button>);
            },
        },
    ];
    if (isLoading) {
        return <Loader />;
    }
    return (<>
      <p className="section-title">Параметры</p>
      <Table locale={{
            triggerDesc: 'Отсортировано по возрастанию',
            triggerAsc: 'Без сортировки',
            cancelSort: 'Отсортировано по убыванию',
        }} columns={columns} dataSource={paramsData} rowKey={'key'} loading={isLoading} bordered={false} expandable={{
            expandedRowRender: function () { return (<DragDrop setNewParams={handleDrop} items={sounds} checked={checkedSounds}/>); },
            showExpandColumn: false,
            expandedRowKeys: [expanded],
        }}/>
    </>);
};
export default Parameters;
