import React from 'react';
import { Popover } from 'antd';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { ReactComponent as BanIcon } from '@/assets/images/table-icon-false.svg';
import { ReactComponent as UnBanIcon } from '@/assets/images/table-icon-true.svg';
import { access } from '@/components/wrapper';
import { loginAccess } from '@/hooks/query/login';
var parseAudio = function (audio, status) {
    if (!audio || status === 'Закрыт по неявке')
        return <span>Записи нет</span>;
    if (audio) {
        var audioArr = String(audio).split(',');
        return audioArr.map(function (record, idx) {
            var audioPath = record.split('/');
            var src = "/records/".concat(audioPath[audioPath.length - 1]);
            return (<audio key={nanoid()} src={src} controls style={idx !== 0 ? { marginTop: '10px' } : {}}/>);
        });
    }
};
var downloadExtraData = function (row) {
    try {
        var chatData = JSON.parse(row.extradata);
        var chatFile_1 = '';
        chatData.forEach(function (chatMessage) {
            chatFile_1 += "".concat(moment(chatMessage.time).format('DD.MM.YYYY HH:mm:ss'), " ").concat(chatMessage.type, ": ").concat(chatMessage.text ? chatMessage.text : chatMessage.file.downloadLink);
            chatFile_1 += '\r\n';
        });
        var dataStr = "data:text/plain;charset=utf-8,".concat(encodeURIComponent(chatFile_1));
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute('href', dataStr);
        downloadAnchorNode.setAttribute('download', "chat-".concat(row.ticket_id, ".txt"));
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }
    catch (e) {
        console.log(e);
    }
};
export var getColumns = function (setOpenModal, setCurrentIP, statusData) {
    var columns = [
        {
            title: 'Информация о клиенте',
            fixed: 'left',
            children: [
                {
                    title: 'Id',
                    dataIndex: 'ticket_id',
                    key: 'ticket_id',
                    width: 100,
                    fixed: 'left',
                    sorter: function (a, b) { return Number(a.ticket_id) - Number(b.ticket_id); },
                },
                {
                    title: '№',
                    dataIndex: 'ticket_number',
                    key: 'ticket_number',
                    width: 100,
                    fixed: 'left',
                },
            ],
        },
        {
            title: 'Дата',
            className: 'draggable',
            children: [
                {
                    title: 'Создания',
                    dataIndex: 'create_data',
                    key: 'create_data',
                    width: 160,
                    sorter: function (a, b) {
                        var aToSec = moment(a.create_data, 'DD.MM.YYYY HH:mm:ss').format('x');
                        var bToSec = moment(b.create_data, 'DD.MM.YYYY HH:mm:ss').format('x');
                        return Number(aToSec) - Number(bToSec);
                    },
                },
                {
                    title: 'Вызова',
                    dataIndex: 'start_proc_ticket',
                    key: 'start_proc_ticket',
                    width: 160,
                },
                {
                    title: 'Закрытия',
                    dataIndex: 'close_data',
                    key: 'close_data',
                    width: 160,
                },
            ],
        },
        {
            title: 'Длительность',
            className: 'draggable',
            children: [
                {
                    title: 'Ожидания',
                    dataIndex: 'total_wait_time',
                    width: 160,
                    sorter: function (a, b) {
                        var aToSec = moment(a.total_wait_time, 'HH:mm:ss').format('x');
                        var bToSec = moment(b.total_wait_time, 'HH:mm:ss').format('x');
                        return Number(aToSec) - Number(bToSec);
                    },
                },
                {
                    title: 'Oбслуживания',
                    dataIndex: 'total_proc_time',
                    width: 160,
                    sorter: function (a, b) {
                        var aToSec = moment(a.total_proc_time, 'HH:mm:ss').format('x');
                        var bToSec = moment(b.total_proc_time, 'HH:mm:ss').format('x');
                        return Number(aToSec) - Number(bToSec);
                    },
                },
            ],
        },
        {
            title: 'Услуга',
            dataIndex: 'queue_name',
            className: 'draggable',
        },
        {
            title: 'Рабочее место',
            dataIndex: 'workplace_name',
            className: 'draggable',
        },
        {
            title: 'Оператор',
            dataIndex: 'user_fio',
            className: 'draggable',
            width: 200,
            render: function (_, rec) {
                return rec.user_fio === 'Система управления терминалами' ? (<p>Система</p>) : (<p>{rec.user_fio}</p>);
            },
        },
        {
            title: 'Источник',
            dataIndex: 'source',
            width: 200,
            className: 'draggable',
        },
        {
            title: 'Доп. данные',
            dataIndex: 'extradata',
            width: 200,
            className: 'draggable',
            render: function (_, res) {
                var extradata = res.extradata;
                return (<div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
            {extradata ? (<button className="addButton" onClick={function () { return downloadExtraData(res); }}>
                Скачать
              </button>) : ('Нет')}
          </div>);
            },
        },
        {
            title: 'Кол-во решенных вопросов',
            dataIndex: 'solved_questions',
            width: 150,
            className: 'draggable',
            sorter: function (a, b) { return Number(a.solved_questions) - Number(b.solved_questions); },
        },
        {
            title: 'Приоритет',
            dataIndex: 'prior',
            width: 130,
            className: 'draggable',
        },
        {
            title: 'Оценка',
            dataIndex: 'ticket_mark',
            width: 100,
            className: 'draggable',
            sorter: function (a, b) { return Number(a.ticket_mark) - Number(b.ticket_mark); },
        },
        {
            title: 'Аудиозапись',
            dataIndex: 'audio',
            width: 320,
            className: 'draggable',
            render: function (_, res) {
                var audio = res.audio, status = res.status;
                return (<div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
            {parseAudio(audio, String(status))}
          </div>);
            },
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            width: 150,
            className: 'draggable',
        },
        {
            title: 'Субстатус',
            dataIndex: 'substatus',
            width: 200,
            className: 'draggable',
            render: function (_, rec) {
                if (rec.substatus) {
                    var currentStatus = statusData.find(function (el) {
                        return el.substatuses.some(function (el) { return el.id === rec.substatus; });
                    });
                    var currentSubStatus = currentStatus === null || currentStatus === void 0 ? void 0 : currentStatus.substatuses.find(function (el) { return el.id === rec.substatus; });
                    return <p>{currentSubStatus === null || currentSubStatus === void 0 ? void 0 : currentSubStatus.description}</p>;
                }
                return <p>Нет субстатуса</p>;
            },
        },
    ];
    if (loginAccess || access) {
        columns.push({
            title: 'Ip',
            dataIndex: 'address',
            width: 150,
            align: 'center',
            className: 'draggable',
        }, {
            title: 'Браузер',
            dataIndex: 'browser',
            width: 150,
            align: 'center',
        }, {
            title: 'Операционная система',
            dataIndex: 'os',
            width: 150,
            align: 'center',
            className: 'draggable',
        }, {
            title: 'Город',
            dataIndex: 'city',
            width: 200,
            align: 'center',
            className: 'draggable',
            render: function (value) {
                console.log(value);
                return value;
            },
        }, {
            title: 'Блокировка клиента',
            key: 'ban',
            align: 'center',
            render: function (_, res) {
                return res.banned.some(function (el) { return el.address === res.address; }) ? (<button onClick={function () {
                        setOpenModal(function (state) { return !state; });
                        setCurrentIP(res.address);
                    }}>
              <Popover placement="topLeft" content={<p>Разблокировать клиента</p>}>
                <UnBanIcon />
              </Popover>
            </button>) : (<button onClick={function () {
                        setOpenModal(function (state) { return !state; });
                        setCurrentIP(res.address);
                    }}>
              <Popover placement="topLeft" content={<p>Заблокировать клиента</p>}>
                <BanIcon />
              </Popover>
            </button>);
            },
        });
    }
    return columns;
};
