import moment from 'moment/moment';
import { Store } from 'pullstate';
import { dateFormat } from '@/constants/common';
export var App = new Store({
    app: {
        loading: false,
        error: null,
    },
    user: {
        fio: '',
        roles: [],
    },
});
export var FilterStore = new Store({
    initialParams: {
        operator: 'all',
        workPlace: 'all',
        queue: 'all',
        close_cnt: 'all',
        groupinType: 'wp_id',
        bunch_id: 'all',
        all_cnt: 'all',
        neyavka_cnt: 'all',
        avg_wait_time: 'all',
        total_wait_time: 'all',
        avg_proc_time: 'all',
        total_proc_time: 'all',
        total_work_time: 'all',
        avg_work_time: 'all',
        mark1: 'all',
        mark2: 'all',
        mark3: 'all',
        mark4: 'all',
        mark5: 'all',
        markcnt: 'all',
        share_cnt: 'all',
        solved_questions: 'all',
        create_data: 'all',
        close_data: 'all',
        ticket_number: 'all',
        status: 'all',
        substatus: 'all',
        prior: 'all',
        ticket_mark: 'all',
        dateType: 'day',
        totalproc: 'all',
        pincode: 'all',
        clientfio: 'all',
        clientmail: 'all',
        clientphone: 'all',
        dateTypeDescription: 'день',
        startDate: moment().startOf('month').format(dateFormat),
        endDate: moment().endOf('day').format(dateFormat),
        historyDate: moment().format(dateFormat),
        intervalReprotHistoryDate: 'all',
        orderArr: [],
        limit: 'all',
        offset: 'all',
        registered: 'all',
        ticket_id: 'all',
    },
});
