var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { ReactComponent as Check } from '@/assets/images/check.svg';
import { ReactComponent as Close } from '@/assets/images/close.svg';
import Checkbox from '@/components/input/checkbox';
var CheckBoxList = function (_a) {
    var item = _a.item, setData = _a.setData, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var _c = useState(true), checkedAll = _c[0], setCheckedAll = _c[1];
    var options = item.options, checked = item.checked, name = item.name;
    var _d = useState(__spreadArray([], checked, true)), check = _d[0], setCheck = _d[1];
    var handleCheck = function (e) {
        var _a, _b;
        var dataset = e.target.dataset;
        var checkArr = __spreadArray([], check, true);
        var index = checkArr.indexOf(Number(dataset.id));
        if (index === -1) {
            checkArr.push(Number(dataset.id));
            setCheck(checkArr);
            setData((_a = {}, _a[name] = checkArr, _a));
        }
        else {
            checkArr.splice(index, 1);
            setCheck(checkArr);
            setData((_b = {}, _b[name] = checkArr, _b));
        }
    };
    var checkAll = function () {
        var _a;
        var checkArr = [];
        options.forEach(function (item) {
            checkArr.push(Number(item.id));
        });
        setCheck(checkArr);
        setData((_a = {}, _a[name] = checkArr, _a));
        setCheckedAll(function (prevState) { return !prevState; });
    };
    var cancelAll = function () {
        var _a;
        setCheck([]);
        setData((_a = {}, _a[name] = [], _a));
        setCheckedAll(function (prevState) { return !prevState; });
    };
    var renderList = function (array) {
        return array.map(function (item) {
            return (<Checkbox style={{ padding: '0' }} key={item.id} id={item.id} name={item.name} text={item.name} value={check.some(function (i) { return i === item.id; })} onChange={handleCheck} disabled={disabled}/>);
        });
    };
    return (<div style={{ margin: '10px' }}>
      {renderList(options)}
      {!disabled &&
            (checkedAll ? (<button onClick={checkAll} className="multiselect__top-btn operatorHideWidgetsBtn">
            <Check />
            Выбрать всё
          </button>) : (<button onClick={cancelAll} className="multiselect__top-btn operatorHideWidgetsBtn">
            <Close />
            Отменить всё
          </button>))}
    </div>);
};
export default CheckBoxList;
