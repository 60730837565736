import React, { useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import { getDaysReportSql } from '@/pages/statistics/reports/tabs/consolidated/getSqlStr';
import { consolidatedReport } from '@/utils/rest/pages/reports';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
var DaysReport = function (_a) {
    var filterParams = _a.filterParams;
    var daysReportSql = getDaysReportSql(filterParams.startDate, filterParams.endDate);
    var _b = useQuery('daysReportData', function () {
        return consolidatedReport.get(daysReportSql);
    }), isLoading = _b.isLoading, data = _b.data, refetch = _b.refetch;
    var days = {
        mon: 'Понедельник',
        tue: 'Вторник',
        wed: 'Среда',
        thu: 'Четверг',
        fri: 'Пятница',
        sat: 'Суббота',
        sun: 'Воскресенье',
    };
    var labels = [];
    var closeData = [];
    var noneData = [];
    var delData = [];
    var createData = [];
    if (!isLoading) {
        data.data.forEach(function (el) {
            var index = Object.keys(days).indexOf(el[0]);
            labels[index] = days[el[0]];
            closeData[index] = el[2];
            noneData[index] = el[3];
            delData[index] = el[4];
            createData[index] = el[5];
        });
    }
    var options = {
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 20,
                },
            },
        },
    };
    var graphData = {
        labels: labels,
        datasets: [
            {
                label: 'Всего создано',
                data: createData,
                backgroundColor: 'rgba(118, 201, 68, 0.7)',
            },
            {
                label: 'Удалено системой',
                data: delData,
                backgroundColor: 'rgba(55, 121, 142, 0.7)',
            },
            {
                label: 'Закрыто',
                data: closeData,
                backgroundColor: 'rgba(242, 143, 87, 0.7)',
            },
            {
                label: 'Закрыто по неявке',
                data: noneData,
                backgroundColor: 'rgba(236, 81, 81, 0.7)',
            },
        ],
    };
    useEffect(function () {
        refetch();
    }, [filterParams]);
    return (<div className="report__section">
      <p className="section-title">
        Среднее распределение клиентов по дням недели
      </p>
      {!isLoading && <Bar data={graphData} options={options} height={120}/>}
    </div>);
};
export default DaysReport;
