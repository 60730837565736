import React, { useMemo } from 'react';
import { ReactComponent as LeftArr } from '@/assets/images/table-left-arr.svg';
import { ReactComponent as RightArr } from '@/assets/images/table-right-arr.svg';
var TablePagination = function (_a) {
    var count = _a.count, perPage = _a.perPage, handlePage = _a.handlePage, activePage = _a.activePage, handlePerPage = _a.handlePerPage;
    var countPages = useMemo(function () {
        return count ? Math.ceil(count / perPage) : 0;
    }, [count, perPage]);
    var firstPage = activePage === 1;
    var lastPage = activePage === countPages;
    var twoPages = countPages === 2;
    var handleShowRows = function (val) {
        handlePerPage(val);
        handlePage(1);
    };
    var pageBtn = function (page) { return (<li key={page} onClick={handlePage ? function () { return handlePage(page); } : undefined} className={Number(activePage) === page
            ? 'pagination__button pagination__button_active'
            : 'pagination__button'}>
      {page}
    </li>); };
    var getActivePage = function () {
        return firstPage
            ? activePage
            : lastPage && !twoPages
                ? activePage - 2
                : activePage - 1;
    };
    var getMaxPages = function () {
        return activePage + (twoPages && !lastPage ? 2 : firstPage ? 3 : lastPage ? 1 : 2);
    };
    var pages = useMemo(function () {
        if (count && count <= perPage)
            return null;
        var pageArr = [];
        for (var i = getActivePage(); i < getMaxPages(); i++) {
            pageArr.push(i);
        }
        return (<>
        <button className="pagination__button" onClick={function () { return handlePage(1); }}>
          <LeftArr />
          <LeftArr />
        </button>
        <button className="pagination__button" onClick={activePage > 1 ? function () { return handlePage(activePage - 1); } : undefined}>
          <LeftArr />
        </button>
        {pageArr.map(function (item) { return pageBtn(item); })}
        {activePage + 1 < countPages && countPages !== 3 && (<>
            <li className="pagination__button">...</li>
            {pageBtn(countPages)}
          </>)}
        <button className="pagination__button" onClick={activePage !== countPages
                ? function () { return handlePage(activePage + 1); }
                : undefined}>
          <RightArr />
        </button>
        <button className="pagination__button" onClick={function () { return handlePage(countPages); }}>
          <RightArr />
          <RightArr />
        </button>
      </>);
    }, [perPage, count, activePage]);
    var rowsCount = useMemo(function () {
        return (<>
        <p>Показывать</p>
        <ul>
          {[8, 16, 24].map(function (item) { return (<li key={item} className={perPage === item
                    ? 'pagination__button pagination__button_active'
                    : 'pagination__button'} onClick={function () { return handleShowRows(item); }}>
              {item}
            </li>); })}
        </ul>
      </>);
    }, [perPage]);
    return (<div className="pagination">
      <div className="pagination__count">{rowsCount}</div>
      <div className="pagination__pages">{pages}</div>
    </div>);
};
export default TablePagination;
