import React, { useState } from 'react';
import { ReactComponent as Expand } from '@/assets/images/expand.svg';
import { ReactComponent as FloppyDisk } from '@/assets/images/floppy-disk.svg';
import { ReactComponent as Trash } from '@/assets/images/table-trash.svg';
import Input from '@/components/input/input';
import RangeSlider from '@/components/input/rangeSlider';
import Star from '@/components/star/star';
import Stars from '@/components/stars';
import './condition.css';
import '@/components/stars/stars.css';
var ConditionPrior = function (_a) {
    var id = _a.id, _b = _a.isOpen, isOpen = _b === void 0 ? false : _b, title = _a.title, prefix = _a.prefix, prior = _a.prior, onDelete = _a.onDelete, onChangeInput = _a.onChangeInput, onChangePrior = _a.onChangePrior, onBlur = _a.onBlur, background = _a.background, onSave = _a.onSave;
    var _c = useState(isOpen), expand = _c[0], setExpand = _c[1];
    var expandHandler = function () { return setExpand(function (prevState) { return !prevState; }); };
    var _d = useState(Number(prior) || 0), rating = _d[0], setRating = _d[1];
    var onChangeRating = function (e, prior) {
        setRating(Number(e.target.value));
        if (onChangePrior && id) {
            onChangePrior(e, prior, id);
        }
    };
    var starColor;
    switch (true) {
        case rating < 1:
            starColor = 'star';
            break;
        case rating < 20:
            starColor = 'star star--gray-gradient';
            break;
        case rating < 30:
            starColor = 'star star--orange';
            break;
        case rating < 45:
            starColor = 'star star--orange-gradient';
            break;
        case rating < 55:
            starColor = 'star star--lemon';
            break;
        case rating < 70:
            starColor = 'star star--lemon-gradient';
            break;
        case rating < 80:
            starColor = 'star star--lime';
            break;
        case rating < 100:
            starColor = 'star star--lime-gradient';
            break;
        default:
            starColor = 'star star--green';
    }
    return (<>
      {expand ? (<div className={background === 'transparent'
                ? 'condition__prior'
                : 'condition__prior transparent'}>
          <div className="condition__top">
            <Input placeholder="Введите наименование" onChange={function (e) { return onChangeInput(e, id); }} value={title} id={id} name={'name'}/>
            <Input placeholder="Введите префикс" onChange={function (e) { return onChangeInput(e, id); }} value={prefix} id={id} name={'valprefix'}/>
            <div className="miniPrior__buttons">
              <FloppyDisk style={{ cursor: 'pointer' }} onClick={function () {
                onBlur(rating, id);
                setExpand(false);
            }}/>
              <Trash style={{ cursor: 'pointer' }} onClick={onDelete}/>
              <Expand style={{ cursor: 'pointer' }} onClick={expandHandler}/>
            </div>
          </div>
          <p>Приоритет</p>
          <div className="condition__bottom">
            <RangeSlider value={rating} onChange={function (e) { return onChangeRating(e, Number(e.target.value)); }} onBlur={function (e) {
                return onBlur && onBlur(+e.target.value, id);
            }} max={100} min={0} step={1}>
              <Stars />
            </RangeSlider>
          </div>
        </div>) : (<div className={background === 'transparent'
                ? 'condition__miniPrior miniPrior'
                : 'condition__miniPrior miniPrior transparent'}>
          <div className="miniPrior__left">
            <div className="miniPrior__title">{title}</div>
            <div className="miniPrior__prefix">{prefix}</div>
          </div>
          <div className="miniPrior__right">
            <div className="miniPrior__rating">
              <p>{rating}</p>
              <div className={starColor}>
                <Star />
              </div>
            </div>
            <div className="miniPrior__buttons">
              <FloppyDisk style={{ cursor: 'pointer' }} onClick={function () {
                onSave && onSave();
                setExpand(false);
            }} onBlur={function () {
                onBlur(rating, id);
            }}/>
              <Trash style={{ cursor: 'pointer' }} onClick={onDelete}/>
              <Expand style={{ cursor: 'pointer' }} onClick={expandHandler}/>
            </div>
          </div>
        </div>)}
    </>);
};
export default ConditionPrior;
