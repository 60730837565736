var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ReactComponent as Edit } from '@/assets/images/table-edit.svg';
import AddBtn from '@/components/button/addBtn';
import FormDelete from '@/components/formDelete';
import Input from '@/components/input/input';
import Loader from '@/components/loader';
import Modal from '@/components/modal';
import Table from '@/components/table';
import { getColumnsAnswersItem } from '@/pages/services/tabs/servicePolls/helpers/columns';
import { getNewAction, getPayloadDeleteAnswer, getPayloadSubmitAnswer, getPayloadUpdateAnswer, } from '@/pages/services/tabs/servicePolls/helpers/utils';
import { polls } from '@/utils/rest/pages/services';
var PollsQuestionEdit = function (_a) {
    var _b, _c;
    var data = _a.data, handleCancel = _a.handleCancel, refetch = _a.refetch, _d = _a.background, background = _d === void 0 ? 'transparent' : _d, _e = _a.pollsQuestion, pollsQuestion = _e === void 0 ? true : _e;
    var isLoading = useQuery('polls updated', polls.get).isLoading;
    var _f = useState(data), formData = _f[0], setFormData = _f[1];
    var _g = useState(''), action = _g[0], setAction = _g[1];
    var _h = useState(''), question = _h[0], setQuestion = _h[1];
    var _j = useState(''), questionName = _j[0], setQuestionName = _j[1];
    var _k = useState(null), currentRow = _k[0], setCurrentRow = _k[1];
    useEffect(function () { return setFormData(data); }, [data]);
    var pollsUpdateQuestion = useMutation(polls.updateQuestion, {
        onSuccess: function () {
            setCurrentRow(null);
            if (refetch) {
                refetch();
            }
        },
    });
    var handleQuestion = function (e) {
        return setQuestion(e.target.value);
    };
    var handleQuestionName = function (e) {
        return formData
            ? setFormData([__assign(__assign({}, formData[0]), { questiontext: e.target.value })])
            : setQuestionName(e.target.value);
    };
    var handleQuestionSubmit = function () {
        var _a;
        if (formData) {
            var payload = getPayloadSubmitAnswer(currentRow, formData, questionName, question);
            var isValid_1 = true;
            payload.correctAnswers.forEach(function (el) {
                if (el.answertxt === '') {
                    isValid_1 = false;
                }
            });
            if (isValid_1) {
                pollsUpdateQuestion.mutate({ id: (_a = formData[0]) === null || _a === void 0 ? void 0 : _a.id, payload: payload });
            }
        }
    };
    var handleQuestionUpdate = function () {
        var _a;
        if (formData) {
            var payload = getPayloadUpdateAnswer(currentRow, formData, questionName);
            pollsUpdateQuestion.mutate({ id: (_a = formData[0]) === null || _a === void 0 ? void 0 : _a.id, payload: payload });
        }
    };
    var handleDelete = function () {
        var _a;
        if (currentRow === null || currentRow === void 0 ? void 0 : currentRow.id) {
            var payload = getPayloadDeleteAnswer(currentRow, formData, questionName);
            formData && pollsUpdateQuestion.mutate({ id: (_a = formData[0]) === null || _a === void 0 ? void 0 : _a.id, payload: payload });
        }
    };
    var columns = getColumnsAnswersItem(currentRow, action, handleQuestionUpdate, background);
    var handleRow = function (e, rowData, column) {
        var newAction = getNewAction(column.field);
        if ((currentRow === null || currentRow === void 0 ? void 0 : currentRow.id) === rowData.id && action === newAction) {
            handleCancel();
        }
        else {
            setAction(newAction || (column === null || column === void 0 ? void 0 : column.field));
            setCurrentRow(rowData);
        }
    };
    var pollsQuestionHandleCancel = function () {
        setAction('');
        setCurrentRow(null);
    };
    if (isLoading) {
        return <Loader />;
    }
    return (<div className="editable-item__form">
      <Modal clean open={!!currentRow && action === 'delete'}>
        <FormDelete handleSubmit={handleDelete} handleCancel={pollsQuestionHandleCancel}/>
      </Modal>
      <div className={background === 'transparent'
            ? 'editable-item__form-row'
            : 'editable-item__form-row editable-item__form-row_transparent'}>
        <Input text="Текст вопроса" placeholder="Введите наименование" value={formData ? (_b = formData[0]) === null || _b === void 0 ? void 0 : _b.questiontext : questionName} name="surveyname" onChange={handleQuestionName} width={360}/>
        <div className="edit-wrapper">
          <Edit style={{ cursor: 'pointer' }} onClick={handleQuestionUpdate}/>
        </div>
        {pollsQuestion && (<>
            <Input text="Ответ" placeholder="Введите ответ" value={question} name=" surveyname" onChange={handleQuestion} width={360}/>
            <div className="button-wrapper">
              <AddBtn handleClick={handleQuestionSubmit} text="Добавить ответ"/>
            </div>
          </>)}
      </div>
      <Table columns={columns} headerStyle={{ fontSize: 14 }} cellStyle={{ fontSize: 12 }} rowStyle={function () { return ({
            background: background === 'transparent' ? '#F6F6F6' : '#FFFFFF',
        }); }} isLoading={isLoading} onClick={handleRow} data={formData && ((_c = formData[0]) === null || _c === void 0 ? void 0 : _c.answers)} paginationBottom={false}/>
    </div>);
};
export default PollsQuestionEdit;
