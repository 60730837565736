import React, { useEffect, useState } from 'react';
import './input.css';
var RangeSlider = function (_a) {
    var value = _a.value, onChange = _a.onChange, name = _a.name, max = _a.max, step = _a.step, min = _a.min, _b = _a.width, width = _b === void 0 ? 470 : _b, children = _a.children, onBlur = _a.onBlur;
    var renderSteps = function () {
        var steps = [];
        for (var i = 0; i <= max; i += step) {
            steps.push(<option key={i} value={i}>
          {i}
        </option>);
        }
        return steps;
    };
    var _c = useState(value), valuePosition = _c[0], setValuePosition = _c[1];
    useEffect(function () {
        setValuePosition(value);
    }, [value]);
    return (<label className="range-slider" htmlFor={name} style={{ maxWidth: width }}>
      <input name={name} type="range" max={max} min={min} step={step} value={value} onChange={onChange} onBlur={onBlur} list="steps"/>
      <span className="range-slider__children">{children}</span>
      <datalist id="steps">{renderSteps()}</datalist>
      <p className="range-slider__value" style={{ left: "".concat(valuePosition, "%") }}>
        {value}
      </p>
      <div className="range-slider__scale">
        <div>
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill="#37798E"/>
          </svg>
          <p>0</p>
        </div>
        <div>
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill="#37798E"/>
          </svg>
          <p>25</p>
        </div>
        <div>
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill="#37798E"/>
          </svg>
          <p>50</p>
        </div>
        <div>
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill="#37798E"/>
          </svg>
          <p>75</p>
        </div>
        <div>
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill="#37798E"/>
          </svg>
          <p>100</p>
        </div>
      </div>
    </label>);
};
export default RangeSlider;
