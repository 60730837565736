import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import CheckAllBtn from '@/components/button/checkAllBtn';
import DeleteAll from '@/components/button/deleteAll';
import Element from './element';
var Column = function (_a) {
    var id = _a.id, column = _a.column, items = _a.items, checkAll = _a.checkAll, delAll = _a.delAll, buttons = _a.buttons, select = _a.select, options = _a.options, handleOptionSelect = _a.handleOptionSelect;
    return (<>
      <div key={id}>
        <p className="draggable__title">{column.title}</p>
        <Droppable droppableId={id}>
          {function (provided) { return (<div className="draggable__field" ref={provided.innerRef} {...provided.droppableProps}>
              {buttons && column.id === 'select' ? (<CheckAllBtn text={'Выбрать всё'} handleClick={checkAll}/>) : (buttons && (<DeleteAll text={'Удалить всё'} handleClick={delAll}/>))}
              {items.map(function (item, index) { return (<Element handleOptionSelect={handleOptionSelect} options={options} select={select} column_id={column.id} index={index} item={item} key={item.id}/>); })}
              {provided.placeholder}
            </div>); }}
        </Droppable>
      </div>
    </>);
};
export default Column;
