var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo, useState, } from 'react';
import { getPages } from '@/utils';
import Loader from '../loader';
import TablePagination from './tablePagination';
import TableRow from './tableRow';
import './table.css';
var Table = function (_a) {
    var data = _a.data, columns = _a.columns, onClick = _a.onClick, rowStyle = _a.rowStyle, handlePage = _a.handlePage, paginationTop = _a.paginationTop, activePage = _a.activePage, headerStyle = _a.headerStyle, handleOrder = _a.handleOrder, order = _a.order, _b = _a.perPage, perPage = _b === void 0 ? 8 : _b, isLoading = _a.isLoading, cellStyle = _a.cellStyle, onExpand = _a.onExpand, _c = _a.paginationBottom, paginationBottom = _c === void 0 ? true : _c, _d = _a.valueKey, valueKey = _d === void 0 ? 'id' : _d;
    var _e = useState(1), currentPage = _e[0], setCurrentPage = _e[1];
    var _f = useState(), activeRowId = _f[0], setActiveRowId = _f[1];
    var _g = useState(perPage), currentPerPage = _g[0], setCurrentPerPage = _g[1];
    var currentActivePage = activePage || currentPage;
    var handleClick = function (e, rowData, column) {
        if (onClick) {
            onClick(e, rowData, column);
            setActiveRowId(rowData[valueKey]);
        }
    };
    var renderHeaders = useMemo(function () {
        return columns.map(function (item) { return (<th key={item.field} className="flex-row" style={__assign(__assign({}, headerStyle), item.headerStyle)} onClick={handleOrder
                ? function () {
                    if (item.order) {
                        handleOrder(item === null || item === void 0 ? void 0 : item.field);
                    }
                }
                : undefined}>
          {item.title}{' '}
          {(item === null || item === void 0 ? void 0 : item.field) && (order === null || order === void 0 ? void 0 : order.name[0]) === (item === null || item === void 0 ? void 0 : item.field) ? (<span style={{
                    transform: "rotate(".concat((order === null || order === void 0 ? void 0 : order.top) ? '-90deg' : '90deg', ")"),
                }}>
              ❱
            </span>) : null}
          {item.order && (order === null || order === void 0 ? void 0 : order.name[0]) !== item.field ? (<span style={{ transform: 'rotate(-90deg)' }}>❱</span>) : null}
        </th>); });
    }, [columns]);
    var renderRows = useMemo(function () {
        if (data) {
            var currentRows = getPages(data, currentPerPage, currentActivePage);
            return currentRows === null || currentRows === void 0 ? void 0 : currentRows.map(function (rowData, i) { return (<React.Fragment key={rowData[valueKey]}>
          <TableRow rowStyle={rowStyle} cellStyle={cellStyle} rowData={rowData} columns={columns} onClick={handleClick} index={i}/>
          {Number(rowData[valueKey]) === Number(activeRowId) && onExpand && (<tr style={{ width: '100%' }}>
              {onExpand(rowData, i)}
            </tr>)}
        </React.Fragment>); });
        }
    }, [data, columns, activeRowId]);
    var pagination = function () {
        if (data) {
            return (<TablePagination handlePage={handlePage || setCurrentPage} activePage={currentActivePage} handlePerPage={setCurrentPerPage} count={data.length} perPage={currentPerPage}/>);
        }
    };
    if (isLoading) {
        return <Loader />;
    }
    return (<>
      {data && data.length > 0 ? (<>
          {paginationTop && pagination()}
          <table className="table-box">
            <tbody>
              <tr className="flex-table flex-table__header">{renderHeaders}</tr>
              {renderRows}
            </tbody>
          </table>
          {paginationBottom && pagination()}
        </>) : null}
      {data && data.length < 1 && <p className="table-info">Нет данных</p>}
    </>);
};
export default Table;
