var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import { getGraphSqlString } from '@/pages/statistics/reports/tabs/consolidated/getSqlStr';
import { BasicSettings } from '@/pages/statistics/reports/tabs/consolidated/graphSettings';
import { consolidatedReport } from '@/utils/rest/pages/reports';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
var LineReport = function (_a) {
    var filterParams = _a.filterParams;
    var _b = useState({
        labels: [],
        datasets: [],
    }), reportParams = _b[0], setReportParams = _b[1];
    var sqlCreate = getGraphSqlString(filterParams.startDate, filterParams.endDate, 0);
    var sqlClose = getGraphSqlString(filterParams.startDate, filterParams.endDate, 3);
    var sqlNone = getGraphSqlString(filterParams.startDate, filterParams.endDate, 4);
    var _c = useQuery('graphCreateData', function () { return consolidatedReport.get(sqlCreate); }), isCreateLoading = _c.isLoading, graphCreateData = _c.data, graphCreateRefetch = _c.refetch;
    var _d = useQuery('graphCloseData', function () { return consolidatedReport.get(sqlClose); }), isCloseLoading = _d.isLoading, graphCloseData = _d.data, graphCloseRefetch = _d.refetch;
    var _e = useQuery('graphNoneData', function () { return consolidatedReport.get(sqlNone); }), isNoneLoading = _e.isLoading, graphNoneData = _e.data, graphNoneRefetch = _e.refetch;
    var dataGraph = {};
    var dataClose = {};
    var dataNone = {};
    for (var i = 0; i < 24; i += 1) {
        dataGraph[i] = 0;
        dataClose[i] = 0;
        dataNone[i] = 0;
    }
    if (!isCreateLoading) {
        graphCreateData.data.forEach(function (element) {
            dataGraph[Number(element[1])] = Number(element[0]);
        });
    }
    if (!isCloseLoading) {
        graphCloseData.data.forEach(function (element) {
            dataClose[Number(element[1])] = Number(element[0]);
        });
    }
    if (!isNoneLoading) {
        graphNoneData.data.forEach(function (element) {
            dataNone[Number(element[1])] = Number(element[0]);
        });
    }
    var allCntData = [];
    for (var i = 0; i < 24; i += 1) {
        allCntData.push(Number(Object.values(dataNone)[i]) + Number(Object.values(dataClose)[i]));
    }
    var createData = __assign(__assign({ label: 'Создано', data: Object.values(dataGraph) }, BasicSettings), { pointHoverBackgroundColor: '#37798E', borderColor: '#37798E', backgroundColor: '#37798E' });
    var noneData = __assign(__assign({ label: 'Закрыто по неявке', data: Object.values(dataNone) }, BasicSettings), { pointHoverBackgroundColor: '#f75454', borderColor: '#FF515B', backgroundColor: '#FF515B' });
    var closeData = __assign(__assign({ label: 'Закрытых', data: Object.values(dataClose) }, BasicSettings), { pointHoverBackgroundColor: '#F28F57', borderColor: '#F28F57', backgroundColor: '#F28F57' });
    var allData = __assign(__assign({ label: 'Всего обслужено', data: allCntData }, BasicSettings), { pointHoverBackgroundColor: '#76C944', borderColor: '#76C944', backgroundColor: '#76C944' });
    var graphOptions = {
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 20,
                },
            },
        },
    };
    useEffect(function () {
        var reportSettings = {
            labels: [
                '00:00',
                '',
                '02:00',
                '',
                '04:00',
                '',
                '06:00',
                '',
                '08:00',
                '',
                '10:00',
                '',
                '12:00',
                '',
                '14:00',
                '',
                '16:00',
                '',
                '18:00',
                '',
                '20:00',
                '',
                '22:00',
                '',
            ],
            datasets: [createData, allData, closeData, noneData],
        };
        setReportParams(reportSettings);
    }, [graphCreateData, graphCloseData, graphNoneData]);
    useEffect(function () {
        graphCreateRefetch();
        graphCloseRefetch();
        graphNoneRefetch();
    }, [filterParams]);
    return (<>
      <div className="report__graph">
        <p className="section-title">Распределение клиентов по часам </p>
        {!isCloseLoading && !isCreateLoading && !isNoneLoading && (<Line data={reportParams} options={graphOptions} height={140}/>)}
      </div>
    </>);
};
export default LineReport;
