import React from 'react';
import './alert.css';
var Alert = function (_a) {
    var handleSubmit = _a.handleSubmit, text = _a.text;
    return (<div className="alert">
      <p className="alert__title">{text}</p>
      <div className="alert__buttons">
        <button onClick={handleSubmit}>Хорошо</button>
      </div>
    </div>);
};
export default Alert;
