var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { notification } from 'antd';
import { getToken } from '@/utils';
import { baseGetApi, publicApi, rootApi, rootApiAdd } from '..';
export var services = {
    get: function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('queue', 'get')];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    update: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi("queue/".concat(data.id), 'update', {
                        payload: data.payload,
                    })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res];
            }
        });
    }); },
    add: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi("queue", 'add', { payload: data.payload })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res];
            }
        });
    }); },
    delete: function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi("queue", 'delete', { id: id })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res];
            }
        });
    }); },
    wplaceDelete: function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi("wplace", 'delete', { id: id })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res];
            }
        });
    }); },
};
export var copies = {
    get: function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, rootApi('/api/backups')];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.backups];
            }
        });
    }); },
    add: function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, rootApiAdd('/api/createbackup')];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res];
            }
        });
    }); },
};
export var users = {
    get: function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('user', 'get')];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    getUser: function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi("user/".concat(id), 'get')];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    getRoles: function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('role', 'get')];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data.filter(function (el) { return el.id !== 3 && el.id !== 7; })];
            }
        });
    }); },
    add: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('user', 'add', {
                        payload: { data: data.payload },
                    })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    update: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('user', 'update', {
                        payload: { data: data.payload },
                        id: data.id,
                    })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    delete: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('user', 'delete', { id: data.id })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    getFilters: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('usertxttfieldfilterattachment', 'get', {
                        payload: { data: { user_id: data.user_id } },
                    })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    getTicketsFields: function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('ticketsfields', 'get')];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    addFilters: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('usertxttfieldfilterattachment', 'add', {
                        payload: {
                            data: { fid: data.fid, fvs: [data.fvs], user_id: data.user_id },
                        },
                    })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    delFilters: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('usertxttfieldfilterattachment', 'delete', {
                        payload: {
                            data: { fid: data.fid, fvs: [data.fvs], user_id: data.user_id },
                        },
                    })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res.data];
            }
        });
    }); },
};
export var workWeeks = {
    get: function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('dworkweek', 'get')];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    update: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi("dworkweek/".concat(data.id), 'update', {
                        payload: { data: data.payload },
                    })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    add: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('dworkweek', 'add', {
                        payload: { data: payload },
                    })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    delete: function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi("dworkweek/".concat(id), 'delete')];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res.data];
            }
        });
    }); },
};
export var officeParams = {
    get: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('officeparams', 'get')];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data.map(function (param) {
                            var newValue = data === null || data === void 0 ? void 0 : data.find(function (item) { return item.officeparam_id === param.id; });
                            if (newValue) {
                                param.value = newValue.overridevalue;
                            }
                            return param;
                        })];
            }
        });
    }); },
    add: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('officeparams', 'update', {
                        payload: { data: [{ param: data.param, value: data.value }] },
                    })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res];
            }
        });
    }); },
};
export var officeParamsBinding = {
    get: function (id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, baseGetApi('officeparamsbinding', 'get', {
                    payload: { data: id },
                })];
        });
    }); },
    add: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('officeparamsbinding', 'add', {
                        payload: { data: payload },
                    })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res];
            }
        });
    }); },
};
export var soundlist = {
    get: function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, rootApi('/api/soundlist')];
        });
    }); },
};
export var intervals = {
    get: function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('dworkinterval', 'get')];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    add: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('dworkinterval', 'add', {
                        payload: { data: __assign({}, payload) },
                    })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    update: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi("dworkinterval/".concat(data.id), 'update', {
                        payload: { data: data.payload },
                    })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    delete: function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi("dworkinterval/".concat(id), 'delete')];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res.data];
            }
        });
    }); },
};
export var days = {
    get: function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('dworkday', 'get')];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    add: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('dworkday', 'add', {
                        payload: { data: payload },
                    })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    update: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi("dworkday/".concat(data.id), 'update', {
                        payload: { data: data.payload },
                    })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    delete: function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi("dworkday/".concat(id), 'delete')];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res.data];
            }
        });
    }); },
};
export var polls = {
    get: function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, publicApi('surveys', {
                        action: 'get',
                        token: getToken(),
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    addSurvey: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('qcsurvey', 'add', {
                        payload: { data: payload },
                    })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res.data];
            }
        });
    }); },
    addQuestion: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('qcquestion', 'add', {
                        payload: {
                            data: {
                                correctAnswers: payload.correctAnswers,
                                operator_mark: payload.operator_mark,
                                questiontext: payload.questiontext,
                            },
                        },
                    })];
                case 1:
                    res = _a.sent();
                    return [4 /*yield*/, polls.update({
                            id: payload.surveyId,
                            payload: {
                                connectedQuestions: payload.connectedQuestions.concat(res.id),
                                surveyname: payload.surveyname,
                            },
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); },
    updateQuestion: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, correctAnswers, questiontext, operator_mark, res;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = data.payload, correctAnswers = _a.correctAnswers, questiontext = _a.questiontext, operator_mark = _a.operator_mark;
                    return [4 /*yield*/, baseGetApi("qcquestion/".concat(data.id), 'update', {
                            payload: {
                                data: {
                                    correctAnswers: correctAnswers,
                                    questiontext: questiontext,
                                    operator_mark: operator_mark,
                                },
                            },
                        })];
                case 1:
                    res = _b.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res];
            }
        });
    }); },
    update: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('qcsurvey', 'update', {
                        payload: { data: data.payload },
                        id: "".concat(data.id, "/"),
                    })];
                case 1:
                    res = _a.sent();
                    if (res.error) {
                        notification.error({
                            message: 'Ошибка',
                            description: res.error.text.rus,
                        });
                    }
                    return [2 /*return*/, res];
            }
        });
    }); },
    delete: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('qcsurvey', 'delete', {
                        id: data.id + '/',
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); },
    deleteQuestion: function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, baseGetApi('qcquestion', 'delete', {
                        id: data.id + '/',
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); },
};
