import React from 'react';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import { ReactComponent as Download } from '@/assets/images/download.svg';
// import { ReactComponent as Trash } from '@/assets/images/table-trash.svg';
import CopiesBtn from '@/components/button/copiesBtn';
import AntTableWrapper from '@/components/table/antTableWrapper';
import { dateFormat } from '@/constants/common';
import { copies } from '@/utils/rest/pages/services';
import { apiRoot } from 'config';
var Copies = function () {
    var _a = useQuery('backups', copies.get), isLoading = _a.isLoading, data = _a.data, refetch = _a.refetch;
    if (!isLoading) {
        data === null || data === void 0 ? void 0 : data.forEach(function (el, idx) {
            el['key'] = idx;
        });
    }
    var columns = [
        { dataIndex: 'name', title: 'Название', align: 'center' },
        { dataIndex: 'path', title: 'Путь', align: 'center' },
        {
            dataIndex: 'date',
            title: 'Дата создания',
            align: 'center',
            render: function (_, rowData) { return moment(String(rowData.date)).format(dateFormat); },
        },
        {
            dataIndex: 'download',
            width: 100,
            align: 'center',
            render: function (_, rowData) { return (<span>
          <a href={"".concat(apiRoot).concat(rowData.path)} download>
            <Download />
          </a>
        </span>); },
        },
        // {
        //   field: 'delete',
        //   style: { width: '4%', cursor: 'pointer' },
        //   render: () => (
        //     <span>
        //       <Trash />
        //     </span>
        //   ),
        // },
    ];
    var addCopyMutation = useMutation(copies.add, { onSuccess: refetch });
    var addCopy = function () {
        addCopyMutation.mutate();
    };
    return (<>
      <CopiesBtn text={'Создать резервную копию'} handleClick={addCopy}/>
      <p className="section-title">Резервные копии</p>
      {/*<Table*/}
      {/*  data={data}*/}
      {/*  columns={columns}*/}
      {/*  isLoading={isLoading}*/}
      {/*  headerStyle={{ fontSize: 14 }}*/}
      {/*  cellStyle={{ fontSize: 12 }}*/}
      {/*/>*/}
      <AntTableWrapper columns={columns} data={data} rowKey={'key'} loading={isLoading} bordered={false}/>
    </>);
};
export default Copies;
