import React, { useEffect, useMemo, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { ReactComponent as Arrow } from '@/assets/images/table-left-arr.svg';
import './input.css';
var Select = function (_a) {
    var _b = _a.options, options = _b === void 0 ? [
        { name: 'Да', value: 1 },
        { name: 'Нет', value: 0 },
    ] : _b, valid = _a.valid, value = _a.value, onChange = _a.onChange, disabled = _a.disabled, width = _a.width, text = _a.text, placeholder = _a.placeholder, name = _a.name, type = _a.type, children = _a.children, left = _a.left;
    var _c = useState(false), isOpen = _c[0], setIsOpen = _c[1];
    var el = useRef(null);
    var handleClose = function (e) {
        var _a;
        if (e.target !== el.current && !((_a = el.current) === null || _a === void 0 ? void 0 : _a.contains(e.target))) {
            setIsOpen(false);
        }
    };
    useEffect(function () {
        if (isOpen) {
            document.body.addEventListener('click', handleClose);
        }
        else {
            document.body.removeEventListener('click', handleClose);
        }
        return function () {
            document.body.removeEventListener('click', handleClose);
        };
    }, [isOpen]);
    var handleSelect = function () {
        if (!disabled) {
            setIsOpen(function (oldValue) { return !oldValue; });
        }
    };
    var handleOption = function (option) {
        if (onChange) {
            onChange({ option: option, name: name });
            setIsOpen(function (oldValue) { return !oldValue; });
        }
    };
    var renderOptions = useMemo(function () {
        return options === null || options === void 0 ? void 0 : options.map(function (item) { return (<li key={item.value} onClick={function () { return handleOption(item); }}>
        {item.name}
      </li>); });
    }, [options]);
    var renderSelectedValue = function () {
        if (type === 'boolean') {
            return value ? 'Да' : 'Нет';
        }
        if (typeof value === 'object') {
            return value.map(function (el) { return <p key={nanoid()}>{el}</p>; });
        }
        return value || placeholder;
    };
    return (<label className={valid && !value ? 'select select_alert' : 'select'} style={{ maxWidth: width }}>
      {text && <p className="select__title">{text}</p>}
      <div style={{ fontSize: 14 }} onClick={handleSelect} className={isOpen ? 'select__value select__value_active' : 'select__value'}>
        <>
          {renderSelectedValue()}
          <Arrow />
        </>
      </div>
      {type === 'dropdown' ? (<div ref={el} className={isOpen
                ? left
                    ? 'select__dropdown select__dropdown_active select__dropdown-left'
                    : 'select__dropdown select__dropdown_active'
                : 'select__dropdown'}>
          {children}
        </div>) : (<div className={isOpen
                ? 'select__options_active select__options'
                : 'select__options'}>
          {renderOptions}
        </div>)}
    </label>);
};
export default Select;
