export var ticketStatuses = {
    0: 'Создан и находится в изначальной очереди',
    1: 'Вызван на обработку',
    2: 'В процессе обработки',
    5: 'Переведён в новую очередь',
    6: 'Ожидание решения',
    7: 'Возвращён в очередь',
    8: 'Ручная обработка диспетчером',
    9: 'Удален из-за наступления нового дня (талон по какой-то причине просуществовал до ночи в системе)',
    11: 'Переведен на рабочее место',
};
export var wpStatuses = {
    1: 'Оператор ожидает',
    2: 'Оператор вызвал талон',
    3: 'Обслуживает талон',
    4: 'Оператор на паузе',
    5: 'Оператор ожидает',
    8: 'Оператор на паузе',
};
