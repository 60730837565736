var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { ReactComponent as Save } from '@/assets/images/floppy-disk.svg';
import { ReactComponent as Delete } from '@/assets/images/table-trash.svg';
import Button from '@/components/button';
import Input from '@/components/input/input';
import { statuses } from '@/utils/rest/pages/statuses';
var SubstatusesEdit = function (_a) {
    var data = _a.data, refetch = _a.refetch, handleCancel = _a.handleCancel;
    var _b = useState({
        parent_status: Number(data.id),
        id: '',
        description: '',
    }), currentSubstatus = _b[0], setCurrentSubstatus = _b[1];
    var deleteSubstatus = useMutation('deleteSubstatus', statuses.delSubStatus, {
        onSuccess: function () {
            refetch();
            handleCancel();
        },
    });
    var updateSubStatus = useMutation('updateSubstatus', statuses.updateSubStatus, {
        onSuccess: function () {
            refetch();
            handleCancel();
        },
    });
    var handleSubmit = function () {
        updateSubStatus.mutate(currentSubstatus);
        setCurrentSubstatus({
            parent_status: Number(data.id),
            id: '',
            description: '',
        });
    };
    var handleDelete = function (id) {
        deleteSubstatus.mutate(id);
    };
    var handleChange = function (e) {
        var value = e.target.value;
        var newSubstatus = __assign({}, currentSubstatus);
        newSubstatus.description = value;
        setCurrentSubstatus(newSubstatus);
    };
    var handleClick = function (substatus) {
        var newCurrentSubstatus = __assign({}, currentSubstatus);
        newCurrentSubstatus.id = substatus.id;
        newCurrentSubstatus.description = substatus.description;
        setCurrentSubstatus(newCurrentSubstatus);
    };
    return (<>
      {data.substatuses.length > 0 ? (data.substatuses.map(function (el) { return (<div className="substatus__item" key={el.id}>
            {el.id === currentSubstatus.id ? (<Input value={currentSubstatus.description} onChange={handleChange} autoFocus={true} width={300}/>) : (<p onClick={function () { return handleClick(el); }}>{el.description}</p>)}
            {el.id === currentSubstatus.id ? (<button onClick={handleSubmit}>
                <Save />
              </button>) : (<button style={{ height: '20px' }} onClick={function () { return handleDelete(el.id); }}>
                <Delete />
              </button>)}
          </div>); })) : (<h3 style={{ margin: '0 auto', width: 'fit-content' }}>
          Нет субстатусов
        </h3>)}
      <div className="editable-item__form-btns">
        <Button text="Закрыть" onClick={handleCancel}/>
      </div>
    </>);
};
export default SubstatusesEdit;
