import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { getCardSqlString } from '@/pages/statistics/reports/tabs/consolidated/getSqlStr';
import { consolidatedReport } from '@/utils/rest/pages/reports';
var CardReport = function (_a) {
    var filterParams = _a.filterParams;
    var sqlCardReport = getCardSqlString(filterParams.startDate, filterParams.endDate);
    var _b = useQuery('cardData', function () { return consolidatedReport.get(sqlCardReport); }), isCardLoading = _b.isLoading, cardData = _b.data, cardRefetch = _b.refetch;
    useEffect(function () {
        cardRefetch();
    }, [filterParams]);
    return (<div className="report__card">
      <p className="report__card__text report__card__text-prim">
        Обслужено клиентов
      </p>
      {!isCardLoading && !cardData.error && (<>
          <p className="report__card__text__value report__card__text__value-prim">
            {cardData.data[0][cardData.columnNames.indexOf('count')]}
          </p>
          <p className="report__card__text">Закрыто</p>
          <p className="report__card__text__value">
            {cardData.data[0][cardData.columnNames.indexOf('close_cnt')]
                ? cardData.data[0][cardData.columnNames.indexOf('close_cnt')]
                : 0}
          </p>
          <p className="report__card__text">Неявка</p>
          <p className="report__card__text__value">
            {cardData.data[0][cardData.columnNames.indexOf('none_cnt')]
                ? cardData.data[0][cardData.columnNames.indexOf('none_cnt')]
                : 0}
          </p>
          <p className="report__card__text">Среднее время обслуживания</p>
          <p className="report__card__text__value">
            {cardData.data[0][cardData.columnNames.indexOf('avg_proc_time')]
                ? cardData.data[0][cardData.columnNames.indexOf('avg_proc_time')]
                : 0}
          </p>
          <p className="report__card__text">
            Среднее время
            <br /> ожидания
          </p>
          <p className="report__card__text__value">
            {cardData.data[0][cardData.columnNames.indexOf('avg_wait_time')]
                ? cardData.data[0][cardData.columnNames.indexOf('avg_wait_time')]
                : 0}
          </p>
          <p className="report__card__text">
            Удалено клиентов
            <br /> системой
          </p>
          <p className="report__card__text__value">
            {cardData.data[0][cardData.columnNames.indexOf('del_cnt')]
                ? cardData.data[0][cardData.columnNames.indexOf('del_cnt')]
                : 0}
          </p>
        </>)}
    </div>);
};
export default CardReport;
