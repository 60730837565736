export var archiveTypes = {
    exactlyOperator: 'Отчет по оператору',
    exactlyQueue: 'Отчет по очереди',
    exactlyWorkplace: 'Отчет по рабочему месту',
    ticketReport: 'Отчет по клиентам',
    queueReport: 'Отчет по услугам',
    operatorReport: 'Отчет по операторам',
    workPlaceQuery: 'Отчет по рабочим местам',
    ticketHistoryReport: 'Отчет история талона',
    markReport: 'Отчет по оценкам',
    clockwiseReport: 'Почасовой отчет',
    dynamicsServiceTimeReport: 'Отчет по динамике времени обслуживания по услугам',
    operatorLogi: 'Отчет по входам операторов',
    dictionaryReport: 'Отчет по опросам',
    requestReport: 'Отчет по оценкам',
    sourceReport: 'Отчет по "источнику"',
};
