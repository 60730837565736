import moment from 'moment';
export var columns = [
    {
        title: 'Информация о клиенте',
        fixed: 'left',
        children: [
            {
                title: 'Id',
                dataIndex: 'ticket_id',
                key: 'ticket_id',
                width: 100,
                fixed: 'left',
                sorter: function (a, b) { return Number(a.ticket_id) - Number(b.ticket_id); },
            },
            {
                title: '№',
                dataIndex: 'ticket_number',
                key: 'ticket_number',
                width: 100,
                fixed: 'left',
            },
        ],
    },
    {
        title: 'Дата',
        children: [
            {
                title: 'Создания',
                dataIndex: 'create_data',
                key: 'create_data',
                width: 160,
                sorter: function (a, b) {
                    var aToSec = moment(a.create_data, 'DD.MM.YYYY HH:mm:ss').format('x');
                    var bToSec = moment(b.create_data, 'DD.MM.YYYY  HH:mm:ss').format('x');
                    return Number(aToSec) - Number(bToSec);
                },
            },
            {
                title: 'Закрытия',
                dataIndex: 'close_data',
                key: 'close_data',
                width: 160,
            },
        ],
    },
    {
        title: 'Услуга',
        dataIndex: 'queue_name',
        width: 160,
    },
    {
        title: 'Оператор',
        dataIndex: 'user_fio',
        width: 160,
    },
];
