var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '@/assets/images/new-logo.svg';
import Button from '@/components/button';
import Input from '@/components/input/input';
import Loader from '@/components/loader';
import { loginDefault, passwordDefault } from '@/constants/auth';
import { useLogin, useLoginPost } from '@/hooks/query/login';
import { setToken } from '@/utils';
import packageInfo from '../../../package.json';
import texts from './texts.json';
import './login.css';
var Login = function () {
    var version = packageInfo.version;
    var t = useTranslation(['login']).t;
    var isLoading = useLogin(setToken).isLoading;
    var mutation = useLoginPost(setToken);
    var _a = useState({
        username: loginDefault,
        password: passwordDefault,
    }), login = _a[0], setLogin = _a[1];
    var _b = useState({
        username: false,
        password: false,
    }), error = _b[0], setError = _b[1];
    var handleInput = useCallback(function (e) {
        var _a = e.target, value = _a.value, name = _a.name;
        setLogin(function (oldValue) {
            var _a;
            return (__assign(__assign({}, oldValue), (_a = {}, _a[name] = value, _a)));
        });
        if (value && (error.username || error.password)) {
            setError(function (oldValue) {
                var _a;
                return (__assign(__assign({}, oldValue), (_a = {}, _a[name] = false, _a)));
            });
        }
    }, [error]);
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var username, password;
        return __generator(this, function (_a) {
            e.preventDefault();
            username = login.username, password = login.password;
            if (username && password) {
                mutation.mutate({
                    userLogin: username,
                    userPasswd: password,
                });
            }
            else {
                setError({ username: !username, password: !password });
            }
            return [2 /*return*/];
        });
    }); };
    if (isLoading || mutation.isLoading) {
        return <Loader />;
    }
    return (<div className="login">
      <div className="login__box">
        <div style={{ textAlign: 'end', margin: '0 14px 0 0' }}>{"v. ".concat(version)}</div>
        <div className="login__logo">
          <img src={logo} alt="logo"/>
          {/*<div className="login__locale">*/}
          {/*  <Locale />*/}
          {/*</div>*/}
        </div>
        <div className="login__content">
          <form className="login__form">
            <p className="login__form-title">{t('login')}</p>
            <Input name="username" placeholder={texts.loginPlaceholder} value={login.username} text={texts.login} onChange={handleInput} error={error.username}/>
            <Input name="password" type="password" placeholder={texts.passwordPlaceholder} value={login.password} text={texts.password} onChange={handleInput} error={error.password}/>
            <Button text="Вход" type="submit" onClick={handleSubmit}/>
          </form>
        </div>
      </div>
    </div>);
};
export default Login;
