var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import Select from '@/components/input/select';
import MultiSelect from '@/components/multiSelect';
var QueueSelect = function (_a) {
    var handleSetFilters = _a.handleSetFilters, filtersData = _a.filtersData;
    var _b = useState([]), selectedQueues = _b[0], setSelectedQueues = _b[1];
    var queues = filtersData === null || filtersData === void 0 ? void 0 : filtersData.queues.map(function (item) { return ({
        id: item.id,
        name: item.fullname,
    }); });
    var handleCheckQueues = function (id) {
        if (id === -2) {
            setSelectedQueues([]);
            return;
        }
        if (selectedQueues.includes(id)) {
            var newSelectedQueues = __spreadArray([], selectedQueues, true);
            newSelectedQueues.splice(newSelectedQueues.indexOf(id), 1);
            setSelectedQueues(__spreadArray([], newSelectedQueues, true));
            return;
        }
        if (id === -1) {
            var newSelectedQueues_1 = [];
            queues === null || queues === void 0 ? void 0 : queues.forEach(function (item) {
                newSelectedQueues_1.push(item.id);
            });
            setSelectedQueues(newSelectedQueues_1);
            return;
        }
        setSelectedQueues(__spreadArray(__spreadArray([], selectedQueues, true), [id], false));
    };
    useEffect(function () { return handleSetFilters('queue', selectedQueues); }, [selectedQueues]);
    var values = queues === null || queues === void 0 ? void 0 : queues.filter(function (el) { return selectedQueues.includes(el.id); });
    return (<Select name="queues" text="Список услуг" type="dropdown" placeholder="Выбрать" left={true} value={values && values.length > 0 ? values === null || values === void 0 ? void 0 : values.map(function (el) { return el.name; }) : undefined}>
      <div>
        <MultiSelect handleCheck={handleCheckQueues} selected={selectedQueues} data={queues}/>
      </div>
    </Select>);
};
export default QueueSelect;
