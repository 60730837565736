import React from 'react';
var Star = function () {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5655 9.10305C19.9591 8.71939 20.0981 8.15632 19.9283 7.63289C19.7581 7.10946 19.3148 6.73581 18.7701 6.65652L13.9275 5.95287C13.7213 5.92284 13.543 5.79348 13.4509 5.60646L11.2859 1.21879C11.0428 0.725801 10.5494 0.419434 9.99959 0.419434C9.45013 0.419434 8.95673 0.725801 8.71364 1.21879L6.54824 5.60686C6.45613 5.79388 6.27752 5.92324 6.07127 5.95327L1.22866 6.65692C0.684407 6.73581 0.240674 7.10986 0.07047 7.63329C-0.0993337 8.15672 0.039633 8.71979 0.433305 9.10345L3.93711 12.5188C4.08649 12.6645 4.15497 12.8744 4.11973 13.0794L3.29314 17.902C3.21985 18.3265 3.33118 18.7394 3.60591 19.065C4.03282 19.5724 4.77812 19.727 5.37403 19.4138L9.70483 17.1367C9.88585 17.0418 10.1137 17.0426 10.2943 17.1367L14.6255 19.4138C14.8362 19.5248 15.0609 19.5808 15.2927 19.5808C15.716 19.5808 16.1173 19.3926 16.3933 19.065C16.6684 18.7394 16.7793 18.3257 16.706 17.902L15.879 13.0794C15.8438 12.874 15.9123 12.6645 16.0617 12.5188L19.5655 9.10305Z" fill="#A6A6A6"/>
      <defs>
        <linearGradient id="gradient_gray" x2="0" y2="90%">
          <stop stopColor="#A6A6A6" offset="0%"/>
          <stop stopColor="#F28F57" offset="100%"/>
        </linearGradient>
        <linearGradient id="gradient_orange" x2="0" y2="90%">
          <stop stopColor="#F28F57" offset="0%"/>
          <stop stopColor="#F2D01F" offset="100%"/>
        </linearGradient>
        <linearGradient id="gradient_yellow" x2="0" y2="90%">
          <stop stopColor="#F2D01F" offset="0%"/>
          <stop stopColor="#BEC944" offset="100%"/>
        </linearGradient>
        <linearGradient id="gradient_green" x2="0" y2="90%">
          <stop stopColor="#BEC944" offset="0%"/>
          <stop stopColor="#76C944" offset="100%"/>
        </linearGradient>
      </defs>
    </svg>);
};
export default Star;
