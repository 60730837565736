import moment from 'moment';
import { dateFormat } from '@/constants/common';
export var defaultSelectOptions = [
    { value: '', name: 'Отменить' },
    { value: '>', name: 'Больше' },
    { value: '>=', name: 'Больше или равно' },
    { value: '=', name: 'Равно' },
    { value: '<', name: 'Меньше' },
    { value: '<=', name: 'Меньше или равно' },
    { value: 'range', name: 'Между' },
];
export var priorityOptions = [
    { value: 'all', name: 'Все' },
    { value: 'Высокий', name: 'Высокий' },
    { value: 'Обычный', name: 'Обычный' },
];
export var statusOptions = [
    { value: 'all', name: 'Все' },
    { value: 'Закрыт', name: 'Закрыт' },
    { value: 'Закрыт по неявке', name: 'Закрыт по неявке' },
    { value: 'Удален системой', name: 'Удален системой' },
    { value: "'Закрыт', 'Закрыт по неявке'", name: 'Обработано' },
];
export var gradeOptions = [
    { value: 'all', name: 'Все' },
    { value: '5', name: 'Отлично' },
    { value: '4', name: 'Хорошо' },
    { value: '3', name: 'Нормально' },
    { value: '2', name: 'Плохо' },
    { value: '1', name: 'Очень плохо' },
    { value: '0', name: 'Без' },
];
export var initialParams = {
    operator: 'all',
    workPlace: 'all',
    queue: 'all',
    close_cnt: 'all',
    groupinType: 'wp_id',
    bunch_id: 'all',
    all_cnt: 'all',
    neyavka_cnt: 'all',
    avg_wait_time: 'all',
    total_wait_time: 'all',
    avg_proc_time: 'all',
    total_proc_time: 'all',
    total_work_time: 'all',
    avg_work_time: 'all',
    mark1: 'all',
    mark2: 'all',
    mark3: 'all',
    mark4: 'all',
    mark5: 'all',
    markcnt: 'all',
    share_cnt: 'all',
    solved_questions: 'all',
    create_data: 'all',
    close_data: 'all',
    ticket_number: 'all',
    status: 'all',
    prior: 'all',
    ticket_mark: 'all',
    dateType: 'day',
    totalproc: 'all',
    pincode: 'all',
    clientfio: 'all',
    clientmail: 'all',
    clientphone: 'all',
    dateTypeDescription: 'день',
    startDate: moment().startOf('month').format(dateFormat),
    endDate: moment().endOf('day').format(dateFormat),
    historyDate: moment().format(dateFormat),
    intervalReprotHistoryDate: 'all',
    orderArr: [],
    limit: 'all',
    offset: 'all',
    registered: 'all',
    ticket_id: 'all',
};
export var FieldDescription = function (fields) {
    var fieldMap = {
        workplace_name: { title: 'Рабочее место', isNumber: false },
        all_cnt: { title: 'Обслужено: Всего', isNumber: true },
        close_cnt: { title: 'Обслужено: Закрыто', isNumber: true },
        neyavka_cnt: { title: 'Обслужено: Закрыто по неявке', isNumber: true },
        total_wait_time: { title: 'Общая длительность ожидания', isNumber: true },
        avg_wait_time: { title: 'Средняя длительность ожидания', isNumber: true },
        total_proc_time: {
            title: 'Общая длительность обслуживания',
            isNumber: true,
        },
        avg_proc_time: {
            title: 'Средняя длительность обслуживания',
            isNumber: true,
        },
        queue_name: { title: 'Услуга', isNumber: false },
        user_fio: { title: 'Оператор', isNumber: false },
        name: { title: 'Название группы', isNumber: false },
        total_work_time: { title: 'Общее время работы', isNumber: true },
        avg_work_time: { title: 'Средняя время обслуживания', isNumber: true },
        share_cnt: { title: 'Клиентов обработано', isNumber: true },
        solved_questions: { title: 'Кол-во решенных вопросов', isNumber: true },
        mark1: { title: 'Oценка: Очень плохо', isNumber: true },
        mark2: { title: 'Oценка: Плохо', isNumber: true },
        mark3: { title: 'Oценка: Нормально', isNumber: true },
        mark4: { title: 'Oценка: Хорошо', isNumber: true },
        mark5: { title: 'Oценка: Отлично', isNumber: true },
        markavg: { title: 'Средняя оценка', isNumber: true },
        markcnt: { title: 'Всего оценок', isNumber: true },
        create_data: { title: 'Дата обслуживания: Начало', isNumber: true },
        close_data: { title: 'Дата обслуживания: Окончание', isNumber: true },
        ticket_number: { title: '№ Клиента', isNumber: false },
        prior: { title: 'Приоритет', isNumber: false },
        status: { title: 'Статус', isNumber: false },
        ndate: { title: 'Дата', isNumber: true },
    };
    return fieldMap[fields];
};
