var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Button from '@/components/button';
import AddBtn from '@/components/button/addBtn';
import FormDelete from '@/components/formDelete';
import Input from '@/components/input/input';
import Loader from '@/components/loader';
import Modal from '@/components/modal';
import Table from '@/components/table';
import { getColumnsConnectedQuestionsItem } from '@/pages/services/tabs/servicePolls/helpers/columns';
import { getNewAction, getPayloadSubmitQuestion, getPayloadSubmitSurvey, } from '@/pages/services/tabs/servicePolls/helpers/utils';
import PollsQuestionExpand from '@/pages/services/tabs/servicePolls/pollsQuestionExpand';
import { polls } from '@/utils/rest/pages/services';
var PollsEdit = function (_a) {
    var _b, _c;
    var data = _a.data, refetch = _a.refetch, _d = _a.background, background = _d === void 0 ? 'transparent' : _d, _e = _a.pollsQuestion, pollsQuestion = _e === void 0 ? true : _e;
    var isLoading = useQuery('polls updated', polls.get).isLoading;
    var _f = useState(''), questionName = _f[0], setQuestionName = _f[1];
    var _g = useState(''), question = _g[0], setQuestion = _g[1];
    var _h = useState(), action = _h[0], setAction = _h[1];
    var _j = useState(data), formData = _j[0], setFormData = _j[1];
    var _k = useState(null), currentRow = _k[0], setCurrentRow = _k[1];
    var memoConnectedQuestions = useMemo(function () {
        var _a;
        return formData &&
            ((_a = formData[0]) === null || _a === void 0 ? void 0 : _a.connectedQuestions.filter(function (question) { return question.id === (currentRow === null || currentRow === void 0 ? void 0 : currentRow.id); }));
    }, [formData, currentRow]);
    var pollsAdd = useMutation(polls.addSurvey, {
        onSuccess: function () {
            if (refetch) {
                refetch();
            }
        },
    });
    var pollsAddQuestion = useMutation(polls.addQuestion, {
        onSuccess: function () {
            setQuestion('');
            if (refetch) {
                refetch();
            }
        },
    });
    var pollsDeleteQuestion = useMutation(polls.deleteQuestion, {
        onSuccess: function () {
            setCurrentRow(null);
            if (refetch) {
                refetch();
            }
        },
    });
    var pollsUpdate = useMutation(polls.update, {
        onSuccess: function () { return refetch && refetch(); },
    });
    var pollsUpdateQuestion = useMutation(polls.updateQuestion, {
        onSuccess: function () { return refetch && refetch(); },
    });
    var handleQuestion = function (e) {
        return setQuestion(e.target.value);
    };
    var handleQuestionName = function (e) {
        return formData && (formData === null || formData === void 0 ? void 0 : formData.length) > 0
            ? setFormData([__assign(__assign({}, formData[0]), { surveyname: e.target.value })])
            : setQuestionName(e.target.value);
    };
    var handleSubmit = function (e) {
        var _a;
        e.preventDefault();
        var payload = getPayloadSubmitSurvey(formData, questionName);
        if (formData && formData.length > 0) {
            pollsUpdate.mutate({
                id: (_a = formData[0]) === null || _a === void 0 ? void 0 : _a.id,
                payload: payload,
            });
        }
        else {
            pollsAdd.mutate(payload);
        }
    };
    var handleQuestionSubmit = function () {
        if (formData) {
            var payload = getPayloadSubmitQuestion(formData, questionName, question);
            pollsAddQuestion.mutate(payload);
        }
    };
    var handleUpdateQuestion = function (rowData) {
        if (data) {
            var id = rowData.id, correctAnswers = rowData.answers, operator_mark = rowData.operator_mark, questiontext = rowData.questiontext;
            pollsUpdateQuestion.mutate({
                id: id,
                payload: {
                    correctAnswers: correctAnswers,
                    operator_mark: operator_mark,
                    questiontext: questiontext,
                },
            });
        }
    };
    var columns = getColumnsConnectedQuestionsItem(currentRow, action, handleUpdateQuestion);
    var handleRow = function (e, rowData, column) {
        var newAction = getNewAction(column.field);
        if ((currentRow === null || currentRow === void 0 ? void 0 : currentRow.id) === rowData.id && action === newAction) {
            handleExpandCancel();
        }
        else {
            setAction(newAction || (column === null || column === void 0 ? void 0 : column.field));
            setCurrentRow(rowData);
        }
    };
    var handleExpandCancel = function () {
        setCurrentRow(null);
    };
    var handleDelete = function () {
        if (currentRow) {
            pollsDeleteQuestion.mutate({ id: currentRow.id });
            setAction(undefined);
        }
    };
    var pollsHandleCancel = function () {
        setAction(undefined);
    };
    if (isLoading) {
        return <Loader />;
    }
    return (<div className="editable-item__form">
      <Modal clean open={action === 'delete'}>
        <FormDelete handleSubmit={handleDelete} handleCancel={pollsHandleCancel}/>
      </Modal>
      {!!data && (<p className="editable-item__title">{'Редактирование опроса'}</p>)}
      <div className={background === 'transparent'
            ? 'editable-item__form-row'
            : 'editable-item__form-row editable-item__form-row_transparent'}>
        <div className="drawerStyle">
          <Input text="Наименование опроса" placeholder="Введите наименование" value={formData && formData.length > 0
            ? (_b = formData[0]) === null || _b === void 0 ? void 0 : _b.surveyname
            : questionName} name="surveyname" onChange={handleQuestionName} width={380}/>
        </div>
        {pollsQuestion && (<>
            <Input text="Вопрос" placeholder="Введите вопрос" value={question} name="surveyname" onChange={handleQuestion} width={380}/>
            <div className="button-wrapper">
              <AddBtn handleClick={handleQuestionSubmit} text={'Добавить вопрос'}/>
            </div>
          </>)}
      </div>
      <Table columns={columns} headerStyle={{ fontSize: 14 }} cellStyle={{ fontSize: 12 }} rowStyle={function (_, i) { return ({ background: i % 2 ? '#F7F7F7' : '#FFFFFF' }); }} isLoading={isLoading} onClick={handleRow} data={formData && ((_c = formData[0]) === null || _c === void 0 ? void 0 : _c.connectedQuestions)} paginationBottom={false} perPage={100} onExpand={function (rowData, i) { return (<PollsQuestionExpand refetch={refetch} action={action} data={memoConnectedQuestions} handleCancel={handleExpandCancel} background={i % 2 ? 'transparent' : '#fff'} isOpen={Boolean(currentRow)}/>); }}/>
      <div className="editable-item__form-btns">
        <Button text="Сохранить" onClick={handleSubmit} type="submit"/>
        {/* {!!data && <Button text="Отменить" onClick={handleCancel} />} */}
      </div>
    </div>);
};
export default PollsEdit;
