var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import Alert from '@/components/alert';
import Button from '@/components/button';
import Input from '@/components/input/input';
import Loader from '@/components/loader';
import Modal from '@/components/modal';
import DateTimePicker from '@/components/period/dateTimePicker';
import Table from '@/components/table';
import { dateFormat } from '@/constants/common';
import { getNewsColumns } from '@/pages/system/content/utils/utils';
import { content } from '@/utils/rest/pages/system';
import './news.css';
var News = function () {
    var _a = useQuery('contentNews', content.getNews), isLoading = _a.isLoading, data = _a.data, refetch = _a.refetch;
    var _b = useState(moment().format('DD.MM.YYYY')), date = _b[0], setDate = _b[1];
    var _c = useState({ text: '', title: '' }), news = _c[0], setNews = _c[1];
    var _d = useState(false), modal = _d[0], setModal = _d[1];
    var _e = useState('Произошла ошибка'), errorText = _e[0], setErrorText = _e[1];
    var deleteHandler = function (item) { return deleteNews.mutate(item.id); };
    var columns = getNewsColumns(deleteHandler);
    var deleteNews = useMutation(content.deleteNews, {
        onSuccess: function () { return refetch(); },
        onError: function (_a) {
            var _b;
            var response = _a.response;
            if (response) {
                setErrorText((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.error);
                setModal(true);
            }
        },
    });
    var uploadNews = useMutation(content.addNews, {
        onSuccess: function () {
            refetch();
            setDate(moment().format('DD.MM.YYYY'));
            setNews({ text: '', title: '' });
        },
        onError: function (_a) {
            var _b;
            var response = _a.response;
            if (response) {
                setErrorText((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.error);
                setModal(true);
            }
        },
    });
    var handleInput = function (e) {
        var _a = e.target, value = _a.value, name = _a.name;
        setNews(function (oldData) {
            var _a;
            return (__assign(__assign({}, oldData), (_a = {}, _a[name] = value, _a)));
        });
    };
    var submitHandler = function () {
        uploadNews.mutate({
            date: moment(date).format('DD.MM.YYYY'),
            text: news.text,
            title: news.title,
        });
    };
    if (isLoading)
        return <Loader />;
    return (<div className="content__box">
      <Modal clean={true} open={modal}>
        <Alert text={errorText} handleSubmit={function () {
            setModal(false);
            setErrorText('Произошла ошибка');
        }}/>
      </Modal>
      <div className="content__top">
        {uploadNews.isLoading ? (<Loader />) : (<div className="content__left content__left--news">
            <Input text="Введите название новости" value={news.title} width={300} name="title" onChange={handleInput}/>
            <Input text="Введите текст новости" value={news.text} name="text" width={300} onChange={handleInput}/>
            <div className="content__dates-wrapper">
              <p className="content__dates-title">Дата новости</p>
              <div className="content__dates">
                <div className="content__calendar">
                  <DateTimePicker defaultDate={moment().format(dateFormat)} setParentDate={setDate}/>
                </div>
              </div>
            </div>
            <Button disabled={Boolean(!news.text || !news.title || !date)} text="Загрузить новость" onClick={submitHandler}/>
          </div>)}
      </div>
      <div className="content__bottom">
        <p className="section-title">Список загруженных новостей</p>
        <Table data={data} columns={columns} isLoading={isLoading} headerStyle={{ fontSize: 14 }} cellStyle={{ fontSize: 12 }}/>
      </div>
    </div>);
};
export default News;
