// eslint-disable-next-line import/no-named-as-default
import i18n, { use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import resources from '@/utils/locales';
use(LanguageDetector)
    .use(initReactI18next)
    .init({
    fallbackLng: 'en',
    debug: false,
    supportedLngs: ['en', 'ru'],
    resources: resources,
    interpolation: {
        escapeValue: false,
    },
});
export default i18n;
