var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Table } from 'antd';
import moment, { duration } from 'moment';
import { useQuery } from 'react-query';
import AntTableWrapper from '@/components/table/antTableWrapper';
import { dateFormat } from '@/constants/common';
import CardReport from '@/pages/dashboard/components/cardRepoort';
import LineReport from '@/pages/dashboard/components/lineReport';
import TimeReport from '@/pages/dashboard/components/timeReport';
import { ticketStatuses, wpStatuses } from '@/pages/dashboard/statuses';
import { getColumnSearchProps } from '@/pages/services/tabs/servicePolls/helpers/utils';
import { secToTime } from '@/utils';
import { getMonitoring } from '@/utils/rest/pages/system';
import texts from './texts.json';
import './dashboard.css';
// import { useTranslation } from 'react-i18next';
// import { ticketStatuses, wpStatuses } from '@/pages/dashboard/statuses';
// import { Column } from '@/types/common';
// import Table from '@/components/table';
// import { dateFormat } from '@/constants/common';
// import { MonitoringTicket, MonitoringWplace } from '@/models/monitoring';
var Dashboard = function () {
    var _a = useQuery('dashboard', getMonitoring, {
        refetchInterval: 5000,
        onSuccess: function (data) { return setTableData(data); },
    }), isLoading = _a.isLoading, data = _a.data;
    var _b = useState(), tableData = _b[0], setTableData = _b[1];
    var wplacesColumns = [
        __assign({ dataIndex: 'placename', title: 'Номер' }, getColumnSearchProps('placename', 'по номеру')),
        __assign({ dataIndex: 'wplaceName', title: 'Наименование рабочего места' }, getColumnSearchProps('wplaceName', 'по рабочему месту')),
        __assign({ dataIndex: 'userFIO', title: 'Оператор' }, getColumnSearchProps('userFIO', 'по оператору')),
        {
            dataIndex: 'status',
            title: 'Статус',
            render: function (_, res) {
                if (Object.keys(wpStatuses).includes(String(res.status))) {
                    return wpStatuses[Number(res.status)];
                }
                else {
                    return 'Статус неизвестен';
                }
            },
        },
        __assign({ dataIndex: 'ticketNumber', title: 'Номер клиента', render: function (_, res) {
                return res.ticketNumber ? res.ticketNumber : 'Нет клиентов в работе';
            } }, getColumnSearchProps('ticketNumber', 'по номеру клиента')),
        {
            dataIndex: 'idle_since',
            title: 'Время простоя',
            render: function (_, res) {
                var totalTime = '00:00:00';
                if (res.idle_since) {
                    totalTime = secToTime(duration(moment().diff(res.idle_since)).asSeconds());
                }
                return totalTime;
            },
        },
    ];
    var ticketsColumns = [
        __assign({ dataIndex: 'number', title: 'Номер', sorter: function (a, b) { return Number(a.number) - Number(b.number); } }, getColumnSearchProps('number', 'по номеру')),
        __assign({ dataIndex: 'queueName', title: 'Наименование услуги' }, getColumnSearchProps('queueName', 'по наименованию')),
        {
            dataIndex: 'status',
            title: 'Статус',
            render: function (_, res) {
                if (Object.keys(ticketStatuses).includes(String(res.status))) {
                    return ticketStatuses[Number(res.status)];
                }
                else {
                    return 'Статус неизвестен';
                }
            },
        },
        {
            dataIndex: 'createdOn',
            title: 'Создан',
            render: function (_, res) { return moment(res.createdOn).format(dateFormat); },
        },
        {
            dataIndex: 'modifiedOn',
            title: 'Изменен',
            render: function (_, res) { return moment(res.modifiedOn).format(dateFormat); },
        },
    ];
    var queuesColumns = [
        __assign({ dataIndex: 'name', title: 'Наименование услуги' }, getColumnSearchProps('name', 'по наименованию')),
        {
            dataIndex: 'idle_operators',
            title: 'Кол-во операторов',
            sorter: function (a, b) { return Number(a.idle_operators) - Number(b.idle_operators); },
        },
        {
            dataIndex: 'cnt_clean',
            title: 'В изначальной очереди',
            sorter: function (a, b) { return Number(a.cnt_clean) - Number(b.cnt_clean); },
        },
        {
            dataIndex: 'max_initial_wait',
            title: 'Время ожидания в изначальной очереди',
            render: function (_, res) { return secToTime(Number(res.max_initial_wait)); },
            sorter: function (a, b) { return Number(a.max_initial_wait) - Number(b.max_initial_wait); },
        },
        {
            dataIndex: 'cnt_dirty',
            title: 'Ожидание решения',
            sorter: function (a, b) { return Number(a.cnt_dirty) - Number(b.cnt_dirty); },
        },
        {
            dataIndex: 'max_secondary_wait',
            title: 'Суммарное время ожидания решения',
            render: function (_, res) { return secToTime(Number(res.max_secondary_wait)); },
            sorter: function (a, b) {
                return Number(a.max_secondary_wait) - Number(b.max_secondary_wait);
            },
        },
    ];
    return (<div className="dashboard">
      <div className="dashboard__top">
        <CardReport data={data === null || data === void 0 ? void 0 : data.statistics}/>
        <LineReport />
      </div>
      <TimeReport data={data === null || data === void 0 ? void 0 : data.statistics}/>
      <div className="dashboard__table">
        <p className="section-title">Список клиентов</p>
        {/*<Table*/}
        {/*  paginationTop*/}
        {/*  data={data?.tickets || []}*/}
        {/*  columns={ticketsColumns}*/}
        {/*  isLoading={isLoading}*/}
        {/*  perPage={8}*/}
        {/*/>*/}
        <AntTableWrapper columns={ticketsColumns} data={data === null || data === void 0 ? void 0 : data.tickets} rowKey={'id'} loading={isLoading}/>
      </div>
      <div className="dashboard__table">
        <p className="section-title">{texts.queues}</p>
        <AntTableWrapper columns={queuesColumns} data={data === null || data === void 0 ? void 0 : data.queues} rowKey={'id'} loading={isLoading}/>
      </div>
      <div className="dashboard__table">
        <p className="section-title">{texts.workplaces}</p>
        <Table columns={wplacesColumns} dataSource={tableData === null || tableData === void 0 ? void 0 : tableData.wplaces} rowKey={'id'} loading={isLoading}/>
      </div>
    </div>);
};
export default Dashboard;
