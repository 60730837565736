var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { notification, Table } from 'antd';
import { useMutation, useQuery } from 'react-query';
import ExportBtn from '@/components/button/exportBtn';
import ReportFilters from '@/pages/statistics/reports/filters/reportFilters';
import { columns } from '@/pages/statistics/reports/tabs/survey/tableColumns';
import { FilterStore } from '@/store';
import { conditions } from '@/utils/rest/pages/conditions';
import { queueObjects, surveyReport } from '@/utils/rest/pages/reports';
var Survey = function () {
    var _a = useState([]), tableData = _a[0], setTableData = _a[1];
    var _b = useState([]), tableColumns = _b[0], setTableColumns = _b[1];
    var _c = useState([]), notTextFields = _c[0], setNotTextFields = _c[1];
    var _d = useState(400), scroll = _d[0], setScroll = _d[1];
    var _e = useState({}), answers = _e[0], setAnswers = _e[1];
    var filterParams = FilterStore.useState(function (s) { return s.initialParams; });
    var setFilterParams = function (newFilterParams) {
        FilterStore.update(function (s) {
            s.initialParams = newFilterParams;
        });
    };
    var fieldsData = useQuery('ticketFields', conditions.get).data;
    var filtersData = useQuery('filtersData', queueObjects.get).data;
    var report = useMutation('surveyReportData', surveyReport.get);
    var exportReport = useMutation('exportData', surveyReport.exportReport, {
        onSuccess: function (data) {
            var success = data.answer.success;
            if (success) {
                notification.success({
                    message: 'Отчет подготавливается',
                    description: 'По окончанию подготовки отчет будет доступен для скачивания в разделе "Архив"',
                });
            }
            else {
                notification.error({
                    message: 'Упс',
                    description: 'Что-то пошло не так',
                });
            }
        },
    });
    var answersData = useMutation('answerData', surveyReport.getAnswers);
    var applyFilters = function () {
        report.mutate(filterParams);
        answersData.mutate(filterParams);
    };
    var exportData = function () {
        exportReport.mutate(filterParams);
    };
    useEffect(function () {
        report.mutate(filterParams);
        answersData.mutate(filterParams);
    }, []);
    useEffect(function () {
        var nonTF = [];
        var tColumns = __spreadArray([], columns, true);
        fieldsData === null || fieldsData === void 0 ? void 0 : fieldsData.forEach(function (el) {
            if (el.type !== 'text') {
                nonTF.push(Number(el.id));
            }
            tColumns.push({
                dataIndex: "fid-".concat(el.id),
                title: String(el.name),
                width: String(el.rus_name).length > 500 ? String(el.rus_name).length : 140,
            });
        });
        setTableColumns(tColumns);
        setNotTextFields(nonTF);
        if (fieldsData && (fieldsData === null || fieldsData === void 0 ? void 0 : fieldsData.length) > 0) {
            setScroll(function (prevState) { return prevState + (fieldsData === null || fieldsData === void 0 ? void 0 : fieldsData.length) * 100; });
        }
    }, [fieldsData]);
    useEffect(function () {
        var _a;
        if (!report.isLoading) {
            var resultArr_1 = [];
            (_a = report.data) === null || _a === void 0 ? void 0 : _a.forEach(function (el) {
                if (el.dictionary !== '[]' && el.dictionary !== '[null]') {
                    var dictObj_1 = {};
                    var dictVals = JSON.parse(el.dictionary);
                    dictVals.forEach(function (dv) {
                        dictObj_1["fid-".concat(dv.fid)] = notTextFields.includes(dv.fid)
                            ? dv.fv.map(function (fv) { return answers[fv]; }).toString()
                            : dv.fv.toString();
                    });
                    resultArr_1.push(__assign(__assign({}, el), dictObj_1));
                }
            });
            setTableData(resultArr_1);
        }
    }, [report.data, answers]);
    useEffect(function () {
        var _a;
        if (!answersData.isLoading) {
            var answers_1 = {};
            (_a = answersData.data) === null || _a === void 0 ? void 0 : _a.forEach(function (el) {
                answers_1[Number(el.id)] = el.name;
            });
            setAnswers(answers_1);
        }
    }, [answersData.data]);
    return (<>
      <ReportFilters showFilters={{
            main: ['reportRange', 'operators', 'queues'],
            others: [],
            orders: [],
        }} filterParams={filterParams} filtersData={filtersData} setFilterParams={setFilterParams} applyFilters={applyFilters}/>
      <p className="table-title">Отчёт</p>
      <ExportBtn onClick={exportData}/>
      <Table columns={tableColumns} dataSource={tableData} rowKey={'ticket_id'} loading={report.isLoading} scroll={{
            x: scroll,
        }}/>
    </>);
};
export default Survey;
