import React from 'react';
// import { ReactComponent as Components } from '@/assets/images/nav-components.svg';
// import { ReactComponent as Constructor } from '@/assets/images/nav-constructor.svg';
import { ReactComponent as Content } from '@/assets/images/nav-content.svg';
import { ReactComponent as Dashboard } from '@/assets/images/nav-dashboard.svg';
import { ReactComponent as Services } from '@/assets/images/nav-services.svg';
import { ReactComponent as Statistics } from '@/assets/images/nav-statistics.svg';
import { ReactComponent as System } from '@/assets/images/nav-system.svg';
import { ReactComponent as User } from '@/assets/images/nav-user.svg';
import p from '@/constants/paths';
// Поле private - ограничение доступа для оператора
export default [
    {
        name: 'Мониторинг',
        path: p.dashboard,
        icon: <Dashboard />,
        private: false,
    },
    {
        name: 'Статистика',
        path: p.statistics,
        icon: <Statistics />,
        private: false,
        subnav: [
            {
                name: 'Отчеты',
                path: p.reports,
                subnav: [{ name: 'Сводный', path: p.consolidated }],
            },
            { name: 'Архив', path: p.archive },
        ],
    },
    {
        name: 'Компоненты системы',
        path: p['services-editor'],
        icon: <Services />,
        private: true,
        subnav: [
            { name: 'Услуги', path: p.serviceList },
            {
                name: 'Расписание услуги',
                path: p.serviceSchedule,
                subtab: [
                    {
                        path: p.serviceIntervals,
                        name: 'Список услуг',
                    },
                    { path: p.serviceDays, name: 'Дни' },
                    { path: p.serviceWeeks, name: 'Недели' },
                ],
            },
            { name: 'Рабочие места', path: p.workplaces },
            { name: 'Дополнительные поля', path: p.serviceConditions },
            {
                path: p.servicePolls,
                name: 'Опросы',
            },
            {
                path: p.substatuses,
                name: 'Субстатусы',
            },
        ],
    },
    // { name: 'Конструктор', path: p.constructor, icon: <Constructor /> },
    {
        name: 'Пользователи',
        path: p.users,
        private: true,
        icon: <User />,
    },
    // {
    // name: 'Компоненты системы',
    // path: p.components,
    // icon: <Components />,
    // subnav: [
    //   { name: 'Пользователи', path: p.users },
    // { name: 'Табло оператора', path: p.operatorsBoard },
    // { name: 'Зоны обслуживания', path: p.serviceAreas },
    // ],
    // },
    {
        name: 'Система',
        path: p.system,
        private: true,
        icon: <System />,
        subnav: [
            { name: 'Параметры сервера', path: p.parameters },
            // { name: 'Хранилище', path: p.storage },
            { name: 'Резервные копии', path: p.copies },
            { name: 'Сессии', path: p.sessions },
            { name: 'О системе', path: p.about },
        ],
    },
    {
        name: 'Загрузка контента',
        path: p.content,
        private: true,
        icon: <Content />,
        subnav: [
            { name: 'Шрифты', path: p.fonts },
            { name: 'Видео', path: p.videos },
            { name: 'Новости', path: p.news },
            // { name: 'Изображения', path: p.images },
            { name: 'Бегущая строка', path: p.runningLines },
        ],
    },
];
