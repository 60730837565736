var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef } from 'react';
import { useTransition, animated } from 'react-spring';
import './editableItem.css';
var EditableItem = function (_a) {
    var isOpen = _a.isOpen, _b = _a.background, background = _b === void 0 ? 'transparent' : _b, children = _a.children, cell = _a.cell;
    var el = useRef(null);
    var transitions = useTransition(isOpen, {
        from: { maxHeight: '0', transform: 'scaleY(0)' },
        enter: { maxHeight: '1800px', transform: 'scaleY(1)' },
        leave: { maxHeight: '0', transform: 'scaleY(0)' },
        reverse: isOpen,
        delay: 0,
    });
    var content = transitions(function (styles, item) {
        return item && (<animated.div ref={el} className={'editable-item'} style={__assign(__assign({}, styles), { background: background })}>
          {children}
        </animated.div>);
    });
    return cell ? (<td colSpan={cell} style={{ width: '100%', background: background }}>
      {content}
    </td>) : (content);
};
export default EditableItem;
