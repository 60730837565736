import React from 'react';
import moment from 'moment/moment';
import { ReactComponent as Edit } from '@/assets/images/table-edit.svg';
import { ReactComponent as FalseIcon } from '@/assets/images/table-icon-false.svg';
import { ReactComponent as TrueIcon } from '@/assets/images/table-icon-true.svg';
import { ReactComponent as Trash } from '@/assets/images/table-trash.svg';
import { dateFormat } from '@/constants/common';
var getColumns = function (roles, isRoot, setCurrentRow, setShowAdd, setAction) {
    var columns = [
        { dataIndex: 'id', width: 100, title: 'ID', align: 'center' },
        {
            dataIndex: 'fio',
            title: 'Логин',
            render: function (_, res) { return (<>
          <p>{res.username}</p>
          <br />
          <p>{res.fio}</p>
        </>); },
        },
        {
            dataIndex: 'connectedRoles',
            title: 'Роль',
            align: 'center',
            render: function (value) {
                var userRoles = value.filter(function (el) {
                    return roles === null || roles === void 0 ? void 0 : roles.some(function (role) { return role.id === el; });
                });
                if (userRoles.length === 0) {
                    return <p style={{ width: 'fit-content' }}>Роли не подключены</p>;
                }
                return (<div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {userRoles.map(function (role) {
                        var currentRole = roles === null || roles === void 0 ? void 0 : roles.find(function (el) { return el.id === role; });
                        if (currentRole) {
                            return <p key={currentRole.id}>{currentRole.printname}</p>;
                        }
                        else {
                            return null;
                        }
                    })}
          </div>);
            },
        },
        {
            dataIndex: 'modified_by_username',
            title: 'Кем изменён',
        },
        {
            dataIndex: 'modified_on',
            title: 'Дата изменения',
            render: function (value) { return moment(value).format(dateFormat); },
        },
        {
            dataIndex: 'hidden',
            title: 'Работа на АРМ',
            align: 'center',
            render: function (_, res) {
                return res.hidden ? (<span>
            <FalseIcon />
          </span>) : (<span>
            <TrueIcon />
          </span>);
            },
        },
        {
            dataIndex: 'blocked',
            title: 'Вход через панель администратора',
            align: 'center',
            render: function (_, res) {
                return res.blocked ? (<span>
            <FalseIcon />
          </span>) : (<span>
            <TrueIcon />
          </span>);
            },
        },
    ];
    var rootColumns = [
        {
            dataIndex: 'edit',
            width: 55,
            align: 'center',
            render: function (_, res) { return (<button onClick={function () {
                    setCurrentRow(res);
                    setShowAdd(true);
                }}>
          <Edit />
        </button>); },
        },
        {
            dataIndex: 'delete',
            width: 55,
            align: 'center',
            render: function (_, res) { return (<button onClick={function () {
                    setCurrentRow(res);
                    setAction('delete');
                }}>
          <Trash />
        </button>); },
        },
    ];
    if (isRoot) {
        columns.push.apply(columns, rootColumns);
    }
    return columns;
};
export default getColumns;
