import React from 'react';
import './contentInput.css';
var ContentInput = function (_a) {
    var isShown = _a.isShown, onChange = _a.onChange, text = _a.text, content = _a.content, accept = _a.accept, extensions = _a.extensions;
    return (<>
      {isShown ? (<label htmlFor="fontInput" className="content__input-wrapper">
          {!content && <span>{"\u041F\u0435\u0440\u0435\u0442\u0430\u0449\u0438\u0442\u0435 ".concat(text, " \u0441\u044E\u0434\u0430")}</span>}
          {extensions && (<span>
              Поддерживаемые форматы {text}: {extensions.join(', ')}
            </span>)}
          <input className="content__fileInput" id="fontInput" type="file" onChange={onChange} accept={accept}/>
          <span className="content__file">{content && content.name}</span>
        </label>) : (<span>Для загрузки введите название</span>)}
    </>);
};
export default ContentInput;
