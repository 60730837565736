var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Drawer } from 'antd';
import Button from '@/components/button';
import Order from '@/pages/statistics/reports/filters/filterItems/order';
import { initialParams } from '@/pages/statistics/reports/filters/filterParametrs';
import MainFilter from '@/pages/statistics/reports/filters/mainFilter';
import { renderSecondaryFilter } from '@/pages/statistics/reports/filters/secondaryFilter';
import './reportFilters.css';
var ReportFilters = function (_a) {
    var showFilters = _a.showFilters, filterParams = _a.filterParams, setFilterParams = _a.setFilterParams, filtersData = _a.filtersData, applyFilters = _a.applyFilters, exportData = _a.exportData;
    var _b = useState(false), showAdd = _b[0], setShowAdd = _b[1];
    var handleSetFilters = function (name, data) {
        if (!String(data).includes('undefined') || typeof data !== 'string') {
            if (typeof data === 'object' && data.length !== 0) {
                var newFilterParams = __assign({}, filterParams);
                newFilterParams[name] = data.join(', ');
                setFilterParams(newFilterParams);
            }
            if (typeof data === 'object' && data.length === 0) {
                var newFilterParams = __assign({}, filterParams);
                newFilterParams[name] = 'all';
                setFilterParams(newFilterParams);
            }
            if (typeof data === 'string' && name) {
                var newFilterParams = __assign({}, filterParams);
                newFilterParams[name] = data;
                setFilterParams(newFilterParams);
            }
        }
    };
    var handleSetOrder = function (orders) {
        var newFilterParams = __assign(__assign({}, filterParams), { orderArr: [] });
        orders.forEach(function (order) {
            if (typeof order.name !== 'number' && order.name.length > 0) {
                switch (order.name) {
                    case 'create_data':
                    case 'close_data':
                        if (Array.isArray(newFilterParams['orderArr'])) {
                            newFilterParams['orderArr'].push("".concat(order.name, "::timestamp ").concat(order.value));
                        }
                        break;
                    default:
                        if (Array.isArray(newFilterParams['orderArr'])) {
                            newFilterParams['orderArr'].push("".concat(order.name, " ").concat(order.value));
                        }
                }
            }
        });
        setFilterParams(newFilterParams);
    };
    var handleShow = function () {
        setShowAdd(function (prevState) { return !prevState; });
    };
    var handleCancel = function () {
        setShowAdd(false);
        setFilterParams(initialParams);
    };
    return (<>
      <p className="section-title">Фильтры</p>
      <div className="editable-item__form">
        <MainFilter filtersData={filtersData} showFilters={showFilters.main} handleSetFilters={handleSetFilters}/>
        <Drawer width={1020} placement="right" onClose={function () { return setShowAdd(false); }} open={showAdd}>
          <div className="report_filters__secondary">
            {renderSecondaryFilter({
            handleSetFilters: handleSetFilters,
            showFilters: showFilters.others,
        })}
          </div>
          {showFilters.orders.length > 0 && (<Order handleSetOrder={handleSetOrder} showAdd={showAdd} orderFields={showFilters.orders}/>)}
          <div style={{ margin: '0 auto', width: 'fit-content' }}>
            <Button text="Применить" onClick={applyFilters}/>
          </div>
        </Drawer>
        {(showFilters.others.length > 0 || showFilters.orders.length > 0) && (<button className="report_filters__btn" onClick={handleShow}>
            <p className="report_filters__btn-text">
              {!showAdd
                ? 'Показать дополнительные фильтры'
                : 'Свернуть фильтры'}
            </p>
          </button>)}
        <div className="editable-item__form-btns">
          <Button text="Применить" onClick={applyFilters}/>
          <Button text="Отменить" onClick={handleCancel}/>
          {exportData && <Button text="Экспортировать" onClick={exportData}/>}
        </div>
      </div>
    </>);
};
export default ReportFilters;
