import React, { useState } from 'react';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import { ReactComponent as Download } from '@/assets/images/download.svg';
import { ReactComponent as Prepare } from '@/assets/images/prepare-download.svg';
import { ReactComponent as Refresh } from '@/assets/images/refresh-arrow.svg';
import { ReactComponent as Trash } from '@/assets/images/table-trash.svg';
import FormDelete from '@/components/formDelete';
import Loader from '@/components/loader';
import Modal from '@/components/modal';
import Table from '@/components/table';
import { dateFormat } from '@/constants/common';
import { archiveTypes } from '@/constants/statistics';
import { archive } from '@/utils/rest';
import { apiRoot } from '../../../../config';
import texts from '../texts.json';
import './archive.css';
var Archive = function () {
    var _a = useQuery('archive', archive.get), isLoading = _a.isLoading, data = _a.data, refetch = _a.refetch;
    var _b = useState(null), currentRow = _b[0], setCurrentRow = _b[1];
    var _c = useState(''), action = _c[0], setAction = _c[1];
    var deleteArch = useMutation('deleteArchive', archive.delete, {
        onSuccess: function () { return refetch(); },
    });
    var columns = [
        {
            field: 'createdAt',
            title: 'Дата создания',
            style: { textAlign: 'center' },
            render: function (rowData) { return moment(rowData.createdAt).format(dateFormat); },
        },
        {
            field: 'fileName',
            title: 'Название файла',
            style: { textAlign: 'center' },
            headerStyle: { textAlign: 'center' },
        },
        {
            field: 'type',
            title: 'Тип отчета',
            style: { textAlign: 'center' },
            headerStyle: { textAlign: 'center' },
            render: function (rowData) { return archiveTypes[rowData.type]; },
        },
        {
            field: 'status',
            title: 'Статус',
            style: { textAlign: 'center' },
            headerStyle: { textAlign: 'center' },
            render: function (rowData) {
                return rowData.status ? texts.readyDownload : texts.prepareDownload;
            },
        },
        {
            field: '',
            title: 'Расширение',
            style: { textAlign: 'center' },
            render: function (rowData) { var _a, _b; return (_b = (_a = rowData.fileName) === null || _a === void 0 ? void 0 : _a.split('.')) === null || _b === void 0 ? void 0 : _b.pop(); },
        },
        {
            field: 'download',
            style: {
                width: '4%',
                cursor: 'pointer',
            },
            render: function (rowData) {
                return rowData.status ? (<a href={"".concat(apiRoot).concat(rowData.fileUrl)} download>
            <Download />
          </a>) : (<Prepare />);
            },
        },
        {
            field: 'delete',
            style: { width: '4%', cursor: 'pointer' },
            render: function (rowData) { return (<span style={{
                    transition: '.3s',
                    opacity: Number(rowData.id) === Number(currentRow === null || currentRow === void 0 ? void 0 : currentRow.id) &&
                        action === 'delete'
                        ? '.3'
                        : 1,
                }}>
          <Trash />
        </span>); },
        },
    ];
    var handleCancel = function (e) {
        e.preventDefault();
        setCurrentRow(null);
    };
    var handleRow = function (e, rowData, column) {
        var newAction = column.field === 'delete' ? column.field : '';
        if ((currentRow === null || currentRow === void 0 ? void 0 : currentRow.id) === rowData.id && action === newAction) {
            handleCancel(e);
        }
        else {
            setAction(newAction);
            setCurrentRow(rowData);
        }
    };
    var handleDelete = function () {
        if (currentRow) {
            deleteArch.mutate(currentRow.id);
        }
        setAction('');
        setCurrentRow(null);
    };
    var handleRefetch = function () {
        refetch();
    };
    if (isLoading) {
        return <Loader />;
    }
    return (<div className="archive">
      <Modal clean open={action === 'delete'}>
        <FormDelete handleCancel={function () { return setAction(''); }} handleSubmit={handleDelete}/>
      </Modal>
      <button onClick={handleRefetch} className="archive__refresh-btn">
        <Refresh />
        {texts.refresh}
      </button>

      <p className="section-title">{texts.archiveTitle}</p>
      <Table data={data} columns={columns} isLoading={isLoading} headerStyle={{ fontSize: 14 }} cellStyle={{ fontSize: 12 }} onClick={handleRow} rowStyle={function (_, i) { return ({
            background: i % 2 ? 'transparent' : '#fff',
        }); }}/>
    </div>);
};
export default Archive;
