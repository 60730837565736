import React from 'react';
var CardReport = function (_a) {
    var data = _a.data;
    return (<>
      {data && (<div className="dashboard__card">
          <p className="dashboard__card__text dashboard__card__text-prim">
            Рабочие места
          </p>
          <p className="dashboard__card__text dashboard__card__text-prim">
            {data === null || data === void 0 ? void 0 : data.countWplacesTotal}
          </p>

          <p className="dashboard__card__text dashboard__card__text-green">
            Активно
          </p>
          <p className="dashboard__card__text dashboard__card__text-green">
            {data === null || data === void 0 ? void 0 : data.countWplacesIdle}
          </p>

          <p className="dashboard__card__text dashboard__card__text-orange">
            Включен
          </p>
          <p className="dashboard__card__text dashboard__card__text-orange">
            {data === null || data === void 0 ? void 0 : data.countWplacesBusy}
          </p>

          <p className="dashboard__card__text dashboard__card__text-prim">
            Клиенты
          </p>
          <p className="dashboard__card__text dashboard__card__text-prim">
            {data === null || data === void 0 ? void 0 : data.countTicketsNow}
          </p>

          <p className="dashboard__card__text dashboard__card__text-green">
            Обслуживается
          </p>
          <p className="dashboard__card__text dashboard__card__text-green">
            {data === null || data === void 0 ? void 0 : data.countWplacesBusy}
          </p>

          <p className="dashboard__card__text dashboard__card__text-orange">
            Ожидает
          </p>
          <p className="dashboard__card__text dashboard__card__text-orange">
            {data === null || data === void 0 ? void 0 : data.countTicketsWaitingNow}
          </p>

          <p className="dashboard__card__text dashboard__card__text-prim">
            За сегодня
          </p>
          <p className="dashboard__card__text dashboard__card__text-prim">
            {data === null || data === void 0 ? void 0 : data.countAllTicketsToday}
          </p>

          <p className="dashboard__card__text dashboard__card__text-green">
            Обслужено
          </p>
          <p className="dashboard__card__text dashboard__card__text-green">
            {data === null || data === void 0 ? void 0 : data.countClosedTicketsToday}
          </p>

          <p className="dashboard__card__text dashboard__card__text-orange">
            Неявка
          </p>
          <p className="dashboard__card__text dashboard__card__text-orange">
            {data === null || data === void 0 ? void 0 : data.countDeletedTicketsToday}
          </p>
        </div>)}
    </>);
};
export default CardReport;
