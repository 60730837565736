var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { useQuery } from 'react-query';
import Button from '@/components/button';
import Input from '@/components/input/input';
import Select from '@/components/input/select';
import DateTimePicker from '@/components/period/dateTimePicker';
import { Actions, actionsArray, parameters } from '@/pages/logs/consts';
import texts from '@/pages/services/tabs/texts.json';
import { initialParams } from '@/pages/statistics/reports/filters/filterParametrs';
import './logs.css';
import { sessions } from '@/utils/rest/pages/system';
var Logs = function () {
    // const [toTime, setToTime] = useState('');
    // const [fromTime, setFromTime] = useState('');
    //TODO: не понятно пока-что как опрокидывать запрос со <Временем>, пока что только дата
    var _a = useState(''), chosenComment = _a[0], setChosenComment = _a[1];
    var _b = useState([]), filter = _b[0], setFilter = _b[1];
    var _c = useState({
        users: { value: 0, name: '' },
        actions: { value: 0, name: '' },
    }), formData = _c[0], setFormData = _c[1];
    var _d = useState(initialParams.startDate), fromDate = _d[0], setFromDate = _d[1];
    var _e = useState(initialParams.endDate), toDate = _e[0], setToDate = _e[1];
    var _f = useQuery('users', sessions.getUser), isLoading = _f.isLoading, users = _f.data;
    var _g = useQuery(['actions', filter], function () { return sessions.getUserAction(filter); }), isLoadingSessions = _g.isLoading, actions = _g.data;
    useEffect(function () {
        console.log(users);
    }, [isLoading]);
    if (!isLoadingSessions) {
        actions === null || actions === void 0 ? void 0 : actions.forEach(function (el, idx) {
            el['key'] = idx;
        });
    }
    var findById = function (id) {
        if (usersNames) {
            var result = usersNames.find(function (user) { return user.value === id; });
            if (result) {
                return result.name;
            }
        }
    };
    var usersNames = users === null || users === void 0 ? void 0 : users.map(function (user) { return ({
        value: user.id,
        name: user.username,
    }); });
    var columns = [
        {
            dataIndex: 'created_by_id',
            title: 'Пользователь',
            align: 'center',
            render: function (value) { return <span>{findById(value)}</span>; },
        },
        {
            dataIndex: 'constant',
            title: 'Дата',
            align: 'center',
            render: function (_, rowData) { return (<span>{moment(rowData.created_on).format('DD.MM.YYYY HH:mm:ss')}</span>); },
        },
        {
            dataIndex: 'action',
            title: 'Действие',
            align: 'center',
            render: function (_, rowData) { return <span>{Actions[rowData.action]}</span>; },
        },
        {
            dataIndex: 'comment',
            title: 'Комментарий',
            align: 'center',
            render: function (_, rowData) { return <span>{rowData.comment.slice(0, 80)}</span>; },
        },
    ];
    var submitHandler = function () {
        var _a = parameters(fromDate, toDate, chosenComment, formData), from = _a.from, to = _a.to, createdBy = _a.createdBy, someAction = _a.someAction, comment = _a.comment;
        setFilter([from, to, createdBy, someAction, comment]);
    };
    var handleSelect = function (_a) {
        var option = _a.option, name = _a.name;
        setFormData(function (oldData) {
            var _a;
            return (__assign(__assign({}, oldData), (_a = {}, _a[name] = option, _a)));
        });
    };
    var handleCancel = function () {
        setFormData({
            users: { value: 0, name: '' },
            actions: { value: 0, name: '' },
        });
        setChosenComment('');
        setFromDate(initialParams.startDate);
        setToDate(initialParams.endDate);
        setFilter([]);
    };
    return (<div className="logs">
      <p className="section-title">{texts.logTitle}</p>
      <div className="logs__filtration">
        <div className="logs__inputs">
          <div className="logs__dates-wrapper">
            <p className="logs__dates-title">Дата</p>
            <div className="logs__dates">
              <p>С</p>
              <div className="logs__calendar">
                <DateTimePicker defaultDate={String(fromDate)} setParentDate={setFromDate}/>
              </div>
              <p>по</p>
              <div className="logs__calendar">
                <DateTimePicker defaultDate={String(toDate)} setParentDate={setToDate}/>
              </div>
            </div>
          </div>
          <Select name="users" text="Пользователи" width={380} value={formData.users.name} options={usersNames} placeholder="Выберите из списка" onChange={handleSelect}/>
          <Select name="actions" text="Действия" width={380} value={formData.actions.name} options={actionsArray} placeholder="Выберите из списка" onChange={handleSelect}/>
          <Input width={380} value={chosenComment} text="Комментарий" placeholder="Введите комментарий" onChange={function (e) { return setChosenComment(e.target.value); }}/>
        </div>
        <div className="logs__buttons">
          <Button text="Применить" onClick={submitHandler}/>
          <Button text="Сбросить" onClick={handleCancel}/>
        </div>
      </div>
      <p className="section-title">{texts.sessionsTitle}</p>
      <div style={{ width: '100%' }}>
        <Table columns={columns} dataSource={actions} rowKey={'key'} loading={isLoading} bordered={false} size={'small'}/>
      </div>
    </div>);
};
export default Logs;
