import React from 'react';
import 'react-day-picker/lib/style.css';
import './period.css';
import { DatePicker } from 'antd';
import locale from 'antd/es/locale/ru_RU';
import dayjs from 'dayjs';
import { dateFormat } from '@/constants/common';
var DateTimePicker = function (_a) {
    var setParentDate = _a.setParentDate, defaultDate = _a.defaultDate, width = _a.width, _b = _a.showTime, showTime = _b === void 0 ? true : _b;
    var handleChange = function (date, dateString) {
        if (setParentDate) {
            setParentDate(dateString);
        }
    };
    return (<div style={{ maxWidth: width }} className="date-time">
      <DatePicker className="date-time-picker" onChange={handleChange} defaultValue={dayjs(defaultDate, dateFormat)} format={dateFormat} showTime={showTime} style={{
            border: 'none',
            outline: 'none',
        }} locale={locale.DatePicker}/>
    </div>);
};
export default DateTimePicker;
