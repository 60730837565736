import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import AddBtn from '@/components/button/addBtn';
import FormDelete from '@/components/formDelete';
import Modal from '@/components/modal';
import AntTableWrapper from '@/components/table/antTableWrapper';
import getColumns from '@/pages/components/users/tableColumns';
import UserEdit from '@/pages/components/users/userEdit';
import { users } from '@/utils/rest/pages/services';
var Users = function () {
    var _a = useState(undefined), currentRow = _a[0], setCurrentRow = _a[1];
    var _b = useState(), action = _b[0], setAction = _b[1];
    var _c = useState(false), showAdd = _c[0], setShowAdd = _c[1];
    var _d = useState([]), tableData = _d[0], setTableData = _d[1];
    var _e = useState({
        id: 0,
        fio: '',
        username: '',
        hidden: true,
        blocked: true,
        connectedQueues: [],
        connectedRoles: [],
    }), userData = _e[0], setUserData = _e[1];
    var _f = useQuery('users', users.get, {
        onSuccess: function (data) {
            data === null || data === void 0 ? void 0 : data.forEach(function (el, idx) {
                el['key'] = idx;
            });
            setTableData(data);
        },
    }), isFetching = _f.isFetching, refetch = _f.refetch;
    var _g = useQuery('roles', users.getRoles), isLoadingRoles = _g.isLoading, roles = _g.data;
    var userDel = useMutation(users.delete, {
        onSuccess: function () {
            refetch();
        },
    });
    var queryClient = useQueryClient();
    var userInfo = queryClient.getQueryData('userInfo');
    var isRoot = userInfo === null || userInfo === void 0 ? void 0 : userInfo.data.roles.includes('eq_root');
    var handleCancel = function () {
        setCurrentRow(undefined);
        setShowAdd(false);
        setAction(undefined);
    };
    var handleDelete = function () {
        if (currentRow === null || currentRow === void 0 ? void 0 : currentRow.id) {
            userDel.mutate({ id: Number(currentRow.id) });
            setCurrentRow(undefined);
            setAction(undefined);
        }
    };
    useEffect(function () {
        if (currentRow) {
            var currentUserData = {
                id: currentRow.id,
                fio: currentRow.fio,
                username: currentRow.username,
                hidden: currentRow.hidden,
                blocked: currentRow.blocked,
                connectedQueues: currentRow.connectedQueues,
                connectedRoles: currentRow.connectedRoles,
                default_wplace: currentRow.default_wplace,
            };
            setUserData(currentUserData);
        }
    }, [currentRow]);
    return (<>
      <Modal clean open={action === 'delete'}>
        <FormDelete handleCancel={function () { return setAction(undefined); }} handleSubmit={handleDelete}/>
      </Modal>
      <AddBtn handleClick={function () {
            setShowAdd(function (prevState) { return !prevState; });
        }} text={'Добавить'}/>
      <Drawer width={920} placement="right" onClose={handleCancel} open={showAdd} destroyOnClose={true}>
        <UserEdit isOpen={showAdd} refetch={refetch} userData={currentRow ? userData : undefined} handleCancel={handleCancel}/>
      </Drawer>
      <p className="section-title">Пользователи</p>
      {!isLoadingRoles && (<AntTableWrapper columns={getColumns(roles, isRoot, setCurrentRow, setShowAdd, setAction)} data={tableData} rowKey={'key'} loading={isFetching || isLoadingRoles} bordered={false}/>)}
    </>);
};
export default Users;
