var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import Select from '@/components/input/select';
import MultiSelect from '@/components/multiSelect';
var OperatorSelect = function (_a) {
    var filtersData = _a.filtersData, handleSetFilters = _a.handleSetFilters;
    var _b = useState([]), selectedUsers = _b[0], setSelectedUsers = _b[1];
    var users = filtersData === null || filtersData === void 0 ? void 0 : filtersData.users.map(function (item) { return ({
        id: item.id,
        name: item.fio,
    }); });
    var handleCheckUsers = function (id) {
        if (id === -2) {
            setSelectedUsers([]);
            return;
        }
        if (selectedUsers.includes(id)) {
            var newSelectedUsers = __spreadArray([], selectedUsers, true);
            newSelectedUsers.splice(newSelectedUsers.indexOf(id), 1);
            setSelectedUsers(__spreadArray([], newSelectedUsers, true));
            return;
        }
        if (id === -1) {
            var newSelectedUsers_1 = [];
            users === null || users === void 0 ? void 0 : users.forEach(function (item) {
                newSelectedUsers_1.push(item.id);
            });
            setSelectedUsers(newSelectedUsers_1);
            return;
        }
        setSelectedUsers(__spreadArray(__spreadArray([], selectedUsers, true), [id], false));
    };
    useEffect(function () { return handleSetFilters('operator', selectedUsers); }, [selectedUsers]);
    var values = users === null || users === void 0 ? void 0 : users.filter(function (el) { return selectedUsers.includes(el.id); });
    return (<Select name="operators" text="Список операторов" type="dropdown" placeholder="Выбрать" value={values && values.length > 0 ? values === null || values === void 0 ? void 0 : values.map(function (el) { return el.name; }) : undefined}>
      <MultiSelect handleCheck={handleCheckUsers} selected={selectedUsers} data={users}/>
    </Select>);
};
export default OperatorSelect;
