import React from 'react';
import { ReactComponent as ArrowUp } from '@/assets/images/arrowUp.svg';
import { ReactComponent as Check } from '@/assets/images/check.svg';
import { ReactComponent as Edit } from '@/assets/images/table-edit.svg';
import { ReactComponent as Trash } from '@/assets/images/table-trash.svg';
export var getColumnsConnectedQuestionsItem = function (currentRow, action, handleUpdateQuestion) {
    var columns = [
        {
            field: 'questiontext',
            title: 'Список вопросов',
            style: { textAlign: 'center' },
            render: function (rowData) { return (<span className="connected-question" key={rowData.id}>
          {rowData.questiontext}
        </span>); },
        },
        {
            field: 'edit',
            style: {
                width: '3%',
                cursor: 'pointer',
            },
            render: function (rowData) { return (<span style={{
                    transition: '.3s',
                    opacity: Number(rowData.id) === (currentRow === null || currentRow === void 0 ? void 0 : currentRow.id) && action === 'edit'
                        ? '.3'
                        : 1,
                }}>
          {Number(rowData.id) === (currentRow === null || currentRow === void 0 ? void 0 : currentRow.id) && action === 'edit' ? (<ArrowUp />) : (<Edit />)}
        </span>); },
        },
        {
            field: 'check',
            style: {
                width: '3%',
                cursor: 'pointer',
            },
            render: function (rowData, i) { return (<span className="service-polls__checkbox" style={{ background: i % 2 ? '#FFFFFF' : '#F6F6F6' }} onClick={function () {
                    rowData.operator_mark = !rowData.operator_mark;
                    handleUpdateQuestion(rowData);
                }}>
          {rowData.operator_mark && <Check />}
        </span>); },
        },
        {
            field: 'delete',
            style: { width: '3%', cursor: 'pointer' },
            render: function (rowData) { return (<span style={{
                    transition: '.3s',
                    opacity: Number(rowData.id) === (currentRow === null || currentRow === void 0 ? void 0 : currentRow.id) && action === 'delete'
                        ? '.3'
                        : 1,
                }}>
          <Trash />
        </span>); },
        },
    ];
    return columns;
};
export var getColumnsAnswersItem = function (currentRow, action, handleQuestionUpdate, background) {
    var columns = [
        {
            field: 'questiontext',
            title: 'Ответы',
            style: { textAlign: 'center' },
            render: function (rowData) { return (<span className="connected-question" key={rowData.id}>
          {rowData.answertxt}
        </span>); },
        },
        {
            field: 'check',
            style: {
                width: '3%',
                cursor: 'pointer',
            },
            render: function (rowData) { return (<span onClick={function () {
                    rowData.correct = !rowData.correct;
                    handleQuestionUpdate();
                }} className="service-polls__checkbox" style={{
                    background: background === 'transparent' ? '#FFFFFF' : '#F7F7F7',
                }}>
          {rowData.correct && <Check />}
        </span>); },
        },
        {
            field: 'delete',
            style: { width: '3%', cursor: 'pointer' },
            render: function (rowData) { return (<span style={{
                    transition: '.3s',
                    opacity: Number(rowData.id) === (currentRow === null || currentRow === void 0 ? void 0 : currentRow.id) && action === 'delete'
                        ? '.3'
                        : 1,
                }}>
          <Trash />
        </span>); },
        },
    ];
    return columns;
};
