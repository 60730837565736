var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Drawer } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { ReactComponent as Edit } from '@/assets/images/table-edit.svg';
import { ReactComponent as Trash } from '@/assets/images/table-trash.svg';
import AddBtn from '@/components/button/addBtn';
import FormDelete from '@/components/formDelete';
import Modal from '@/components/modal';
import AntTableWrapper from '@/components/table/antTableWrapper';
import { getColumnSearchProps } from '@/pages/services/tabs/servicePolls/helpers/utils';
import { formatDate } from '@/utils';
import { conditions } from '@/utils/rest/pages/conditions';
import ConditionEdit from './conditionEdit';
var Conditions = function () {
    var _a = useState(undefined), currentRow = _a[0], setCurrentRow = _a[1];
    var _b = useState(), action = _b[0], setAction = _b[1];
    var _c = useState(false), showAddForm = _c[0], setShowAddForm = _c[1];
    var _d = useQuery('ticketFields', conditions.get), data = _d.data, refetch = _d.refetch, isLoading = _d.isLoading;
    if (!isLoading) {
        data === null || data === void 0 ? void 0 : data.forEach(function (el, idx) {
            el['key'] = idx;
        });
    }
    var deleteMutation = useMutation('delete conditions', conditions.delete, {
        //TODO: те поля что удаляемы - удаляются, а так, что то с бэком, error: 'Справочник не найден'
        onSuccess: function (data) {
            refetch();
            if (data.status === 'failed') {
                alert(data.error.comment);
            }
        },
    });
    var conditionsType = [
        { name: 'Текстовое поле', value: 'text' },
        { name: 'Одиночный выбор', value: 'select' },
    ];
    var columns = [
        __assign({ dataIndex: 'name', title: 'Наименование', align: 'center' }, getColumnSearchProps('name', 'по наименованию')),
        {
            dataIndex: 'type',
            title: 'Тип',
            align: 'center',
            render: function (_, res) {
                var _a;
                return (<span>{(_a = conditionsType.find(function (el) { return el.value === res.type; })) === null || _a === void 0 ? void 0 : _a.name}</span>);
            },
        },
        { dataIndex: 'description', title: 'Описание', align: 'center' },
        {
            dataIndex: 'modified_on',
            title: 'Дата',
            align: 'center',
            width: 200,
            render: function (_, res) {
                return (<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <span>
              Создан {formatDate(String(res.created_on), 'YYYY.MM.DD HH:mm:ss')}
            </span>
            <span>
              Изменен{' '}
              {formatDate(String(res.modified_on), 'YYYY.MM.DD HH:mm:ss')}
            </span>
          </div>);
            },
        },
        {
            dataIndex: 'modified_by_username',
            title: 'Кем изменён',
            align: 'center',
        },
        {
            dataIndex: 'edit',
            align: 'center',
            width: 55,
            render: function (_, res) { return (<button onClick={function () {
                    setCurrentRow(res);
                    setShowAddForm(true);
                }}>
          <Edit />
        </button>); },
        },
        {
            dataIndex: 'delete',
            align: 'center',
            width: 55,
            render: function (_, res) { return (<button onClick={function () {
                    setCurrentRow(res);
                    setAction('delete');
                }}>
          <Trash />
        </button>); },
        },
    ];
    var handleCancel = function () {
        setCurrentRow(undefined);
        setShowAddForm(false);
    };
    var handleShowAddForm = function () {
        setShowAddForm(function (oldValue) { return !oldValue; });
    };
    var handleDelete = function () {
        if (currentRow) {
            deleteMutation.mutate(Number(currentRow.id));
            setCurrentRow(undefined);
            setAction(undefined);
            refetch();
        }
    };
    return (<div className="intervals">
      <Modal clean open={action === 'delete'}>
        <FormDelete handleCancel={function () { return setAction(undefined); }} handleSubmit={handleDelete}/>
      </Modal>
      <AddBtn handleClick={handleShowAddForm}/>
      <Drawer width={620} placement="right" onClose={handleCancel} open={showAddForm} destroyOnClose={true}>
        <ConditionEdit handleCancel={handleCancel} isOpen={showAddForm} refetch={refetch} data={currentRow}/>
      </Drawer>
      <p className="section-title">Дополнительные поля</p>
      <AntTableWrapper columns={columns} data={data} rowKey={'key'} loading={isLoading} bordered={false}/>
    </div>);
};
export default Conditions;
