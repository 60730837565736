import React from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Redirect, Route, Switch, } from 'react-router-dom';
import ServerFaultPage from '@/components/serverFault';
import paths from '@/constants/paths';
import About from '@/pages/about';
import OperatorsBoard from '@/pages/components/operatorsBoard';
import Users from '@/pages/components/users';
import Conditions from '@/pages/conditions';
import Dashboard from '@/pages/dashboard';
import Login from '@/pages/login';
import Services from '@/pages/services';
import Sessions from '@/pages/sessions';
import Archive from '@/pages/statistics/archive';
import Registers from '@/pages/statistics/registers';
import Reports from '@/pages/statistics/reports';
import Storage from '@/pages/storage';
import Content from '@/pages/system/content';
import Copies from '@/pages/system/copies';
import Parameters from '@/pages/system/parameters';
import Workspaces from '@/pages/workplaces';
import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';
// import Logs from '@/pages/logs';
// import Storage from '@/pages/storage';
// import Copies from '@/pages/copies';
// import Sessions from '@/pages/sessions';
// import About from '@/pages/about';
var App = function (_a) {
    var match = _a.match;
    var i18n = useTranslation().i18n;
    var url = match.url, params = match.params;
    var path = function (path) { return "".concat(url).concat(path); };
    var changeLanguage = function (lng) {
        i18n.changeLanguage(lng);
    };
    if (i18n.language != params.locale) {
        changeLanguage(params.locale);
    }
    return (<Switch>
      <PublicRoute restricted={true} component={Login} path={path(paths.login)} lang={url} exact/>
      <PrivateRoute component={Dashboard} path={path('/')} exact/>
      <PrivateRoute component={Dashboard} path={path(paths.dashboard)} exact/>
      <PrivateRoute component={Reports} path={path(paths['reports-tab'])} exact/>
      <PrivateRoute component={Reports} path={path(paths.reports)} exact/>
      <PrivateRoute component={Archive} path={path(paths.archive)} exact/>
      <PrivateRoute component={Registers} path={path(paths.registers)} exact/>
      <PrivateRoute component={Services} path={path(paths['services-tab'])} exact/>
      <PrivateRoute component={Workspaces} path={path(paths.workplaces)} exact/>
      <PrivateRoute component={Conditions} path={path(paths.serviceConditions)} exact/>
      <PrivateRoute component={Users} path={path(paths.users)} exact/>
      <PrivateRoute component={Copies} path={path(paths.copies)} exact/>
      <PrivateRoute component={About} path={path(paths.about)} exact/>
      <PrivateRoute component={Sessions} path={path(paths.sessions)} exact/>
      <PrivateRoute component={Storage} path={path(paths.storage)} exact/>
      <PrivateRoute component={Copies} path={path(paths.copies)} exact/>
      <PrivateRoute component={Content} path={path(paths['content-tab'])} exact/>
      <PrivateRoute component={Parameters} path={path(paths.parameters)} exact/>
      <PrivateRoute component={OperatorsBoard} path={path(paths.operatorsBoard)} exact/>
      <Route component={ServerFaultPage}/>
    </Switch>);
};
var Routes = function () {
    var i18n = useTranslation().i18n;
    return (<BrowserRouter>
      <Switch>
        <Route path="/:locale" component={App}/>
        <Redirect to={"/".concat(i18n.language, "/login")}/>
      </Switch>
    </BrowserRouter>);
};
export default Routes;
