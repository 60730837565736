import React, { useEffect, useState } from 'react';
import Input from '@/components/input/input';
import Select from '@/components/input/select';
import { defaultSelectOptions } from '@/pages/statistics/reports/filters/filterParametrs';
import { FilterStore } from '@/store';
var ProcTimeFilter = function (_a) {
    var handleSetFilters = _a.handleSetFilters;
    var filterParams = FilterStore.useState(function (s) { return s.initialParams; });
    var totalOptionState = defaultSelectOptions.find(function (el) { return el.value === filterParams.total_proc_time.split(' ')[0]; });
    var avgOptionState = defaultSelectOptions.find(function (el) { return el.value === filterParams.avg_wait_time.split(' ')[0]; });
    var _b = useState({
        value: totalOptionState ? totalOptionState.value : '',
        name: totalOptionState ? totalOptionState.name : '',
    }), totalOption = _b[0], setTotalOption = _b[1];
    var _c = useState({
        value: avgOptionState ? avgOptionState.value : '',
        name: avgOptionState ? avgOptionState.name : '',
    }), avgOption = _c[0], setAvgOption = _c[1];
    var _d = useState(filterParams.total_proc_time.split(' ')[1]), totalValue = _d[0], setTotalValue = _d[1];
    var _e = useState(filterParams.avg_wait_time.split(' ')[1]), avgValue = _e[0], setAvgValue = _e[1];
    var handleSelect = function (_a) {
        var option = _a.option, name = _a.name;
        switch (name) {
            case 'total_proc_time':
                if (option.value === '') {
                    setTotalOption({
                        value: '',
                        name: '',
                    });
                    setTotalValue(undefined);
                    handleSetFilters(name, 'all');
                    break;
                }
                setTotalOption(option);
                break;
            case 'avg_proc_time':
                if (option.value === '') {
                    setAvgOption({
                        value: '',
                        name: '',
                    });
                    setAvgValue(undefined);
                    handleSetFilters(name, 'all');
                }
                setAvgOption(option);
                break;
        }
    };
    var handleInput = function (e) {
        var _a = e.target, value = _a.value, name = _a.name;
        switch (name) {
            case 'total_proc_time':
                setTotalValue(value);
                handleSetFilters(name, "".concat(totalOption.value, " ").concat(value));
                break;
            case 'avg_proc_time':
                setAvgValue(value);
                handleSetFilters(name, "".concat(avgOption.value, " ").concat(value));
                break;
        }
    };
    useEffect(function () {
        if (totalValue) {
            handleSetFilters('total_proc_time', "".concat(totalOption.value, " ").concat(totalValue));
        }
        if (avgValue) {
            handleSetFilters('avg_proc_time', "".concat(avgOption.value, " ").concat(avgValue));
        }
    }, [totalOption, avgOption]);
    return (<div className="report_filters__box">
      <p className="report_filters__title">Длительность обслуживания</p>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Общая</p>
        <Select width={264} placeholder="Выбрать" name="total_proc_time" value={totalOption.name} onChange={handleSelect} options={defaultSelectOptions}/>
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Минут</p>
        <Input type="number" name="total_proc_time" width={264} onChange={handleInput} value={totalValue} disabled={totalOption.name === ''}/>
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Средняя</p>
        <Select width={264} placeholder="Выбрать" name="avg_proc_time" value={avgOption.name} onChange={handleSelect} options={defaultSelectOptions}/>
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Минут</p>
        <Input type="number" name="avg_proc_time" width={264} onChange={handleInput} value={avgValue} disabled={avgOption.name === ''}/>
      </div>
    </div>);
};
export default ProcTimeFilter;
