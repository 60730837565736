import React from 'react';
import './formDelete.css';
var FormDelete = function (_a) {
    var handleSubmit = _a.handleSubmit, handleCancel = _a.handleCancel;
    return (<div className="form-delete">
      <p className="form-delete__title">Удалить?</p>
      <div className="form-delete__buttons">
        <button onClick={handleSubmit}>Ок</button>
        <button onClick={handleCancel}>Отмена</button>
      </div>
    </div>);
};
export default FormDelete;
