var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Button from '@/components/button';
import Checkbox from '@/components/input/checkbox';
import Input from '@/components/input/input';
import Select from '@/components/input/select';
import Loader from '@/components/loader';
import MultiSelect from '@/components/multiSelect';
import { workplaces } from '@/utils/rest';
import { services, users } from '@/utils/rest/pages/services';
import './user.css';
var UserEdit = function (_a) {
    var _b;
    var userData = _a.userData, _c = _a.background, background = _c === void 0 ? 'transparent' : _c, handleCancel = _a.handleCancel, refetch = _a.refetch;
    var _d = useState(false), error = _d[0], setError = _d[1];
    var _e = useState(false), valid = _e[0], setValid = _e[1];
    var _f = useState([]), workPlaces = _f[0], setWorkPlaces = _f[1];
    var _g = useState(__assign({ fio: '', username: '', hidden: false, blocked: false, connectedQueues: [], connectedRoles: [], default_wplace: null }, userData)), formData = _g[0], setFormData = _g[1];
    var _h = useQuery('queue', services.get), isQueueLoading = _h.isLoading, queueData = _h.data;
    var unboundWPlaces = useQuery('unbound', workplaces.getUnbound).data;
    var WPlaces = useQuery(['workplaces', unboundWPlaces], workplaces.get, {
        onSuccess: function (data) {
            var options = [
                { name: 'Отвязать рабочее место', value: 'none' },
            ];
            var filteredData = data.filter(function (el) {
                return unboundWPlaces.includes(el.id);
            });
            filteredData.forEach(function (el) {
                var item = { name: el.name, value: el.id };
                options.push(item);
            });
            setWorkPlaces(options);
        },
    }).data;
    var queryClient = useQueryClient();
    var rolesData = queryClient.getQueryData('roles');
    var userId = userData === null || userData === void 0 ? void 0 : userData.id;
    var fio = formData.fio, username = formData.username, hidden = formData.hidden, pwd = formData.pwd, blocked = formData.blocked, connectedQueues = formData.connectedQueues, connectedRoles = formData.connectedRoles, default_wplace = formData.default_wplace;
    var selected;
    if (default_wplace && WPlaces) {
        selected = (_b = WPlaces.find(function (el) { return el.id === default_wplace; })) === null || _b === void 0 ? void 0 : _b.name;
    }
    var userAdd = useMutation(users.add, {
        onSuccess: function () {
            if (refetch) {
                refetch();
            }
        },
    });
    var userUpdate = useMutation(users.update, {
        onSuccess: function (data) {
            var users = queryClient.getQueryData('users');
            if (users) {
                var newUsers = users.map(function (item) {
                    if (item.id === data.updatedId) {
                        return __assign(__assign({}, item), formData);
                    }
                    return item;
                });
                queryClient.setQueryData('users', newUsers);
            }
            if (refetch) {
                refetch();
            }
        },
    });
    var handleInput = function (e) {
        var _a = e.target, value = _a.value, name = _a.name;
        setFormData(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleCheck = function (e) {
        var _a = e.target, checked = _a.checked, name = _a.name;
        setFormData(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = checked, _a)));
        });
    };
    var handleSelect = function (option) {
        if (option.option.value === 'none') {
            setFormData(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[option.name] = null, _a)));
            });
        }
        else {
            setFormData(function (prevState) {
                var _a;
                return (__assign(__assign({}, prevState), (_a = {}, _a[option.name] = option.option.value, _a)));
            });
        }
    };
    var handleMultiCheckRoles = function (id) {
        if (id === -1) {
            var allRoles_1 = [];
            rolesData === null || rolesData === void 0 ? void 0 : rolesData.forEach(function (item) {
                allRoles_1.push(item.id);
            });
            setFormData(__assign(__assign({}, formData), { connectedRoles: __spreadArray([], allRoles_1, true) }));
            return;
        }
        if (id === -2) {
            setFormData(__assign(__assign({}, formData), { connectedRoles: [] }));
            return;
        }
        var checkIndex = connectedRoles.indexOf(id);
        if (checkIndex === -1) {
            setFormData(__assign(__assign({}, formData), { connectedRoles: __spreadArray(__spreadArray([], connectedRoles, true), [id], false) }));
        }
        else {
            connectedRoles.splice(checkIndex, 1);
            setFormData(__assign(__assign({}, formData), { connectedRoles: __spreadArray([], connectedRoles, true) }));
        }
    };
    var handleMultiCheckQueues = function (id) {
        if (id === -1) {
            var allQueues_1 = [];
            queueData === null || queueData === void 0 ? void 0 : queueData.map(function (item) { return allQueues_1.push(item.id); });
            setFormData(__assign(__assign({}, formData), { connectedQueues: __spreadArray([], allQueues_1, true) }));
            return;
        }
        if (id === -2) {
            setFormData(__assign(__assign({}, formData), { connectedQueues: [] }));
            return;
        }
        var checkIndex = connectedQueues.indexOf(id);
        if (checkIndex === -1) {
            setFormData(__assign(__assign({}, formData), { connectedQueues: __spreadArray(__spreadArray([], connectedQueues, true), [id], false) }));
        }
        else {
            connectedQueues.splice(checkIndex, 1);
            setFormData(__assign(__assign({}, formData), { connectedQueues: __spreadArray([], connectedQueues, true) }));
        }
    };
    useEffect(function () {
        if (fio !== '' && username !== '') {
            setValid(true);
        }
    }, [fio, username, pwd, setError]);
    useEffect(function () {
        setFormData(function (prevState) { return (__assign(__assign({}, prevState), userData)); });
    }, [userData]);
    var saveUserInfo = function () {
        if (formData.username.length > 0 && formData.fio.length > 0 && userData) {
            //TODO проверка пароля
            userUpdate.mutate({ payload: formData, id: userId });
            handleCancel();
            return;
        }
        if (valid && !userData) {
            setError(false);
            userAdd.mutate({ payload: formData });
            handleCancel();
            return;
        }
        setError(true);
    };
    if (isQueueLoading) {
        return <Loader />;
    }
    return (<>
      <div className="editable-item__form">
        <div className="editable-item__form-rows">
          <div className={background === 'transparent'
            ? 'editable-item__form-column'
            : 'editable-item__form-column editable-item__form-column_transparent'}>
            <div className="drawerStyle">
              <Input text="ФИО" placeholder="Фамилия Имя Отчество" name="fio" value={fio} width={380} onChange={handleInput} error={fio === '' && error}/>
              <Input text="Логин" placeholder="Введите логин" name="username" value={username} width={380} onChange={handleInput} error={username === '' && error}/>
              <Input text="Пароль" placeholder="Введите пароль" type="password" name="pwd" value={pwd} width={380} onChange={handleInput} error={error}/>
              <Select name={'default_wplace'} text={'Привязанное рабочее место'} options={workPlaces} onChange={handleSelect} placeholder={'Выберите рабочее место для привязки'} value={selected ? selected : 'Рабочее место не привязано'}/>
            </div>
            <div>
              <p className="field-text__title">Разрешения</p>
              <Checkbox text="Запрет работы на АРМ" name="hidden" value={hidden} onChange={handleCheck}/>
              <Checkbox text="Запрет входа через панель администрирования" name="blocked" value={blocked} onChange={handleCheck}/>
            </div>
          </div>
          <div className="editable-item__form-column user-edit__right-column">
            <MultiSelect handleCheck={handleMultiCheckRoles} selected={connectedRoles} title="Роли" data={rolesData === null || rolesData === void 0 ? void 0 : rolesData.map(function (item) { return ({
            id: item.id,
            name: item.printname,
        }); })}/>
            <MultiSelect handleCheck={handleMultiCheckQueues} selected={connectedQueues} title="Очереди" data={queueData === null || queueData === void 0 ? void 0 : queueData.map(function (item) { return ({
            id: item.id,
            name: item.name,
        }); })}/>
          </div>
        </div>
      </div>
      <div className="editable-item__form-btns">
        <Button text="Сохранить" onClick={saveUserInfo}/>
        {/* <Button text="Отменить" onClick={hanldeCancel} /> */}
      </div>
    </>);
};
export default UserEdit;
