import React from 'react';
import { ReactComponent as Edit } from '@/assets/images/table-edit.svg';
export var getColumns = function (setShowAction, setCurrentRow) {
    var columns = [
        {
            dataIndex: 'id',
            title: 'ID',
            width: 100,
            align: 'center',
        },
        {
            dataIndex: 'description',
            title: 'Описание',
            width: 400,
        },
        {
            dataIndex: 'substatuses',
            title: 'Субстатусы',
            render: function (_, rec) {
                return (<div className="table__statuses__cell">
            {rec.substatuses.map(function (el) { return (<p key={el.id}>{el.description}</p>); })}
          </div>);
            },
        },
        {
            dataIndex: 'edit',
            width: 80,
            align: 'center',
            render: function (_, res) { return (<button onClick={function () {
                    setCurrentRow(res);
                    setShowAction(true);
                }}>
          <Edit />
        </button>); },
        },
    ];
    return columns;
};
