import React, { useEffect } from 'react';
import { notification } from 'antd';
import { useMutation, useQuery } from 'react-query';
import ExportBtn from '@/components/button/exportBtn';
import Loader from '@/components/loader';
import AntTableWrapper from '@/components/table/antTableWrapper';
import ReportFilters from '@/pages/statistics/reports/filters/reportFilters';
import { columns } from '@/pages/statistics/reports/tabs/services/tableColumns';
import { FilterStore } from '@/store';
import { queueObjects, serviceReport } from '@/utils/rest/pages/reports';
var Services = function () {
    var filterParams = FilterStore.useState(function (s) { return s.initialParams; });
    var _a = useQuery('filtersData', queueObjects.get), filtersData = _a.data, isLoadingFilters = _a.isLoading;
    var report = useMutation('serviceReportData', serviceReport.get);
    var exportReport = useMutation('exportData', serviceReport.exportReport);
    var applyFilters = function () {
        report.mutate(filterParams);
    };
    var setFilterParams = function (newFilterParams) {
        FilterStore.update(function (s) {
            s.initialParams = newFilterParams;
        });
    };
    var exportData = function () {
        exportReport.mutate({ filterParams: filterParams, columns: columns }, {
            onSuccess: function (data) {
                var success = data.success;
                if (success) {
                    notification.success({
                        message: 'Отчет подготавливается',
                        description: 'По окончанию подготовки отчет будет доступен для скачивания в разделе "Архив"',
                    });
                }
                else {
                    notification.error({
                        message: 'Упс',
                        description: 'Что-то пошло не так',
                    });
                }
            },
        });
    };
    useEffect(function () {
        applyFilters();
    }, []);
    if (isLoadingFilters) {
        return <Loader />;
    }
    return (<>
      <ReportFilters applyFilters={applyFilters} filtersData={filtersData} filterParams={filterParams} setFilterParams={setFilterParams} showFilters={{
            main: ['reportRange', 'queues'],
            others: [
                'servedfilters',
                'waitTimeFilters',
                'procTimeFilter',
                'actionFilters',
            ],
            orders: [
                'queue_name',
                'all_cnt',
                'close_cnt',
                'neyavka_cnt',
                'total_wait_time',
                'avg_wait_time',
                'total_proc_time',
                'avg_proc_time',
            ],
        }}/>
      <p className="table-title">Отчёт</p>
      <ExportBtn onClick={exportData}/>
      <AntTableWrapper rowKey="queue" columns={columns} data={report.data} scroll={{ x: 2850 }} loading={report.isLoading}/>
    </>);
};
export default Services;
