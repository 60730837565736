var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import { ReactComponent as Trash } from '@/assets/images/table-trash.svg';
import Button from '@/components/button';
import FormDelete from '@/components/formDelete';
import Select from '@/components/input/select';
import Loader from '@/components/loader';
import Modal from '@/components/modal';
import AntTableWrapper from '@/components/table/antTableWrapper';
import texts from '@/pages/services/tabs/texts.json';
import './sessions.css';
import { sessions } from '@/utils/rest/pages/system';
var Sessions = function () {
    var _a = useState(null), currentRow = _a[0], setCurrentRow = _a[1];
    var _b = useState({
        users: { value: 0, name: '' },
    }), formData = _b[0], setFormData = _b[1];
    var _c = useQuery('users', sessions.getUser), isLoading = _c.isLoading, users = _c.data, refetchUsers = _c.refetch;
    var _d = useQuery('sessions', sessions.getUserSession), isLoadingSessions = _d.isLoading, allSessions = _d.data, refetchSessions = _d.refetch;
    if (!isLoadingSessions) {
        allSessions === null || allSessions === void 0 ? void 0 : allSessions.forEach(function (el, idx) {
            el['key'] = idx;
        });
    }
    var addMutation = useMutation(sessions.addUserSession, {
        onSuccess: function () {
            refetchSessions();
            refetchUsers();
        },
    });
    var deleteMutation = useMutation(sessions.deleteUserSession, {
        onSuccess: function () {
            setCurrentRow(null);
            refetchSessions();
            refetchUsers();
        },
    });
    var usersNames = useMemo(function () {
        return users === null || users === void 0 ? void 0 : users.map(function (user) { return ({
            value: user.id,
            name: user.username,
        }); });
    }, [users]);
    var findById = function (id) {
        if (usersNames) {
            var result = usersNames.find(function (user) { return user.value === id; });
            if (result) {
                return result.name;
            }
        }
    };
    var columns = [
        {
            dataIndex: 'user_id',
            title: 'Пользователь',
            align: 'center',
            render: function (_, rowData) { return <span>{findById(rowData.user_id)}</span>; },
        },
        { dataIndex: 'session', title: 'Сессия', align: 'center' },
        {
            dataIndex: 'constant',
            title: 'Тип',
            align: 'center',
            render: function (_, rowData) { return (<span>{rowData.constant ? 'Постоянная' : 'Временная'}</span>); },
        },
        {
            dataIndex: 'created_on',
            title: 'Дата создания',
            align: 'center',
            render: function (_, rowData) { return (<span>{moment(rowData.created_on).format('YYYY-MM-DD HH:mm:ss')}</span>); },
        },
        {
            dataIndex: 'delete',
            width: 100,
            align: 'center',
            render: function (_, rowData) { return (<span onClick={function () { return setCurrentRow(rowData.id); }}>
          <Trash />
        </span>); },
        },
    ];
    var handleSelect = function (_a) {
        var option = _a.option, name = _a.name;
        setFormData(function (oldData) {
            var _a;
            return (__assign(__assign({}, oldData), (_a = {}, _a[name] = option, _a)));
        });
    };
    var handleAdd = function () {
        addMutation.mutate(formData.users.value);
    };
    var handleDelete = function () { return currentRow && deleteMutation.mutate(currentRow); };
    return (<div className="sessions">
      <Modal clean open={!!currentRow}>
        <FormDelete handleCancel={function () { return setCurrentRow(null); }} handleSubmit={handleDelete}/>
      </Modal>
      {!isLoadingSessions && !isLoading ? (<>
          <div className="editable-item__form-row">
            <Select name="users" text="Создание постоянной сессии для пользователя" width={480} value={formData.users.name} options={usersNames} placeholder="Выберите из списка" onChange={handleSelect}/>
            <div className="sessions__button-wrapper">
              <Button disabled={!(formData.users.value && formData.users.name)} text="Создать" onClick={handleAdd} type="submit"/>
            </div>
          </div>
          <p className="section-title">{texts.sessionsTitle}</p>
          {/*<Table*/}
          {/*  data={allSessions}*/}
          {/*  paginationTop={true}*/}
          {/*  columns={columns}*/}
          {/*  isLoading={isLoadingSessions && isLoading}*/}
          {/*  headerStyle={{ fontSize: 14 }}*/}
          {/*  cellStyle={{ fontSize: 12 }}*/}
          {/*/>*/}
          <div style={{ width: '100%' }}>
            <AntTableWrapper columns={columns} data={allSessions} bordered={false} rowKey={'key'} loading={isLoadingSessions || isLoading}/>
          </div>
        </>) : (<Loader />)}
    </div>);
};
export default Sessions;
