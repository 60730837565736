import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import AntTableWrapper from '@/components/table/antTableWrapper';
import { getOperatorReportSql } from '@/pages/statistics/reports/tabs/consolidated/getSqlStr';
import { consolidatedReport } from '@/utils/rest/pages/reports';
var OperatorTable = function (_a) {
    var filterParams = _a.filterParams;
    var sql = getOperatorReportSql(filterParams.startDate, filterParams.endDate);
    var tableData = [];
    var _b = useQuery('operatorReport', function () {
        return consolidatedReport.get(sql);
    }), isLoading = _b.isLoading, data = _b.data, refetch = _b.refetch;
    if (!isLoading) {
        data.data.forEach(function (el) {
            var obj = {
                id: Number(el[0]),
                user_name: el[2],
                work_time: el[6],
                work_day: el[4],
                part_cnt: el[3],
                client_pd: el[5],
                grade: String(Math.floor(Number(el[7]) * 100) / 100),
            };
            tableData.push(obj);
        });
    }
    useEffect(function () {
        refetch();
    }, [filterParams]);
    var columns = [
        {
            dataIndex: 'user_name',
            title: 'Оператор',
            width: 280,
        },
        {
            dataIndex: 'work',
            title: 'Рабочее время',
            render: function (_, res) {
                return (<>
            <div className="report__table__text">
              <p>Суммарное время обслуживания клиентов:</p>
              <p>{res.work_time ? res.work_time : '00:00:00'}</p>
            </div>
            <br />
            <div className="report__table__text">
              <p>Рабочих дней:</p>
              <p>{res.work_day ? res.work_day : '0'}</p>
            </div>
          </>);
            },
        },
        {
            dataIndex: 'client',
            title: 'Клиентов обработано',
            render: function (_, res) { return (<>
          <div className="report__table__text">
            <p>Всего:</p>
            <p>{res.part_cnt ? res.part_cnt : '0'}</p>
          </div>
          <br />
          <div className="report__table__text">
            <p>В среднем за день:</p>
            <p>{res.client_pd ? res.client_pd : '0'}</p>
          </div>
        </>); },
        },
        {
            dataIndex: 'grade',
            title: 'Оценка',
            align: 'center',
            render: function (_, res) { return <p>{res.grade ? res.grade : 'Нет оценок'}</p>; },
        },
    ];
    return (<div className="report__section report__section-table">
      <p className="section-title">Операторы</p>
      {/*<Table*/}
      {/*  valueKey="id"*/}
      {/*  columns={columns}*/}
      {/*  isLoading={isLoading}*/}
      {/*  data={tableData}*/}
      {/*  headerStyle={{ fontSize: 14 }}*/}
      {/*  cellStyle={{ fontSize: 12 }}*/}
      {/*  paginationTop*/}
      {/*/>*/}
      <AntTableWrapper columns={columns} data={tableData} rowKey={'id'} loading={isLoading} scroll={{ x: 700 }}/>
    </div>);
};
export default OperatorTable;
