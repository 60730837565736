var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, } from 'react-beautiful-dnd';
import { useMutation, useQuery } from 'react-query';
import Button from '@/components/button';
import AddBtn from '@/components/button/addBtn';
import Input from '@/components/input/input';
import Select from '@/components/input/select';
import ConditionPrior from '@/pages/conditions/conditionPrior';
import { conditions } from '@/utils/rest/pages/conditions';
import './condition.css';
var ConditionEdit = function (_a) {
    var _b;
    var data = _a.data, handleCancel = _a.handleCancel, refetch = _a.refetch, _c = _a.background, background = _c === void 0 ? 'transparent' : _c;
    var _d = useState([]), values = _d[0], setValues = _d[1];
    var _e = useState([]), newValues = _e[0], setNewValues = _e[1];
    var selectOptions = [
        { name: 'Текстовое поле', value: 'text' },
        { name: 'Одиночный выбор', value: 'select' },
    ];
    var refetchDictionary = useQuery('get dictionary', function () { return conditions.getDictionary(data === null || data === void 0 ? void 0 : data.dict_id); }, {
        enabled: !!(data === null || data === void 0 ? void 0 : data.dict_id) && (data === null || data === void 0 ? void 0 : data.dict_id) !== 0,
        onSuccess: function (data) {
            setValues(data.values);
        },
    }).refetch;
    var _f = useState({
        name: (data === null || data === void 0 ? void 0 : data.name) || '',
        type: (data === null || data === void 0 ? void 0 : data.type) || '',
        rus_name: (data === null || data === void 0 ? void 0 : data.rus_name) || '',
        description: (data === null || data === void 0 ? void 0 : data.description) || '',
        dict_id: (data === null || data === void 0 ? void 0 : data.dict_id) || 0,
    }), formData = _f[0], setFormData = _f[1];
    var name = formData.name, description = formData.description, rus_name = formData.rus_name, type = formData.type, dict_id = formData.dict_id;
    var addMutation = useMutation('add conditions', conditions.add, {
        onSuccess: function (data) {
            if (refetch) {
                refetch();
            }
            if (newValues.length > 0) {
                newValues.forEach(function (el) {
                    delete el.id;
                    el.dictionary_id = data.data.dict_id;
                    addDictionaryMutation.mutate({ payload: el });
                });
            }
        },
    });
    var updateMutation = useMutation('update conditions', conditions.update, {
        onSuccess: function () {
            if (refetch) {
                refetch();
            }
        },
    });
    var updatePosition = useMutation('position', conditions.updateDictionaryPosition);
    var addDictionaryMutation = useMutation('add dictionary', conditions.addDictionary, {
        onSuccess: function () {
            if (data) {
                refetchDictionary();
            }
            if (refetch) {
                refetch();
            }
        },
    });
    var updateDictionaryMutation = useMutation('update dictionary', conditions.updateDictionary, {
        onSuccess: function () {
            if (data) {
                refetchDictionary();
            }
            if (refetch) {
                refetch();
            }
        },
    });
    var deleteDictionaryMutation = useMutation('delete dictionary', conditions.deleteDictionary, {
        onSuccess: function () {
            refetchDictionary();
            if (refetch) {
                refetch();
            }
        },
    });
    var handleInput = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        if (name === 'description') {
            var newData = __assign(__assign({}, formData), (_a = {}, _a[name] = value, _a.rus_name = value, _a));
            setFormData(newData);
        }
        else {
            setFormData(function (oldData) {
                var _a;
                return (__assign(__assign({}, oldData), (_a = {}, _a[name] = value, _a)));
            });
        }
    };
    var handleSubmit = function () {
        if (data) {
            var updateData = {
                name: name,
                type: type,
                dict_id: dict_id,
                rus_name: rus_name,
                description: description,
            };
            updateMutation.mutate({ id: data.id, payload: updateData });
        }
        else {
            var newData = {
                name: name,
                type: type,
                rus_name: rus_name,
                description: description,
            };
            addMutation.mutate({ payload: newData });
        }
        handleCancel();
    };
    var handleSelect = function (_a) {
        var option = _a.option, name = _a.name;
        setFormData(function (oldData) {
            var _a;
            return (__assign(__assign({}, oldData), (_a = {}, _a[name] = option.value, _a)));
        });
    };
    var onBlur = function (prior, id) {
        var currentPrior = values === null || values === void 0 ? void 0 : values.find(function (el) { return el.id === id; });
        if (currentPrior && prior !== undefined) {
            updateDictionaryMutation.mutate({
                payload: __assign(__assign({}, currentPrior), { prior: prior.toString() }),
                id: currentPrior.id,
            });
        }
    };
    var onChangePrior = function (e, prior, id) {
        if (values.length > 0) {
            var changedValues = values.map(function (el) {
                return el.id === +e.target.id ? __assign(__assign({}, el), { prior: prior }) : el;
            });
            setValues(changedValues);
        }
        else {
            var changedValues = newValues.map(function (el) {
                return el.id === id ? __assign(__assign({}, el), { prior: prior }) : el;
            });
            setNewValues(changedValues);
        }
    };
    var onChangePriorInput = function (e, id) {
        if (values) {
            var changedValues = values.map(function (el) {
                var _a;
                return el.id === Number(id) ? __assign(__assign({}, el), (_a = {}, _a[e.target.name] = e.target.value, _a)) : el;
            });
            setValues(changedValues);
        }
        if (newValues) {
            var changedValues = newValues.map(function (el) {
                var _a;
                return el.id === Number(id) ? __assign(__assign({}, el), (_a = {}, _a[e.target.name] = e.target.value, _a)) : el;
            });
            setNewValues(changedValues);
        }
    };
    var addValue = function (dictionary_id) {
        if (dictionary_id === 0) {
            alert('dictionary_id = 0');
        }
        var payload = {
            dictionary_id: dictionary_id,
            handjob: false,
            name: "\u0421\u0432\u043E\u0439\u0441\u0442\u0432\u043E ".concat(values ? values.length + 1 : '1'),
            prior: 0,
            valprefix: '',
        };
        addDictionaryMutation.mutate({ payload: payload });
    };
    var addNewValue = function () {
        var addedValues = __spreadArray([], newValues, true);
        var value = {
            id: Number("".concat(newValues.length + 1)),
            handjob: false,
            name: "\u0421\u0432\u043E\u0439\u0441\u0442\u0432\u043E ".concat(newValues ? newValues.length + 1 : '1'),
            prior: 0,
            valprefix: '',
        };
        addedValues.push(value);
        setNewValues(addedValues);
    };
    var reorder = function (list, startIndex, endIndex) {
        var result = Array.from(list);
        var removed = result.splice(startIndex, 1)[0];
        result.splice(endIndex, 0, removed);
        return result;
    };
    var onDragEnd = function (result) {
        if (!result.destination) {
            return;
        }
        var reorderValues = reorder(values, result.source.index, result.destination.index);
        setValues(reorderValues);
    };
    var handleDelete = function (id) {
        if (newValues.length > 0) {
            var changedValues_1 = __spreadArray([], newValues, true);
            changedValues_1.forEach(function (el, index) {
                if (el.id === id) {
                    changedValues_1.splice(index, 1);
                }
            });
            setNewValues(changedValues_1);
        }
        else {
            id && deleteDictionaryMutation.mutate(id);
        }
    };
    useEffect(function () {
        values.forEach(function (value, idx) {
            console.log("".concat(value.name, ": ").concat(idx));
            var payload = {
                position: idx,
                id: value.id,
            };
            updatePosition.mutate(payload);
        });
    }, [values]);
    return (<div className="editable-item__form">
      <div className={background === 'transparent'
            ? 'editable-item__form-row'
            : 'editable-item__form-row editable-item__form-row_transparent'}>
        <div className="drawerStyle">
          <Input name="name" placeholder="Введите название" text="Наименование" value={name} onChange={handleInput} width={380}/>
          <Input name="description" placeholder="Введите название" text="Описание" value={description} onChange={handleInput} width={380}/>
          {!data && (<Select options={selectOptions} name="type" text="Тип" placeholder="Выберите из списка" onChange={handleSelect} value={(_b = selectOptions.find(function (el) { return el.value === formData.type; })) === null || _b === void 0 ? void 0 : _b.name}/>)}
        </div>
      </div>
      {formData.type && formData.type !== 'text' && (data === null || data === void 0 ? void 0 : data.id) ? (<div>
          <AddBtn handleClick={function () { return addValue(formData.dict_id); }} text={'Добавить элемент для выбора'}/>
        </div>) : (formData.type !== 'text' && (<div>
            <AddBtn handleClick={addNewValue} text={'Добавить элемент для выбора'}/>
          </div>))}
      <div className={background === 'transparent'
            ? 'editable-item__form-row'
            : 'editable-item__form-row editable-item__form-row_transparent'}>
        <div className="condition">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {function (provided) { return (<div {...provided.droppableProps} ref={provided.innerRef} style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
            }}>
                  {values === null || values === void 0 ? void 0 : values.map(function (item, idx) { return (<Draggable draggableId={item.name} index={idx} key={item.id}>
                      {function (provided) { return (<div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                          <ConditionPrior key={item.id} id={item.id} isOpen={false} title={item.name} prefix={item.valprefix} prior={item.prior} onChangePrior={onChangePrior} onBlur={onBlur} onChangeInput={onChangePriorInput} background={background} onDelete={function () {
                        handleDelete(item.id);
                    }}/>
                        </div>); }}
                    </Draggable>); })}
                </div>); }}
            </Droppable>
          </DragDropContext>
          {/*{values?.map((el) => (*/}
          {/*  <ConditionPrior*/}
          {/*    key={el.id}*/}
          {/*    id={el.id}*/}
          {/*    isOpen={false}*/}
          {/*    title={el.name}*/}
          {/*    prefix={el.valprefix}*/}
          {/*    prior={el.prior}*/}
          {/*    onChangePrior={onChangePrior}*/}
          {/*    onBlur={onBlur}*/}
          {/*    onChangeInput={onChangePriorInput}*/}
          {/*    background={background}*/}
          {/*    onDelete={() => {*/}
          {/*      handleDelete(el.id);*/}
          {/*    }}*/}
          {/*  />*/}
          {/*))}*/}
          {/*{newValues?.map((el) => (*/}
          {/*  <ConditionPrior*/}
          {/*    id={el.id}*/}
          {/*    key={el.id}*/}
          {/*    isOpen={false}*/}
          {/*    title={el.name}*/}
          {/*    prefix={el.valprefix}*/}
          {/*    prior={el.prior}*/}
          {/*    background={background}*/}
          {/*    onChangePrior={onChangePrior}*/}
          {/*    onChangeInput={onChangePriorInput}*/}
          {/*    onDelete={() => {*/}
          {/*      handleDelete(el.id);*/}
          {/*    }}*/}
          {/*    onBlur={onBlur}*/}
          {/*  />*/}
          {/*))}*/}
        </div>
      </div>

      <div className="editable-item__form-btns">
        <Button text="Сохранить" onClick={handleSubmit}/>
        {/* {!!data && <Button text="Отменить" onClick={handleCancel} />} */}
      </div>
    </div>);
};
export default ConditionEdit;
