var TableConfig = function () {
    return {
        columns: [
            {
                dataIndex: '3',
                title: 'Вопрос',
            },
            {
                dataIndex: '6',
                title: 'Тип вопроса',
            },
            {
                dataIndex: '4',
                title: 'Ответ',
            },
        ],
    };
};
export default TableConfig;
