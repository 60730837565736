import React from 'react';
import ActionFilters from '@/pages/statistics/reports/filters/filterItems/actionFilters';
import ClientParamsFilters from '@/pages/statistics/reports/filters/filterItems/clientParamsFilters';
import MarkFilters from '@/pages/statistics/reports/filters/filterItems/markFilters';
import ProcTimeFilter from '@/pages/statistics/reports/filters/filterItems/procTimeFilter';
import ServedDateFilters from '@/pages/statistics/reports/filters/filterItems/servedDateFilters';
import ServedFilters from '@/pages/statistics/reports/filters/filterItems/servedFilters';
import ServedTimeFilters from '@/pages/statistics/reports/filters/filterItems/servedTimeFilters';
import WaitTimeFilters from '@/pages/statistics/reports/filters/filterItems/waitTimeFilters';
import WorkTimeFilter from '@/pages/statistics/reports/filters/filterItems/workTimeFilter';
export var renderSecondaryFilter = function (_a) {
    var showFilters = _a.showFilters, handleSetFilters = _a.handleSetFilters;
    var filtersMap = {
        clientParamsFilters: (<ClientParamsFilters handleSetFilters={handleSetFilters} key={1}/>),
        servedDateFilters: (<ServedDateFilters handleSetFilters={handleSetFilters} key={2}/>),
        servedTimeFilters: (<ServedTimeFilters handleSetFilters={handleSetFilters} key={3}/>),
        servedfilters: (<ServedFilters handleSetFilters={handleSetFilters} key={4}/>),
        waitTimeFilters: (<WaitTimeFilters handleSetFilters={handleSetFilters} key={5}/>),
        procTimeFilter: (<ProcTimeFilter handleSetFilters={handleSetFilters} key={6}/>),
        actionFilters: (<ActionFilters handleSetFilters={handleSetFilters} key={7}/>),
        workTimeFilter: (<WorkTimeFilter handleSetFilters={handleSetFilters} key={8}/>),
        markFilters: <MarkFilters handleSetFilters={handleSetFilters} key={9}/>,
    };
    return showFilters.map(function (item) { return filtersMap[item]; });
};
