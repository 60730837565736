import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ReactComponent as Edit } from '@/assets/images/table-edit.svg';
import { ReactComponent as FalseIcon } from '@/assets/images/table-icon-false.svg';
import { ReactComponent as TrueIcon } from '@/assets/images/table-icon-true.svg';
import { ReactComponent as Trash } from '@/assets/images/table-trash.svg';
import AddBtn from '@/components/button/addBtn';
import EditableItem from '@/components/editableItem';
import FormDelete from '@/components/formDelete';
import Modal from '@/components/modal';
import Table from '@/components/table';
import BoardEdit from '@/pages/components/operatorsBoard/boardEdit';
import { boards } from '@/utils/rest/pages/boards';
var OperatorsBoard = function () {
    var _a = useState(null), currentRow = _a[0], setCurrentRow = _a[1];
    var _b = useState(''), action = _b[0], setAction = _b[1];
    var _c = useState(false), showAdd = _c[0], setShowAdd = _c[1];
    var initialData = {
        address: '',
        config: {
            digitParams: [],
        },
        description: '',
        enabled: true,
        name: '',
        number: 0,
        oid: 0,
        type: 'abt_matrix',
    };
    var _d = useQuery('digitBoards', boards.get), isLoading = _d.isLoading, digitBardsData = _d.data, refetch = _d.refetch;
    var deleteBoard = useMutation(boards.delete, {
        onSuccess: function () { return refetch(); },
    });
    var handleDelete = function () {
        var userId = currentRow === null || currentRow === void 0 ? void 0 : currentRow.id;
        deleteBoard.mutate(userId);
        setCurrentRow(null);
    };
    // Колонки таблицы
    var columns = [
        {
            field: 'name',
            title: 'Наименование',
            style: { cursor: 'pointer', paddingLeft: '12px' },
        },
        {
            field: 'address',
            title: 'IP адрес',
            style: { textAlign: 'center' },
            render: function (rowData) { return (<span>
          <p>{rowData.address.split(':')[0]}</p>
        </span>); },
        },
        {
            field: 'port',
            title: 'Порт',
            style: { textAlign: 'center' },
            render: function (rowData) { return (<span>
          <p>{rowData.address.split(':')[1]}</p>
        </span>); },
        },
        { field: 'number', title: 'Номер', style: { textAlign: 'center' } },
        { field: 'created_by_username', title: 'Кем создан' },
        {
            field: 'created_on',
            title: 'Дата создания',
            style: { textAlign: 'center' },
        },
        {
            field: 'enabled',
            title: 'Статус',
            style: { textAlign: 'center' },
            render: function (rowData) {
                return rowData.enabled ? (<span>
            <TrueIcon />
          </span>) : (<span>
            <FalseIcon />
          </span>);
            },
        },
        {
            field: 'edit',
            style: { width: '4%', cursor: 'pointer' },
            render: function (rowData) { return (<span style={{
                    transition: '.3s',
                    opacity: Number(rowData.id) === (currentRow === null || currentRow === void 0 ? void 0 : currentRow.id) && action === 'edit'
                        ? '.3'
                        : '1',
                }}>
          <Edit />
        </span>); },
        },
        {
            field: 'delete',
            style: { width: '4%', cursor: 'pointer' },
            render: function () { return (<span>
          <Trash />
        </span>); },
        },
    ];
    // Действия пользователя
    var handleRow = function (e, rowData, column) {
        var newAction = ((column === null || column === void 0 ? void 0 : column.field) !== 'delete' && 'edit') || (column === null || column === void 0 ? void 0 : column.field);
        if ((currentRow === null || currentRow === void 0 ? void 0 : currentRow.id) === rowData.id && action === newAction) {
            handleCancel();
        }
        else {
            setAction(newAction || (column === null || column === void 0 ? void 0 : column.field));
            setCurrentRow(rowData);
        }
    };
    var handleCancel = function () {
        setCurrentRow(null);
        setShowAdd(false);
    };
    // Рендер компонента
    return (<>
      <Modal clean open={!!currentRow && action === 'delete'}>
        <FormDelete handleCancel={function () { return setCurrentRow(null); }} handleSubmit={handleDelete}/>
      </Modal>
      <AddBtn handleClick={function () {
            setShowAdd(function (prevState) { return !prevState; });
        }} text={'Добавить'}/>
      <EditableItem isOpen={showAdd}>
        <BoardEdit handleCancel={handleCancel} data={initialData} refetch={refetch}/>
      </EditableItem>
      <p className="section-title">Табло оператора</p>
      <Table columns={columns} isLoading={isLoading} data={digitBardsData} headerStyle={{ fontSize: 14 }} cellStyle={{ fontSize: 12 }} paginationTop={true} onClick={handleRow} rowStyle={function (_, i) { return ({
            background: i % 2 ? 'transparent' : '#fff',
        }); }} onExpand={function (rowData, i) {
            return (<EditableItem isOpen={Boolean(currentRow) && action === 'edit'} cell={9} background={i % 2 ? 'transparent' : '#fff'}>
              <BoardEdit data={rowData} handleCancel={handleCancel} refetch={refetch} background={i % 2 ? 'transparent' : '#fff'}/>
            </EditableItem>);
        }}/>
    </>);
};
export default OperatorsBoard;
