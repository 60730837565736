import React, { useEffect, useState } from 'react';
import moment, { duration } from 'moment';
import './about.css';
import { useQuery } from 'react-query';
import AboutCard from '@/components/aboutCard';
import Loader from '@/components/loader';
import { getBVersions, getFVersions, getLicenseVersion, } from '@/utils/rest/pages/system';
import packageInfo from '../../../package.json';
import texts from './texts.json';
// import { License } from '@/models/license';
var About = function () {
    var _a = useState(true), isLoading = _a[0], setLoading = _a[1];
    var isPanelUpdated = true;
    var _b = useState(true), isLicenseUpdated = _b[0], setLicenseUpdated = _b[1];
    var _c = useState(''), licenseInfo = _c[0], setLicenseInfo = _c[1];
    var _d = useState(''), bSWorkTime = _d[0], setBSWorkTime = _d[1];
    var _e = useState(''), dBLastUpdate = _e[0], setDBLastUpdate = _e[1];
    var _f = useQuery('getVersions', getBVersions), bLoading = _f.isLoading, bVers = _f.data;
    var _g = useQuery('fsversion', getFVersions), fLoading = _g.isLoading, fVers = _g.data;
    var _h = useQuery('license', getLicenseVersion), lLoading = _h.isLoading, lVers = _h.data;
    var bVersions = bVers === null || bVers === void 0 ? void 0 : bVers.data;
    var fVersions = fVers === null || fVers === void 0 ? void 0 : fVers.versions;
    var licenses = lVers === null || lVers === void 0 ? void 0 : lVers.data;
    var adminVersion = packageInfo.version;
    var formatData = function () {
        // format licenses
        var license = getLastLicense();
        // let license = undefined;
        if (license) {
            var licFrom = moment(license === null || license === void 0 ? void 0 : license.validfrom).format('DD.MM.YYYY');
            var licTo = moment(license === null || license === void 0 ? void 0 : license.validto).format('DD.MM.YYYY');
            setLicenseInfo(licFrom + ' - ' + licTo);
        }
        else {
            setLicenseInfo('Лицензия не найдена'); // TODO узнать у Макса насчёт тестовой лицензии
        }
        // format bVersions
        var formattedDBUpdate = moment(bVersions === null || bVersions === void 0 ? void 0 : bVersions.dbLastUpgradedOn, 'YYYY.MM.DD H:m:s').format('HH:mm:ss - DD.MM.YYYY'); // форматируем дату последнего обновления БД
        setDBLastUpdate(formattedDBUpdate);
    };
    var getLastLicense = function () {
        // получить последнюю добавленную лицензию с active=true
        return licenses === null || licenses === void 0 ? void 0 : licenses.reverse().find(function (item) {
            if (item.active)
                return item;
        });
    };
    var checkLicActuality = function () {
        var lic = getLastLicense();
        if (!lic)
            return false;
        var currDate = moment();
        var licEnds = moment(lic.validto);
        var diff = licEnds.diff(currDate, 'seconds');
        return diff > 0;
    };
    // const checkPanelActuality = () => {
    //   return fVersions?.current.admin == fVersions?.latest.admin;
    // };
    var checkUpdates = function () {
        if (!checkLicActuality())
            setLicenseUpdated(false);
        // if (!checkPanelActuality()) setPanelUpdated(false);
    };
    var updateBSWorkTime = function () {
        var currDate = moment();
        var bStartTime = moment(bVersions === null || bVersions === void 0 ? void 0 : bVersions.eqServerLoadedOn, 'YYYY.MM.DD HH:mm:ss');
        var difference = duration(currDate.diff(bStartTime));
        var days = difference.days(); // вот эта херня по-другому не работает
        var hours = difference.hours(); // делая по-нормальному, не через duration, а через format, moment выводит days+1
        var minutes = difference.minutes(); // возможно из-за того, что либа заточена под даты,
        var seconds = difference.seconds(); // а даты 0 не может быть
        var result = days +
            ' дн. ' +
            hours +
            ' час. ' +
            minutes +
            ' мин. ' +
            seconds +
            ' сек.';
        setBSWorkTime(result);
    };
    var updateLicenseBtn = function () {
        console.log('update license');
    };
    var updatePanelBtn = function () {
        console.log('update panel');
    };
    var renderParameters = function () {
        var license = getLastLicense();
        var params = license &&
            Object.entries(license.licdescriptionjsn).filter(function (el) { return typeof el[1] === 'number'; });
        return (<div className="about__parameters">
        {params &&
                params.map(function (el) { return (<div className="about__parameter" key={el[0]}>
              <p>{el[0]}</p>
              <p style={{ color: '#777777' }}>{el[1]}</p>
            </div>); })}
      </div>);
    };
    var renderModules = function () {
        var license = getLastLicense();
        var modules = license &&
            Object.entries(license.licdescriptionjsn).filter(function (el) { return typeof el[1] === 'boolean'; });
        return (<div className="about__parameters">
        {modules &&
                modules.map(function (el) { return (<div className="about__parameter" key={el[0]}>
              <p>{el[0]}</p>
              <p style={{ color: el[1] ? '#76C944' : '#FF515B' }}>
                {el[1] ? 'Включено' : 'Выключено'}
              </p>
            </div>); })}
      </div>);
    };
    useEffect(function () {
        if (!bLoading && !lLoading && !fLoading) {
            formatData();
            checkUpdates();
            updateBSWorkTime();
            var bSWorkTimeUpdater_1 = setInterval(function () {
                updateBSWorkTime();
            }, 5000);
            setLoading(false);
            return function () {
                clearInterval(bSWorkTimeUpdater_1);
            };
        }
    }, [bLoading, lLoading, fLoading]);
    if (isLoading) {
        return <Loader />;
    }
    return (<div className="about">
      <section className="about__system">
        <p className="section-title">О системе</p>
        <div className="about__cards">
          {isLicenseUpdated ? (<AboutCard title={texts.license} value={licenseInfo} size="big" card="license"/>) : (<AboutCard title={texts.license} value={licenseInfo} size="big" card="license" btnText={texts.updateLicense} btnCallback={function () { return updateLicenseBtn(); }}/>)}
          <AboutCard title={texts.bServerVersion} value={bVersions === null || bVersions === void 0 ? void 0 : bVersions.version_baq} secondTitle={texts.bServerWorkTime} secondValue={bSWorkTime} size="big" isDouble={true}/>
          <AboutCard title={texts.dbVersion} value={bVersions === null || bVersions === void 0 ? void 0 : bVersions.baqServerDbVersion} secondTitle={texts.lastDbUpdate} secondValue={dBLastUpdate} size="big" isDouble={true}/>
          {isPanelUpdated ? (<AboutCard title={texts.panelVersion} value={adminVersion} size="big"/>) : (<AboutCard title={texts.panelVersion} value={fVersions === null || fVersions === void 0 ? void 0 : fVersions.current.admin} size="big" card="panelV" btnText={texts.update} btnCallback={function () { return updatePanelBtn(); }} secondTitle={texts.panelNewVersion} secondValue={fVersions === null || fVersions === void 0 ? void 0 : fVersions.latest.admin} isDouble={true}/>)}
          <AboutCard title={texts.fServerVersion} value={fVersions === null || fVersions === void 0 ? void 0 : fVersions.current.server} size="small"/>
          <AboutCard title={texts.clLibsVersion} value={bVersions === null || bVersions === void 0 ? void 0 : bVersions.version_cl} size="small"/>
        </div>
      </section>
      <section className="about__params">
        <p className="section-title">Параметры</p>
        {renderParameters()}
      </section>
      <section className="about__modules">
        <p className="section-title">Модули</p>
        {renderModules()}
      </section>
    </div>);
};
export default About;
