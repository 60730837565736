import React from 'react';
import { secToTime } from '@/utils';
var TimeReport = function (_a) {
    var data = _a.data;
    return (<>
      {data && (<div className="dashboard__time">
          <div className="dashboard__time__item">
            <p className="dashboard__time__item__header">
              Среднее время обслуживания
            </p>
            <div className="dashboard__time__item__value">
              <p className="dashboard__time__item__text">
                {secToTime(data.avSecProcTicket)}
              </p>
            </div>
          </div>
          <div className="dashboard__time__item">
            <p className="dashboard__time__item__header">
              Среднее время ожидания
            </p>
            <div className="dashboard__time__item__value">
              <p className="dashboard__time__item__text">
                {secToTime(data.avSecWaitTicket)}
              </p>
            </div>
          </div>
          <div className="dashboard__time__item">
            <p className="dashboard__time__item__header">
              Максимальное время ожидания
            </p>
            <div className="dashboard__time__item__value">
              <p className="dashboard__time__item__text">
                {secToTime(data.maxSecWaitTicketNow)}
              </p>
            </div>
          </div>
        </div>)}
    </>);
};
export default TimeReport;
