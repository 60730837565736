import React, { useState } from 'react';
import { Drawer } from 'antd';
import { useQuery } from 'react-query';
import AddBtn from '@/components/button/addBtn';
import AntTableWrapper from '@/components/table/antTableWrapper';
import NewSubstatus from '@/pages/components/substatuses/newSubstatus';
import SubstatusesEdit from '@/pages/components/substatuses/substatusesEdit';
import { getColumns } from '@/pages/components/substatuses/tableColumns';
import { statuses } from '@/utils/rest/pages/statuses';
import './index.css';
var Substatuses = function () {
    var _a = useState(false), showAdd = _a[0], setShowAdd = _a[1];
    var _b = useState([]), tableData = _b[0], setTableData = _b[1];
    var _c = useState(undefined), currentRow = _c[0], setCurrentRow = _c[1];
    var _d = useQuery('statuses', statuses.get, {
        onSuccess: function (data) {
            data === null || data === void 0 ? void 0 : data.forEach(function (el, idx) {
                el['key'] = idx;
            });
            setTableData(data);
        },
    }), isLoading = _d.isLoading, refetch = _d.refetch;
    var onDrawerClose = function () {
        setCurrentRow(undefined);
        setShowAdd(false);
    };
    var columns = getColumns(setShowAdd, setCurrentRow);
    return (<>
      <AddBtn handleClick={function () {
            setShowAdd(function (prevState) { return !prevState; });
        }} text={'Добавить'}/>
      <Drawer width={620} placement="right" onClose={onDrawerClose} open={showAdd} destroyOnClose={true}>
        {currentRow ? (<SubstatusesEdit handleCancel={onDrawerClose} data={currentRow} refetch={refetch}/>) : (<NewSubstatus statusData={tableData} setShowAdd={setShowAdd} refetch={refetch}/>)}
      </Drawer>
      <p className="section-title">Таблица статусов</p>
      <AntTableWrapper columns={columns} data={tableData} rowKey={'id'} loading={isLoading}/>
    </>);
};
export default Substatuses;
