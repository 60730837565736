var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { Table } from 'antd';
import locale from 'antd/es/locale/ru_RU';
import ReactDragListView from 'react-drag-listview';
var AntTableWrapper = function (_a) {
    var columns = _a.columns, data = _a.data, rowKey = _a.rowKey, loading = _a.loading, _b = _a.scroll, scroll = _b === void 0 ? {} : _b, expandable = _a.expandable, _c = _a.bordered, bordered = _c === void 0 ? true : _c, _d = _a.size, size = _d === void 0 ? 'large' : _d, components = _a.components;
    var _e = useState(columns), tableColumns = _e[0], setTableColumns = _e[1];
    var dragProps = {
        onDragEnd: function (fromIndex, toIndex) {
            if (tableColumns && !tableColumns[toIndex].fixed) {
                var newColumns = __spreadArray([], tableColumns, true);
                var item = newColumns.splice(fromIndex, 1)[0];
                newColumns.splice(toIndex, 0, item);
                setTableColumns(newColumns);
            }
        },
        nodeSelector: 'th',
        handleSelector: '.draggable',
    };
    return (<ReactDragListView.DragColumn {...dragProps}>
      <Table className="ant__table" locale={__assign(__assign({}, locale.Table), { triggerDesc: 'Отсортировано по возрастанию', triggerAsc: 'Без сортировки', cancelSort: 'Отсортировано по убыванию' })} columns={tableColumns} dataSource={data} rowKey={rowKey} bordered={bordered} scroll={__assign(__assign({}, scroll), { y: 590 })} loading={loading} pagination={{
            locale: locale.Pagination,
        }} expandable={expandable} size={size} components={components}/>
    </ReactDragListView.DragColumn>);
};
export default AntTableWrapper;
