var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Drawer } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { ReactComponent as Edit } from '@/assets/images/table-edit.svg';
import { ReactComponent as Trash } from '@/assets/images/table-trash.svg';
import AddBtn from '@/components/button/addBtn';
import FormDelete from '@/components/formDelete';
import Loader from '@/components/loader';
import Modal from '@/components/modal';
import AntTableWrapper from '@/components/table/antTableWrapper';
import { getColumnSearchProps } from '@/pages/services/tabs/servicePolls/helpers/utils';
import { services } from '@/utils/rest';
import texts from '../texts.json';
import ListEdit from './listEdit';
import './list.css';
var ServiceList = function () {
    var _a = useQuery('services', services.get), isLoading = _a.isLoading, data = _a.data, refetch = _a.refetch;
    var _b = useState(undefined), currentRow = _b[0], setCurrentRow = _b[1];
    var _c = useState(), action = _c[0], setAction = _c[1];
    var _d = useState(false), showAdd = _d[0], setShowAdd = _d[1];
    var deleteMutation = useMutation(services.delete, {
        onSuccess: function () {
            setCurrentRow(undefined);
            setAction(undefined);
            refetch();
        },
    });
    var tableData = [];
    var columns = [
        __assign(__assign({ dataIndex: 'id', title: 'ID' }, getColumnSearchProps('id', 'по ID')), { sorter: function (a, b) { return Number(a.id) - Number(b.id); } }),
        __assign({ dataIndex: 'prefix', title: 'Префикс' }, getColumnSearchProps('prefix', 'по префиксу')),
        __assign(__assign({ dataIndex: 'position', title: 'Позиция' }, getColumnSearchProps('position', 'по позиции')), { sorter: function (a, b) { return Number(a.position) - Number(b.position); } }),
        __assign({ dataIndex: 'name', title: 'Короткое название' }, getColumnSearchProps('name', 'по названию')),
        __assign({ dataIndex: 'fullname', title: 'Полное название' }, getColumnSearchProps('fullname', 'по названию')),
        {
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            width: 55,
            render: function (_, res) { return (<button onClick={function () {
                    setShowAdd(true);
                    setCurrentRow(res);
                }}>
          <Edit />
        </button>); },
        },
        {
            dataIndex: 'delete',
            align: 'center',
            width: 55,
            render: function (_, res) { return (<button onClick={function () {
                    setCurrentRow(res);
                    setAction('delete');
                }}>
          <Trash />
        </button>); },
        },
    ];
    var handleAdd = function () {
        setShowAdd(function (oldVal) { return !oldVal; });
    };
    var handleDelete = function () {
        if (currentRow) {
            deleteMutation.mutate(Number(currentRow.id));
        }
    };
    var drawerClose = function () {
        setShowAdd(false);
        setCurrentRow(undefined);
    };
    if (!isLoading) {
        data.forEach(function (el, idx) {
            el['key'] = idx;
            tableData.push(el);
        });
    }
    if (isLoading) {
        return <Loader />;
    }
    return (<div className="service-list">
      <Modal clean open={action === 'delete'}>
        <FormDelete handleCancel={function () {
            setCurrentRow(undefined);
            setAction(undefined);
        }} handleSubmit={handleDelete}/>
      </Modal>
      <AddBtn handleClick={handleAdd}/>

      <Drawer width={620} placement="right" onClose={drawerClose} open={showAdd} destroyOnClose={true}>
        <ListEdit handleCancel={drawerClose} refetch={refetch} data={currentRow}/>
      </Drawer>
      <p className="section-title">{texts.listTitle}</p>
      <AntTableWrapper columns={columns} data={tableData} rowKey={'key'} loading={isLoading} bordered={false}/>
    </div>);
};
export default ServiceList;
