import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import SqlQuery from './sqlQuery';
import TableConfig from './tableConfig';
var SessionResultTable = function (_a) {
    var sessionDataId = _a.sessionDataId;
    var _b = useState(), ReportParams = _b[0], setReportParams = _b[1];
    useEffect(function () {
        SqlQuery(sessionDataId).then(function (resp) {
            var data = resp.data, query = resp.query, avgMark = resp.avgMark;
            var params = {
                items: data,
                query: query,
                TableConfig: TableConfig(),
                avgMark: avgMark,
            };
            params.items.forEach(function (item) {
                // eslint-disable-next-line prefer-destructuring
                if (item[4] == null && item[5] != null)
                    item[4] = item[5];
            });
            setReportParams(params);
        });
    }, []);
    return (<>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
        <div>Средняя оценка</div>
        <div style={{ fontWeight: '700' }}>{ReportParams === null || ReportParams === void 0 ? void 0 : ReportParams.avgMark}</div>
      </div>
      <Table columns={ReportParams === null || ReportParams === void 0 ? void 0 : ReportParams.TableConfig.columns} dataSource={ReportParams === null || ReportParams === void 0 ? void 0 : ReportParams.items} bordered/>
    </>);
};
export default SessionResultTable;
