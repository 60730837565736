import React, { memo, useState } from 'react';
import './input.css';
import { ReactComponent as PasswordEyeClosed } from '@/assets/images/password-close.svg';
import { ReactComponent as PasswordEye } from '@/assets/images/password.svg';
var Input = function (_a) {
    var text = _a.text, name = _a.name, _b = _a.value, value = _b === void 0 ? '' : _b, error = _a.error, placeholder = _a.placeholder, onChange = _a.onChange, _c = _a.type, type = _c === void 0 ? 'text' : _c, upperCase = _a.upperCase, width = _a.width, id = _a.id, disabled = _a.disabled, autoFocus = _a.autoFocus, onBlur = _a.onBlur;
    var _d = useState(false), visible = _d[0], setVisible = _d[1];
    return (<label htmlFor={name} className="field-text" style={{ width: width }}>
      {text && <span className="field-text__title">{text}</span>}
      <input autoFocus={autoFocus} onBlur={onBlur} disabled={disabled} className="field-text__input" id={name} type={type === 'password' && !visible
            ? 'password'
            : type === 'password' && visible
                ? 'text'
                : type} name={name} placeholder={placeholder} data-id={id} value={value} min={0} onChange={function (e) {
            upperCase ? (e.target.value = e.target.value.toUpperCase()) : null;
            onChange ? onChange(e) : null;
        }} style={{
            border: error ? '1px solid red' : 0,
            whiteSpace: 'normal',
        }}/>
      {typeof error === 'string' && error && (<span className="field-text__error">{error}</span>)}
      {type === 'password' && visible && (<span className="field-icon" onClick={function () { return setVisible(false); }}>
          <PasswordEyeClosed />
        </span>)}
      {type === 'password' && !visible && (<span className="field-icon" onClick={function () { return setVisible(true); }}>
          <PasswordEye />
        </span>)}
    </label>);
};
export default memo(Input);
