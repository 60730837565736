import React from 'react';
import { ReactComponent as Star } from '@/assets/images/star.svg';
import './stars.css';
var Stars = function () {
    return (<div className="stars">
      <div className="star star--gray">
        <Star />
        <p>Отсутствует</p>
      </div>
      <div className="star star--orange">
        <Star />
        <p>Низкий</p>
      </div>
      <div className="star star--lemon">
        <Star />
        <p>Средний</p>
      </div>
      <div className="star star--lime">
        <Star />
        <p>Высокий</p>
      </div>
      <div className="star star--green">
        <Star />
        <p>Наивысший</p>
      </div>
    </div>);
};
export default Stars;
