import React, { useEffect, useState } from 'react';
import Input from '@/components/input/input';
import Select from '@/components/input/select';
import { defaultSelectOptions } from '@/pages/statistics/reports/filters/filterParametrs';
import { FilterStore } from '@/store';
var ServedFilters = function (_a) {
    var handleSetFilters = _a.handleSetFilters;
    var filterParams = FilterStore.useState(function (s) { return s.initialParams; });
    var allOptionsState = defaultSelectOptions.find(function (el) { return el.value === filterParams.all_cnt.split(' ')[0]; });
    var closeOptionState = defaultSelectOptions.find(function (el) { return el.value === filterParams.close_cnt.split(' ')[0]; });
    var noneOptionState = defaultSelectOptions.find(function (el) { return el.value === filterParams.neyavka_cnt.split(' ')[0]; });
    var _b = useState({
        value: allOptionsState ? allOptionsState.value : '',
        name: allOptionsState ? allOptionsState.name : '',
    }), allOption = _b[0], setAllOption = _b[1];
    var _c = useState({
        value: closeOptionState ? closeOptionState.value : '',
        name: closeOptionState ? closeOptionState.name : '',
    }), closeOption = _c[0], setCloseOption = _c[1];
    var _d = useState({
        value: noneOptionState ? noneOptionState.value : '',
        name: noneOptionState ? noneOptionState.name : '',
    }), noneOption = _d[0], setNoneOption = _d[1];
    var _e = useState(filterParams.all_cnt.split(' ')[1]), allValue = _e[0], setAllValue = _e[1];
    var _f = useState(filterParams.close_cnt.split(' ')[1]), closeValue = _f[0], setCloseValue = _f[1];
    var _g = useState(filterParams.neyavka_cnt.split(' ')[1]), noneValue = _g[0], setNoneValue = _g[1];
    var _h = useState(), secondaryAllValue = _h[0], setSecondaryAllValue = _h[1];
    var _j = useState(), secondaryCloseValue = _j[0], setSecondaryCloseValue = _j[1];
    var _k = useState(), secondaryNoneValue = _k[0], setSecondaryNoneValue = _k[1];
    var handleSelect = function (_a) {
        var option = _a.option, name = _a.name;
        switch (name) {
            case 'all_cnt':
                if (option.value === '') {
                    setAllOption({
                        value: '',
                        name: '',
                    });
                    setAllValue(undefined);
                    handleSetFilters(name, 'all');
                    break;
                }
                setAllOption(option);
                break;
            case 'close_cnt':
                if (option.value === '') {
                    setCloseOption({
                        value: '',
                        name: '',
                    });
                    setCloseValue(undefined);
                    handleSetFilters(name, 'all');
                    break;
                }
                setCloseOption(option);
                break;
            case 'neyavka_cnt':
                if (option.value === '') {
                    setNoneOption({
                        value: '',
                        name: '',
                    });
                    setNoneValue(undefined);
                    handleSetFilters(name, 'all');
                    break;
                }
                setNoneOption(option);
                break;
        }
    };
    var handleInput = function (e) {
        var _a = e.target, value = _a.value, name = _a.name;
        switch (name) {
            case 'all_cnt':
                setAllValue(value);
                handleSetFilters(name, "".concat(allOption.value, " ").concat(value));
                break;
            case 'close_cnt':
                setCloseValue(value);
                handleSetFilters(name, "".concat(closeOption.value, " ").concat(value));
                break;
            case 'neyavka_cnt':
                setNoneValue(value);
                handleSetFilters(name, "".concat(noneOption.value, " ").concat(value));
                break;
            case 'all_cnt_secondary':
                setSecondaryAllValue(value);
                handleSetFilters('all_cnt', "> ".concat(allValue, "  \n and all_cnt < ").concat(value));
                break;
            case 'close_cnt_secondary':
                setSecondaryCloseValue(value);
                handleSetFilters('close_cnt', "> ".concat(closeValue, "  \n and all_cnt < ").concat(value));
                break;
            case 'neyavka_cnt_secondary':
                setSecondaryNoneValue(value);
                handleSetFilters('neyavka_cnt', "> ".concat(noneValue, "  \n and all_cnt < ").concat(value));
                break;
        }
    };
    useEffect(function () {
        if (allValue) {
            handleSetFilters('all_cnt', "".concat(allOption.value, " ").concat(allValue));
        }
        if (closeValue) {
            handleSetFilters('close_cnt', "".concat(closeOption.value, " ").concat(closeValue));
        }
        if (noneValue) {
            handleSetFilters('neyavka_cnt', "".concat(noneOption.value, " ").concat(noneValue));
        }
    }, [allOption, closeOption, noneOption]);
    return (<div className="report_filters__box">
      <p className="report_filters__title">Обслужено</p>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Всего</p>
        <Select width={200} placeholder="Выбрать" name="all_cnt" value={allOption.name} onChange={handleSelect} options={defaultSelectOptions}/>
        <Input type="number" width={80} name="all_cnt" value={allValue} onChange={handleInput} disabled={allOption.name === ''}/>
        {allOption.value === 'range' && (<Input type="number" width={80} name="all_cnt_secondary" value={secondaryAllValue} onChange={handleInput} disabled={allOption.name === ''}/>)}
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Закрыто</p>
        <Select width={200} placeholder="Выбрать" name="close_cnt" value={closeOption.name} onChange={handleSelect} options={defaultSelectOptions}/>
        <Input type="number" width={80} name="close_cnt" value={closeValue} onChange={handleInput} disabled={closeOption.name === ''}/>
        {closeOption.value === 'range' && (<Input type="number" width={80} name="close_cnt_secondary" value={secondaryCloseValue} onChange={handleInput} disabled={closeOption.name === ''}/>)}
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Неявка</p>
        <Select width={200} placeholder="Выбрать" name="neyavka_cnt" value={noneOption.name} onChange={handleSelect} options={defaultSelectOptions}/>
        <Input type="number" width={80} name="neyavka_cnt" value={noneValue} onChange={handleInput} disabled={noneOption.name === ''}/>
        {noneOption.value === 'range' && (<Input type="number" width={80} name="neyavka_cnt_secondary" value={secondaryNoneValue} onChange={handleInput} disabled={noneOption.name === ''}/>)}
      </div>
    </div>);
};
export default ServedFilters;
