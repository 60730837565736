export var getCardSqlString = function (startTime, endTime) {
    return "select count(id),\n(sum(count_close) + sum(count_neyavka)) as cnt,\nsum(count_close) as close_cnt,\nsum(count_neyavka) as none_cnt,\nsum(count_del) as del_cnt,\n(sum(totalproc) / NULLIF((sum(count_close) + sum(count_neyavka)), 0) * interval '1 sec') as avg_proc_time,\n(sum(totalwait) / NULLIF((sum(count_close) + sum(count_neyavka)), 0) * interval '1 sec') as avg_wait_time\nfrom\n(select distinct(ticket_id) as id,\ncase when status = 3 then 1 else 0 end as count_close,\ncase when status = 4 then 1 else 0 end as count_neyavka,\ncase when status = 9 then 1 else 0 end as count_del,\ncase when status = 9 then 0 else totalproc end as totalproc,\ncase when status = 9 then 0 else totalwait end as totalwait\nfrom eq.ttickethistory where modified_on::timestamp >= '".concat(startTime, "' \nand modified_on::timestamp <= '").concat(endTime, "' and status in (3,4,9)) t");
};
export var getGraphSqlString = function (startTime, endTime, status) {
    return "select count(ss.ticket_id) as cnt,\n  ss.data_hh from (select tt.* from (select\n  to_char(ht.modified_on, 'HH24') as data_hh,\n  to_char(ht.modified_on, 'DD.MM.YYYY') as data_dd_mm_yyyy,\n  ht.modified_on,\n  ht.status,\n  ht.ticket_id\n  from eq.ttickethistory ht) tt \n  where tt.modified_on::timestamp >= '".concat(startTime, "'\n  and tt.modified_on::timestamp <= '").concat(endTime, "'\n  and tt.status = '").concat(status, "') ss\n  group by data_hh order by data_hh");
};
export var getAvgWaitSqlString = function (startTime, endTime) {
    return "select qt.fullname, qt.deleted, coalesce(ft.avg_wait_time, '0') as avg_wait_time, ft.avg_none_format_time from (\n    (select id, fullname, deleted from eq.tqueue) qt left join (select q_id,\n    (sum(totalwait) / count(distinct ticket_id)) * interval '1 sec' as avg_wait_time,\n    (sum(totalwait) / count(distinct ticket_id)) as avg_none_format_time\n    from eq.ttickethistory where modified_on::timestamp >= '".concat(startTime, "' and modified_on::timestamp <= '").concat(endTime, "' and status in(3, 4)\n    group by q_id) ft on qt.id = ft.q_id)  where qt.deleted = false");
};
export var getAvgProcSqlTime = function (startTime, endTime) {
    return "select qt.fullname, qt.deleted, ft.avg_proc_time, ft.avg_none_format_time from (\n    (select id, fullname, deleted from eq.tqueue) qt left join (select q_id,\n    (sum(totalproc) / count(distinct ticket_id)) * interval '1 sec' as avg_proc_time,\n    (sum(totalproc) / count(distinct ticket_id)) as avg_none_format_time\n    from eq.ttickethistory where modified_on::timestamp >= '".concat(startTime, "' and modified_on::timestamp <= '").concat(endTime, "' and status in(3, 4)\n    group by q_id) ft on qt.id = ft.q_id)  where qt.deleted = false");
};
export var getDaysReportSql = function (startTime, endTime) {
    return "select \n    days,\n    count(distinct(q.unic_data)) as daycount,\n    case when sum(count_close) = 0 then 0 else (sum(count_close) / count(distinct(q.unic_data))) end as count_close,\n    case when sum(count_neyavka) = 0 then 0 else (sum(count_neyavka) / count(distinct(q.unic_data))) end as count_neyavka,\n    case when sum(count_del) = 0 then 0 else (sum(count_del) / count(distinct(q.unic_data))) end as count_del,\n    case when sum(count_crate) = 0 then 0 else (sum(count_crate) / count(distinct(q.unic_data))) end as count_crate\n    from\n    (select\n      to_char(modified_on, 'dy') as days,\n      to_char(modified_on, 'DD.MM.YYYY') as unic_data,\n      case when status = 3 then 1 else 0 end as count_close,\n      case when status = 4 then 1 else 0 end as count_neyavka,\n      case when status = 9 then 1 else 0 end as count_del,\n      case when status = 0 then 1 else 0 end as count_crate\n      from\n      eq.ttickethistory\n      where\n      modified_on::timestamp >= '".concat(startTime, "'\n      and modified_on::timestamp <= '").concat(endTime, "'\n      and status in (0,3,4,9))q group by q.days");
};
export var getOperatorReportSql = function (startTime, endTime) {
    return "select\n  ot.*, tc.part_cnt, days.work_day,\n  (tc.part_cnt / days.work_day) as tpd,\n  (tc.work_time * interval '1 sec') as work_time, mt.avg_mark, mt.mark_cnt, tc.work_time\n  from (\n    select\n    ut.id as user_id,\n    ut.username,\n    ut.fio as user_fio\n    from\n    eq.tuser ut,\n    eq.tuaroleuser urt\n    where\n    ut.id = urt.user_id\n    and urt.role_id = 4 and ut.deleted = false) ot\n  left join (\n  select act.modified_by_id,\n  count(act.ticket_id) as part_cnt,\n  sum(act.action_time) as work_time\n  from\n  (\n  select\n  ticket_id,\n  count(status) as action_cnt,\n  modified_by_id,\n  sum(procforsecs) as action_time\n  from\n  eq.ttickethistory\n  where\n  status != 0\n  and ticket_id in (\n  select\n  ticket_id\n  from\n  eq.ttickethistory\n  where\n  status in (3,\n  4, 9))\n  and modified_on::timestamp >= '".concat(startTime, "'\n  and modified_on::timestamp <= '").concat(endTime, "'\n  group by\n  ticket_id,\n  modified_by_id ) act\n  group by\n  act.modified_by_id) tc on\n  tc.modified_by_id = ot.user_id\n  left join (\n  select\n  ss.modified_by_id,\n  count (distinct(ss.days)) as work_day\n  from\n  (\n  select\n  modified_by_id,\n  to_char(modified_on, 'DD.MM.YYYY') as days\n  from\n  eq.ttickethistory\n  where\n  modified_on::timestamp >= '").concat(startTime, "'\n  and modified_on::timestamp <= '").concat(endTime, "') ss\n  group by\n  modified_by_id) days on\n  days.modified_by_id = ot.user_id\n  left join (\n  select user_id,\n  count(mark) as mark_cnt,\n  sum(mark) as mark_sum,\n  (sum(mark)*1.0/ count(mark)*1.0) as avg_mark\n  from\n  eq.tticketmarks\n  where\n  modified_on::timestamp >= '").concat(startTime, "'\n  and modified_on::timestamp <= '").concat(endTime, "'\n  and ticket_id in (\n  select\n  ticket_id\n  from\n  eq.ttickethistory\n  where\n  status in (3,\n  4, 9))\n  group by\n  user_id) mt on\n  ot.user_id = mt.user_id");
};
