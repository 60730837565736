import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import './modal.css';
var Modal = function (_a) {
    var open = _a.open, onClose = _a.onClose, children = _a.children, clean = _a.clean;
    useEffect(function () {
        if (open) {
            document.body.style.overflow = 'hidden';
            document.body.oncontextmenu = function () { return false; };
        }
        return function () {
            document.body.style.overflow = 'unset';
            document.body.oncontextmenu = function () { return true; };
        };
    }, [open]);
    var renderModal = function () {
        return clean ? (<>{children}</>) : (<div onContextMenu={function () { return false; }} data-testid="modal-test" className="modal-box" onClick={onClose}>
        <div className="modal-content" onClick={function (e) {
                e.stopPropagation();
                e.preventDefault();
            }}>
          <div className="modal-control">
            <span onClick={onClose} className="modal-close">
              &times;
            </span>
          </div>
          {children}
        </div>
      </div>);
    };
    return <>{open ? createPortal(renderModal(), document.body) : null}</>;
};
export default Modal;
