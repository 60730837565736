import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient, } from 'react-query';
import { useHistory } from 'react-router-dom';
import paths from '@/constants/paths';
import { getToken } from '@/utils';
import { login } from '@/utils/rest';
export var useLogin = function (setToken) {
    var token = new URLSearchParams(window.location.search).get('token') || '';
    return useQuery('userInfo', login.getUserInfo, {
        enabled: !!getToken() || !!token,
        onSuccess: function (data) {
            if (data.status === 'ok') {
                setToken(token);
            }
        },
    });
};
export var loginAccess = false;
export var useLoginPost = function (setToken) {
    var history = useHistory();
    var query = useQueryClient();
    var i18n = useTranslation().i18n;
    return useMutation(login.userLogin, {
        onSuccess: function (data) {
            if (data.session) {
                if (data.userRoles.includes('eq_manager') ||
                    data.userRoles.includes('eq_root') ||
                    data.userRoles.includes('eq_reporter')) {
                    if (data.userRoles.includes('eq_manager') ||
                        data.userRoles.includes('eq_root'))
                        loginAccess = true;
                    setToken(data.session);
                    var newData = {
                        data: {
                            fio: data.userFio,
                            roles: data.userRoles,
                            username: data.userLogin,
                        },
                    };
                    query.setQueryData('userInfo', newData);
                    history.push("/".concat(i18n.language).concat(paths.dashboard));
                }
                else {
                    notification.error({
                        message: 'Отказано в доступе',
                        description: "\u0423 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F ".concat(data.userLogin, " \u043D\u0435\u0442 \u043F\u0440\u0430\u0432 \u0434\u043E\u0441\u0442\u0443\u043F\u0430"),
                    });
                }
            }
            if (data.error && data.error.code === '213') {
                notification.error({
                    message: 'Отказано в доступе',
                    description: 'Пользователь с таким логином не найден',
                });
            }
            if (data.error && data.error.code === '212') {
                notification.error({
                    message: 'Отказано в доступе',
                    description: 'Введён неверный пароль',
                });
            }
        },
    });
};
