var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { ReactComponent as AddBtnImg } from '@/assets/images/add-btn.svg';
import { ReactComponent as Trash } from '@/assets/images/table-trash.svg';
import Button from '@/components/button';
import Checkbox from '@/components/input/checkbox';
import Input from '@/components/input/input';
import Select from '@/components/input/select';
import './board.css';
import { BoardTypes } from '@/types/common';
import { boards } from '@/utils/rest/pages/boards';
var BoardEdit = function (_a) {
    var _b;
    var data = _a.data, handleCancel = _a.handleCancel, refetch = _a.refetch, _c = _a.background, background = _c === void 0 ? 'transparent' : _c;
    // Начальное состояние
    var options = Object.keys(BoardTypes).map(function (item) {
        return { value: item, name: item };
    });
    var initData = {
        address: data.address,
        config: data.config,
        description: data.description,
        enabled: data.enabled,
        name: data.name,
        number: data.number,
        oid: data.oid,
        type: data.type,
    };
    var userId = data.id;
    var _d = useState(initData), displayData = _d[0], setDisplayData = _d[1];
    var _e = useState({
        digParName: '',
        digParValue: '',
    }), displayParam = _e[0], setDisplayParam = _e[1];
    var name = displayData.name, address = displayData.address, config = displayData.config, enabled = displayData.enabled, number = displayData.number, type = displayData.type;
    var digParName = displayParam.digParName, digParValue = displayParam.digParValue;
    // Отправка данных на сервер
    var addBoard = useMutation(boards.add, {
        onSuccess: function () { return refetch(); },
    });
    var updateBoard = useMutation(boards.update, {
        onSuccess: function () { return refetch(); },
    });
    // Действия пользователя
    var handleInput = function (e) {
        var _a;
        var _b = e.target, value = _b.value, name = _b.name;
        var newData = __assign(__assign({}, displayData), (_a = {}, _a[name] = value, _a));
        setDisplayData(newData);
    };
    var handleInputParam = function (e) {
        var _a;
        var _b = e.target, value = _b.value, name = _b.name;
        var newData = __assign(__assign({}, displayParam), (_a = {}, _a[name] = value, _a));
        setDisplayParam(newData);
    };
    var handleSelect = function (e) {
        var _a;
        var newData = __assign(__assign({}, displayData), (_a = {}, _a[e.name] = e.option.name, _a));
        setDisplayData(newData);
    };
    var handleCheck = function (e) {
        var newData = __assign(__assign({}, displayData), { enabled: e.target.checked });
        setDisplayData(newData);
    };
    var handleDeleteParam = function (e) {
        var params = __spreadArray([], displayData.config.digitParams, true);
        var newParams = params.filter(function (item) { return item.digParName !== e; });
        var newData = __assign(__assign({}, displayData), { config: { digitParams: newParams } });
        setDisplayData(newData);
    };
    var handleSaveBoard = function () {
        if (userId) {
            updateBoard.mutate({ payload: displayData, id: userId });
        }
        else {
            addBoard.mutate(__assign({}, displayData));
        }
        handleCancel();
    };
    var handleSaveParam = function () {
        displayData.config.digitParams.push(displayParam);
        setDisplayData(displayData);
        refetch();
    };
    // Рендер компонента
    return (<>
      <div className="editable-item__form">
        <div className={background === 'transparent'
            ? 'editable-item__form-row'
            : 'editable-item__form-row editable-item__form-row_transparent'}>
          <Input text="Наменование" placeholder="Введите наименование" value={name} name={'name'} width={280} onChange={handleInput}/>
          <Input text="IP адрес" placeholder="192.168.3.203:80" name={'address'} value={address} width={280} onChange={handleInput}/>
          <Input text="Номер" placeholder="Введите номер" name={'number'} width={280} value={number} onChange={handleInput} type="number"/>
          <Select text="Тип табло" placeholder="Выберете из списка" name={'type'} value={type} width={280} options={options} onChange={handleSelect}/>
        </div>
        <Checkbox text="Включить табло" name={'enabled'} value={enabled} onChange={handleCheck}/>
        <p className="section-title">Параметры табло</p>
        <div className={background === 'transparent'
            ? 'editable-item__form-row'
            : 'editable-item__form-row editable-item__form-row_transparent'}>
          <div className="params__inputs">
            <Input text="Наменование" placeholder="Введите вопрос" value={digParName} name={'digParName'} onChange={handleInputParam} width={380}/>
            <Input text="Значение" placeholder="Введите вопрос" value={digParValue} name={'digParValue'} onChange={handleInputParam} width={380}/>
            <button className="add-param-btn" onClick={handleSaveParam}>
              <AddBtnImg />
              Добавить
            </button>
          </div>
        </div>
        <div className="editable-item__grid-box editable-item__card-box">
          {(_b = config.digitParams) === null || _b === void 0 ? void 0 : _b.map(function (item) { return (<div className={background === 'transparent'
                ? 'editable-item__card'
                : 'editable-item__card editable-item__card-transparent'} key={item.digParName}>
              <div>
                <h3 className="editable-item__card-title">{item.digParName}</h3>
                <p className="editable-item__card-text">{item.digParValue}</p>
              </div>
              <button onClick={function () {
                handleDeleteParam(item.digParName);
            }}>
                <span>
                  <Trash />
                </span>
              </button>
            </div>); })}
        </div>
      </div>
      <div className="editable-item__form-btns">
        <Button text="Сохранить" onClick={handleSaveBoard}/>
        <Button text="Отменить" onClick={handleCancel}/>
      </div>
    </>);
};
export default BoardEdit;
