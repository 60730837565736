import React, { useState } from 'react';
import { Drawer } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { ReactComponent as Edit } from '@/assets/images/table-edit.svg';
import { ReactComponent as Trash } from '@/assets/images/table-trash.svg';
import AddBtn from '@/components/button/addBtn';
import FormDelete from '@/components/formDelete';
import Modal from '@/components/modal';
import AntTableWrapper from '@/components/table/antTableWrapper';
import texts from '@/pages/services/tabs/texts.json';
import { formatDate } from '@/utils';
import { days, workWeeks } from '@/utils/rest';
import ConnectedDays from './connectedDays';
import WeekEdit from './weekEdit';
import '../schedule.css';
var Weeks = function () {
    var _a = useQuery('days', days.get), daysData = _a.data, isLoadingDays = _a.isLoading;
    var _b = useQuery('workWeeks', workWeeks.get), weeks = _b.data, refetch = _b.refetch, isLoading = _b.isLoading;
    var _c = useState(undefined), currentRow = _c[0], setCurrentRow = _c[1];
    var _d = useState(), action = _d[0], setAction = _d[1];
    var _e = useState(false), showAdd = _e[0], setShowAdd = _e[1];
    var handleAdd = function () {
        setShowAdd(function (oldValue) { return !oldValue; });
    };
    var deleteWeek = useMutation('deleteWeek', workWeeks.delete, {
        onSuccess: function () { return refetch(); },
    });
    if (!isLoading) {
        weeks === null || weeks === void 0 ? void 0 : weeks.forEach(function (el, idx) {
            el['key'] = idx;
        });
    }
    var columns = [
        { dataIndex: 'name', title: 'Наименование' },
        { dataIndex: 'description', title: 'Описание' },
        {
            dataIndex: 'modified_by_username',
            title: 'Изменен',
            align: 'center',
        },
        {
            dataIndex: 'modified_on',
            title: 'Дата изменения',
            align: 'center',
            render: function (_, res) {
                return (<span>
            {formatDate(String(res.modified_on), 'DD.MM.YYYY HH:mm:ss')}
          </span>);
            },
        },
        {
            title: 'Подключенные дни',
            align: 'center',
            render: function (_, res) {
                return daysData ? <ConnectedDays data={res} days={daysData}/> : null;
            },
        },
        {
            dataIndex: 'edit',
            width: 55,
            align: 'center',
            render: function (_, res) { return (<button onClick={function () {
                    setShowAdd(true);
                    setCurrentRow(res);
                }}>
          <Edit />
        </button>); },
        },
        {
            dataIndex: 'delete',
            width: 55,
            align: 'center',
            render: function (_, res) { return (<button onClick={function () {
                    setCurrentRow(res);
                    setAction('delete');
                }}>
          <Trash />
        </button>); },
        },
    ];
    var handleCancel = function () {
        setCurrentRow(undefined);
        setShowAdd(false);
    };
    var handleDelete = function () {
        if (currentRow) {
            deleteWeek.mutate(Number(currentRow.id));
            setCurrentRow(undefined);
            setAction(undefined);
        }
    };
    return (<div className="weeks">
      <Modal clean open={action === 'delete'}>
        <FormDelete handleCancel={function () { return setAction(undefined); }} handleSubmit={handleDelete}/>
      </Modal>
      <AddBtn handleClick={handleAdd}/>
      <Drawer width={620} placement="right" onClose={handleCancel} open={showAdd} destroyOnClose={true}>
        <WeekEdit handleCancel={handleCancel} isOpen={showAdd} refetch={refetch} data={currentRow}/>
      </Drawer>
      <p className="section-title">{texts.weeksTitle}</p>
      <AntTableWrapper columns={columns} data={weeks} rowKey={'key'} loading={isLoading || isLoadingDays} bordered={false}/>
    </div>);
};
export default Weeks;
