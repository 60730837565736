var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import ReportFilters from '@/pages/statistics/reports/filters/reportFilters';
import AvgTimeReport from '@/pages/statistics/reports/tabs/consolidated/components/avgTimeReport';
import CardReport from '@/pages/statistics/reports/tabs/consolidated/components/cardReport';
import DayReport from '@/pages/statistics/reports/tabs/consolidated/components/dayReport';
import LineReport from '@/pages/statistics/reports/tabs/consolidated/components/lineReport';
import './consolidated.css';
import OperatorTable from '@/pages/statistics/reports/tabs/consolidated/components/operatorTable';
import { FilterStore } from '@/store';
var Consolidated = function () {
    var filterParams = FilterStore.useState(function (s) { return s.initialParams; });
    var _a = useState(__assign({}, filterParams)), newFilterParams = _a[0], setNewFilterParams = _a[1];
    var setFilterParams = function (reportFilterParams) {
        FilterStore.update(function (s) {
            s.initialParams = reportFilterParams;
        });
    };
    var handleApplyFilters = function () {
        setNewFilterParams(filterParams);
    };
    return (<>
      <ReportFilters showFilters={{
            main: ['reportRange'],
            others: [],
            orders: [],
        }} filterParams={filterParams} setFilterParams={setFilterParams} applyFilters={handleApplyFilters}/>
      <div>
        <p className="section-title">Отчёт</p>
        <div className="report__top">
          <CardReport filterParams={newFilterParams}/>
          <LineReport filterParams={newFilterParams}/>
        </div>
        <div className="report__top">
          <AvgTimeReport filterParams={newFilterParams}/>
        </div>
        <div className="report__top">
          <DayReport filterParams={newFilterParams}/>
        </div>
        <OperatorTable filterParams={newFilterParams}/>
      </div>
    </>);
};
export default Consolidated;
