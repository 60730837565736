var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Drawer } from 'antd';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import { ReactComponent as StatusFalse } from '@/assets/images/interval-status-false.svg';
import { ReactComponent as StatusTrue } from '@/assets/images/interval-status-true.svg';
import { ReactComponent as Edit } from '@/assets/images/table-edit.svg';
import { ReactComponent as Trash } from '@/assets/images/table-trash.svg';
import AddBtn from '@/components/button/addBtn';
import FormDelete from '@/components/formDelete';
import Loader from '@/components/loader';
import Modal from '@/components/modal';
import AntTableWrapper from '@/components/table/antTableWrapper';
import { getColumnSearchProps } from '@/pages/services/tabs/servicePolls/helpers/utils';
import texts from '@/pages/services/tabs/texts.json';
import { formatDate } from '@/utils';
import { intervals } from '@/utils/rest';
import IntervalEdit from './intervalEdit';
import '../schedule.css';
var Intervals = function () {
    var _a = useState(undefined), currentRow = _a[0], setCurrentRow = _a[1];
    var _b = useState(), action = _b[0], setAction = _b[1];
    var _c = useState(false), showAdd = _c[0], setShowAdd = _c[1];
    var _d = useQuery('intervals', intervals.get), data = _d.data, refetch = _d.refetch, isLoading = _d.isLoading;
    var intervalDelete = useMutation('deleteInterval', intervals.delete, {
        onSuccess: function () {
            refetch();
        },
    });
    if (!isLoading) {
        data.forEach(function (el, idx) {
            el['key'] = idx;
        });
    }
    var columns = [
        __assign({ dataIndex: 'name', title: 'Наименование' }, getColumnSearchProps('name', 'по наименованию')),
        __assign({ dataIndex: 'description', title: 'Описание' }, getColumnSearchProps('description', 'по описанию')),
        {
            dataIndex: 'status',
            title: 'Статус',
            align: 'center',
            render: function (_, res) { return (res.status === 1 ? <StatusFalse /> : <StatusTrue />); },
        },
        {
            dataIndex: 'modified_by_username',
            title: 'Изменен',
            align: 'center',
        },
        {
            dataIndex: 'modified_on',
            title: 'Дата изменения',
            align: 'center',
            render: function (_, res) {
                return (<span>
            {formatDate(String(res.modified_on), 'YYYY.MM.DD HH:mm:ss')}
          </span>);
            },
        },
        {
            dataIndex: 'timebegin',
            title: 'Начало',
            align: 'center',
            render: function (_, res) {
                return (<span>
            {moment(res.timebegin, 'YYYY.MM.DD HH:mm:ss').format('HH:mm')}
          </span>);
            },
        },
        {
            dataIndex: 'timeend',
            title: 'Окончание',
            align: 'center',
            render: function (_, res) {
                return (<span>
            {moment(res.timeend, 'YYYY.MM.DD HH:mm:ss').format('HH:mm')}
          </span>);
            },
        },
        {
            dataIndex: 'edit',
            align: 'center',
            width: 55,
            render: function (_, res) { return (<button onClick={function () {
                    setShowAdd(true);
                    setCurrentRow(res);
                }}>
          <Edit />
        </button>); },
        },
        {
            dataIndex: 'delete',
            align: 'center',
            width: 55,
            render: function (_, res) { return (<button onClick={function () {
                    setCurrentRow(res);
                    setAction('delete');
                }}>
          <Trash />
        </button>); },
        },
    ];
    var handleAdd = function () {
        setShowAdd(function (oldValue) { return !oldValue; });
    };
    var closeDrawer = function () {
        setShowAdd(false);
        setCurrentRow(undefined);
    };
    var handleDelete = function () {
        if (currentRow) {
            intervalDelete.mutate(Number(currentRow.id));
            setCurrentRow(undefined);
            setAction(undefined);
        }
    };
    if (isLoading) {
        return <Loader />;
    }
    return (<div className="intervals">
      <Modal clean open={action === 'delete'}>
        <FormDelete handleCancel={function () { return setAction(undefined); }} handleSubmit={handleDelete}/>
      </Modal>
      <AddBtn handleClick={handleAdd}/>
      <Drawer width={620} placement="right" onClose={closeDrawer} open={showAdd} destroyOnClose={true}>
        <IntervalEdit handleAdd={handleAdd} handleCancel={closeDrawer} isOpen={showAdd} refetch={refetch} data={currentRow}/>
      </Drawer>
      <p className="section-title">{texts.intervalsTitle}</p>
      <AntTableWrapper columns={columns} data={data} rowKey={'key'} loading={isLoading} bordered={false}/>
    </div>);
};
export default Intervals;
