export var operatorHideWidgetsData = [
    {
        id: 1,
        key: 'fullname',
        name: 'Полное имя клиента в талоне',
    },
    {
        id: 2,
        key: 'phonenumber',
        name: 'Номер телефона клиента в талоне',
    },
    { id: 3, key: 'email', name: 'E-mail клиента в талоне' },
    { id: 4, key: 'comment', name: 'Комментарий к талону' },
    {
        id: 5,
        key: 'refundv',
        name: 'Кнопка "Вернуть в очередь" в окне валидации',
    },
    {
        id: 6,
        key: 'questioncount',
        name: 'Счетчик решенных вопросов',
    },
    {
        id: 7,
        key: 'filters',
        name: 'Кнопка "Подключенные фильтры"',
    },
];
