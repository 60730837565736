import React from 'react';
import { DatePicker } from 'antd';
import locale from 'antd/es/locale/ru_RU';
import 'dayjs/locale/ru.js';
import dayjs from 'dayjs';
import { dateFormat } from '@/constants/common';
import { FilterStore } from '@/store';
var FilterRange = function () {
    var filterParams = FilterStore.useState(function (s) { return s.initialParams; });
    var onChange = function (values) {
        var startDate = values && values[0];
        var endDate = values && values[1];
        if (startDate && endDate) {
            FilterStore.update(function (s) {
                s.initialParams.startDate = startDate.format(dateFormat);
                s.initialParams.endDate = endDate.format(dateFormat);
            });
        }
    };
    return (<div className="report_filters__dates-wrapper">
      <p className="field-text__title">Период</p>
      <div className="report_filters__dates">
        <div className="report_filters__calendar">
          <DatePicker.RangePicker className="report_filters__dates__range" locale={locale.DatePicker} showTime onChange={onChange} defaultValue={[
            dayjs(filterParams.startDate, dateFormat),
            dayjs(filterParams.endDate, dateFormat),
        ]} format={dateFormat}/>
        </div>
      </div>
    </div>);
};
export default FilterRange;
