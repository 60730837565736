import moment from 'moment';
import { dateFormat } from '@/constants/common';
export var Actions;
(function (Actions) {
    Actions[Actions["\u0410\u0432\u0442\u043E\u0440\u0438\u0437\u0430\u0446\u0438\u044F"] = 1] = "\u0410\u0432\u0442\u043E\u0440\u0438\u0437\u0430\u0446\u0438\u044F";
    Actions[Actions["\u041E\u0442\u043A\u043B\u044E\u0447\u0435\u043D\u0438\u0435"] = 2] = "\u041E\u0442\u043A\u043B\u044E\u0447\u0435\u043D\u0438\u0435";
    Actions[Actions["\u0421\u043E\u0437\u0434\u0430\u043D\u0438\u0435 \u043E\u0431\u044A\u0435\u043A\u0442\u0430 \u043D\u0430 \u0441\u0435\u0440\u0432\u0435\u0440\u0435"] = 3] = "\u0421\u043E\u0437\u0434\u0430\u043D\u0438\u0435 \u043E\u0431\u044A\u0435\u043A\u0442\u0430 \u043D\u0430 \u0441\u0435\u0440\u0432\u0435\u0440\u0435";
    Actions[Actions["\u0423\u0434\u0430\u043B\u0435\u043D\u0438\u0435 \u043E\u0431\u044A\u0435\u043A\u0442\u0430 \u043D\u0430 \u0441\u0435\u0440\u0432\u0435\u0440\u0435"] = 4] = "\u0423\u0434\u0430\u043B\u0435\u043D\u0438\u0435 \u043E\u0431\u044A\u0435\u043A\u0442\u0430 \u043D\u0430 \u0441\u0435\u0440\u0432\u0435\u0440\u0435";
    Actions[Actions["\u0418\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u0435 \u043E\u0431\u044A\u0435\u043A\u0442\u0430 \u043D\u0430 \u0441\u0435\u0440\u0432\u0435\u0440\u0435"] = 5] = "\u0418\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u0435 \u043E\u0431\u044A\u0435\u043A\u0442\u0430 \u043D\u0430 \u0441\u0435\u0440\u0432\u0435\u0440\u0435";
    Actions[Actions["\u0418\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u0435 \u043F\u0430\u0440\u0430\u043C\u0435\u0442\u0440\u043E\u0432 \u0441\u0435\u0440\u0432\u0435\u0440\u0430"] = 6] = "\u0418\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u0435 \u043F\u0430\u0440\u0430\u043C\u0435\u0442\u0440\u043E\u0432 \u0441\u0435\u0440\u0432\u0435\u0440\u0430";
    Actions[Actions["\u0412\u0445\u043E\u0434 \u043F\u043E \u0432\u0435\u0431"] = 7] = "\u0412\u0445\u043E\u0434 \u043F\u043E \u0432\u0435\u0431";
})(Actions || (Actions = {}));
export var actionsArray = [
    { value: Actions['Авторизация'], name: Actions[1] },
    { value: Actions['Отключение'], name: Actions[2] },
    { value: Actions['Создание объекта на сервере'], name: Actions[3] },
    { value: Actions['Удаление объекта на сервере'], name: Actions[4] },
    { value: Actions['Изменение объекта на сервере'], name: Actions[5] },
    { value: Actions['Изменение параметров сервера'], name: Actions[6] },
    { value: Actions['Вход по веб'], name: Actions[7] },
];
export var parameters = function (fromDate, toDate, chosenComment, formData) {
    var from = {
        column: 'created_on::DATE',
        comparator: '>=',
        value: moment(fromDate, dateFormat).format('YYYY-MM-DD'),
    };
    var to = {
        column: 'created_on::DATE',
        comparator: '<=',
        value: moment(toDate, dateFormat).format('YYYY-MM-DD'),
    };
    var createdBy = formData.users.value
        ? {
            column: 'created_by_id',
            comparator: '=',
            value: formData.users.value,
        }
        : null;
    var someAction = formData.actions.value
        ? {
            column: 'action',
            comparator: '=',
            value: formData.actions.value,
        }
        : null;
    var comment = chosenComment
        ? {
            column: 'comment',
            comparator: 'LIKE',
            value: "%".concat(chosenComment, "%"),
        }
        : null;
    return { from: from, to: to, createdBy: createdBy, someAction: someAction, comment: comment };
};
