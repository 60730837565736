import React, { memo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { ReactComponent as Logout } from '@/assets/images/logout.svg';
import { removeToken } from '@/utils';
import './account.css';
import { login } from '@/utils/rest';
// import Locale from './locale';
var Account = function () {
    var _a;
    var query = useQueryClient();
    var history = useHistory();
    var user = query.getQueryData('userInfo');
    var logout = useMutation('logout', login.userLogout, {
        onSuccess: function () {
            removeToken();
            window.location.reload();
            history.push("/");
        },
    });
    var handleLogout = function () {
        logout.mutate();
    };
    return (<div className="account-box">
      {/*<Locale />*/}
      <div className="account" onClick={handleLogout}>
        <div className="account__icon">
          <Logout />
        </div>
        <p className="account__name">
          <span className="account__firstname">{(_a = user === null || user === void 0 ? void 0 : user.data) === null || _a === void 0 ? void 0 : _a.fio}</span>
        </p>
      </div>
    </div>);
};
export default memo(Account);
