import React from 'react';
import medal from '@/assets/images/about-license.png';
import './aboutCard.css';
var AboutCard = function (_a) {
    var title = _a.title, value = _a.value, btnText = _a.btnText, btnCallback = _a.btnCallback, size = _a.size, card = _a.card, isDouble = _a.isDouble, secondTitle = _a.secondTitle, secondValue = _a.secondValue;
    return (<div className={"about-card ".concat(size == 'big' ? 'about-card_big' : 'about-card_small', " ").concat(isDouble ? 'about-card_double' : '')}>
      <div className={"about-card__content ".concat(card == 'license' ? 'about-card__content_license' : '')}>
        {card == 'license' && <img className="about-card__icon" src={medal}/>}
        <span className="about-card__title">{title}</span>
        <span className="about-card__value">{value}</span>
      </div>
      {isDouble && (<div className={"about-card__content ".concat(card == 'panelV' ? 'about-card__content_green' : '')}>
          <span className="about-card__title">{secondTitle}</span>
          <span className="about-card__value">{secondValue}</span>
        </div>)}
      {btnCallback && (<button className="about-card__update-btn" onClick={function () { return btnCallback(); }}>
          {btnText}
        </button>)}
    </div>);
};
export default AboutCard;
