import React, { useState } from 'react';
import { ReactComponent as CheckAll } from '@/assets/images/checkAll.svg';
import { ReactComponent as Close } from '@/assets/images/close.svg';
import { ReactComponent as Search } from '@/assets/images/search.svg';
import texts from './texts.json';
import './multiSelect.css';
var MultiSelect = function (_a) {
    var _b = _a.width, width = _b === void 0 ? 780 : _b, title = _a.title, data = _a.data, handleCheck = _a.handleCheck, selected = _a.selected;
    var _c = useState(''), text = _c[0], setText = _c[1];
    var handleCheckAll = function () {
        handleCheck(-1);
    };
    var handleCancel = function () {
        handleCheck(-2);
    };
    var handleSearch = function (e) {
        var value = e.target.value;
        setText(value);
    };
    return (<div className="multiselect" style={{ maxWidth: width }}>
      {title && <p className="field-text__title">{title}</p>}
      <div className="multiselect__content">
        <div className="multiselect__top">
          <div className="multiselect__top-btns">
            <button className="multiselect__top-btn" onMouseDown={handleCheckAll}>
              <CheckAll />
              {texts.checkAll}
            </button>
            <button className="multiselect__top-btn" onClick={handleCancel}>
              <Close />
              {texts.cancelAll}
            </button>
          </div>
          <div className="multiselect__search">
            <input type="text" onChange={handleSearch}/>
            <Search />
          </div>
        </div>
        <div className="multiselect__body">
          {data === null || data === void 0 ? void 0 : data.filter(function (item) {
            return text
                ? item.name.toLowerCase().includes(text.toLowerCase())
                : item;
        }).map(function (item) { return (<button key={String(item.id)} onClick={function () { return handleCheck(item.id); }} className={selected.includes(item.id) ? 'active' : undefined}>
                {item.name}
              </button>); })}
        </div>
      </div>
    </div>);
};
export default MultiSelect;
