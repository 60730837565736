import React from 'react';
import { ReactComponent as CheckAll } from '@/assets/images/checkAll.svg';
import './button.css';
var CheckAllBtn = function (_a) {
    var handleClick = _a.handleClick, _b = _a.text, text = _b === void 0 ? 'Добавить' : _b;
    return (<button className="small-btn" onClick={handleClick}>
      <CheckAll />
      {text}
    </button>);
};
export default CheckAllBtn;
