import React from 'react';
import moment from 'moment';
import { ReactComponent as Comment } from '@/assets/images/commentText.svg';
import { ReactComponent as Microphone } from '@/assets/images/microphone.svg';
import { ReactComponent as Video } from '@/assets/images/videoPlayer.svg';
export default function TableConfig(filteredData, handleClick) {
    return [
        {
            title: 'Тип',
            dataIndex: 'type',
            key: 'type',
            width: 120,
            fixed: 'left',
            filters: [
                {
                    text: 'Обращение',
                    value: 'Обращение',
                },
                {
                    text: 'Опрос',
                    value: 'Опрос',
                },
            ],
            onFilter: function (value, record) { return record.type.indexOf(String(value)) === 0; },
        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            fixed: 'left',
            sorter: function (a, b) { return Number(a.id) - Number(b.id); },
        },
        {
            title: 'Id талона',
            dataIndex: 'ticket_id',
            key: 'ticket_id',
            width: 100,
            fixed: 'left',
            sorter: function (a, b) { return Number(a.ticket_id) - Number(b.ticket_id); },
        },
        {
            title: '№ талона',
            dataIndex: 'number',
            key: 'number',
            width: 150,
            fixed: 'left',
        },
        {
            title: 'Дата прохождения',
            dataIndex: 'created_on',
            key: 'created_on',
            width: 160,
            fixed: 'left',
            sorter: function (a, b) {
                var aToSec = moment(a.created_on, 'DD.MM.YYYY HH:mm:ss').get('milliseconds');
                var bToSec = moment(b.created_on, 'DD.MM.YYYY  HH:mm:ss').get('milliseconds');
                return aToSec - bToSec;
            },
        },
        {
            title: 'Источник',
            dataIndex: 'source',
            width: 200,
            filters: filteredData && filteredData.sourceList,
            onFilter: function (value, record) { return record.source.indexOf(String(value)) === 0; },
        },
        {
            title: 'Оператор',
            dataIndex: 'fio',
            width: 200,
            filters: filteredData && filteredData.operatorList,
            onFilter: function (value, record) { return record.fio.indexOf(String(value)) === 0; },
        },
        {
            title: 'Медиа',
            children: [
                {
                    title: 'Фотография',
                    dataIndex: 'photo_b64',
                    width: 170,
                    render: function (_, row) {
                        var photoB64 = row.photo_b64;
                        var regEx = /data:image\/([a-zA-Z]*);base64,([^"]*)/g;
                        if (photoB64 && regEx.test(photoB64)) {
                            return (<img src={photoB64} data-name="photo" style={{ cursor: 'pointer', height: 50 }} alt="customer"/>);
                        }
                        return 'Нет фотографии';
                    },
                },
                {
                    title: 'Аудио запись',
                    dataIndex: 'audiorecordpath',
                    width: 100,
                    align: 'center',
                    render: function (_, res) { return (<div style={{ display: 'flex' }}>
              {res.audiorecordpath !== '' ? (<div style={{ marginLeft: '5px' }}>
                  <button onClick={function () { return handleClick('micro', res); }} data-name="micro" type="button">
                    <Microphone />
                  </button>
                </div>) : (<span>Нет записи </span>)}
            </div>); },
                },
                {
                    title: 'Видео запись',
                    dataIndex: 'videorecordpath',
                    width: 100,
                    align: 'center',
                    render: function (_, res) { return (<div style={{ display: 'flex' }}>
              {res.videorecordpath !== '' ? (<div style={{ marginLeft: '5px' }}>
                  <button onClick={function () { return handleClick('video', res); }} data-name="video" type="button">
                    <Video />
                  </button>
                </div>) : (<span>Нет записи </span>)}
            </div>); },
                },
                {
                    title: 'Текстовое',
                    dataIndex: 'textrecord',
                    width: 100,
                    align: 'center',
                    render: function (_, res) { return (<div style={{ display: 'flex' }}>
              {res.textrecord !== '' ? (<div style={{ marginLeft: '5px' }}>
                  <button onClick={function () { return handleClick('comment', res); }} data-name="comment" type="button">
                    <Comment />
                  </button>
                </div>) : (<span>Нет записи</span>)}
            </div>); },
                },
            ],
        },
    ];
}
