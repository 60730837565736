var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { ReactComponent as Drop } from '@/assets/images/drop.svg';
import Column from '@/components/drag&drop/column';
import './drag&drop.css';
// Компонент
var DragDrop = function (_a) {
    var items = _a.items, checked = _a.checked, setNewParams = _a.setNewParams, _b = _a.showAdd, showAdd = _b === void 0 ? true : _b, _c = _a.buttons, buttons = _c === void 0 ? true : _c, _d = _a.select, select = _d === void 0 ? false : _d, options = _a.options, handleOptionSelect = _a.handleOptionSelect;
    // Получение списка всех возможных и активных параметров
    var paramItems = {};
    var checkedParamItems = {};
    items.forEach(function (item, index) {
        var _a, _b;
        if (checked.includes(item.value)) {
            checkedParamItems[item.value] = {
                id: index,
                field: item.value,
                description: item.item ? item.item.title : item.name,
                isNumber: (_a = item.item) === null || _a === void 0 ? void 0 : _a.isNumber,
            };
        }
        else {
            paramItems[item.value] = {
                id: index,
                field: item.value,
                description: item.item ? item.item.title : item.name,
                isNumber: (_b = item.item) === null || _b === void 0 ? void 0 : _b.isNumber,
            };
        }
    });
    // Начальное состояние
    var _e = useState({
        items: __assign(__assign({}, paramItems), checkedParamItems),
        columns: {
            select: {
                id: 'select',
                title: 'Доступные поля',
                fieldsIds: __spreadArray([], Object.keys(paramItems), true),
            },
            selected: {
                id: 'selected',
                title: 'Выбранные поля',
                fieldsIds: __spreadArray([], Object.keys(checkedParamItems), true),
            },
        },
        columnOrder: ['select', 'selected'],
    }), possibleToSortArea = _e[0], setPossibleToSortArea = _e[1];
    // Действия пользователя
    var onDragEnd = function (result) {
        var _a, _b;
        var destination = result.destination, source = result.source, draggableId = result.draggableId;
        var newParamsArr = [];
        if (!destination)
            return;
        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;
        }
        var startColumn = possibleToSortArea.columns[source.droppableId];
        var endColumn = possibleToSortArea.columns[destination.droppableId];
        if (endColumn.id === 'selected') {
            newParamsArr = __spreadArray([], endColumn.fieldsIds, true);
            newParamsArr.push(draggableId);
        }
        if (endColumn.id === 'select') {
            newParamsArr = __spreadArray([], startColumn.fieldsIds, true);
            var index = newParamsArr.indexOf(draggableId);
            newParamsArr.splice(index, 1);
        }
        if (startColumn === endColumn) {
            var newItemsIds = Array.from(startColumn.fieldsIds);
            newItemsIds.splice(source.index, 1);
            newItemsIds.splice(destination.index, 0, draggableId);
            var newColumn = __assign(__assign({}, startColumn), { fieldsIds: newItemsIds });
            var newState_1 = __assign(__assign({}, possibleToSortArea), { columns: __assign(__assign({}, possibleToSortArea.columns), (_a = {}, _a[source.droppableId] = newColumn, _a)) });
            setPossibleToSortArea(newState_1);
            return;
        }
        var startItemsIds = Array.from(startColumn.fieldsIds);
        startItemsIds.splice(source.index, 1);
        var newStartItemsIds = __assign(__assign({}, startColumn), { fieldsIds: startItemsIds });
        var endItemsIds = Array.from(endColumn.fieldsIds);
        endItemsIds.splice(destination.index, 0, draggableId);
        var newEndItemsIds = __assign(__assign({}, endColumn), { fieldsIds: endItemsIds });
        var newState = __assign(__assign({}, possibleToSortArea), { columns: __assign(__assign({}, possibleToSortArea.columns), (_b = {}, _b[source.droppableId] = newStartItemsIds, _b[destination.droppableId] = newEndItemsIds, _b)) });
        setPossibleToSortArea(newState);
        setNewParams(newParamsArr);
    };
    var handleCheckAll = function () {
        var currentSelectIds = __spreadArray([], possibleToSortArea.columns.select.fieldsIds, true);
        var newState = __assign(__assign({}, possibleToSortArea), { columns: __assign(__assign({}, possibleToSortArea.columns), { select: __assign(__assign({}, possibleToSortArea.columns.select), { fieldsIds: [] }), selected: __assign(__assign({}, possibleToSortArea.columns.selected), { fieldsIds: __spreadArray(__spreadArray([], possibleToSortArea.columns.selected.fieldsIds, true), currentSelectIds, true) }) }) });
        var newParamsArr = __spreadArray([], currentSelectIds, true);
        setNewParams(newParamsArr);
        setPossibleToSortArea(newState);
    };
    var handleDelAll = function () {
        var currentSelectedIds = __spreadArray([], possibleToSortArea.columns.selected.fieldsIds, true);
        var newState = __assign(__assign({}, possibleToSortArea), { columns: __assign(__assign({}, possibleToSortArea.columns), { select: __assign(__assign({}, possibleToSortArea.columns.select), { fieldsIds: __spreadArray(__spreadArray([], possibleToSortArea.columns.select.fieldsIds, true), currentSelectedIds, true) }), selected: __assign(__assign({}, possibleToSortArea.columns.selected), { fieldsIds: [] }) }) });
        setNewParams([]);
        setPossibleToSortArea(newState);
    };
    // Рендер компонента
    return (<DragDropContext onDragEnd={onDragEnd}>
      <div className="draggable__container">
        {possibleToSortArea === null || possibleToSortArea === void 0 ? void 0 : possibleToSortArea.columnOrder.map(function (item, index) {
            var column = possibleToSortArea.columns[item];
            var field = column.fieldsIds.map(function (el) { return possibleToSortArea.items[el]; });
            return (<div className="draggable__column" key={index}>
              <Column options={options} select={select} buttons={buttons} showAdd={showAdd} checkAll={handleCheckAll} delAll={handleDelAll} id={item} column={column} items={field} key={item} handleOptionSelect={handleOptionSelect}/>
              {index === 0 && <Drop className="draggable__img"/>}
            </div>);
        })}
      </div>
    </DragDropContext>);
};
export default DragDrop;
