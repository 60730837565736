import React from 'react';
import { useParams } from 'react-router';
import ReportTabs from '@/components/tabs/reportTabs';
import tabsNav from './tabs-nav';
import './reports.css';
var Reports = function () {
    var _a;
    var _b = useParams(), tab = _b.tab, subtab = _b.subtab;
    var currentTab = tabsNav.find(function (item) { return item.id.includes(tab); });
    var currentSubTab = (_a = currentTab === null || currentTab === void 0 ? void 0 : currentTab.subtab) === null || _a === void 0 ? void 0 : _a.find(function (item) {
        return item.id.includes(subtab);
    });
    return (<div className="reports">
      <ReportTabs data={tabsNav} activeTab={currentTab}/>
      {(currentSubTab === null || currentSubTab === void 0 ? void 0 : currentSubTab.component) || (currentTab === null || currentTab === void 0 ? void 0 : currentTab.component)}
    </div>);
};
export default Reports;
