import React, { useRef } from 'react';
import { Button, Input, Space } from 'antd';
import { ReactComponent as SmallSearch } from '@/assets/images/search-small.svg';
export var lowerCasedPolls = function (pollsData, filter) {
    return pollsData === null || pollsData === void 0 ? void 0 : pollsData.filter(function (survey) {
        return survey.surveyname.toLowerCase().includes(filter.toLowerCase()) ||
            survey.connectedQuestions.filter(function (question) {
                return question.questiontext.toLowerCase().includes(filter.toLowerCase());
            }).length;
    });
};
export var getNewAction = function (field) {
    return (field === 'check' || field === 'delete' || field === 'edit') && field;
};
export var getPayloadSubmitSurvey = function (formData, questionName) {
    var _a, _b;
    return ({
        surveyname: formData ? (_a = formData[0]) === null || _a === void 0 ? void 0 : _a.surveyname : questionName,
        connectedQuestions: formData
            ? (_b = formData[0]) === null || _b === void 0 ? void 0 : _b.connectedQuestions.map(function (question) { return question.id; })
            : [],
    });
};
var handleSearch = function (selectedKeys, confirm) {
    confirm();
};
var handleReset = function (clearFilters) {
    clearFilters();
};
export var getColumnSearchProps = function (dataIndex, title) { return ({
    filterDropdown: function (_a) {
        var setSelectedKeys = _a.setSelectedKeys, selectedKeys = _a.selectedKeys, clearFilters = _a.clearFilters, confirm = _a.confirm;
        var searchInput = useRef(null);
        return (<div style={{
                padding: 8,
            }}>
        <Input ref={searchInput} placeholder={"\u041F\u043E\u0438\u0441\u043A ".concat(title)} value={selectedKeys[0]} onChange={function (e) {
                return setSelectedKeys(e.target.value ? [e.target.value] : []);
            }} onPressEnter={function () { return handleSearch(selectedKeys, confirm); }} style={{
                marginBottom: 8,
                display: 'block',
            }}/>
        <Space>
          <Button type="primary" onClick={function () { return handleSearch(selectedKeys, confirm); }} size="small" style={{
                width: 90,
            }}>
            Поиск
          </Button>
          <Button onClick={function () { return clearFilters && handleReset(clearFilters); }} size="small" style={{
                width: 90,
            }}>
            Сброс
          </Button>
        </Space>
      </div>);
    },
    filterIcon: <SmallSearch />,
    onFilter: function (value, record) {
        return record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(String(value).toLowerCase());
    },
}); };
export var getPayloadSubmitQuestion = function (formData, questionName, question) {
    var _a, _b, _c;
    return formData && {
        correctAnswers: [],
        operator_mark: false,
        questiontext: question,
        connectedQuestions: formData
            ? (_a = formData[0]) === null || _a === void 0 ? void 0 : _a.connectedQuestions.map(function (question) { return question.id; })
            : [],
        surveyname: formData ? (_b = formData[0]) === null || _b === void 0 ? void 0 : _b.surveyname : questionName,
        surveyId: (_c = formData[0]) === null || _c === void 0 ? void 0 : _c.id,
    };
};
export var getPayloadSubmitAnswer = function (currentRow, formData, questionName, question) {
    var _a, _b;
    return ({
        questiontext: formData ? (_a = formData[0]) === null || _a === void 0 ? void 0 : _a.questiontext : questionName,
        operator_mark: formData ? formData[0].operator_mark : false,
        correctAnswers: formData
            ? (_b = formData[0]) === null || _b === void 0 ? void 0 : _b.answers.map(function (answer) { return ({
                answerint: answer.answerint,
                answertxt: answer.answertxt,
                correct: answer.correct,
                id: answer.id,
                type: answer.type,
            }); }).concat({
                answerint: question,
                answertxt: question,
                correct: false,
                id: Math.random(),
                type: 3,
            })
            : [],
    });
};
export var getPayloadDeleteAnswer = function (currentRow, formData, questionName) {
    var _a, _b;
    return ({
        questiontext: formData ? (_a = formData[0]) === null || _a === void 0 ? void 0 : _a.questiontext : questionName,
        operator_mark: formData ? formData[0].operator_mark : false,
        correctAnswers: formData
            ? (_b = formData[0]) === null || _b === void 0 ? void 0 : _b.answers.map(function (answer) { return ({
                answerint: answer.answerint,
                answertxt: answer.answertxt,
                correct: answer.correct,
                id: answer.id,
                type: answer.type,
            }); }).filter(function (answer) { return answer.id !== (currentRow === null || currentRow === void 0 ? void 0 : currentRow.id); })
            : [],
    });
};
export var getPayloadUpdateAnswer = function (currentRow, formData, questionName) {
    var _a, _b;
    return ({
        questiontext: formData ? (_a = formData[0]) === null || _a === void 0 ? void 0 : _a.questiontext : questionName,
        operator_mark: formData ? formData[0].operator_mark : false,
        correctAnswers: formData
            ? (_b = formData[0]) === null || _b === void 0 ? void 0 : _b.answers.map(function (answer) { return ({
                answerint: answer.answerint,
                answertxt: answer.answertxt,
                correct: answer.correct,
                id: answer.id,
                type: answer.type,
            }); })
            : [],
    });
};
