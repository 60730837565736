var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import CopiesBtn from '@/components/button/copiesBtn';
import './storage.css';
import Checkbox from '@/components/input/checkbox';
import Input from '@/components/input/input';
import Loader from '@/components/loader';
import { sessionStorage } from '@/utils/rest/pages/system';
var Storage = function () {
    var _a = useQuery('storage', sessionStorage.get, {
        onSuccess: function (result) {
            result && setFormData(result);
            result && setCheck(result.enabled);
        },
    }), isLoading = _a.isLoading, data = _a.data, refetch = _a.refetch;
    var _b = useState(data), formData = _b[0], setFormData = _b[1];
    var _c = useState(false), check = _c[0], setCheck = _c[1];
    var updateMutation = useMutation(sessionStorage.update, {
        onSuccess: function () {
            refetch();
        },
    });
    var onChangeInput = function () {
        setCheck(!check);
        setFormData(__assign(__assign({}, formData), { enabled: check }));
    };
    var onChange = function (e) {
        var _a;
        setFormData(__assign(__assign({}, formData), (_a = {}, _a[e.target.name] = e.target.value, _a)));
    };
    var onChangeDeep = function (e) {
        var _a;
        setFormData(__assign(__assign({}, formData), { dc_customrolesjson: __assign(__assign({}, formData.dc_customrolesjson), (_a = {}, _a[e.target.name] = e.target.value, _a)) }));
    };
    var submitHandler = function () {
        updateMutation.mutate({
            enabled: check,
            name: formData.name,
            dc_fqdn: formData.dc_fqdn,
            dc_passwd: formData.dc_passwd,
            last_sync: formData.last_sync,
            dc_username: formData.dc_username,
            timeout_sync: formData.timeout_sync,
            dc_serverfqdn: formData.dc_serverfqdn,
            dc_sync_script: formData.dc_sync_script,
            last_sync_message: formData.last_sync_message,
            dc_customrolesjson: formData.dc_customrolesjson,
        });
    };
    if (isLoading) {
        return <Loader />;
    }
    return (<div className="storage">
      <div className="storage__sync">
        <div className="storage__button-container">
          <div className="storage__checkbox-container">
            <CopiesBtn handleClick={submitHandler} text="Синхронизировать"/>
            <Checkbox name="enabled" onChange={onChangeInput} value={check} text={check ? 'Синхронизация включена' : 'Синхронизация отключена'}/>
          </div>
          <div className="storage__date-container">
            <div className="storage__date">
              <p>Время последней синхронизации</p>
              <p className="info-settings__subtitle">
                {moment(formData === null || formData === void 0 ? void 0 : formData.last_sync).format('DD.MM.YYYY - HH:mm:ss')}
              </p>
            </div>
          </div>
        </div>
        <div className="storage__info">
          <h2 className="section-title">
            Настройки синхронизации с Active Directory
          </h2>
          <div className="storage__info-settings info-settings">
            <div className="info-settings__left">
              <div>
                <div className="info-settings__card">
                  <p>Кем создан</p>
                  <p className="info-settings__subtitle">
                    {formData === null || formData === void 0 ? void 0 : formData.created_by_username}
                  </p>
                </div>
                <div className="info-settings__card">
                  <p>Кем изменён</p>
                  <p className="info-settings__subtitle">
                    {formData === null || formData === void 0 ? void 0 : formData.modified_by_username}
                  </p>
                </div>
              </div>
              <div>
                <div className="info-settings__card">
                  <p>Дата создания</p>
                  <p className="info-settings__subtitle">
                    {moment(formData === null || formData === void 0 ? void 0 : formData.created_on).format('DD.MM.YYYY - HH:mm:ss')}
                  </p>
                </div>
                <div className="info-settings__card">
                  <p>Дата изменения</p>
                  <p className="info-settings__subtitle">
                    {moment(formData === null || formData === void 0 ? void 0 : formData.modified_on).format('DD.MM.YYYY - HH:mm:ss')}
                  </p>
                </div>
              </div>
            </div>
            <div className="info-settings__right">
              <div className="info-settings__card">
                <p>Результат синхронизации</p>
                <p className="info-settings__subtitle">
                  {formData === null || formData === void 0 ? void 0 : formData.last_sync_message}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="storage__settings">
        <h2 className="section-title">Настройки</h2>
        <div className="storage__inputs">
          <Input name="name" width={380} text="Название" onChange={onChange} value={formData === null || formData === void 0 ? void 0 : formData.name}/>
          <Input name="dc_fqdn" width={380} text="FQDN домена" onChange={onChange} value={formData === null || formData === void 0 ? void 0 : formData.dc_fqdn}/>
          <Input name="dc_username" width={380} text="Логин синхронизации с AD" value={formData === null || formData === void 0 ? void 0 : formData.dc_username} onChange={onChange}/>
        </div>
        <div className="storage__inputs">
          <Input name="dc_serverfqdn" width={380} text="IP адрес или имя сервера AD" value={formData === null || formData === void 0 ? void 0 : formData.dc_serverfqdn} onChange={onChange}/>
          <Input name="timeout_sync" width={380} text="Интервал синхронизации (сек.)" value={formData === null || formData === void 0 ? void 0 : formData.timeout_sync} onChange={onChange}/>
          <Input name="dc_passwd" width={380} text="Пароль" value={formData === null || formData === void 0 ? void 0 : formData.dc_passwd} onChange={onChange} type="password"/>
        </div>
      </div>
      <div className="storage__settings">
        <h2 className="section-title">Настройки синхронизации</h2>
        <div className="storage__inputs">
          <Input name="eq_root" width={580} text="Администратор" onChange={onChangeDeep} value={formData === null || formData === void 0 ? void 0 : formData.dc_customrolesjson.eq_root}/>
          <Input name="eq_manager" width={580} text="Суперчитатель" onChange={onChangeDeep} value={formData === null || formData === void 0 ? void 0 : formData.dc_customrolesjson.eq_manager}/>
        </div>
        <div className="storage__inputs">
          <Input name="eq_controller" width={580} text="Диспетчер" onChange={onChangeDeep} value={formData === null || formData === void 0 ? void 0 : formData.dc_customrolesjson.eq_controller}/>
          <Input name="eq_operator" width={580} text="Оператор" onChange={onChangeDeep} value={formData === null || formData === void 0 ? void 0 : formData.dc_customrolesjson.eq_operator}/>
        </div>
        <div className="storage__inputs storage__inputs--alone">
          <Input name="eq_reporter" width={580} text="Читатель отчётов" onChange={onChangeDeep} value={formData === null || formData === void 0 ? void 0 : formData.dc_customrolesjson.eq_reporter}/>
        </div>
      </div>
    </div>);
};
export default Storage;
