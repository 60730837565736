var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { baseGetApi } from '@/utils/rest';
var SqlQuery = function (startDate, endDate) { return __awaiter(void 0, void 0, void 0, function () {
    var query, queryExport, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                query = "select mt.*, count(*) OVER() AS total_count from (select  sd.id,\n  sd.ticket_id,\n  syrv.survey_id,\n  case when syrv.survey_id is null then '\u041E\u0431\u0440\u0430\u0449\u0435\u043D\u0438\u0435' else '\u041E\u043F\u0440\u043E\u0441' end as type,\n  quest.surveyname,\n  sd.audiorecordpath, \n  sd.videorecordpath, \n  sd.photo_b64,\n  to_char(sd.created_on, 'DD.MM.YYYY hh24:MI') as created_on,\n  sd.textrecord,\n  CASE WHEN th.\"number\" is null THEN '\u0411\u0435\u0437 \u0442\u0430\u043B\u043E\u043D\u0430' \n  when th.\"number\" is not null then th.\"number\"\n  end as \"number\",\n  CASE WHEN th.username is null THEN '\u041D\u0435 \u043F\u0440\u0438\u0432\u044F\u0437\u0430\u043D' \n  WHEN th.username is not null then th.username \n  end as username,\n  CASE WHEN th.fio is null THEN '\u041D\u0435 \u043F\u0440\u0438\u0432\u044F\u0437\u0430\u043D' \n  WHEN th.fio is not null then th.fio \n  end as fio,\n  coalesce(sd.source, '\u041D\u0435 \u0443\u043A\u0430\u0437\u0430\u043D') as source\n  from eq.tqcsessiondata sd\n  left join (\n  select survey_id, sessiondata_id from eq.tqcsessionquestion\n  ) syrv \n  on sd.id = syrv.sessiondata_id\n  left join (select ww.surveyname,\n  qq.sessiondata_id \n  from eq.tqcsessionquestion qq,\n  eq.tqcsurvey ww\n  where qq.survey_id = ww.id) quest\n  on sd.id = quest.sessiondata_id\n  left join (\n  select \n  distinct (th.ticket_id), \n  th.number,\n  tu.username, tu.fio\n  from eq.ttickethistory th,\n  eq.tuser tu \n  where th.status in (3, 4, 9) \n  and tu.id = th.modified_by_id\n  ) th \n  on sd.ticket_id = th.ticket_id) mt \n  WHERE created_on::timestamp >= '".concat(startDate, "'\n  and created_on::timestamp <= '").concat(endDate, "' \n  ");
                queryExport = "select mt.*, count(*) OVER() AS total_count from (select  sd.id,\n    sd.ticket_id,\n    syrv.survey_id,\n    COALESCE(quest.surveyname, '\u041E\u0431\u0440\u0430\u0449\u0435\u043D\u0438\u0435') as surveyname,\n    sd.audiorecordpath, \n    sd.videorecordpath, \n    1 as id,\n    to_char(sd.created_on, 'DD.MM.YYYY hh24:MI') as created_on,\n    sd.textrecord,\n    CASE WHEN th.\"number\" is null THEN '\u0411\u0435\u0437 \u0442\u0430\u043B\u043E\u043D\u0430' \n    when th.\"number\" is not null then th.\"number\"\n    end as \"number\",\n    CASE WHEN th.username is null THEN '\u041D\u0435 \u043F\u0440\u0438\u0432\u044F\u0437\u0430\u043D' \n    WHEN th.username is not null then th.username \n    end as username,\n    CASE WHEN th.fio is null THEN '\u041D\u0435 \u043F\u0440\u0438\u0432\u044F\u0437\u0430\u043D' \n    WHEN th.fio is not null then th.fio \n    end as fio,\n    coalesce(sd.source, '\u041D\u0435 \u0443\u043A\u0430\u0437\u0430\u043D') as source\n    from eq.tqcsessiondata sd\n    left join (\n    select survey_id, sessiondata_id from eq.tqcsessionquestion\n    ) syrv \n    on sd.id = syrv.sessiondata_id\n    left join (select ww.surveyname,\n    qq.sessiondata_id \n    from eq.tqcsessionquestion qq,\n    eq.tqcsurvey ww\n    where qq.survey_id = ww.id) quest\n    on sd.id = quest.sessiondata_id\n    left join (\n    select \n    distinct (th.ticket_id), \n    th.number,\n    tu.username, tu.fio\n    from eq.ttickethistory th,\n    eq.tuser tu \n    where th.status in (3, 4, 9) \n    and tu.id = th.modified_by_id\n    ) th \n    on sd.ticket_id = th.ticket_id) mt \n    WHERE created_on::timestamp >= '".concat(startDate, "'\n    and created_on::timestamp <= '").concat(endDate, "'");
                data = [];
                return [4 /*yield*/, baseGetApi('genreportfromquery', 'get', {
                        payload: { data: { querySql: "".concat(query) } },
                    }).then(function (dataFromQuery) {
                        var columnNames = dataFromQuery.columnNames, answerData = dataFromQuery.data;
                        answerData.forEach(function (dv) {
                            var rElem = {
                                type: '',
                                id: '',
                                ticket_id: '',
                                number: '',
                                created_on: '',
                                source: '',
                                fio: '',
                                photo_b64: '',
                                audiorecordpath: '',
                                videorecordpath: '',
                                textrecord: '',
                            };
                            dv.forEach(function (v, idx) {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                rElem[columnNames[idx]] = v;
                            });
                            data.push(rElem);
                        });
                    })];
            case 1:
                _a.sent();
                return [2 /*return*/, { data: data, queryExport: queryExport }];
        }
    });
}); };
export default SqlQuery;
