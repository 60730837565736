import React from 'react';
import paths from '@/constants/paths';
import Substatuses from '@/pages/components/substatuses';
import ServicesList from './tabs/serviceList';
import ServicePolls from './tabs/servicePolls';
import ScheduleDays from './tabs/serviceSchedule/days';
import ScheduleIntervals from './tabs/serviceSchedule/intervals';
import ScheduleWeeks from './tabs/serviceSchedule/weeks';
export default [
    { id: paths.serviceList, name: 'Список услуг', component: <ServicesList /> },
    {
        id: paths.serviceSchedule,
        name: 'Расписание услуги',
        component: <ScheduleIntervals />,
        subtab: [
            {
                id: paths.serviceIntervals,
                name: 'Интервалы',
                component: <ScheduleIntervals />,
            },
            { id: paths.serviceDays, name: 'Дни', component: <ScheduleDays /> },
            { id: paths.serviceWeeks, name: 'Недели', component: <ScheduleWeeks /> },
        ],
    },
    {
        id: paths.servicePolls,
        name: 'Опросы',
        component: <ServicePolls />,
    },
    {
        id: paths.substatuses,
        name: 'Субстатусы',
        component: <Substatuses />,
    },
];
