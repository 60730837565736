import React, { memo } from 'react';
import { ReactComponent as Check } from '@/assets/images/check.svg';
var Checkbox = function (_a) {
    var name = _a.name, text = _a.text, value = _a.value, onChange = _a.onChange, id = _a.id, title = _a.title, _b = _a.disabled, disabled = _b === void 0 ? false : _b, style = _a.style;
    return (<label htmlFor={name} className={disabled ? 'checkbox checkbox-disabled' : 'checkbox'} style={style}>
      {title && <span className="checkbox__title">{title}</span>}
      <span className="checkbox__content">
        <span className="checkbox__checkmark">{value && <Check />}</span>
        <span className="checkbox__text">{text}</span>
      </span>
      <input id={name} name={name} data-id={id} type="checkbox" onChange={onChange} checked={value} value={undefined} disabled={disabled}/>
    </label>);
};
export default memo(Checkbox);
