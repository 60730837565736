import moment from 'moment';
export var columns = [
    {
        title: 'Информация об операторе',
        fixed: 'left',
        children: [
            {
                title: 'Id',
                dataIndex: 'operator',
                key: 'operator',
                width: 70,
                fixed: 'left',
                sorter: function (a, b) { return Number(a.operator) - Number(b.operator); },
                // filteredValue:
                //   [JSON.stringify({ valueMore: 10, valueLess: 15 })] || null,
                // onFilter: (value, record) => filteredValues(value, record, 'operator'),
            },
            {
                title: 'ФИО',
                dataIndex: 'user_fio',
                key: 'user_fio',
                width: 250,
                fixed: 'left',
                // filteredValue: filteredData.user_fio || null,
                // onFilter: (value, record) =>
                //   record.user_fio.includes(filteredData.user_fio),
            },
        ],
    },
    {
        title: 'Обслужено',
        className: 'draggable',
        children: [
            {
                title: 'Закрыто',
                dataIndex: 'close_cnt',
                key: 'close_cnt',
                width: 110,
                sorter: function (a, b) { return Number(a.close_cnt) - Number(b.close_cnt); },
            },
            {
                title: 'Неявка',
                dataIndex: 'neyavka_cnt',
                key: 'neyavka_cnt',
                width: 110,
                sorter: function (a, b) { return Number(a.neyavka_cnt) - Number(b.neyavka_cnt); },
            },
            {
                title: 'Всего',
                dataIndex: 'all_cnt',
                key: 'all_cnt',
                width: 110,
                sorter: function (a, b) { return Number(a.all_cnt) - Number(b.all_cnt); },
            },
        ],
    },
    {
        title: 'Кол-во обслуженных клиентов',
        dataIndex: 'share_cnt',
        width: 150,
        className: 'draggable',
        sorter: function (a, b) { return Number(a.share_cnt) - Number(b.share_cnt); },
    },
    {
        title: 'Длительность обслуживания',
        className: 'draggable',
        children: [
            {
                title: 'Общая',
                dataIndex: 'total_work_time',
                sorter: function (a, b) {
                    var aToSec = moment(a.total_work_time, 'HH:mm:ss').format('x');
                    var bToSec = moment(b.total_work_time, 'HH:mm:ss').format('x');
                    return Number(aToSec) - Number(bToSec);
                },
                width: 150,
            },
            {
                title: 'Средняя',
                dataIndex: 'avg_work_time',
                sorter: function (a, b) {
                    var aToSec = moment(a.avg_work_time, 'HH:mm:ss').format('x');
                    var bToSec = moment(b.avg_work_time, 'HH:mm:ss').format('x');
                    return Number(aToSec) - Number(bToSec);
                },
                width: 150,
            },
        ],
    },
    {
        title: 'Вопросы',
        className: 'draggable',
        children: [
            {
                title: 'Кол-во решенных',
                dataIndex: 'solved_questions',
                width: 150,
                sorter: function (a, b) {
                    return Number(a.solved_questions) - Number(b.solved_questions);
                },
            },
            {
                title: 'Среднее время',
                dataIndex: 'avg_quest_time',
                sorter: function (a, b) {
                    var aToSec = moment(a.avg_quest_time, 'HH:mm:ss').format('x');
                    var bToSec = moment(b.avg_quest_time, 'HH:mm:ss').format('x');
                    return Number(aToSec) - Number(bToSec);
                },
                width: 150,
            },
        ],
    },
    {
        title: 'Оценка',
        className: 'draggable',
        children: [
            {
                title: 'Всего',
                dataIndex: 'markcnt',
                width: 100,
                sorter: function (a, b) { return Number(a.markcnt) - Number(b.markcnt); },
            },
            {
                title: 'Средняя',
                dataIndex: 'markavg',
                width: 100,
                sorter: function (a, b) { return Number(a.markavg) - Number(b.markavg); },
            },
            {
                title: 'Отлично',
                dataIndex: 'mark5',
                width: 100,
                sorter: function (a, b) { return Number(a.mark5) - Number(b.mark5); },
            },
            {
                title: 'Хорошо',
                dataIndex: 'mark4',
                width: 100,
                sorter: function (a, b) { return Number(a.mark4) - Number(b.mark4); },
            },
            {
                title: 'Нормально',
                dataIndex: 'mark3',
                width: 100,
                sorter: function (a, b) { return Number(a.mark3) - Number(b.mark3); },
            },
            {
                title: 'Плохо',
                dataIndex: 'mark2',
                width: 100,
                sorter: function (a, b) { return Number(a.mark2) - Number(b.mark2); },
            },
            {
                title: 'Очень плохо',
                dataIndex: 'mark1',
                width: 100,
                sorter: function (a, b) { return Number(a.mark1) - Number(b.mark1); },
            },
        ],
    },
];
