import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import logo from '@/assets/images/new-logo.svg';
import { createSubLink } from '@/utils';
import Account from '../account';
import Navigation from '../navigation';
import navList from '../navigation/navList';
import BreadCrumbs from './breadcrumbs';
import './header.css';
var Header = function () {
    var pathname = useLocation().pathname;
    var i18n = useTranslation().i18n;
    var activeNav = useMemo(function () { return navList.find(function (item) { return pathname.includes(item.path); }); }, [pathname]);
    var renderSubnav = useMemo(function () {
        var _a;
        return ((_a = activeNav === null || activeNav === void 0 ? void 0 : activeNav.subnav) === null || _a === void 0 ? void 0 : _a.length) ? (<div className="header__sub-navigation">
        {activeNav === null || activeNav === void 0 ? void 0 : activeNav.subnav.map(function (item) {
                return (<li key={item.path} className="navigation__menu-item">
              <Link to={"/".concat(i18n.language).concat(createSubLink(item))} className={pathname.includes(item.path)
                        ? 'navigation__menu-link navigation__menu-link_active'
                        : 'navigation__menu-link'}>
                <span>{item.icon}</span>
                {item.name}
              </Link>
            </li>);
            })}
      </div>) : null;
    }, [activeNav, pathname]);
    return (<header className="header">
      <div className="header__menu-box">
        <div className="header__menu">
          <Link to={"/".concat(i18n.language, "/")}>
            <img src={logo} alt="" className="header__logo"/>
          </Link>
          <Navigation activeNav={activeNav}/>
          <Account />
        </div>
      </div>
      {renderSubnav}
      <BreadCrumbs activeNav={activeNav}/>
    </header>);
};
export default Header;
