var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import Select from '@/components/input/select';
import MultiSelect from '@/components/multiSelect';
var WplaceSelect = function (_a) {
    var handleSetFilters = _a.handleSetFilters, filtersData = _a.filtersData;
    var _b = useState([]), selectedWplaces = _b[0], setSelectedWplaces = _b[1];
    var wplaces = filtersData === null || filtersData === void 0 ? void 0 : filtersData.wplaces.map(function (item) { return ({
        id: item.id,
        name: item.name,
    }); });
    var handleCheckWorkplace = function (id) {
        if (id === -2) {
            setSelectedWplaces([]);
            return;
        }
        if (selectedWplaces.includes(id)) {
            var newSelectedWplaces = selectedWplaces;
            newSelectedWplaces.splice(newSelectedWplaces.indexOf(id), 1);
            setSelectedWplaces(__spreadArray([], newSelectedWplaces, true));
            return;
        }
        if (id === -1) {
            var newSelectedWplaces_1 = [];
            wplaces === null || wplaces === void 0 ? void 0 : wplaces.forEach(function (item) {
                newSelectedWplaces_1.push(item.id);
            });
            setSelectedWplaces(newSelectedWplaces_1);
            return;
        }
        setSelectedWplaces(__spreadArray(__spreadArray([], selectedWplaces, true), [id], false));
    };
    useEffect(function () { return handleSetFilters('workPlace', selectedWplaces); }, [selectedWplaces]);
    var values = wplaces === null || wplaces === void 0 ? void 0 : wplaces.filter(function (el) { return selectedWplaces.includes(el.id); });
    return (<Select name="wplaces" text="Список рабочих мест" type="dropdown" placeholder="Выбрать" value={values && values.length > 0 ? values === null || values === void 0 ? void 0 : values.map(function (el) { return el.name; }) : undefined}>
      <MultiSelect handleCheck={handleCheckWorkplace} selected={selectedWplaces} data={wplaces}/>
    </Select>);
};
export default WplaceSelect;
