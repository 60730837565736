import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { access } from '@/components/wrapper';
import { loginAccess } from '@/hooks/query/login';
import { createSubLink } from '@/utils';
import './navigation.css';
import navList from './navList';
var Navigation = function (_a) {
    var activeNav = _a.activeNav;
    var i18n = useTranslation().i18n;
    var renderList = function () {
        return navList.map(function (item) {
            return !item.private ? (<li key={item.path} className="navigation__menu-item">
          <Link to={"/".concat(i18n.language).concat(createSubLink(item))} className={(activeNav === null || activeNav === void 0 ? void 0 : activeNav.name) === item.name
                    ? 'navigation__menu-link navigation__menu-link_active'
                    : 'navigation__menu-link'}>
            <span>{item.icon}</span>
            {item.name}
          </Link>
        </li>) : ((loginAccess || access) && (<li key={item.path} className="navigation__menu-item">
            <Link to={"/".concat(i18n.language).concat(createSubLink(item))} className={(activeNav === null || activeNav === void 0 ? void 0 : activeNav.name) === item.name
                    ? 'navigation__menu-link navigation__menu-link_active'
                    : 'navigation__menu-link'}>
              <span>{item.icon}</span>
              {item.name}
            </Link>
          </li>));
        });
    };
    return (<nav className="navigation">
      <ul className="navigation__menu">{renderList()}</ul>
    </nav>);
};
export default Navigation;
