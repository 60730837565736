import React from 'react';
import { Line } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import { getMonitoringGraph } from '@/utils/rest/pages/system';
var LineReport = function () {
    var _a = useQuery('monitoringGraph', getMonitoringGraph, {
        refetchInterval: 5000,
    }), data = _a.data, isLoading = _a.isLoading;
    var graphValues = [];
    if (data) {
        data.hours.forEach(function (el) {
            graphValues.push(el.closed);
        });
    }
    var graphData = {
        label: 'Количество обслуженных клиентов',
        data: graphValues,
        lineTension: 0.4,
        pointRadius: 1,
        pointHoverRadius: 5,
        pointHitRadius: 5,
        borderColor: '#37798E',
        backgroundColor: '#37798E',
    };
    var graphOptions = {
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 20,
                },
            },
        },
    };
    var reportParams = {
        labels: [
            '00:00',
            '',
            '02:00',
            '',
            '04:00',
            '',
            '06:00',
            '',
            '08:00',
            '',
            '10:00',
            '',
            '12:00',
            '',
            '14:00',
            '',
            '16:00',
            '',
            '18:00',
            '',
            '20:00',
            '',
            '22:00',
            '',
        ],
        datasets: [graphData],
    };
    return (<>
      <div className="dashboard__graph">
        <p className="dashboard__graph__title">
          Распределение обслуженных клиентов за день
        </p>
        {!isLoading && (<Line data={reportParams} options={graphOptions} height={140}/>)}
      </div>
    </>);
};
export default LineReport;
