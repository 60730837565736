import React from 'react';
var ConnectedDays = function (_a) {
    var data = _a.data, days = _a.days;
    var getDay = function (id) {
        var _a;
        return (<span>{((_a = days === null || days === void 0 ? void 0 : days.find(function (item) { return item.id === id; })) === null || _a === void 0 ? void 0 : _a.name) || 'Не задано'}</span>);
    };
    return (<div className="connected-days">
      <p>ПН: {getDay(Number(data.monday_id))}</p>
      <p>ВТ: {getDay(Number(data.tuesday_id))}</p>
      <p>СР: {getDay(Number(data.wednesday_id))}</p>
      <p>ЧТ: {getDay(Number(data.thursday_id))}</p>
      <p>ПТ: {getDay(Number(data.friday_id))}</p>
      <p>СБ: {getDay(Number(data.saturday_id))}</p>
      <p>ВС: {getDay(Number(data.sunday_id))}</p>
    </div>);
};
export default ConnectedDays;
