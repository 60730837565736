import React from 'react';
import { ReactComponent as Trash } from '@/assets/images/table-trash.svg';
import './button.css';
var DeleteAllBtn = function (_a) {
    var handleClick = _a.handleClick, _b = _a.text, text = _b === void 0 ? 'Добавить' : _b;
    return (<button className="small-btn" onClick={handleClick}>
      <Trash />
      {text}
    </button>);
};
export default DeleteAllBtn;
