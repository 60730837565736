var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Button from '@/components/button';
import Input from '@/components/input/input';
import Select from '@/components/input/select';
import Loader from '@/components/loader';
import { findOption } from '@/utils';
import { workWeeks } from '@/utils/rest';
var WeekEdit = function (_a) {
    var data = _a.data, handleCancel = _a.handleCancel, refetch = _a.refetch, _b = _a.background, background = _b === void 0 ? 'transparent' : _b;
    var queryClient = useQueryClient();
    var days = queryClient.getQueryData('days') || [];
    var _c = useState(__assign({ name: '', description: '', monday_id: 0, tuesday_id: 0, wednesday_id: 0, thursday_id: 0, friday_id: 0, saturday_id: 0, sunday_id: 0 }, data)), formData = _c[0], setFormData = _c[1];
    var monday_id = formData.monday_id, tuesday_id = formData.tuesday_id, wednesday_id = formData.wednesday_id, thursday_id = formData.thursday_id, friday_id = formData.friday_id, saturday_id = formData.saturday_id, sunday_id = formData.sunday_id, name = formData.name, description = formData.description;
    var weekAdd = useMutation('week', workWeeks.add, {
        onSuccess: function () {
            // TODO: Попросить сделать новый ответ с созданной услугой
            if (refetch) {
                refetch();
            }
        },
    });
    var weekUpdate = useMutation(workWeeks.update, {
        onSuccess: function (data) {
            var weeks = queryClient.getQueryData('workWeeks');
            if (weeks) {
                var newWeeks = weeks.map(function (item) {
                    if (item.id === data.updatedId) {
                        return __assign(__assign({}, item), formData);
                    }
                    return item;
                });
                queryClient.setQueryData('workWeeks', newWeeks);
            }
        },
    });
    var handleInput = function (e) {
        var _a = e.target, name = _a.name, value = _a.value, checked = _a.checked;
        if (name === 'status') {
            setFormData(function (oldData) { return (__assign(__assign({}, oldData), { status: checked ? 2 : 1 })); });
        }
        else {
            setFormData(function (oldData) {
                var _a;
                return (__assign(__assign({}, oldData), (_a = {}, _a[name] = value, _a)));
            });
        }
    };
    var handleSelect = function (_a) {
        var option = _a.option, name = _a.name;
        setFormData(function (oldData) {
            var _a;
            return (__assign(__assign({}, oldData), (_a = {}, _a[name] = option.value, _a)));
        });
    };
    var handleSubmit = function (e) {
        var newData = {
            description: description,
            name: name,
            monday_id: monday_id,
            tuesday_id: tuesday_id,
            wednesday_id: wednesday_id,
            thursday_id: thursday_id,
            friday_id: friday_id,
            saturday_id: saturday_id,
            sunday_id: sunday_id,
        };
        e.preventDefault();
        if (data) {
            weekUpdate.mutate({ id: data.id, payload: newData });
            handleCancel();
        }
        else {
            weekAdd.mutate(newData);
            handleCancel();
        }
    };
    var selectOptions = days === null || days === void 0 ? void 0 : days.map(function (item) { return ({
        name: item.name,
        value: item.id,
    }); });
    return weekAdd.isLoading || weekUpdate.isLoading ? (<Loader />) : (<div className="editable-item__form">
      {!!data && <p className="editable-item__title">Редактирование дня</p>}
      <div className={background === 'transparent'
            ? 'editable-item__form-rows'
            : 'editable-item__form-rows editable-item__form-row_transparent'}>
        <div className="drawerStyle">
          <Input name="name" placeholder="Введите название" text="Наименование" value={name} onChange={handleInput} width={380}/>
          <Input name="description" placeholder="Введите название" text="Описание" value={description} onChange={handleInput} width={380}/>
          <Select name="monday_id" placeholder="Выберите из списка" text="Понедельник" value={findOption(days, monday_id)} onChange={handleSelect} width={380} options={selectOptions}/>
          <Select name="tuesday_id" placeholder="Выберите из списка" text="Вторник" value={findOption(days, tuesday_id)} onChange={handleSelect} width={380} options={selectOptions}/>
          <Select name="wednesday_id" placeholder="Выберите из списка" text="Среда" value={findOption(days, wednesday_id)} onChange={handleSelect} width={380} options={selectOptions}/>
          <Select name="thursday_id" placeholder="Выберите из списка" text="Четверг" value={findOption(days, thursday_id)} onChange={handleSelect} width={380} options={selectOptions}/>
          <Select name="friday_id" placeholder="Выберите из списка" text="Пятница" value={findOption(days, friday_id)} onChange={handleSelect} width={380} options={selectOptions}/>
          <Select name="saturday_id" placeholder="Выберите из списка" text="Суббота" value={findOption(days, saturday_id)} onChange={handleSelect} width={380} options={selectOptions}/>
          <Select name="sunday_id" placeholder="Выберите из списка" text="Воскресенье" value={findOption(days, sunday_id)} onChange={handleSelect} width={380} options={selectOptions}/>
        </div>
      </div>
      <div className="editable-item__form-btns">
        <Button text="Сохранить" onClick={handleSubmit} type="submit"/>
        {/* {!!data && <Button text="Отменить" onClick={handleCancel} />} */}
      </div>
    </div>);
};
export default WeekEdit;
