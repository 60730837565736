import React, { useEffect } from 'react';
import { notification } from 'antd';
import { useMutation, useQuery } from 'react-query';
import ExportBtn from '@/components/button/exportBtn';
import Loader from '@/components/loader';
import AntTableWrapper from '@/components/table/antTableWrapper';
import ReportFilters from '@/pages/statistics/reports/filters/reportFilters';
import { columns } from '@/pages/statistics/reports/tabs/operators/tableColumns';
import { FilterStore } from '@/store';
import { operatorReport, queueObjects } from '@/utils/rest/pages/reports';
// import { Column } from '@/types/common';
// import { OperatorReport } from '@/models/reports';
// import Table from '@/components/table';
var Operators = function () {
    var filterParams = FilterStore.useState(function (s) { return s.initialParams; });
    var setFilterParams = function (newFilterParams) {
        FilterStore.update(function (s) {
            s.initialParams = newFilterParams;
        });
    };
    var _a = useQuery('filtersData', queueObjects.get), filtersData = _a.data, isFilterLoading = _a.isLoading;
    var report = useMutation('operatorReport', operatorReport.get);
    var exportReport = useMutation('exportData', operatorReport.exportReport, {
        onSuccess: function (data) {
            var success = data.success;
            if (success) {
                notification.success({
                    message: 'Отчет подготавливается',
                    description: 'По окончанию подготовки отчет будет доступен для скачивания в разделе "Архив"',
                });
            }
            else {
                notification.error({
                    message: 'Упс',
                    description: 'Что-то пошло не так',
                });
            }
        },
    });
    // const handlePage = (page: number) => {
    //   const newFilterParams = { ...filterParams };
    //   const { limit } = newFilterParams;
    //   setFilterParams((oldData) => ({
    //     ...oldData,
    //     offset: page * Number(limit) - Number(limit),
    //   }));
    // };
    //
    // const handlePerPage = (perPage: number) => {
    //   setFilterParams((oldData) => ({ ...oldData, limit: perPage }));
    // };
    var applyFilters = function () {
        report.mutate(filterParams);
    };
    var exportData = function () {
        exportReport.mutate({ filterParams: filterParams, columns: columns });
    };
    useEffect(function () {
        report.mutate(filterParams);
    }, [filterParams.limit, filterParams.offset]);
    if (isFilterLoading) {
        return <Loader />;
    }
    return (<>
      <ReportFilters applyFilters={applyFilters} filtersData={filtersData} filterParams={filterParams} setFilterParams={setFilterParams} showFilters={{
            main: ['reportRange', 'operators'],
            others: [
                'actionFilters',
                'servedfilters',
                'workTimeFilter',
                'markFilters',
            ],
            orders: [
                'user_fio',
                'all_cnt',
                'close_cnt',
                'neyavka_cnt',
                'total_work_time',
                'avg_work_time',
                'share_cnt',
                'solved_questions',
                'mark1',
                'mark2',
                'mark3',
                'mark4',
                'mark5',
                'markcnt',
            ],
        }}/>
      <p className="table-title">Отчёт</p>
      {/*<Table*/}
      {/*  valueKey="operator"*/}
      {/*  columns={columns}*/}
      {/*  isLoading={report.isLoading}*/}
      {/*  data={report.data}*/}
      {/*  headerStyle={{ fontSize: 14 }}*/}
      {/*  cellStyle={{ fontSize: 12 }}*/}
      {/*  handlePage={handlePage}*/}
      {/*  handlePerPage={handlePerPage}*/}
      {/*  count={Number(*/}
      {/*    report.data && report.data.length*/}
      {/*      ? report.data[0].total_rows_count*/}
      {/*      : null,*/}
      {/*  )}*/}
      {/*/>*/}
      <ExportBtn onClick={exportData}/>
      <AntTableWrapper rowKey="operator" columns={columns} data={report.data} scroll={{ x: 2850 }} loading={report.isLoading}/>
    </>);
};
export default Operators;
