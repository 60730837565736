import React, { useEffect, useState } from 'react';
import Input from '@/components/input/input';
import Select from '@/components/input/select';
import { defaultSelectOptions } from '@/pages/statistics/reports/filters/filterParametrs';
import { FilterStore } from '@/store';
var ServedTimeFilters = function (_a) {
    var _b, _c;
    var handleSetFilters = _a.handleSetFilters;
    var filterParams = FilterStore.useState(function (s) { return s.initialParams; });
    var _d = useState(filterParams['total_wait_time'].split(' ')[0] === 'all'
        ? ''
        : filterParams['total_wait_time'].split(' ')[0]), waitType = _d[0], setWaitType = _d[1];
    var _e = useState(filterParams['total_proc_time'].split(' ')[0] === 'all'
        ? ''
        : filterParams['total_proc_time'].split(' ')[0]), procType = _e[0], setProcType = _e[1];
    var _f = useState(filterParams['total_wait_time'].split(' ')[1]), waitTime = _f[0], setWaitTime = _f[1];
    var _g = useState(filterParams['total_proc_time'].split(' ')[1]), procTime = _g[0], setProcTime = _g[1];
    var _h = useState(waitType !== ''
        ? (_b = defaultSelectOptions.find(function (el) { return el.value === waitType; })) === null || _b === void 0 ? void 0 : _b.name
        : ''), waitTitle = _h[0], setWaitTitle = _h[1];
    var _j = useState(procType !== ''
        ? (_c = defaultSelectOptions.find(function (el) { return el.value === procType; })) === null || _c === void 0 ? void 0 : _c.name
        : ''), procTitle = _j[0], setProcTitle = _j[1];
    var _k = useState(''), waitSecondaryTime = _k[0], setWaitSecondaryTime = _k[1];
    var _l = useState(''), procSecondaryTime = _l[0], setProcSecondaryTime = _l[1];
    var handleSelect = function (_a) {
        var option = _a.option, name = _a.name;
        if (name === 'total_wait_time') {
            if (option.name === 'Отменить') {
                setWaitTitle('');
                setWaitType(option.value);
                setWaitTime('');
                setWaitSecondaryTime('');
                handleSetFilters(name, 'all');
                return;
            }
            setWaitTitle(option.name);
            setWaitType(option.value);
        }
        else if (name === 'total_proc_time') {
            if (option.name === 'Отменить') {
                setProcTitle('');
                setProcType(option.value);
                setProcTime('');
                setProcSecondaryTime('');
                handleSetFilters(name, 'all');
                return;
            }
            setProcTitle(option.name);
            setProcType(option.value);
        }
    };
    var handleInput = function (e) {
        var _a = e.target, value = _a.value, name = _a.name;
        var str;
        switch (name) {
            case 'total_wait_time':
                setWaitTime(value);
                str = "".concat(waitType, " ").concat(value);
                handleSetFilters(name, str);
                break;
            case 'total_proc_time':
                setProcTime(value);
                str = "".concat(procType, " ").concat(value);
                handleSetFilters(name, str);
                break;
            case 'secondary_wait_time':
                setWaitSecondaryTime(value);
                break;
            case 'secondary_proc_time':
                setProcSecondaryTime(value);
        }
    };
    useEffect(function () {
        if (waitSecondaryTime !== '') {
            var str = "> ".concat(waitTime, " *60 * interval '1 sec' \n and total_wait_time::time < ").concat(waitSecondaryTime);
            handleSetFilters('total_wait_time', str);
        }
    }, [waitSecondaryTime]);
    useEffect(function () {
        if (procSecondaryTime !== '') {
            var str = "> ".concat(procTime, " *60 * interval '1 sec' \n and total_proc_time::time < ").concat(procSecondaryTime);
            handleSetFilters('total_proc_time', str);
        }
    }, [procSecondaryTime]);
    useEffect(function () {
        if (waitTime !== '') {
            var str = "".concat(waitType, " ").concat(waitTime);
            handleSetFilters('total_wait_time', str);
        }
        if (procTime !== '') {
            var str = "".concat(procType, " ").concat(procTime);
            handleSetFilters('total_proc_time', str);
        }
    }, [waitType, procType]);
    return (<div className="report_filters__box">
      <p className="report_filters__title">Длительность</p>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Ожидания</p>
        <Select options={defaultSelectOptions} width={270} placeholder="Выбрать" name="total_wait_time" onChange={handleSelect} value={waitTitle}/>
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Количество минут</p>
        <Input name="total_wait_time" type="number" onChange={handleInput} value={waitTime} disabled={waitTitle === ''} width={waitType === 'range' ? 100 : 270}/>
        {waitType === 'range' && (<div className="report_filters__input">
            <p className="report_filters__input-text">и</p>
            <Input width={100} name="secondary_wait_time" type="number" onChange={handleInput} value={waitSecondaryTime}/>
          </div>)}
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Обслуживания</p>
        <Select width={270} options={defaultSelectOptions} placeholder="Выбрать" name="total_proc_time" onChange={handleSelect} value={procTitle}/>
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Количество минут</p>
        <Input name="total_proc_time" type="number" onChange={handleInput} value={procTime} disabled={procTitle === ''} width={procType === 'range' ? 100 : 270}/>
        {procType === 'range' && (<div className="report_filters__input">
            <p className="report_filters__input-text">и</p>
            <Input width={100} name="secondary_proc_time" type="number" onChange={handleInput} value={procSecondaryTime}/>
          </div>)}
      </div>
    </div>);
};
export default ServedTimeFilters;
