import React, { useState } from 'react';
import { Drawer } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { ReactComponent as Edit } from '@/assets/images/table-edit.svg';
import { ReactComponent as Trash } from '@/assets/images/table-trash.svg';
import AddBtn from '@/components/button/addBtn';
import FormDelete from '@/components/formDelete';
import Loader from '@/components/loader';
import Modal from '@/components/modal';
import AntTableWrapper from '@/components/table/antTableWrapper';
import PollsEdit from '@/pages/services/tabs/servicePolls/pollsEdit';
import texts from '@/pages/services/tabs/texts.json';
import { polls } from '@/utils/rest/pages/services';
import './servicepolls.css';
var ServicePolls = function () {
    var _a = useState([]), currentRow = _a[0], setCurrentRow = _a[1];
    var _b = useState(), action = _b[0], setAction = _b[1];
    var _c = useState(false), showAdd = _c[0], setShowAdd = _c[1];
    var _d = useQuery('get polls', polls.get), isLoading = _d.isLoading, pollsData = _d.data, refetch = _d.refetch;
    var deleteMutation = useMutation(polls.delete, {
        onSuccess: function () {
            setCurrentRow([]);
            refetch();
        },
    });
    if (!isLoading) {
        pollsData === null || pollsData === void 0 ? void 0 : pollsData.forEach(function (el, idx) {
            el['key'] = idx;
        });
    }
    var columns = [
        {
            dataIndex: 'surveyname',
            title: 'Наименование',
            width: '30%',
        },
        {
            dataIndex: 'questiontext',
            title: 'Вопросы',
            align: 'center',
            render: function (_, res) {
                var _a;
                return (_a = res.connectedQuestions) === null || _a === void 0 ? void 0 : _a.map(function (question) { return (<span className="connected-question" key={question.id}>
            {question.questiontext}
          </span>); });
            },
        },
        {
            dataIndex: 'edit',
            width: 55,
            align: 'center',
            render: function (_, res) { return (<button onClick={function () {
                    setShowAdd(true);
                    setCurrentRow([res]);
                }}>
          <Edit />
        </button>); },
        },
        {
            dataIndex: 'delete',
            width: 55,
            align: 'center',
            render: function (_, res) { return (<button onClick={function () {
                    setCurrentRow([res]);
                    setAction('delete');
                }}>
          <Trash />
        </button>); },
        },
    ];
    var handleShow = function () { return setShowAdd(function (prevState) { return !prevState; }); };
    var handleCancel = function () {
        setCurrentRow([]);
        setShowAdd(false);
    };
    var closeDrawer = function () {
        setShowAdd(false);
        setCurrentRow([]);
    };
    // const filterHandler = (e: React.ChangeEvent<HTMLInputElement>) =>
    //   setFilter(e.target.value);
    var handleDelete = function () {
        if (currentRow.length !== 0) {
            deleteMutation.mutate({ id: Number(currentRow[0].id) });
            setAction(undefined);
        }
    };
    if (isLoading) {
        return <Loader />;
    }
    return (<>
      <div className="service-polls">
        <Modal clean open={action === 'delete'}>
          <FormDelete handleCancel={function () { return setAction(undefined); }} handleSubmit={handleDelete}/>
        </Modal>
        <div className="service-polls__top">
          <AddBtn handleClick={handleShow}/>
        </div>
      </div>
      <p className="section-title">{texts.pollsTitle}</p>
      <Drawer width={620} placement="right" onClose={closeDrawer} open={showAdd} destroyOnClose={true}>
        <PollsEdit handleCancel={handleCancel} isOpen={showAdd} refetch={refetch} pollsQuestion={false} data={currentRow}/>
      </Drawer>
      <AntTableWrapper columns={columns} data={pollsData} rowKey={'key'} loading={isLoading} bordered={false}/>
      <div />
    </>);
};
export default ServicePolls;
