import * as React from 'react';
import { render } from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import './index.css';
import Routes from './routes';
import '@/utils/i18n';
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});
// uncomment to work with mocks
// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser');
//   worker.start({ onUnhandledRequest: 'bypass' });
// }
render(<QueryClientProvider client={queryClient}>
    <Routes />
  </QueryClientProvider>, document.getElementById('root'));
