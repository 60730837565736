import React from 'react';
import { useParams } from 'react-router';
import Tabs from '@/components/tabs';
import tabs from './utils/tabs-nav';
import './content.css';
var Content = function () {
    var tab = useParams().tab;
    var currentTab = tabs.find(function (item) { return item.id.includes(tab); });
    return (<div className="content">
      <Tabs data={tabs} activeTab={currentTab}/>
      {currentTab === null || currentTab === void 0 ? void 0 : currentTab.component}
    </div>);
};
export default Content;
