var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import moment from 'moment/moment';
import { useMutation, useQueryClient } from 'react-query';
import Button from '@/components/button';
import Checkbox from '@/components/input/checkbox';
import Input from '@/components/input/input';
import Loader from '@/components/loader';
import { intervals } from '@/utils/rest';
var IntervalEdit = function (_a) {
    var data = _a.data, handleCancel = _a.handleCancel, refetch = _a.refetch, _b = _a.background, background = _b === void 0 ? 'transparent' : _b, handleAdd = _a.handleAdd;
    var queryClient = useQueryClient();
    var _c = useState(__assign({ name: '', description: '', status: 1, start: "".concat(moment(data === null || data === void 0 ? void 0 : data.timebegin, 'YYYY.MM.DD HH:mm:ss').format('HH:mm')), end: "".concat(moment(data === null || data === void 0 ? void 0 : data.timeend, 'YYYY.MM.DD HH:mm:ss').format('HH:mm')) }, data)), formData = _c[0], setFormData = _c[1];
    var interval = useMutation('interval', intervals.add, {
        onSuccess: function () {
            if (refetch) {
                refetch();
            }
        },
    });
    var intervalUpdate = useMutation(intervals.update, {
        onSuccess: function (data) {
            var intervals = queryClient.getQueryData('intervals');
            if (intervals) {
                var newIntervals = intervals.map(function (item) {
                    if (item.id === data.updatedId) {
                        return __assign(__assign({}, item), formData);
                    }
                    return item;
                });
                queryClient.setQueryData('intervals', newIntervals);
            }
            if (refetch) {
                refetch();
            }
        },
    });
    var name = formData.name, description = formData.description, status = formData.status, start = formData.start, end = formData.end;
    var handleInput = function (e) {
        var _a = e.target, name = _a.name, value = _a.value, checked = _a.checked;
        if (name === 'status') {
            setFormData(function (oldData) { return (__assign(__assign({}, oldData), { status: checked ? 2 : 1 })); });
        }
        else {
            setFormData(function (oldData) {
                var _a;
                return (__assign(__assign({}, oldData), (_a = {}, _a[name] = value, _a)));
            });
        }
    };
    var handleSubmit = function () {
        var newData = {
            description: description,
            name: name,
            status: status,
            timebegin: start,
            timeend: end,
        };
        if (data) {
            intervalUpdate.mutate({ id: data.id, payload: newData });
        }
        else {
            interval.mutate(newData);
        }
        if (handleAdd) {
            handleAdd();
        }
        handleCancel();
    };
    return interval.isLoading || intervalUpdate.isLoading ? (<Loader />) : (<div className="editable-item__form">
      {!!data && <p className="editable-item__title">Редактирование услуги</p>}
      <div className={background === 'transparent'
            ? 'editable-item__form-row'
            : 'editable-item__form-row editable-item__form-row_transparent'}>
        <div className="drawerStyle">
          <Input name="name" placeholder="Введите название" text="Наименование" value={name} onChange={handleInput} width={380}/>
          <Input name="description" placeholder="Введите название" text="Описание" value={description} onChange={handleInput} width={380}/>
          <Checkbox name="status" text="Включен" title="Статус" value={status !== 1} onChange={handleInput}/>
        </div>
      </div>
      <div className={background === 'transparent'
            ? 'editable-item__form-row'
            : 'editable-item__form-row editable-item__form-row_transparent'}>
        <div className="drawerStyle">
          <div className="interval-edit__interval">
            <p className="interval-edit__interval-title">Интервал</p>
            <div className="interval-edit__intervals-inputs">
              <div className="interval-edit__intervals-input">
                <p className="interval-edit__intervals-input-title">Начало</p>
                <Input name="start" placeholder="Введите название" value={start} onChange={handleInput} width={100} type="time"/>
              </div>
              <div className="interval-edit__intervals-input">
                <p className="interval-edit__intervals-input-title">
                  Окончание
                </p>
                <Input name="end" value={end} onChange={handleInput} width={100} type="time"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="editable-item__form-btns">
        <Button text="Сохранить" onClick={handleSubmit} type="submit"/>
        {/* {!!data && <Button text="Отменить" onClick={handleCancel} />} */}
      </div>
    </div>);
};
export default IntervalEdit;
