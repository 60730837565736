var debug = process.env.NODE_ENV === 'development';
var host = debug ? 'vcdev.a-7.tech' : window.location.hostname;
export var apiPublic = "https://".concat(host, "/server/public");
export var apiRoot = "https://".concat(host);
export var api = "https://".concat(host, "/server/api");
// export const apiPublic = `https://localhost:3030/server/public`;
// export const apiRoot = `https://localhost:3030`;
// export const api = `https://localhost:3030/server/api`;
// export const apiPublic = `https://vcdev.a-7.tech/server/public`;
// export const apiRoot = `https://vcdev.a-7.tech`;
// export const api = `https://vcdev.a-7.tech/server/api`;
// export const apiPublic = `https://vccloud1.a-7.tech/server/public`;
// export const apiRoot = `https://vccloud1.a-7.tech`;
// export const api = `https://vccloud1.a-7.tech/server/api`;
// export const apiPublic = `https://vccloud2.a-7.tech/server/public`;
// export const apiRoot = `https://vccloud2.a-7.tech`;
// export const api = `https://vccloud2.a-7.tech/server/api`;
// export const apiPublic = `https://vccloud0.a-7.tech/server/public`;
// export const apiRoot = `https://vccloud0.a-7.tech`;
// export const api = `https://vccloud0.a-7.tech/server/api`;
