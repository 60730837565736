var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { Drawer } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { ReactComponent as StatusFalse } from '@/assets/images/interval-status-false.svg';
import { ReactComponent as StatusTrue } from '@/assets/images/interval-status-true.svg';
import { ReactComponent as Edit } from '@/assets/images/table-edit.svg';
import { ReactComponent as Trash } from '@/assets/images/table-trash.svg';
import AddBtn from '@/components/button/addBtn';
import DeleteBtn from '@/components/button/deleteBtn';
import FormDelete from '@/components/formDelete';
import Loader from '@/components/loader';
import Modal from '@/components/modal';
import AntTableWrapper from '@/components/table/antTableWrapper';
import { getColumnSearchProps } from '@/pages/services/tabs/servicePolls/helpers/utils';
import { services } from '@/utils/rest';
import { users } from '@/utils/rest/pages/services';
import { workplaces, zones } from '@/utils/rest/pages/workplaces';
import texts from './texts.json';
import WorkplaceEdit from './workplaceEdit';
import './workplaces.css';
var Workplaces = function () {
    var _a = useQuery('workplaces', workplaces.get), isLoading = _a.isLoading, data = _a.data, refetch = _a.refetch;
    var _b = useQuery('zones', zones.get), isLoadingZones = _b.isLoading, zoneData = _b.data;
    var _c = useState(undefined), currentRow = _c[0], setCurrentRow = _c[1];
    var _d = useState(), action = _d[0], setAction = _d[1];
    var _e = useState(false), showAdd = _e[0], setShowAdd = _e[1];
    var deleteMutation = useMutation(services.wplaceDelete, {
        onSuccess: function () {
            setCurrentRow(undefined);
            refetch();
        },
    });
    var userUpdate = useMutation(users.update, {
        onSuccess: function () {
            refetch();
        },
    });
    var getUser = useMutation(users.getUser, {
        onSuccess: function (data) {
            var userData = {
                id: data.id,
                fio: data.fio,
                username: data.username,
                hidden: data.hidden,
                blocked: data.blocked,
                connectedQueues: data.connectedQueues,
                connectedRoles: data.connectedRoles,
                default_wplace: null,
            };
            userUpdate.mutate({ payload: userData, id: data.id });
        },
    });
    var deleteUser = function (rec) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (rec.bound_user_id) {
                getUser.mutate(rec.bound_user_id);
            }
            return [2 /*return*/];
        });
    }); };
    if (!isLoading) {
        data === null || data === void 0 ? void 0 : data.forEach(function (el, idx) {
            el['key'] = idx;
        });
    }
    var columns = [
        __assign({ dataIndex: 'id', title: 'ID', align: 'center', width: 100 }, getColumnSearchProps('id', 'по ID')),
        __assign({ dataIndex: 'name', title: 'Название' }, getColumnSearchProps('name', 'по названию')),
        __assign({ dataIndex: 'placename', title: 'Обозначение', align: 'center' }, getColumnSearchProps('placename', 'по обозначению')),
        __assign({ dataIndex: 'bound_user_fio', title: 'Привязано к оператору', align: 'center', render: function (_, rec) {
                return rec.bound_user_fio ? rec.bound_user_fio : 'Оператор не привязан';
            } }, getColumnSearchProps('bound_user_fio', 'по пользователю')),
        {
            dataIndex: 'connectedZones',
            title: 'Подключенная зона',
            align: 'center',
            render: function (_, res) {
                var _a;
                return (_a = zoneData === null || zoneData === void 0 ? void 0 : zoneData.find(function (zone) { return res.connectedZones && zone.id === res.connectedZones[0]; })) === null || _a === void 0 ? void 0 : _a.name;
            },
        },
        {
            dataIndex: 'disabled',
            title: 'Статус',
            align: 'center',
            render: function (_, res) { return (res.disabled ? <StatusFalse /> : <StatusTrue />); },
        },
        {
            dataIndex: 'deleteUser',
            align: 'center',
            render: function (_, rec) {
                return (<DeleteBtn text={'Отвязать оператора'} handleClick={function () { return deleteUser(rec); }}/>);
            },
        },
        {
            dataIndex: 'edit',
            align: 'center',
            width: 55,
            render: function (_, rec) { return (<button onClick={function () {
                    setCurrentRow(rec);
                    setShowAdd(true);
                }}>
          <Edit />
        </button>); },
        },
        {
            dataIndex: 'delete',
            align: 'center',
            width: 55,
            render: function (_, res) { return (<button onClick={function () {
                    setCurrentRow(res);
                    setAction('delete');
                }}>
          <Trash />
        </button>); },
        },
    ];
    var handleCancel = function () {
        setCurrentRow(undefined);
        setShowAdd(false);
    };
    var handleAdd = function () {
        setShowAdd(function (oldVal) { return !oldVal; });
    };
    var handleDelete = function () {
        if (currentRow) {
            deleteMutation.mutate(Number(currentRow.id));
            setAction(undefined);
        }
    };
    if (isLoading || isLoadingZones) {
        return <Loader />;
    }
    return (<div className="workplaces">
      <Modal clean open={action === 'delete'}>
        <FormDelete handleCancel={function () { return setAction(undefined); }} handleSubmit={handleDelete}/>
      </Modal>
      <AddBtn handleClick={handleAdd}/>
      <Drawer width={840} placement="right" onClose={handleCancel} open={showAdd} destroyOnClose={true}>
        <WorkplaceEdit handleCancel={handleCancel} isOpen={showAdd} refetch={refetch} data={currentRow}/>
      </Drawer>
      <p className="section-title">{texts.title}</p>
      <AntTableWrapper columns={columns} data={data} rowKey={'key'} loading={isLoading} bordered={false}/>
    </div>);
};
export default Workplaces;
