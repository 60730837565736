import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Alert from '@/components/alert';
import Button from '@/components/button';
import ContentInput from '@/components/contentInput/contentInput';
import Input from '@/components/input/input';
import Loader from '@/components/loader';
import Modal from '@/components/modal';
import Table from '@/components/table';
import { getFileExtension, getFontColumns, } from '@/pages/system/content/utils/utils';
import { content } from '@/utils/rest/pages/system';
var Fonts = function () {
    var _a = useQuery('fonts', content.getFonts), isLoading = _a.isLoading, data = _a.data, refetch = _a.refetch;
    var _b = useState(''), text = _b[0], setText = _b[1];
    var _c = useState(), file = _c[0], setFile = _c[1];
    var _d = useState(false), modal = _d[0], setModal = _d[1];
    var _e = useState('Неверный формат файла'), errorText = _e[0], setErrorText = _e[1];
    var deleteHandler = function (item) { return deleteFont.mutate(item.name); };
    var columns = getFontColumns(deleteHandler);
    var deleteFont = useMutation(content.deleteFont, {
        onSuccess: function () { return refetch(); },
        onError: function (_a) {
            var _b;
            var response = _a.response;
            if (response) {
                setErrorText((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.error);
                setModal(true);
            }
        },
    });
    var uploadFont = useMutation(content.addFonts, {
        onSuccess: function () {
            refetch();
            setFile(undefined);
            setText('');
        },
        onError: function (_a) {
            var _b;
            var response = _a.response;
            if (response) {
                setErrorText((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.error);
                setModal(true);
            }
        },
    });
    var fileInputHandler = function (e) {
        e.target.files && setFile(e.target.files[0]);
    };
    var submitHandler = function () {
        var fileExtension = file && getFileExtension(file.name);
        var extensions = [
            'ttf',
            'sfnt',
            'otf',
            'woff',
            'woff2',
        ];
        if (!extensions.includes(fileExtension)) {
            setFile(undefined);
            setModal(true);
            return;
        }
        return file && text && uploadFont.mutate({ file: file, text: text });
    };
    return (<div className="content__box">
      <Modal clean={true} open={modal}>
        <Alert text={errorText} handleSubmit={function () {
            setModal(false);
            setErrorText('Неверный формат файла');
        }}/>
      </Modal>
      <div className="content__top">
        {uploadFont.isLoading ? (<Loader />) : (<>
            <div className="content__left">
              <Input text="Введите название шрифта" value={text} onChange={function (e) { return setText(e.target.value); }}/>
              <Button disabled={Boolean(!text)} text="Загрузить шрифт" onClick={submitHandler}/>
            </div>
            <div className="content__right">
              <ContentInput isShown={Boolean(text)} onChange={fileInputHandler} text="шрифт" content={file} accept={'.ttf, .sfnt, .otf, .woff, .woff2'}/>
            </div>
          </>)}
      </div>
      <div className="content__bottom">
        <p className="section-title">Список загруженных шрифтов</p>
        <Table data={data} columns={columns} isLoading={isLoading} headerStyle={{ fontSize: 14 }} cellStyle={{ fontSize: 12 }}/>
      </div>
    </div>);
};
export default Fonts;
