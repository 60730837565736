var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Drawer } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { ReactComponent as Edit } from '@/assets/images/table-edit.svg';
import { ReactComponent as Trash } from '@/assets/images/table-trash.svg';
import AddBtn from '@/components/button/addBtn';
import FormDelete from '@/components/formDelete';
import Modal from '@/components/modal';
import AntTableWrapper from '@/components/table/antTableWrapper';
import { getColumnSearchProps } from '@/pages/services/tabs/servicePolls/helpers/utils';
import texts from '@/pages/services/tabs/texts.json';
import { formatDate } from '@/utils';
import { days, intervals } from '@/utils/rest';
import DayEdit from './dayEdit';
import '../schedule.css';
var Days = function () {
    var _a = useQuery('days', days.get), data = _a.data, refetch = _a.refetch, isLoading = _a.isLoading;
    var _b = useQuery('intervals', intervals.get), intervalsData = _b.data, isLoadingIntervals = _b.isLoading;
    var _c = useState(undefined), currentRow = _c[0], setCurrentRow = _c[1];
    var _d = useState(), action = _d[0], setAction = _d[1];
    var _e = useState(false), showAdd = _e[0], setShowAdd = _e[1];
    var deleteDay = useMutation('deleteDay', days.delete, {
        onSuccess: function () {
            refetch();
        },
    });
    if (!isLoading) {
        data === null || data === void 0 ? void 0 : data.forEach(function (el, idx) {
            el['key'] = idx;
        });
    }
    var columns = [
        __assign({ dataIndex: 'name', title: 'Наименование' }, getColumnSearchProps('name', 'по наименованию')),
        __assign({ dataIndex: 'description', title: 'Описание' }, getColumnSearchProps('description', 'по описанию')),
        {
            dataIndex: 'modified_by_username',
            title: 'Изменен',
            align: 'center',
        },
        {
            dataIndex: 'modified_on',
            title: 'Дата изменения',
            align: 'center',
            render: function (_, res) {
                return (<span>
            {formatDate(String(res.modified_on), 'DD.MM.YYYY HH:mm:ss')}
          </span>);
            },
        },
        {
            dataIndex: 'workIntervals',
            title: 'Подключенные интервалы',
            align: 'center',
            render: function (_, res) {
                return (<p>
            {res.workIntervals &&
                        res.workIntervals
                            .map(function (item) {
                            var _a;
                            return (_a = intervalsData === null || intervalsData === void 0 ? void 0 : intervalsData.find(function (interval) { return interval.id === item; })) === null || _a === void 0 ? void 0 : _a.name;
                        })
                            .join(', ')}
          </p>);
            },
        },
        {
            dataIndex: 'edit',
            align: 'center',
            width: 55,
            render: function (_, res) { return (<button onClick={function () {
                    setShowAdd(true);
                    setCurrentRow(res);
                }}>
          <Edit />
        </button>); },
        },
        {
            dataIndex: 'delete',
            align: 'center',
            width: 55,
            render: function (_, res) { return (<button onClick={function () {
                    setCurrentRow(res);
                    setAction('delete');
                }}>
          <Trash />
        </button>); },
        },
    ];
    var handleAdd = function () {
        setShowAdd(function (oldValue) { return !oldValue; });
    };
    var handleCancel = function () {
        setCurrentRow(undefined);
        setShowAdd(false);
    };
    var handleDelete = function () {
        if (currentRow) {
            deleteDay.mutate(Number(currentRow.id));
            setCurrentRow(undefined);
            setAction(undefined);
        }
    };
    return (<div className="intervals">
      <Modal clean open={action === 'delete'}>
        <FormDelete handleCancel={function () { return setAction(undefined); }} handleSubmit={handleDelete}/>
      </Modal>
      <AddBtn handleClick={handleAdd}/>
      <Drawer width={820} placement="right" onClose={handleCancel} open={showAdd} destroyOnClose={true}>
        <DayEdit handleCancel={handleCancel} isOpen={showAdd} refetch={refetch} data={currentRow}/>
      </Drawer>
      <p className="section-title">{texts.daysTitle}</p>
      <AntTableWrapper columns={columns} data={data} rowKey={'key'} loading={isLoading || isLoadingIntervals} bordered={false}/>
    </div>);
};
export default Days;
