var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import './style.css';
import { Input, notification } from 'antd';
import { useMutation } from 'react-query';
import { ticketReport } from '@/utils/rest/pages/reports';
var BanForm = function (_a) {
    var handleCancel = _a.handleCancel, currentIP = _a.currentIP, refetch = _a.refetch, banned = _a.banned;
    var _b = useState({
        address: currentIP,
        private_comment: '',
        public_comment: '',
    }), banData = _b[0], setBanData = _b[1];
    var isBanned = false;
    if (banned.some(function (el) { return el.address === currentIP; })) {
        isBanned = true;
    }
    var banMutation = useMutation('banUser', ticketReport.banUser, {
        onSuccess: function (data) {
            if (data.error) {
                notification.error({
                    message: 'Что-то пошло не так',
                });
            }
            else {
                notification.success({
                    message: 'Клиент заблокирован',
                });
                refetch();
            }
        },
    });
    var unbanMutation = useMutation('unban', ticketReport.unbanUser, {
        onSuccess: function () {
            notification.success({
                message: 'Клиент разблокирован',
            });
            refetch();
        },
    });
    var handleInput = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setBanData(__assign(__assign({}, banData), (_a = {}, _a[name] = value, _a)));
    };
    var handleSubmit = function () {
        if (isBanned) {
            unbanMutation.mutate(currentIP);
            handleCancel();
        }
        else {
            banMutation.mutate(banData);
            handleCancel();
        }
    };
    useEffect(function () {
        if (isBanned) {
            var userInfo = banned.filter(function (el) { return el.address === currentIP; });
            setBanData(userInfo[0]);
        }
    }, [isBanned]);
    return (<div className="form-delete">
      <div className="form-delete__box">
        {isBanned ? (<p className="form-delete__title">
            Вы уверены, что хотите разблокировать клиента?
          </p>) : (<p className="form-delete__title">
            Вы уверены, что хотите заблокировать клиента?
          </p>)}
        <p className="form-delete__ip">IP адрес клиента: {currentIP}</p>
        <div className="form-delete__input">
          <p>Публичный комментарий (клиент получит это сообщение)</p>
          {isBanned ? (<p>
              {banData.public_comment.length > 0
                ? banData.public_comment
                : 'Нет комментария'}
            </p>) : (<Input size="large" onChange={handleInput} name="public_comment" placeholder="Введите комментарий..."/>)}
        </div>
        <div className="form-delete__input">
          <p>Внутренний комментарий (невиден клиенту)</p>
          {isBanned ? (<p>
              {banData.private_comment.length > 0
                ? banData.private_comment
                : 'Нет комментария'}
            </p>) : (<Input size="large" onChange={handleInput} name="private_comment" placeholder="Введите комментарий..."/>)}
        </div>
        <div className="form-delete__buttons">
          <button onClick={handleSubmit}>OK</button>
          <button onClick={handleCancel}>Отмена</button>
        </div>
      </div>
    </div>);
};
export default BanForm;
