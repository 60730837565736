var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Button from '@/components/button';
import Checkbox from '@/components/input/checkbox';
import Input from '@/components/input/input';
import Select from '@/components/input/select';
import Loader from '@/components/loader';
import MultiSelect from '@/components/multiSelect';
import { services, workplaces } from '@/utils/rest';
var WorkplaceEdit = function (_a) {
    var _b;
    var data = _a.data, handleCancel = _a.handleCancel, refetch = _a.refetch, _c = _a.background, background = _c === void 0 ? 'transparent' : _c;
    var queryClient = useQueryClient();
    var zones = queryClient.getQueryData('zones');
    var _d = useQuery('services', services.get), isLoading = _d.isLoading, servicesData = _d.data;
    var workPlaceAdd = useMutation('workplace', workplaces.add, {
        onSuccess: function () {
            if (refetch) {
                refetch();
            }
        },
    });
    var workPlaceUpdate = useMutation('workplace', workplaces.update, {
        onSuccess: function (data) {
            var workplaces = queryClient.getQueryData('workplaces');
            if (workplaces) {
                var newWorkplaces = workplaces.map(function (item) {
                    if (item.id === data.data.updatedId) {
                        return __assign(__assign({}, item), { name: name, description: description, placename: [list1, list2, list3].filter(Boolean).join(','), connectedZones: [zone.value], connectedQueues: selectedServices, disabled: disabled });
                    }
                    return item;
                });
                queryClient.setQueryData('workplaces', newWorkplaces);
            }
        },
    });
    var _e = useState({
        name: (data === null || data === void 0 ? void 0 : data.name) || '',
        description: (data === null || data === void 0 ? void 0 : data.description) || '',
        zone: {
            name: '',
            value: (data === null || data === void 0 ? void 0 : data.connectedZones[0]) || -1,
        },
        list1: (data === null || data === void 0 ? void 0 : data.placename.split(',')[0]) || '',
        list2: (data === null || data === void 0 ? void 0 : data.placename.split(',')[1]) || '',
        list3: (data === null || data === void 0 ? void 0 : data.placename.split(',')[2]) || '',
        disabled: !!(data === null || data === void 0 ? void 0 : data.disabled),
        selectedServices: (data === null || data === void 0 ? void 0 : data.connectedQueues) || [],
    }), formData = _e[0], setFormData = _e[1];
    var name = formData.name, description = formData.description, zone = formData.zone, list1 = formData.list1, list2 = formData.list2, list3 = formData.list3, disabled = formData.disabled, selectedServices = formData.selectedServices;
    var handleInput = function (e) {
        var _a = e.target, value = _a.value, name = _a.name;
        setFormData(function (oldData) {
            var _a;
            return (__assign(__assign({}, oldData), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleSelect = function (_a) {
        var option = _a.option, name = _a.name;
        setFormData(function (oldData) {
            var _a;
            return (__assign(__assign({}, oldData), (_a = {}, _a[name] = option, _a)));
        });
    };
    var handleCheckbox = function (e) {
        var _a = e.target, checked = _a.checked, name = _a.name;
        setFormData(function (oldData) {
            var _a;
            return (__assign(__assign({}, oldData), (_a = {}, _a[name] = checked, _a)));
        });
    };
    var handleCheck = function (serviceId) {
        var newServices = __spreadArray([], selectedServices, true);
        if (serviceId === -1) {
            newServices = (servicesData === null || servicesData === void 0 ? void 0 : servicesData.map(function (item) { return item.id; })) || [];
        }
        else if (serviceId === -2) {
            newServices = [];
        }
        else if (selectedServices.includes(serviceId)) {
            newServices = newServices === null || newServices === void 0 ? void 0 : newServices.filter(function (item) { return item !== serviceId; });
        }
        else {
            newServices === null || newServices === void 0 ? void 0 : newServices.push(serviceId);
        }
        setFormData(function (oldData) { return (__assign(__assign({}, oldData), { selectedServices: newServices })); });
    };
    var handleSubmit = function (e) {
        e.preventDefault();
        var payload = {
            data: {
                name: name,
                description: description,
                placename: [list1, list2, list3].filter(Boolean).join(','),
                connectedZones: [zone.value],
                connectedQueues: selectedServices,
                dictionary: { filtersA: [] },
                soundtext: '',
                disabled: disabled,
            },
        };
        if (data) {
            workPlaceUpdate.mutate({
                id: data.id,
                payload: payload,
            });
        }
        else {
            workPlaceAdd.mutate({
                payload: payload,
            });
        }
        handleCancel();
    };
    return isLoading ? (<Loader />) : (<div className="editable-item__form">
      {!!data && (<p className="editable-item__title">{'Редактирование услуги'}</p>)}
      <div className={background === 'transparent'
            ? 'editable-item__form-row'
            : 'editable-item__form-row editable-item__form-row_transparent'}>
        <div className="drawerStyle">
          <Input text="Название" placeholder="Введите название" value={name} name="name" onChange={handleInput} width={280}/>
          <Input text="Описание" placeholder="Короткое описание " value={description} name="description" onChange={handleInput} width={280}/>
          <Select text="Подключенная зона обслуживания" placeholder="Выберите зону обслуживания" value={zone.name || ((_b = zones.find(function (item) { return item.id === zone.value; })) === null || _b === void 0 ? void 0 : _b.name)} name="zone" onChange={handleSelect} width={580} options={zones.map(function (zone) { return ({ name: zone.name, value: zone.id }); })}/>
        </div>
      </div>
      <div className={background === 'transparent'
            ? 'editable-item__form-row'
            : 'editable-item__form-row editable-item__form-row_transparent'}>
        <div className="drawerStyle">
          <Select type="dropdown" name="services" text="Услуги оказываемые рабочим местом" placeholder="Выберите услуги" width={380}>
            <MultiSelect handleCheck={handleCheck} selected={selectedServices} data={servicesData === null || servicesData === void 0 ? void 0 : servicesData.map(function (item) { return ({
            id: item.id,
            name: item.name,
        }); })}/>
          </Select>
        </div>
        <div className="drawerStyle">
          <Input text="Порядок сортировки списка 1" type="number" value={list1} name="list1" onChange={handleInput}/>
          <Input text="Порядок сортировки списка 2" type="number" value={list2} name="list2" onChange={handleInput}/>
          <Input text="Порядок сортировки списка 3" type="number" value={list3} name="list3" onChange={handleInput}/>
        </div>
      </div>
      <div className={background === 'transparent'
            ? 'editable-item__form-row'
            : 'editable-item__form-row editable-item__form-row_transparent'}>
        <div className="drawerStyle">
          <Checkbox name="disabled" value={disabled} onChange={handleCheckbox} text="Отключить рабочее место"/>
        </div>
      </div>
      <div className={background === 'transparent'
            ? 'editable-item__form-row'
            : 'editable-item__form-row editable-item__form-row_transparent'}></div>
      <div className="editable-item__form-btns">
        <Button text="Сохранить" onClick={handleSubmit} type="submit"/>
        {/* {!!data && <Button text="Отменить" onClick={handleCancel} />} */}
      </div>
    </div>);
};
export default WorkplaceEdit;
