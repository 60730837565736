var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'antd';
import ReportFilters from '@/pages/statistics/reports/filters/reportFilters';
import SqlQuery from '@/pages/statistics/reports/tabs/qualityControl/sqlQuery';
import { FilterStore } from '@/store';
import SessionResultTable from './expand/sessionResultTable';
import getTableColumns from './tableConfig';
var expandedRowRender = function (record) { return (<SessionResultTable sessionDataId={record.id}/>); };
var QualityControl = function () {
    var filterParams = FilterStore.useState(function (s) { return s.initialParams; });
    var _a = useState(), tableSettings = _a[0], setTableSettings = _a[1];
    var _b = useState({
        page: 1,
        sizePerPage: 10,
        totalSize: null,
        sortOrder: 'desc',
        filters: 'none',
    }), params = _b[0], setParams = _b[1];
    var _c = useState(), tableData = _c[0], setTableData = _c[1];
    var _d = useState(), columnsFiltersList = _d[0], setColumnsFiltersList = _d[1];
    var _e = useState({}), modalData = _e[0], setModalData = _e[1];
    var _f = useState(''), modalWndtitle = _f[0], setModalWndtitle = _f[1];
    var _g = useState(''), downloadLink = _g[0], setDownloadLink = _g[1];
    var _h = useState(false), isLoading = _h[0], setIsLoading = _h[1];
    var _j = useState(false), isModalOpen = _j[0], setIsModalOpen = _j[1];
    var handleCancel = function () {
        setIsModalOpen(false);
    };
    var handleSave = function () {
        console.log(downloadLink);
        // const link = document.createElement('a');
        // link.href = downloadLink;
        // link.click();
        // document.removeChild(link);
    };
    var getTableData = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!tableSettings) return [3 /*break*/, 2];
                    return [4 /*yield*/, SqlQuery(tableSettings.startDate, tableSettings.endDate).then(function (res) {
                            var data = res.data;
                            var uniqueSource = [];
                            var uniqueOperetors = [];
                            var sourceList = [];
                            var operatorList = [];
                            res.data.forEach(function (el) {
                                if (!uniqueSource.includes(el.source))
                                    uniqueSource.push(el.source);
                                if (!uniqueOperetors.includes(el.fio))
                                    uniqueOperetors.push(el.fio);
                            });
                            uniqueSource.forEach(function (el) {
                                sourceList.push({ text: el, value: el });
                            });
                            uniqueOperetors.forEach(function (el) {
                                operatorList.push({ text: el, value: el });
                            });
                            setColumnsFiltersList({ operatorList: operatorList, sourceList: sourceList });
                            setTableData(data);
                        })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var onSizePerPageChange = function (sizePerPageNumber, pageNumber) {
        setParams(function (v) { return (__assign(__assign({}, v), { sizePerPage: sizePerPageNumber, page: pageNumber })); });
    };
    var onPageChange = function (pageNumber, sizePerPageNumber) {
        setParams(function (v) { return (__assign(__assign({}, v), { sizePerPage: sizePerPageNumber, page: pageNumber })); });
    };
    var onTableChange = function (type, newState) {
        var sortOrder = newState.sortOrder;
        var page = newState.page, sizePerPage = newState.sizePerPage, filters = newState.filters;
        if (params.sortOrder === sortOrder && sortOrder === 'desc') {
            sortOrder = 'asc';
        }
        else {
            sortOrder = 'desc';
        }
        setParams(function (v) { return (__assign(__assign({}, v), { page: page, sizePerPage: sizePerPage, sortOrder: sortOrder, filters: filters })); });
    };
    var handleTableIcon = function (name, row) {
        if (name === 'photo') {
            setModalData(<img style={{ width: '100%' }} src={row.photo_b64} alt=""/>);
            setModalWndtitle('Фотография посетителя');
            setDownloadLink(row.photo_b64);
        }
        else if (name === 'micro') {
            setModalData(<audio style={{ width: '100%' }} controls>
          <source src={row.audiorecordpath}/>
        </audio>);
            setModalWndtitle('Аудио запись');
            setDownloadLink(row.audiorecordpath);
        }
        else if (name === 'video') {
            setModalData(<video controls style={{ width: '100%' }}>
          <source src={row.videorecordpath}/>
        </video>);
            setModalWndtitle('Видео запись');
            setDownloadLink(row.videorecordpath);
        }
        else if (name === 'comment') {
            var data = JSON.parse(row.textrecord);
            setModalData(<div>
          {data.map(function (dItem) { return (<div key={dItem.title}>
              <p>{dItem.title}</p>
              <p>{dItem.value}</p>
            </div>); })}
        </div>);
            setModalWndtitle('Комментарий посетителя');
        }
        setIsModalOpen(true);
    };
    var setFilterParams = function (newFilterParams) {
        FilterStore.update(function (s) {
            s.initialParams = newFilterParams;
        });
    };
    var applyFilters = function () {
        getTableData();
    };
    useEffect(function () {
        var pagination = {
            page: params.page,
            sizePerPage: params.sizePerPage,
            totalSize: params.totalSize,
            sortOrder: params.sortOrder,
            filters: params.filters,
            onPageChange: onPageChange,
            onSizePerPageChange: onSizePerPageChange,
            onTableChange: onTableChange,
            startDate: filterParams.startDate,
            endDate: filterParams.endDate,
        };
        setTableSettings(pagination);
    }, [params, filterParams]);
    useEffect(function () {
        setIsLoading(true);
        getTableData().then(function () {
            setIsLoading(false);
        });
    }, [tableSettings]);
    return (<div>
      <ReportFilters showFilters={{
            main: ['reportRange'],
            others: [],
            orders: [],
        }} filterParams={filterParams} setFilterParams={setFilterParams} applyFilters={applyFilters}/>
      <Modal title={modalWndtitle} open={isModalOpen} footer={modalWndtitle === 'Комментарий посетителя'
            ? [
                <Button key="close" onClick={handleCancel}>
                  Закрыть
                </Button>,
            ]
            : [
                <Button key="close" onClick={handleCancel}>
                  Закрыть
                </Button>,
                <Button key="save" onClick={handleSave}>
                  Сохранить
                </Button>,
            ]}>
        <>{modalData}</>
      </Modal>
      <Table columns={getTableColumns(columnsFiltersList, handleTableIcon)} rowKey={'id'} dataSource={tableData} bordered expandable={{
            expandedRowRender: expandedRowRender,
            rowExpandable: function (record) { return record.type === 'Опрос'; },
        }} loading={isLoading}/>
    </div>);
};
export default QualityControl;
