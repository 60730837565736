import moment from 'moment';
export var columns = [
    {
        title: 'Информация об услуге',
        fixed: 'left',
        children: [
            {
                title: 'Id',
                dataIndex: 'queue',
                key: 'queue',
                width: 70,
                fixed: 'left',
                sorter: function (a, b) { return Number(a.queue) - Number(b.queue); },
            },
            {
                title: 'Название',
                dataIndex: 'queue_name',
                key: 'queue_name',
                width: 250,
                fixed: 'left',
            },
        ],
    },
    {
        title: 'Обслужено',
        className: 'draggable',
        children: [
            {
                title: 'Закрыто',
                dataIndex: 'close_cnt',
                key: 'close_cnt',
                width: 110,
                sorter: function (a, b) { return Number(a.close_cnt) - Number(b.close_cnt); },
            },
            {
                title: 'Неявка',
                dataIndex: 'neyavka_cnt',
                key: 'neyavka_cnt',
                width: 110,
                sorter: function (a, b) { return Number(a.neyavka_cnt) - Number(b.neyavka_cnt); },
            },
            {
                title: 'Всего',
                dataIndex: 'all_cnt',
                key: 'all_cnt',
                width: 110,
                sorter: function (a, b) { return Number(a.all_cnt) - Number(b.all_cnt); },
            },
        ],
    },
    {
        title: 'Кол-во решенных вопросов',
        dataIndex: 'solved_questions',
        className: 'draggable',
        width: 150,
        sorter: function (a, b) { return Number(a.solved_questions) - Number(b.solved_questions); },
    },
    {
        title: 'Длительность',
        className: 'draggable',
        children: [
            {
                title: 'Ожидания',
                children: [
                    {
                        title: 'Средняя',
                        dataIndex: 'avg_wait_time',
                        width: 160,
                        sorter: function (a, b) {
                            var aToSec = moment(a.avg_wait_time, 'HH:mm:ss').format('x');
                            var bToSec = moment(b.avg_wait_time, 'HH:mm:ss').format('x');
                            return Number(aToSec) - Number(bToSec);
                        },
                    },
                    {
                        title: 'Общая',
                        dataIndex: 'total_wait_time',
                        width: 160,
                        sorter: function (a, b) {
                            var aToSec = moment(a.total_wait_time, 'HH:mm:ss').format('x');
                            var bToSec = moment(b.total_wait_time, 'HH:mm:ss').format('x');
                            return Number(aToSec) - Number(bToSec);
                        },
                    },
                    {
                        title: 'Максимальная',
                        dataIndex: 'maxtotalwait_format',
                        width: 160,
                        sorter: function (a, b) {
                            var aToSec = moment(a.maxtotalwait_format, 'HH:mm:ss').format('x');
                            var bToSec = moment(b.maxtotalwait_format, 'HH:mm:ss').format('x');
                            return Number(aToSec) - Number(bToSec);
                        },
                    },
                ],
            },
            {
                title: 'Oбслуживания',
                children: [
                    {
                        title: 'Средняя',
                        dataIndex: 'avg_proc_time',
                        width: 160,
                        sorter: function (a, b) {
                            var aToSec = moment(a.avg_proc_time, 'HH:mm:ss').format('x');
                            var bToSec = moment(b.avg_proc_time, 'HH:mm:ss').format('x');
                            return Number(aToSec) - Number(bToSec);
                        },
                    },
                    {
                        title: 'Общая',
                        dataIndex: 'total_proc_time',
                        width: 160,
                        sorter: function (a, b) {
                            var aToSec = moment(a.total_proc_time, 'HH:mm:ss').format('x');
                            var bToSec = moment(b.total_proc_time, 'HH:mm:ss').format('x');
                            return Number(aToSec) - Number(bToSec);
                        },
                    },
                    {
                        title: 'Максимальная',
                        dataIndex: 'maxtotalproc_format',
                        width: 160,
                        sorter: function (a, b) {
                            var aToSec = moment(a.maxtotalproc_format, 'HH:mm:ss').format('x');
                            var bToSec = moment(b.maxtotalproc_format, 'HH:mm:ss').format('x');
                            return Number(aToSec) - Number(bToSec);
                        },
                    },
                ],
            },
        ],
    },
    {
        title: 'Кол-во обработанных талонов с временем ожидания',
        className: 'draggable',
        children: [
            {
                title: '< 15 мин.',
                children: [
                    {
                        title: 'Кол-во',
                        dataIndex: 'less',
                        sorter: function (a, b) { return Number(a.less) - Number(b.less); },
                        width: 150,
                    },
                    {
                        title: 'Доля от обслуженных (%)',
                        dataIndex: 'lesspersent',
                        sorter: function (a, b) { return Number(a.lesspersent) - Number(b.lesspersent); },
                        width: 170,
                    },
                ],
            },
            {
                title: '15 мин.< и < 30 мин.',
                children: [
                    {
                        title: 'Кол-во',
                        dataIndex: 'range',
                        sorter: function (a, b) { return Number(a.range) - Number(b.range); },
                        width: 150,
                    },
                    {
                        title: 'Доля от обслуженных (%)',
                        dataIndex: 'rangepersent',
                        sorter: function (a, b) { return Number(a.rangepersent) - Number(b.rangepersent); },
                        width: 170,
                    },
                ],
            },
            {
                title: '> 30 мин.',
                children: [
                    {
                        title: 'Кол-во',
                        dataIndex: 'more',
                        sorter: function (a, b) { return Number(a.more) - Number(b.more); },
                        width: 150,
                    },
                    {
                        title: 'Доля от обслуженных (%)',
                        dataIndex: 'morepersent',
                        sorter: function (a, b) { return Number(a.morepersent) - Number(b.morepersent); },
                        width: 170,
                    },
                ],
            },
        ],
    },
];
