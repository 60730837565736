import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Alert from '@/components/alert';
import Button from '@/components/button';
import Input from '@/components/input/input';
import Loader from '@/components/loader';
import Modal from '@/components/modal';
import Table from '@/components/table';
import { getRunningLinesColumns } from '@/pages/system/content/utils/utils';
import { content } from '@/utils/rest/pages/system';
var RunningLines = function () {
    var _a = useQuery('rl', content.getRunningLines), isLoading = _a.isLoading, data = _a.data, refetch = _a.refetch;
    var _b = useState(''), text = _b[0], setText = _b[1];
    var _c = useState(false), modal = _c[0], setModal = _c[1];
    var _d = useState('Произошла ошибка'), errorText = _d[0], setErrorText = _d[1];
    var deletehandler = function (item) {
        deleteRunningLine.mutate(item.id);
    };
    var columns = getRunningLinesColumns(deletehandler);
    var deleteRunningLine = useMutation(content.deleteRunningLine, {
        onSuccess: function () { return refetch(); },
        onError: function (_a) {
            var _b;
            var response = _a.response;
            if (response) {
                setErrorText((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.error);
                setModal(true);
            }
        },
    });
    var uploadRunningLine = useMutation(content.addRunningLine, {
        onSuccess: function () {
            refetch();
            setText('');
        },
        onError: function (_a) {
            var _b;
            var response = _a.response;
            if (response) {
                setErrorText((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.error);
                setModal(true);
            }
        },
    });
    var submitHandler = function () {
        uploadRunningLine.mutate(text);
    };
    if (isLoading)
        return <Loader />;
    return (<div className="content__box">
      <Modal clean={true} open={modal}>
        <Alert text={errorText} handleSubmit={function () {
            setModal(false);
            setErrorText('Произошла ошибка');
        }}/>
      </Modal>
      <div className="content__top">
        {uploadRunningLine.isLoading ? (<Loader />) : (<div className="content__left content__left--news">
            <Input text="Введите текст бегущей строки" value={text} width={580} onChange={function (e) { return setText(e.target.value); }}/>
            <Button disabled={Boolean(!text)} text="Загрузить бегущую строку" onClick={submitHandler}/>
          </div>)}
      </div>
      <div className="content__bottom">
        <p className="section-title">Список загруженных бегущих строк</p>
        <Table data={data} columns={columns} isLoading={isLoading} headerStyle={{ fontSize: 14 }} cellStyle={{ fontSize: 12 }}/>
      </div>
    </div>);
};
export default RunningLines;
