import React from 'react';
import EditableItem from '@/components/editableItem';
import PollsQuestionEdit from '@/pages/services/tabs/servicePolls/pollsQuestionEdit';
var PollsQuestionExpand = function (_a) {
    var action = _a.action, data = _a.data, handleCancel = _a.handleCancel, background = _a.background, isOpen = _a.isOpen, refetch = _a.refetch;
    var renderService = function () {
        if (action === 'delete') {
            return null;
        }
        if (action === 'check') {
            return null;
        }
        if (action === 'edit') {
            return (<PollsQuestionEdit data={data} handleCancel={handleCancel} isOpen={isOpen} background={background} refetch={refetch}/>);
        }
    };
    return (<EditableItem isOpen={isOpen} cell={8} background={background}>
      {renderService()}
    </EditableItem>);
};
export default PollsQuestionExpand;
