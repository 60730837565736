import React from 'react';
import { ReactComponent as Check } from '@/assets/images/check.svg';
import { ReactComponent as Download } from '@/assets/images/download.svg';
import { ReactComponent as Trash } from '@/assets/images/table-trash.svg';
import { apiRoot } from 'config';
export var getFileExtension = function (name) { return name.split('.').pop(); };
export var getFontColumns = function (onClick) {
    var columns = [
        { field: 'name', title: 'Название', style: { textAlign: 'center' } },
        { field: 'file', title: 'Файл', style: { textAlign: 'center' } },
        {
            field: 'download',
            style: { width: '4%', cursor: 'pointer' },
            render: function (rowData) { return (<span>
          <a href={"".concat(apiRoot, "/api/fonts/download?name=").concat(rowData.name)}>
            <Download />
          </a>
        </span>); },
        },
        {
            field: 'delete',
            style: { width: '4%', cursor: 'pointer' },
            render: function (rowData) { return (<span onClick={function () { return onClick(rowData); }}>
          <Trash />
        </span>); },
        },
    ];
    return columns;
};
export var getNewsColumns = function (onClick) {
    var columns = [
        { field: 'title', title: 'Название', style: { textAlign: 'center' } },
        { field: 'text', title: 'Текст', style: { textAlign: 'center' } },
        {
            field: 'date',
            title: 'Дата',
            style: { textAlign: 'center' },
            render: function (rowData) { return <span>{rowData.date}</span>; },
        },
        {
            field: 'delete',
            style: { width: '4%', cursor: 'pointer' },
            render: function (rowData) { return (<span onClick={function () { return onClick(rowData); }}>
          <Trash />
        </span>); },
        },
    ];
    return columns;
};
export var getRunningLinesColumns = function (onClick) {
    var columns = [
        { field: 'text', title: 'Текст', style: { textAlign: 'left' } },
        {
            field: 'delete',
            style: { width: '4%', cursor: 'pointer' },
            render: function (rowData) { return (<span onClick={function () { return onClick(rowData); }}>
          <Trash />
        </span>); },
        },
    ];
    return columns;
};
export var getVideosColumns = function (deleteHandler, updateHandler) {
    var columns = [
        { field: 'name', title: 'Название', style: { textAlign: 'center' } },
        { field: 'originalname', title: 'Файл', style: { textAlign: 'center' } },
        {
            field: 'url',
            style: { width: '4%', cursor: 'pointer' },
            render: function (rowData) { return (<video className="content__video" loop controls style={{ width: '360px', height: '200px' }} src={"".concat(apiRoot).concat(rowData.url)}/>); },
        },
        {
            field: 'check',
            style: { width: '4%', cursor: 'pointer' },
            render: function (rowData, i) { return (<span className="service-polls__checkbox" style={{ background: i % 2 ? '#FFFFFF' : '#F6F6F6' }} onClick={function () { return updateHandler(rowData); }}>
          {rowData.show && <Check />}
        </span>); },
        },
        {
            field: 'delete',
            style: { width: '4%', cursor: 'pointer' },
            render: function (rowData) { return (<span onClick={function () { return deleteHandler(rowData); }}>
          <Trash />
        </span>); },
        },
    ];
    return columns;
};
