import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { createSubTab } from '@/utils';
import './tabs.css';
var ReportTabs = function (_a) {
    var data = _a.data, activeTab = _a.activeTab;
    var i18n = useTranslation().i18n;
    var pathname = useLocation().pathname;
    var renderTabs = useMemo(function () {
        return data.map(function (item) { return (<Link to={"/".concat(i18n.language).concat(createSubTab(item))} key={item.id} className={pathname.includes(item.id)
                ? 'tabs__tab tabs__tab_active'
                : 'tabs__tab'}>
        {item.name}
      </Link>); });
    }, [data, activeTab, pathname]);
    var renderSubTabs = useMemo(function () {
        var _a;
        return (_a = activeTab === null || activeTab === void 0 ? void 0 : activeTab.subtab) === null || _a === void 0 ? void 0 : _a.map(function (item) { return (<Link to={"/".concat(i18n.language).concat(createSubTab(item))} key={item.id} className={pathname.includes(item.id)
                ? 'tabs__tab tabs__tab_small tabs__tab_active'
                : 'tabs__tab tabs__tab_small'}>
        {item.name}
      </Link>); });
    }, [activeTab, data, pathname]);
    return (<div className="tabs">
      <div className="tabs__main">{renderTabs}</div>
      <div className="tabs__sub">{renderSubTabs}</div>
    </div>);
};
export default ReportTabs;
