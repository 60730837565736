import React from 'react';
import { ReactComponent as Upload } from '@/assets/images/upload.svg';
var ExportBtn = function (_a) {
    var onClick = _a.onClick;
    return (<button className="export__btn" onClick={onClick}>
    <Upload />
    <p className="export__btn__text">Экспортировать отчёт</p>
  </button>);
};
export default ExportBtn;
