import React from 'react';
import FilterRange from '@/pages/statistics/reports/filters/filterItems/filterRange';
import OperatorSelect from '@/pages/statistics/reports/filters/filterItems/operatorSelect';
import QueueSelect from '@/pages/statistics/reports/filters/filterItems/queueSelect';
import WplaceSelect from '@/pages/statistics/reports/filters/filterItems/wplaceSelect';
var MainFilter = function (_a) {
    var filtersData = _a.filtersData, handleSetFilters = _a.handleSetFilters, showFilters = _a.showFilters;
    var filtersMap = {
        reportRange: <FilterRange key={1}/>,
        workplaces: (<WplaceSelect filtersData={filtersData} handleSetFilters={handleSetFilters} key={2}/>),
        operators: (<OperatorSelect handleSetFilters={handleSetFilters} filtersData={filtersData} key={3}/>),
        queues: (<QueueSelect filtersData={filtersData} handleSetFilters={handleSetFilters} key={4}/>),
    };
    var renderFilters = function () {
        return showFilters.map(function (item) { return filtersMap[item]; });
    };
    return <div className="report_filters__main">{renderFilters()}</div>;
};
export default MainFilter;
