export default {
    dashboard: '/dashboard',
    statistics: '/statistics',
    reports: '/statistics/reports',
    'reports-tab': '/statistics/reports/:tab/:subtab?',
    consolidated: '/statistics/reports/сonsolidated',
    servicesReport: '/statistics/reports/services',
    survey: '/statistics/reports/survey',
    tickets: '/statistics/reports/tickets',
    source: '/statistics/reports/source',
    operators: '/statistics/reports/operators',
    wplaces: '/statistics/reports/wplaces',
    hourly: '/statistics/reports/hourly',
    quality: '/statistics/reports/quality',
    logs: '/statistics/reports/logs',
    archive: '/statistics/archive',
    registers: '/statistics/registers',
    'services-editor': '/services-editor',
    'services-tab': '/services-editor/services/:tab/:subtab?',
    services: '/services-editor/services',
    serviceList: '/services-editor/services/list',
    serviceQuestions: '/services-editor/services/questions',
    serviceSchedule: '/services-editor/services/schedule',
    serviceIntervals: '/services-editor/services/schedule/intervals',
    serviceDays: '/services-editor/services/schedule/days',
    servicePolls: '/services-editor/services/polls',
    serviceWeeks: '/services-editor/services/schedule/weeks',
    substatuses: '/services-editor/services/substatuses/',
    workplaces: '/services-editor/workplaces',
    serviceConditions: '/services-editor/conditions',
    constructor: '/constructor',
    components: '/components',
    users: '/components/users',
    operatorsBoard: '/components/board',
    serviceAreas: '/components/areas',
    system: '/system',
    parameters: '/system/parameters',
    storage: '/system/storage',
    copies: '/system/copies',
    sessions: '/system/sessions',
    about: '/system/about',
    content: '/content',
    'content-tab': '/content/:tab/',
    fonts: '/content/fonts',
    videos: '/content/videos',
    images: '/system/content/images',
    news: '/content/news',
    runningLines: '/content/runninglines',
    login: '/login',
};
