import loginEn from './en/login.json';
import en from './en/translation.json';
import loginRu from './ru/login.json';
import ru from './ru/translation.json';
export default {
    ru: {
        translation: ru,
        login: loginRu,
    },
    en: {
        translation: en,
        login: loginEn,
    },
};
