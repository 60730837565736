import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import locale from 'antd/es/locale/ru_RU';
import 'dayjs/locale/ru.js';
import dayjs from 'dayjs';
import Select from '@/components/input/select';
import DateTimePicker from '@/components/period/dateTimePicker';
import { dateFormat } from '@/constants/common';
import { defaultSelectOptions } from '@/pages/statistics/reports/filters/filterParametrs';
import { FilterStore } from '@/store';
var ServedDateFilters = function (_a) {
    var _b, _c;
    var handleSetFilters = _a.handleSetFilters;
    var filterParams = FilterStore.useState(function (s) { return s.initialParams; });
    var _d = useState(filterParams['create_data'] === 'all'
        ? ''
        : filterParams['create_data'].split(' ')[0]), startType = _d[0], setStartType = _d[1];
    var _e = useState(filterParams['close_data'] === 'all'
        ? ''
        : filterParams['close_data'].split(' ')[0]), endType = _e[0], setEndType = _e[1];
    var _f = useState(filterParams.startDate), startDate = _f[0], setStartDate = _f[1];
    var _g = useState(filterParams.endDate), endDate = _g[0], setEndDate = _g[1];
    var _h = useState(filterParams['create_data'] === 'all'
        ? undefined
        : (_b = defaultSelectOptions.find(function (el) {
            return el.value ===
                filterParams['create_data'].split(' ')[0].replace(/'/g, '');
        })) === null || _b === void 0 ? void 0 : _b.name), startValue = _h[0], setStartValue = _h[1];
    var _j = useState(filterParams['close_data'] === 'all'
        ? undefined
        : (_c = defaultSelectOptions.find(function (el) {
            return el.value ===
                filterParams['close_data'].split(' ')[0].replace(/'/g, '');
        })) === null || _c === void 0 ? void 0 : _c.name), endValue = _j[0], setEndValue = _j[1];
    var _k = useState(''), startName = _k[0], setStartName = _k[1];
    var _l = useState(''), endName = _l[0], setEndName = _l[1];
    var handleSelect = function (_a) {
        var option = _a.option, name = _a.name;
        if (name === 'create_data') {
            if (option.name === 'Отменить') {
                setStartValue(undefined);
                setStartType('');
                setStartName(name);
                setStartDate(filterParams.startDate);
                return;
            }
            setStartValue(option.name);
            setStartType(option.value);
            setStartName(name);
        }
        else if (name === 'close_data') {
            if (option.name === 'Отменить') {
                setEndValue(undefined);
                setEndType('');
                setEndName(name);
                setEndDate(filterParams.endDate);
                return;
            }
            setEndValue(option.name);
            setEndType(option.value);
            setEndName(name);
        }
    };
    var handleSetStartRange = function (values) {
        if (values) {
            var startDate_1 = values[0];
            var endDate_1 = values[1];
            var str = "> '".concat(startDate_1 === null || startDate_1 === void 0 ? void 0 : startDate_1.format(dateFormat), "'::timestamp and create_data::timestamp < '").concat(endDate_1 === null || endDate_1 === void 0 ? void 0 : endDate_1.format(dateFormat), "'::timestamp");
            handleSetFilters(startName, str);
        }
    };
    var handleSetEndRange = function (values) {
        if (values) {
            var startDate_2 = values[0];
            var endDate_2 = values[1];
            var str = "> '".concat(startDate_2 === null || startDate_2 === void 0 ? void 0 : startDate_2.format(dateFormat), "'::timestamp and close_data::timestamp < '").concat(endDate_2 === null || endDate_2 === void 0 ? void 0 : endDate_2.format(dateFormat), "'::timestamp");
            handleSetFilters(endName, str);
        }
    };
    useEffect(function () {
        var str = "".concat(startType, " '").concat(startDate, "'::timestamp");
        if (startType === '') {
            str = 'all';
        }
        handleSetFilters(startName, str);
    }, [startDate, startName, startType]);
    useEffect(function () {
        var str = "".concat(endType, " '").concat(endDate, "'::timestamp");
        if (endType === '') {
            str = 'all';
        }
        handleSetFilters(endName, str);
    }, [endDate, endName, endType]);
    return (<div className="report_filters__box">
      <p className="report_filters__title">Дата обслуживания</p>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Начало</p>
        <Select width={264} placeholder="Выбрать" name="create_data" value={startValue} onChange={handleSelect} options={defaultSelectOptions}/>
      </div>
      <div className="report_filters__input">
        {startType === 'range' ? (<>
            <p className="report_filters__input-text">Выбрать даты</p>
            <DatePicker.RangePicker onChange={handleSetStartRange} defaultValue={[
                dayjs(filterParams.startDate, dateFormat),
                dayjs(filterParams.endDate, dateFormat),
            ]} format={dateFormat} showTime locale={locale.DatePicker} className="report_filters__dates__range"/>
          </>) : (<>
            <p className="report_filters__input-text">Выбрать дату</p>
            <DateTimePicker setParentDate={setStartDate} defaultDate={filterParams.create_data !== 'all'
                ? filterParams.create_data.replace(/[a-z><=']|:{2,}/g, '')
                : filterParams.startDate}/>
          </>)}
      </div>
      <div className="report_filters__input">
        <p className="report_filters__input-text">Окончание</p>
        <Select width={264} placeholder="Выбрать" name="close_data" value={endValue} onChange={handleSelect} options={defaultSelectOptions}/>
      </div>
      <div className="report_filters__input">
        {endType === 'range' ? (<>
            <p className="report_filters__input-text">Выбрать даты</p>
            <DatePicker.RangePicker onChange={handleSetEndRange} defaultValue={[
                dayjs(filterParams.startDate, dateFormat),
                dayjs(filterParams.endDate, dateFormat),
            ]} format={dateFormat} showTime locale={locale.DatePicker} className="report_filters__dates__range"/>
          </>) : (<>
            <p className="report_filters__input-text">Выбрать дату</p>
            <DateTimePicker setParentDate={setEndDate} defaultDate={filterParams.close_data !== 'all'
                ? filterParams.close_data.replace(/[a-z><=']|:{2,}/g, '')
                : filterParams.endDate}/>
          </>)}
      </div>
    </div>);
};
export default ServedDateFilters;
