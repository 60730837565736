export var BoardTypes;
(function (BoardTypes) {
    BoardTypes["abt_matrix"] = "abt_matrix";
    BoardTypes["akme_udp"] = "akme_udp";
    BoardTypes["yanic_udp"] = "yanic_udp";
    BoardTypes["neuroniq_tcp"] = "neuroniq_tcp";
    BoardTypes["leto"] = "leto";
    BoardTypes["pcs"] = "pcs";
    BoardTypes["android_socket"] = "android_socket";
    BoardTypes["pcs_3tab"] = "pcs_3tab";
    BoardTypes["neuroniq"] = "neuroniq";
    BoardTypes["akis_serial"] = "akis_serial";
    BoardTypes["abit_serial"] = "abit_serial";
    BoardTypes["akis_udp"] = "akis_udp";
})(BoardTypes || (BoardTypes = {}));
