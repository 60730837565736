var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import paths from '@/constants/paths';
import { getToken } from '@/utils';
var PublicRoute = function (_a) {
    var Component = _a.component, restricted = _a.restricted, lang = _a.lang, rest = __rest(_a, ["component", "restricted", "lang"]);
    return (<Route {...rest} render={function (props) {
            return getToken() && restricted ? (<Redirect to={"".concat(lang).concat(paths.dashboard)}/>) : (<Component {...props}/>);
        }}/>);
};
export default PublicRoute;
